<button
  [ngClass]="fullWidth ? 'button-full-width' : ''"
  #tooltip="matTooltip"
  [matTooltip]="tooltipLabel"
  [matTooltipPosition]="toolTipPosition"
  [matTooltipHideDelay]="toolTipHideDelay"
  [attr.aria-label]="ariaLabel"
  mat-button
  [color]="color"
  [disabled]="disabled"
  (click)="clickButton.emit($event)"
  [style.color]="(themeService.isLightTheme$|async) ?'':'white'"
>
  <mat-label>{{ label }} </mat-label>
</button>
