<header>
  <mat-toolbar class="toolbar-container" color="primary">
    <div class="menu-btn-title-panel flex-row gap-title">
      <button
        type="button"
        [attr.aria-label]="'A11Y.LABELS.toggleSidenav' | translate"
        mat-icon-button
        (click)="toggleSidenav()">
        <mat-icon>menu</mat-icon>
      </button>
      <span class="mat-headline-6 no-margin">{{ title }}</span>
    </div>

    <div class="user-panel flex-row">
      <div class="user-dropdown">
        <ds-basic-link-button
          id="user-dropdown-button"
          [ariaLabel]="'A11Y.LABELS.userMenu' | translate"
          [matMenuTriggerFor]="menu"
          [textContent]="userInfo.userName" />
        <mat-menu #menu="matMenu">
          <div [style.color]="(themeService.isLightTheme$|async)?'black':'white'" class="user-info-box flex-col">
            <div class="user-info flex-col">
              <span class="mat-body-1 flex-row">{{ userInfo.userName }}</span>
              <span class="mat-caption flex-row">{{ userInfo.userEmail }}</span>
              <span class="mat-caption flex-row" *ngIf="userInfo.userRole">
                {{ 'LABELS.role' | translate }}: {{ userInfo.userRole }}
              </span>
              <span class="mat-caption flex-row" *ngIf="version">
                {{ 'LABELS.version' | translate }}: {{ version }}
              </span>
            </div>
            <ds-horizontal-divider></ds-horizontal-divider>
            <div class="user-info-box-buttons">
              <mat-nav-list>
                <mat-list-item *ngIf="hasTranslation">
                  <a class="user-info-box-button" (click)="openLanguageChangeDialog()">
                    <mat-icon class="material-icons-outlined">translate</mat-icon>
                    <span class="mat-body-1 no-margin">{{ 'BUTTONS.changeLanguages' | translate }}</span>
                  </a>
                </mat-list-item>
                <mat-list-item (click)="openPasswordChangeDialog()">
                  <a class="user-info-box-button">
                    <mat-icon>password</mat-icon>
                    <span class="mat-body-1 no-margin">{{ 'BUTTONS.changePassword' | translate }}</span>
                  </a>
                </mat-list-item>
                <mat-list-item *ngIf="hasDarkMode" (click)="toggleTheme()">
                  <a class="user-info-box-button">
                    <mat-icon class="material-icons-outlined">dark_mode</mat-icon>
                    <span>
                      <div class="flex justify-between">
                        {{ 'BUTTONS.changeTheme' | translate }}
                        <mat-slide-toggle (change)="toggleTheme($event)" [checked]="!isLightMode"></mat-slide-toggle>
                      </div>
                    </span>
                  </a>
                </mat-list-item>
                <mat-list-item (click)="logOut()">
                  <a class="user-info-box-button">
                    <mat-icon>logout</mat-icon>
                    <span class="mat-body-1 no-margin">{{ 'BUTTONS.logOut' | translate }}</span>
                  </a>
                </mat-list-item>
              </mat-nav-list>
            </div>
          </div>
        </mat-menu>
      </div>
    </div>
  </mat-toolbar>
</header>
