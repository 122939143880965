import { formatDate } from '@angular/common';
import { FieldTypes } from '../model/Enums/FieldTypesEnum';
import { ReservationNames } from '../model/Enums/ReservationTypesEnum';
import { Appointment } from '../model/Object/appointment';

const AGG_STATE_SUFFIX = '_aggState';
const APT_FIELD_SUFFIX = '_dateRange';

export function getTrafficLightLabelFromColor(statusColor: string) {
  switch (statusColor) {
    case 'green':
      return ReservationNames.GREEN;
    case 'orange':
      return ReservationNames.ORANGE;
    case 'red':
      return ReservationNames.RED;
    case 'mixed':
      return ReservationNames.MIXED;
    default:
      return '';
  }
}

export function isAppointmentList(pathKey: string): boolean {
  return typeof pathKey === 'string' && pathKey.endsWith(AGG_STATE_SUFFIX);
}

export function isAppointmentField(pathKey: string): boolean {
  return typeof pathKey === 'string' && pathKey.endsWith(APT_FIELD_SUFFIX);
}

export function convertNumFormatToDE(value: string): string {
  return value.replace(/[.]/g, ',');
}

export function formatAppointment(appointment: Appointment) {
  const from = formatDate(appointment.from, 'dd.MM.yyyy', 'en-US');
  const to = formatDate(appointment.to, 'dd.MM.yyyy', 'en-US');
  const name = appointment.name;
  const status = appointment.status;

  return `${from} - ${to}, ${name}, ${status}`;
}

export function getAddressField(this: any, lookupTo: string, fieldValue: any): string {
  const { address_country, address_location, address_street } = fieldValue;

  const name = lookupTo === FieldTypes.ADDRESS ? fieldValue['company'] || '' : '';
  const country = address_country ? address_country + '/' : '';
  const location = address_location ? address_location + '/' : '';
  const street = address_street || '';

  return name + country + location + street;
}
