<div class="add-dialog-wrapper">
  <div class="flex-row add-dialog-header">
    <h2 mat-dialog-title>
      {{ 'LABELS.create' + dataType | translate }}
    </h2>
    <ds-icon-button class="close-button" color="primary" icon="close" (clickButton)="onModalClose()" />
  </div>
  <div class="add-dialog-content" *ngIf="(formManager.loading$ | async) === false">
    <!-- user message for read only users -->
    <div
      *ngIf="!authService.hasWritePermissionsForDatatype(dataType)"
      class="flex-row no-write-permission-label maring-s">
      <label>{{ 'LABELS.noWritePermissionLabel' + dataType | translate }}</label>
    </div>
    <exp-form-tabs [dataType]="dataType" />
  </div>
  <div class="add-dialog-progress-bar-wrapper" *ngIf="formManager.loading$ | async">
    <mat-progress-bar mode="indeterminate" />
    <span class="flex-col add-dialog-progress-bar-info"> {{ 'MESSAGE.loadingObjectData' | translate }}</span>
  </div>

  <ds-horizontal-divider />
  <div class="dialog-actions" mat-dialog-actions>
    <ds-basic-button (clickButton)="onModalClose()" color="accent" label="{{ 'BUTTONS.cancel' | translate }}" />
    <ds-primary-button
      expThrottleClick
      [attr.throttleTime]="2000"
      icon="add"
      color="accent"
      [disabled]="!isFormTouched || !authService.hasWritePermissionsForDatatype(dataType)"
      (clickButton)="submitForm()"
      label=" {{ 'BUTTONS.save' | translate }}" />
  </div>
</div>
