<div class="dialog-container flex-col" *ngIf="!isLoading; else loadingState">
  <ds-dialog-header [title]="'LABELS.checkChange' | translate" (clickButton)="onModalClose()" />
  <div class="dialog-content">
    <div class="selected-label mat-subtitle-1">
      <span> {{ 'LABELS.selectedObject' | translate }}</span>
    </div>
    <div class="count-value mat-body-2">
      <span>{{ 'LABELS.number' | translate }}{{ editedObjects.length }}</span>
    </div>
    <ds-horizontal-divider />
    <div class="mat-subtitle-1 changed-fields">
      <span>{{ 'LABELS.changedFields' | translate }}</span>
    </div>
    <div>
      <div class="summary-container" *ngFor="let option of editedFields">
        <div class="mat-body-2 field-name">
          <span>{{ option.fieldName }}</span>
        </div>
        <div class="icon-container">
          <mat-icon
            [matTooltip]="option.fieldOfPath.option"
            [color]="getIconByFieldOption(option.fieldOfPath.option) === 'backspace' ? 'warn' : 'primary'"
            [ngClass]="option.fieldOfPath.option === postfixTranslation ? 'rotate-icon' : ''"
            [fontIcon]="getIconByFieldOption(option.fieldOfPath.option)"></mat-icon>
        </div>
        <div class="mat-body-2 field-value">
          <div [innerHTML]="customizeSummary(option)"></div>
        </div>
      </div>
    </div>
  </div>
  <ds-horizontal-divider />
  <ds-dialog-actions
    closeBtnLabel="{{ 'BUTTONS.cancel' | translate }}"
    submitBtnLabel="{{ 'BUTTONS.confirm' | translate }}"
    (clickButton)="onConfirm()"
    (closeBtnClicked)="onModalClose()"
    [isSubmitDisabled]="!authService.hasWritePermissionsForDatatype(this.data.dataType)"
  />
</div>
<ng-template #loadingState>
  <h2 mat-dialog-title>
    {{ 'LABELS.save-changes' | translate }}
  </h2>
  <div class="loading-indicator">
    <mat-progress-bar mode="indeterminate" />
  </div>
</ng-template>
