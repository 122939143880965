import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { ButtonsModule, DividerModule, SelectDialogComponent, ThemeService } from '@frontends/designsystem';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ProjectData, SidenavItem, Tool, TupleMenuViewData } from '../../../model';
import { NavType } from '../../../model/Types/NavType';
import { CmnLocalStorageService } from '../../../services';
import { CmnApiClientService } from '../../../services/exhibition-api/cmn-api-client.service';
import { CmnSessionStorageService } from '../../../services/storage/cmn-session-storage.service';
import { StewardService } from '../../../services/storage/steward.service';
import { getNavType } from '../../../HelperFunctions/helperFunctions';

const ROUTE_REGEX = /([A-Z]*)/gi;

@Component({
  selector: 'cmn-sidenav',
  standalone: true,
  imports: [
    CommonModule,
    MatSidenavModule,
    DividerModule,
    MatListModule,
    MatIconModule,
    TranslateModule,
    MatMenuModule,
    MatButtonModule,
    ButtonsModule,
    RouterModule,
  ],
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavComponent implements OnInit {
  @Input() public navType: NavType = 'ITEM';
  @Input() public sidenavItems!: SidenavItem[];
  @Input() public activeRoute!: string;
  @Input() public hasSettings: boolean | null = false;
  @Input() public settingRoute!: string;
  @Input() public specialRoleTitle!: string;
  @Input() public specialRoleItems!: SidenavItem[];
  @Input() public hasSpecialRole = false;
  @Input() public hasTools = false;
  public hasProjects = false;
  public menuItems: TupleMenuViewData[] = [];
  public tools!: Tool;
  public selectedTool!: string;
  public projects!: ProjectData[];
  public selectedProject!: ProjectData;
  public projectsIconsList: { [key: string]: string } = {};

  constructor(
    private router: Router,
    private matDialog: MatDialog,
    private translateService: TranslateService,
    private cmnLocalStorageService: CmnLocalStorageService,
    private cmnSessionStorageService: CmnSessionStorageService,
    private stewardService: StewardService,
    private cmnApiClient: CmnApiClientService,
    private cdr: ChangeDetectorRef,
    protected themeService: ThemeService,
  ) {
    this.navType = getNavType(this.navType, this.cmnLocalStorageService);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.navType === 'MENU') {
          const matches = this.router.url.match(ROUTE_REGEX);
          this.activeRoute = matches?.[1] ?? '';
        }
        if (!this.projects || this.projects.length === 0) {
          this.projects = this.cmnSessionStorageService.getProjects();
        }
        if (!this.selectedProject) {
          this.selectedProject = this.cmnLocalStorageService.getProject();
        }
        if (this.navType === 'MENU' && !this.tools) {
          this.tools = this.cmnSessionStorageService.getTools();
        }
        this.cdr.markForCheck();
      }
    });
  }

  ngOnInit(): void {
    this.selectedProject = this.cmnLocalStorageService.getProject();
    this.tools = this.cmnSessionStorageService.getTools();
    this.projects = this.cmnSessionStorageService.getProjects();
    if (this.projects) {
      this.projects.forEach((prj) => {
        this.projectsIconsList[prj.project] = prj.artefact || 'category';
      });
    }
    this.selectedTool = this.selectedProject.productType || this.cmnSessionStorageService.getProductType();
    if (this.navType === 'MENU') {
      this.hasTools = true;
      this.hasProjects = true;
      this.getMenuDataForSideNav();
    }
  }

  public getMenuDataForSideNav(): void {
    this.cmnApiClient.getMenuData(this.selectedProject).subscribe((data) => {
      this.menuItems = data;
      this.cdr.detectChanges();
    });
  }

  public isProjectNavItemActive(prjNavItem: ProjectData): boolean {
    return prjNavItem.project === this.selectedProject.project;
  }

  //navigate between toolbox projects - 'rahmen', 'kisten'...
  public switchToProject(project: ProjectData): void {
    this.setPreviousRoute();
    this.cmnLocalStorageService?.setProject(project);
    this.openUrl('./');
  }

  public isNavItemActive(route: string): boolean {
    return route === this.activeRoute;
  }

  //navigate between data types - 'OBJECT', 'ADDRESS' 'INSTITUTE'
  public switchToRoute(item: string): void {
    this.setPreviousRoute();
    this.activeRoute = item;
    this.router.navigateByUrl('/' + item).then(() => {
      this.router.navigate([item]);
    });
  }

  // navigate to settings
  public navigateToSite(route: string): void {
    this.openUrl('/' + route);
  }

  // navigate between Ex-planner/ ToolBox/ Museums
  public navigateToTool(key: string): void {
    let url = this.tools[key];

    if (url) {
      if (!url.includes('https://')) {
        url = 'https://' + url;
        this.openUrl(url);
      }
      if (window.location.href.includes(url)) {
        window.location.href = url;
      }
    }
  }

  public openUrl(url: string) {
    window.open(url, '_self');
  }

  public switchProject(): void {
    if (!this.projects || this.projects.length === 0) {
      this.projects = this.cmnSessionStorageService.getProjects();
    }
    const dialogRef = this.matDialog.open(SelectDialogComponent, {
      role: 'dialog',
      autoFocus: 'dialog',
      data: {
        title: this.translateService.instant('BUTTONS.projectSwitch'),
        closeBtnText: this.translateService.instant('BUTTONS.cancel'),
        selectOptions: this.stewardService.generateProjectSelectOptions(this.projects),
      },
    });

    dialogRef.afterClosed().subscribe((selected) => {
      if (selected) {
        //this.cmnLocalStorageService.onChangeProject();
        this.cmnLocalStorageService.setProject(this.stewardService.findProjectInSessionStorage(selected));
        this.router.navigateByUrl('/').then(() => {
          this.cmnSessionStorageService.clearSessionStorage();
          window.location.reload();
        });
      }
    });
  }

  public getItemIcon(item: string): string {
    return this.projectsIconsList[item];
  }

  private setPreviousRoute() {
    this.cmnSessionStorageService.setPreviousRoute(`${this.router.url}/${this.selectedProject.project}`);
  }
}
