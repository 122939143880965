<button
  [ngClass]="fullWidth ? 'button-full-width' : ''"
  #tooltip="matTooltip"
  [matTooltip]="tooltipLabel"
  [matTooltipPosition]="toolTipPosition"
  [matTooltipHideDelay]="toolTipHideDelay"
  [attr.aria-label]="ariaLabel"
  mat-icon-button
  [color]="color"
  [disabled]="disabled"
  (click)="clickButton.emit($event)">
  <mat-icon [style.color]="(themeService.isLightTheme$|async) ?'':'white'"
            class="material-symbols-outlined">{{ icon }}
  </mat-icon>
</button>
