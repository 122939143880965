<mat-form-field [ngClass]="{ 'mdc-text-field--invalid': matchError }" [color]="color" appearance="outline">
  <mat-label>{{ label }} </mat-label>
  <input
    [minlength]="minlength"
    [pattern]="pattern"
    data-testid="password-input"
    [attr.aria-label]="ariaLabel"
    matInput
    [placeholder]="placeholder"
    [(ngModel)]="passwordValue"
    [type]="hide ? 'password' : 'text'"
    inputMode="text"
    (input)="onChangeInput()"
    (change)="onChange()"
    [required]="required" />
  <button
    data-testid="pw-show-btn"
    mat-icon-button
    matSuffix
    (click)="hide = !hide"
    [attr.aria-label]="ariaLabelHideBtn"
    [attr.aria-pressed]="hide">
    <mat-icon
      [style.color]="(themeService.isLightTheme$|async) ?'':'white'">{{ hide ? 'visibility_off' : 'visibility' }}
    </mat-icon>
  </button>
  <mat-error [class.ng-invalid]="showError">{{ errorMessage }}</mat-error>
  <span class="matchError" *ngIf="matchError">{{ matchPasswordMessage }}</span>
</mat-form-field>
