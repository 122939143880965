<div class="container" *ngIf="!valueOnly ; else showReadonly">
  <ds-icon-badge *ngIf="internal" [iconClass]="iconClass" [tooltip]="tooltip"></ds-icon-badge>
  <mat-form-field [class.ng-invalid]="showError" appearance="outline" [color]="color">
    <mat-label>{{ label }} </mat-label>
    <input
      data-testid="input"
      [attr.aria-label]="ariaLabel"
      matInput
      [placeholder]="placeholder"
      [value]="files"
      [(ngModel)]="files"
      [disabled]="disabled"
      (input)="onChangeInput()"
      [required]="required" />
    <!-- <mat-hint aria-label="Input Hint" *ngIf="showHint"> {{ hintMessage }} </mat-hint>
    <mat-error aria-label="Error Hint" *ngIf="showError">{{ errorMessage }}</mat-error> -->
  </mat-form-field>
</div>
<ng-template #showReadonly >
    <div class="column-container">
      <span class="mat-subtitle-2">{{label}}</span>
      <span class="mat-body-2">{{ files  ? files : '-' }}</span>
    </div>
</ng-template>
