import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ProjectData } from '../../model/Data/ProjectData';
import { ProductType } from '../../model/Types/ProductType';
import { BaseStorageService, StorageType } from '../base/base-storage.service';

const PRODUCT_TYPE = 'productType';
const TOOL_SITES = 'toolSites';
const PROJECT_LIST = 'projectList';
const PREVIOUS_ROUTE = 'previousRoute';

@Injectable({
  providedIn: 'root',
})
export class CmnSessionStorageService extends BaseStorageService {
  private tools!: { [key: string]: string };
  private productType!: ProductType;
  private projectList!: ProjectData[];
  protected projectsListKey: string = PROJECT_LIST;

  constructor(logger: NGXLogger) {
    super(logger, StorageType.SESSION);
  }

  public setProjects(projectList: ProjectData[]) {
    this.projectList = projectList;
    this.setItem(this.projectsListKey, projectList);
  }

  public getProjects(): ProjectData[] {
    if (this.projectList) {
      return this.projectList;
    } else {
      const tempProjectList = this.getItem(this.projectsListKey);
      if (tempProjectList) {
        const prjList = JSON.parse(tempProjectList);
        this.projectList = prjList;
        return prjList;
      } else {
        this.logger.warn(`NO PROJECTS SET`);
        return [];
      }
    }
  }

  public removeProjects(): void {
    this.projectList = [];
    this.removeItem(this.projectsListKey);
  }

  /* ------TOOLS STORAGE ---------- */
  public setTools(tiles: { [key: string]: string }) {
    this.tools = tiles;
    this.setItem(TOOL_SITES, tiles);
  }

  public getTools() {
    if (!this.tools) {
      const tmpTools = this.getItem(TOOL_SITES);
      this.tools = tmpTools ? JSON.parse(tmpTools) : null;
    }

    return this.tools;
  }

  /* ------PRODUCT STORAGE START ---------- */

  public setProductType(type: ProductType) {
    this.productType = type;
    this.setItem(PRODUCT_TYPE, type);
  }

  public getProductType(): ProductType {
    if (this.productType) {
      return this.productType;
    } else {
      const product = JSON.parse(this.getItem(PRODUCT_TYPE) || '{}');
      this.productType = product;
      return product;
    }
  }

  public clearSessionStorage() {
    sessionStorage.clear();
  }

  /* ---------- SET PREVIOUS ROUTE ------------ */

  setPreviousRoute(route: string) {
    this.setItem(PREVIOUS_ROUTE, route);
  }

  getPreviousRoute() {
    const previousRoute = this.getItem(PREVIOUS_ROUTE);
    return previousRoute ? JSON.parse(previousRoute) : null;
  }
}
