import { Injectable } from '@angular/core';
import { GenericMetaForm, InitData, ProductTypes } from '@frontends/commons';
import { GenericMasterFormular, UiControllerService } from '@frontends/exp-apiclient';
import { map, Observable } from 'rxjs';
import { DataType } from '../../../app/model/types/DataTypes';
import { DataTypes } from '../../model/Enums/DataTypesEnum';
import { FieldTypes } from '../../model/Enums/FieldTypesEnum';

@Injectable({
  providedIn: 'root',
})
export class UiApiClientService {
  constructor(private uiController: UiControllerService) {}

  public getInitData(): Observable<InitData> {
    return this.uiController.getInit().pipe(
      map(
        (beInit: import('@frontends/exp-apiclient').InitData) =>
          ({
            type: beInit.type ?? ProductTypes.EXPLANNER,
            projects:
              beInit.projects?.map((beProject: import('@frontends/exp-apiclient').ProjectData) => ({
                docId: beProject.docId ?? '',
                project: beProject.project ?? '',
                name: beProject.name ?? '',
                active: beProject.active ?? false,
                ...beProject,
              })) ?? [],
            tiles: beInit.tiles ?? [],
            permissions: beInit.permissions ?? {},
            ...beInit,
          }) as InitData,
      ),
    );
  }

  public getForm(projectIdentifier: string, dataType: DataType): Observable<GenericMetaForm> {
    return this.uiController.getFormular(projectIdentifier, dataType).pipe(
      map((beMasterForm: GenericMasterFormular) => ({
        docId: beMasterForm.docId ?? '',
        title: beMasterForm.title ?? '',
        forms:
          beMasterForm.forms?.map((beForm) => ({
            title: beForm.title ?? '',
            internal: beForm.internal ?? false,
            active: beForm.active ?? false,
            fields:
              beForm.fields?.map((beField) => ({
                name: beField.name ?? '',
                path: beField.path ?? '',
                type: beField.type ?? FieldTypes.STRING,
                required: beField.required ?? false,
                internal: beField.internal ?? false,
                disabled: beField.disabled ?? false,
                valueOnly: beField.valueOnly ?? false,
                options: beField.options ?? [],
                ...beField,
              })) ?? [],
          })) ?? [],
        dataType: beMasterForm.dataType ?? DataTypes.OBJECT,
        headerFields: beMasterForm.headerFields ?? [],
        imageField: beMasterForm.imageField ?? '',
        titleField: beMasterForm.titleField ?? 'basis_humanId',
      })),
    );
  }

  public getFields(projectIdentifier: string, dataType: DataType): Observable<{ [key: string]: string }> {
    return this.uiController.getFieldsFull(projectIdentifier, dataType);
  }

  public getLabels(projectIdentifier: string, dataType: DataType): Observable<{ [key: string]: string }> {
    return this.uiController.getAllLabel(projectIdentifier, dataType);
  }
}
