<mat-divider />
<div class="dialog-actions-wrapper" mat-dialog-actions>
  <ds-basic-button
    class="dialog-close-btn"
    color="accent"
    [label]="closeBtnLabel"
    (clickButton)="onCloseBtnClicked()" />
  <ds-primary-button
    *ngIf="submitBtnLabel"
    icon="add"
    color="accent"
    [disabled]="isSubmitDisabled"
    (click)="clickButton.emit($event)"
    [label]="submitBtnLabel" />
</div>
