import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'exp-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
  @Output() save = new EventEmitter<boolean>();
  title?: string;
  message?: string;
  buttonYes?: string;
  buttonNo?: string;
  dataType?: string;
  isInfo = false;
  constructor(public dialogRef: MatDialogRef<AlertComponent>, @Inject(MAT_DIALOG_DATA) public data: AlertDialogModel) {
    this.title = data.title;
    this.message = data.message;
    this.isInfo = data.isInfo;
    this.buttonYes = data.buttonYes;
    this.buttonNo = data.buttonNo;
    this.dataType = data.dataType;
  }

  onCommand(): void {
    this.save.emit(false);
  }
  onCancel(): void {
    this.dialogRef.close(false);
  }
  handleSave(): void {
    this.save.emit(true);
  }
}
export class AlertDialogModel {
  constructor(
    public title: string,
    public message: string,
    public isInfo: boolean,
    public buttonYes?: string,
    public buttonNo?: string,
    public dataType?: string
  ) {}
}
