/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, forwardRef, Host, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GenericFormField } from '../../model/GenericFormField';
import { ThemeColors } from '../../types/colors';
import { ThemeService } from './../../services/theme/theme.service';

@Component({
  template: '',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseFieldComponent),
      multi: true,
    },
  ],
})
export abstract class BaseFieldComponent implements ControlValueAccessor, OnInit {
  @Input() public formControlName!: string;
  @Input() public fieldDefinition!: GenericFormField;

  @Input() public value: string | Date | number | boolean | null = '';

  @Input() public label = '';
  @Input() public tooltip = '';
  @Input() public ariaLabel = '';

  @Input() public showError = false;
  @Input() public errorMessage = '';
  @Input() public errorAriaLabel = '';

  @Input() public showHint = false;
  @Input() public hintMessage = '';
  @Input() public hintAriaLabel = '';

  @Input() public iconClass = 'security';
  @Input() public internal = false;
  @Input() public disabled = false;
  @Input() public required = false;
  @Input() public valueOnly = false;

  @Input() public color: ThemeColors = 'accent';

  public control: FormControl = new FormControl();

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer: ControlContainer,
    protected themeService?: ThemeService,
  ) {}

  ngOnInit() {
    if (this.controlContainer && this.controlContainer.control) {
      if (this.formControlName) {
        this.control = this.controlContainer.control.get(this.formControlName) as FormControl;
      } else {
        console.warn('Missing FormControlName directive from host element of the component', this.formControlName);
      }
    } else {
      console.warn("Can't find parent FormGroup directive, FormControlName undefined");
    }

    if (this.fieldDefinition) {
      this.internal = this.fieldDefinition.internal ? this.fieldDefinition.internal : false;
      this.required = this.fieldDefinition.required ? this.fieldDefinition.required : false;
      this.disabled = this.fieldDefinition.disabled ? this.fieldDefinition.disabled : false;
      this.showError = this.fieldDefinition.required ? this.fieldDefinition.required : false;
    }
  }

  public onBlur() {
    this.onChange(this.value);
    this.onTouched();
  }

  // Implement the ControlValueAccessor methods
  protected onChange: (value: any) => void = () => {};
  protected onTouched: () => void = () => {};

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
