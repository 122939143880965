<div class="flex-col">
  <div class="dialog-header">
    <h2 mat-dialog-title class="dialog-header-label">{{ data?.hierarchyName }}</h2>
    <ds-icon-button class="close-button" icon="close" color="primary" mat-dialog-close></ds-icon-button>
  </div>
  <div class="dialog-content" mat-dialog-content>
    <exp-tree-check-filter
      [resultsLength]="data.resultsLength"
      [hierarchyTreeData]="
      data.resultsLength
          ? data?.hierarchyElement?.value
          : sessionStorageService.getHierarchyStructure(data?.hierarchyKey).value
      "
      (hierarchyQueryChanged)="hierarchyQueryChanged($event)"
      (selectedHierarchyDataChanged)="selectedHierarchyDataChanged($event)"
      [dataType]="data?.dataType"
      [hierarchyKey]="data?.hierarchyKey"></exp-tree-check-filter>
  </div>
  <ds-horizontal-divider></ds-horizontal-divider>
  <div class="dialog-actions" mat-dialog-actions>
    <ds-basic-button color="accent" mat-dialog-close label="{{ 'BUTTONS.cancel' | translate }}"></ds-basic-button>
    <ds-primary-button
      color="accent"
      (click)="safeChecklistSelection()"
      label="{{ 'BUTTONS.done' | translate }}"></ds-primary-button>
  </div>
</div>
