<div class="flex-row">
  <ng-container *ngIf="data.docType === 'application/pdf'; else nonPdf">
    <ngx-doc-viewer [url]="data.docUrl" viewer="pdf" style="width: 50vw; height: 70vh"></ngx-doc-viewer>
  </ng-container>
  <ng-template #nonPdf>
    <div>
      <h2>{{ 'MESSAGE.warning' | translate }}</h2>
      <p>{{ 'MESSAGE.WARNING_MESSAGE.unsupportedFiletype' | translate }}</p>
      <ds-basic-button (clickButton)="onClose()" label="{{ 'MESSAGE.ok' | translate }}" />
    </div>
  </ng-template>
  <mat-icon class="close-icon" (click)="onClose()">close</mat-icon>
</div>
