/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService, ObjectData } from '@frontends/commons';
import { NGXLogger } from 'ngx-logger';
import { filter, take } from 'rxjs';
import { DataType } from '../../../app/model/types/DataTypes';
import { AlertService } from '../../../app/services/alert.service';

import { FormManagerService } from '../../../app/services/form/form-manager.service';
import { LocalStorageService } from '../../../app/services/storage/local-storage.service';
@Component({
  selector: 'exp-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss'],
  providers: [FormManagerService],
})
export class EditDialogComponent implements OnInit {
  public dataType!: DataType;
  public isFormTouched = false;
  public isSubmitted = false;
  public hasWritePermissions!: boolean;
  public projectIdentifier!: string;

  private workingObject: ObjectData = {};
  private objectId!: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { lookupToProject: string; lookupToDataType: DataType; lookupToObjectId: string },
    private dialogRef: MatDialogRef<EditDialogComponent>,
    private alert: AlertService,
    public authService: AuthService,
    public formManager: FormManagerService,
    private logger: NGXLogger,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.initializer();

    this.formManager.formTouched$.subscribe((touched) => {
      this.isFormTouched = touched;
    });
    this.formManager.submitted$.subscribe((submitted) => {
      this.isSubmitted = submitted;
      this.logger.debug(`[ADD-DIALOG]: FORM SUBMITED: ${submitted}`);
      if (submitted) {
        this.dialogRef.close(this.formManager.getChangeObject());
      }
    });
    this.setupCustomDialogOnCloseEvent();
    this.hasWritePermissions = this.authService.hasWritePermissionsForDatatype(this.dataType, this.projectIdentifier);
    this.logger.debug('[EDIT-DIALOG]: INITIALIZED', this);
  }

  private initializer(): void {
    this.projectIdentifier = this.data.lookupToProject
      ? this.data.lookupToProject
      : this.localStorageService.getProject()?.project || '';
    this.dataType = this.data.lookupToDataType;
    this.objectId = this.data.lookupToObjectId;

    this.formManager.prepareFormData(this.projectIdentifier, this.dataType, this.objectId);
    this.formManager.loading$.subscribe((loading) => {
      if (!loading) {
        this.logger.debug(`[EDIT-DIALOG]: FROM MANAGER REPARED DATA`);
        this.workingObject = this.formManager.getWorkingObject();
        this.dialogRef.updateSize('980px', '90vh');
      }
    });
  }

  private setupCustomDialogOnCloseEvent(): void {
    this.dialogRef.disableClose = true;
    this.dialogRef.backdropClick().subscribe(() => this.onModalClose());
    this.dialogRef
      .keydownEvents()
      .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
      .subscribe(() => this.onModalClose());
  }

  public onModalClose(): void {
    if (!this.isFormTouched) {
      return this.dialogRef.close();
    }

    const alertDialogRef = this.alert.confirmDiscardDialogChanges();
    alertDialogRef.componentInstance.save.pipe(take(1)).subscribe((isCanDiscard) => {
      if (isCanDiscard) {
        this.dialogRef.close();
      }
      alertDialogRef.close();
    });
  }

  public submitForm(): void {
    this.logger.debug(`[EDIT-DIALOG]: SUBMIT OBJECT`);
    this.formManager.submit('EDIT', this.dataType, this.projectIdentifier);
    this.dialogRef.close(this.formManager.getChangeObject());
  }
}
