<div class="flex-row app-container">
  <mat-sidenav-container class="sidenav-container" (backdropClick)="onBackdropClick()">
    <mat-sidenav
      (click)="destroyFocus()"
      #sidenav
      class="sidenav"
      fixedInViewport
      [attr.role]="breakpointsService.isSmallScreen || breakpointsService.isMediumScreen ? 'dialog' : 'navigation'"
      [mode]="breakpointsService.isSmallScreen || breakpointsService.isMediumScreen ? 'over' : 'side'"
      [opened]="(authService.userIsLoggedIn$ | async) && breakpointsService.isSideNavOpen">
      <cmn-sidenav
        *ngIf="authService.userIsLoggedIn$ | async"
        [hasTools]="true"
        [hasSettings]="authService.isMgmtUser$ | async"
        [settingRoute]="'manage'" />
    </mat-sidenav>
    <mat-sidenav-content
      class="sidenav-content"
      [ngClass]="{
        'sidebar-open':
          breakpointsService.isSideNavOpen &&
          !breakpointsService.isSmallScreen &&
          !breakpointsService.isMediumScreen &&
          (authService.userIsLoggedIn$ | async)
      }">
      <cmn-toolbar
        class="toolbar"
        *ngIf="authService.userIsLoggedIn$ | async"
        [version]="version"
        (sidenavToggled)="sidenav.toggle()" />
      <div [ngClass]="(authService.userIsLoggedIn$ | async) ? 'content' : 'login-content'">
        <router-outlet />
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
