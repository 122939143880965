<div class="flex-row search-dialog-header">
  <h1 class="search-dialog-title">
    {{ 'LABELS.fieldSearch' | translate }}
  </h1>
  <ds-icon-button [mat-dialog-close] class="close-button" color="primary" icon="close" />
</div>
<div *ngIf="userHasChangedSearchField" class="search-dialog-warning">
  <mat-icon>info_outline</mat-icon>
  <span>{{ 'MESSAGE.WARNING_MESSAGE.noManualChangesTaken' | translate }}</span>
</div>
<form [formGroup]="formGroup">
  <div class="search-dialog-fields-wrapper">
    <ng-container formArrayName="searchFieldsRow" *ngFor="let row of formDataRows?.controls; let i = index">
      <div [formGroupName]="i" class="search-dialog-row" (keydown.enter)="$event.preventDefault()">
        <div class="search-dialog-fields">
          <ds-search-select
            formControlName="selectControl"
            [label]="'LABELS.selectField' | translate"
            [options]="fieldNames ? fieldNames : []"
            type="select"
            [required]="!!getSelectControl(i)?.touched && !!getSelectControl(i)?.hasError('required')" />
          <ds-input
            [label]="'LABELS.searchTerm' | translate"
            formControlName="inputControl"
            [errorMessage]="'MESSAGE.WARNING_MESSAGE.requiredFields' | translate"
            [showError]="!!getSelectControl(i)?.touched && !!getSelectControl(i)?.hasError('required')"
            (keydown.enter)="submitForm()" />
        </div>
        <ds-icon-button
          (clickButton)="removeSearchField(i)"
          *ngIf="showOperatorsSelect"
          class="close-button"
          color="primary"
          icon="close"
          (keydown.enter)="$event.preventDefault()" />
      </div>
    </ng-container>
    <ds-basic-icon-badge-button
      label="{{ 'LABELS.addFields' | translate }}"
      (clickButton)="addSearchField()"
      icon="add"
      buttonColor="accent" />
  </div>

  <ng-container *ngIf="showOperatorsSelect">
    <ds-horizontal-divider />
    <div class="search-dialog-operator-wrapper">
      <h2>{{ 'LABELS.searchOperator' | translate }}:</h2>
      <mat-form-field appearance="outline">
        <mat-label>Operator</mat-label>
        <mat-select formControlName="operator">
          <mat-option value="AND">{{ 'LABELS.andSearchOperator' | translate }}</mat-option>
          <mat-option value="OR">{{ 'LABELS.orSearchOperator' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>
  <ds-horizontal-divider />
  <div class="dialog-actions" mat-dialog-actions>
    <ds-basic-button [mat-dialog-close] color="accent" label="{{ 'BUTTONS.cancel' | translate }}" />
    <ds-primary-button
      expThrottleClick
      [attr.throttleTime]="2000"
      icon="add"
      [disabled]="!formGroup.valid"
      color="accent"
      (clickButton)="submitForm()"
      label=" {{ 'BUTTONS.search' | translate }}" />
  </div>
</form>
