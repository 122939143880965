import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ds-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
})
export class LinkComponent {
  @Input() link = '';
  @Input() target: '_self' | '_blank' | '_parent' | '_top' = '_self';
  @Input() text = '';
  @Input() icon = '';
  @Output() clickLink = new EventEmitter<MouseEvent>();
}
