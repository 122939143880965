/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldName, GenericFormField } from '@frontends/commons';
import { TranslateService } from '@ngx-translate/core';
import { DataType } from '../../../../app/model/types/DataTypes';

@Component({
  selector: 'exp-hierarchy',
  templateUrl: './hierarchy.component.html',
  styleUrls: ['./hierarchy.component.scss'],
})
export class HierarchyComponent implements OnInit {
  @Input() hierarchyFormGroup!: FormGroup;
  @Input() field!: GenericFormField;
  @Input() fieldNames: FieldName = {};
  @Input() dataType?: DataType;
  @Input() isRequired = false;
  controlNames: string[] = [];
  options: { [key: string]: string[] } = {};

  private existingValues: any;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.controlNames = Object.keys(this.hierarchyFormGroup.controls);
    this.initializeOptions();
    this.existingValues = this.hierarchyFormGroup.value;
    this.setExistingValues();
  }

  getErrorMessage(field: GenericFormField): string {
    if (field.name) {
      return field.required ? field.name.concat(' ').concat(this.translateService.instant('ERROR.required')) : '';
    } else {
      return '';
    }
  }

  getFieldLabel(field: GenericFormField): string {
    if (field.path) {
      return this.fieldNames[field.path]
        ? this.fieldNames[field?.path]
        : this.translateService.instant(this.dataType + '.' + field?.path);
    } else {
      return '';
    }
  }

  private initializeOptions(): void {
    if (!this.isRequired) {
      this.hierarchyFormGroup.controls['l1'].setValidators(null);
    }

    if (this.field.hierarchy) {
      this.options['l1'] = Object.keys(this.field.hierarchy);
    }
    this.controlNames.forEach((name, index) => {
      if (index > 0) {
        this.options[name] = []; // initialize empty arrays for subsequent dropdowns
      }
    });
  }

  updateOptions(index: number) {
    if (index >= this.controlNames.length) {
      return;
    }

    if (!this.hierarchyFormGroup.value[this.controlNames[index]]) {
      // If the dropdown is cleared, reset all subsequent dropdowns
      for (let i = index + 1; i < this.controlNames.length; i++) {
        this.hierarchyFormGroup.controls[this.controlNames[i]].setValue(undefined);
        this.options[this.controlNames[i]] = [];
      }
      return;
    }
    let currentObj = this.field.hierarchy;
    for (let i = 0; i <= index; i++) {
      const key = this.hierarchyFormGroup.value['l' + (i + 1)];
      if (currentObj) {
        currentObj = currentObj[key];
      }
    }

    this.options[this.controlNames[index + 1]] = Object.keys(currentObj || {});

    // Clear selections for subsequent dropdowns and their options
    for (let i = index + 2; i < this.controlNames.length; i++) {
      this.hierarchyFormGroup.controls[this.controlNames[i]].setValue(undefined);
      this.options[this.controlNames[i]] = [];
    }
  }

  private setExistingValues(): void {
    let currentObj = this.field.hierarchy;
    this.controlNames.forEach((name, index) => {
      if (index > 0) {
        const key = this.existingValues['l' + index];
        if (currentObj) {
          if (key && currentObj[key]) {
            currentObj = currentObj[key];
            this.options[name] = Object.keys(currentObj || {});
          }
        }
      }
    });
  }
}
