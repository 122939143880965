/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable no-underscore-dangle */
import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService, DataType, MinMax, ObjectData } from '@frontends/commons';

import { KeyValue } from '@angular/common';
import { ResponseObjectConcreteData } from '@frontends/exp-apiclient';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { FilterData } from '../../../model/Data/FilterData';
import { FieldTypes } from '../../../model/Enums/FieldTypesEnum';
import { Trafficlight } from '../../../model/Enums/Trafficlight';
import { Fieldtype } from '../../../model/Form/FieldIndex';
import { TColor } from '../../../model/types/Colors';
import { AlertService } from '../../../services/alert.service';
import { ApiClientService } from '../../../services/api-client.service';
import { BreakpointsService } from '../../../services/breakpoints.service';
import { FormService } from '../../../services/form/form.service';
import { LocalStorageService } from '../../../services/storage/local-storage.service';
import { SessionStorageService } from '../../../services/storage/session-storage.service';
import { TranslateHelpService } from '../../../services/translate-help.service';
import { getTrafficLightLabelFromColor, isAppointmentList } from '../../../utils/helperFunctions';
import { FilterDialogComponent } from '../filter-dialog/filter-dialog.component';
import { OverviewTableComponent } from '../overview-table/overview-table.component';

@Component({
  selector: 'exp-facets-filter',
  templateUrl: './facets-filter.component.html',
  styleUrls: ['./facets-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacetsFilterComponent implements OnInit {
  @Input()
  dataType!: DataType;

  @Input()
  tableComponent!: OverviewTableComponent;

  @Input()
  overviewTable!: OverviewTableComponent;

  @Input()
  fieldNames: ObjectData = [];

  @Input()
  resultsLength!: number;

  @Input()
  quickHierarchyFacets!: ResponseObjectConcreteData['hierarchyFacets'];

  @Input()
  quickMinMaxFacets!: ResponseObjectConcreteData['minMaxFacets'];

  @Input()
  minMaxFilters: ResponseObjectConcreteData['minMaxFacets'] = {};

  @Input()
  quickFilterFacets: Map<string, Map<string, number>> = new Map();

  @Input()
  quickFilterReq: { [key: string]: Array<string> } = {}; // request Object to request quickfilter Data

  @Input()
  facets!: string[];

  @Input()
  facetsAreLoading!: boolean;

  @Input()
  pageIndex = 0;

  @Output()
  clearFilter = new EventEmitter<MouseEvent>();

  @Output()
  backendcall = new EventEmitter<any>();

  @Output()
  filterChanged = new EventEmitter<FilterData>();

  selectedItem = new SelectionModel<any>(true, []);

  @Input()
  isLoadingResults!: boolean;

  @Input()
  isDialog!: boolean;

  minMaxFiltersTemp: { [facet: string]: MinMax } = {};
  document?: ObjectData;
  allFieldType: Array<Fieldtype> = [];
  hasFilter = false;
  minmax: Array<string> = [];
  colors: TColor[] = Object.keys(Trafficlight) as TColor[];
  dateRange: { min: number; max: number } = {
    min: 946684800, //JAN 01 2000
    max: 7258118400, //JAN 01 2200
  };
  dimRange: { min: number; max: number } = {
    min: 0,
    max: 4000000,
  };
  searchValue!: string;

  fullMatchIsSelected = false;
  orderedFacetsKeys?: string[];
  unOrderedFacetsKeys?: string[];
  objectIdList!: Array<string>;
  panelExpandedState: { [key: string]: boolean } = {};

  public rangesDate: { [key: string]: FormGroup } = {
    _meta_CREATEAt: new FormGroup({
      start: new FormControl<Date | null>(null),
      end: new FormControl<Date | null>(null),
    }),
    _meta_UPDATEAt: new FormGroup({
      start: new FormControl<Date | null>(null),
      end: new FormControl<Date | null>(null),
    }),
    basis_start_datestring: new FormGroup({
      start: new FormControl<Date | null>(null),
      end: new FormControl<Date | null>(null),
    }),
    basis_end_datestring: new FormGroup({
      start: new FormControl<Date | null>(null),
      end: new FormControl<Date | null>(null),
    }),
  };

  minMaxKey = '_MinMax';

  constructor(
    public breakpointsService: BreakpointsService,
    public localStorageService: LocalStorageService,
    public sessionStorageService: SessionStorageService,
    private dialog: MatDialog,
    private apiClientService: ApiClientService,
    private formService: FormService,
    private translateService: TranslateService,
    public authService: AuthService,
    private logger: NGXLogger,
    private alert: AlertService,
    private translationHelp: TranslateHelpService,
  ) {}

  ngOnInit() {
    this.checkFacetFilter();
    this.getFieldTypeMap();
    this.setDateRangeFormControls();
  }

  // private createDateRangeFormGroup(): void {
  //   const minMaxFacetKeys = this.minMaxFilters
  // }

  public setDateRangeFormControls() {
    const createAt_ = this.sessionStorageService.getMinMaxFilters(this.dataType, this.minMaxKey)?._meta_CREATEAt;
    const updateAt_ = this.sessionStorageService.getMinMaxFilters(this.dataType, this.minMaxKey)?._meta_UPDATEAt;
    const startDate = this.sessionStorageService.getMinMaxFilters(
      this.dataType,
      this.minMaxKey,
    )?.basis_start_datestring;
    const endDate = this.sessionStorageService.getMinMaxFilters(this.dataType, this.minMaxKey)?.basis_end_datestring;

    this.rangesDate['_meta_CREATEAt'].patchValue({
      start: this.toDateOrNull(createAt_?.min),
      end: this.toDateOrNull(createAt_?.max),
    });

    this.rangesDate['_meta_UPDATEAt'].patchValue({
      start: this.toDateOrNull(updateAt_?.min),
      end: this.toDateOrNull(updateAt_?.max),
    });

    this.rangesDate['basis_start_datestring'].patchValue({
      start: this.toDateOrNull(startDate?.min),
      end: this.toDateOrNull(startDate?.max),
    });

    this.rangesDate['basis_end_datestring'].patchValue({
      start: this.toDateOrNull(endDate?.min),
      end: this.toDateOrNull(endDate?.max),
    });
  }

  private toDateOrNull(value: any): Date | null {
    return value ? new Date(value) : null;
  }

  emitFilterChanged(): void {
    if (this.minMaxFilters)
      this.filterChanged.emit({
        searchValue: this.searchValue,
        dataType: this.dataType,
        quickFilterReq: this.quickFilterReq,
        tableComponent: this.tableComponent,
        resultsLength: this.resultsLength,
        pageIndex: this.pageIndex,
        isLoadingResults: this.isLoadingResults,
        facetsAreLoading: false,
        reqObject: {},
        facets: this.facets,
        fullMatchIsSelected: false,
        minMaxFilters: this.minMaxFilters,
        minDate: new Date(0),
        maxDate: new Date(0),
        quickFilterFacets: this.quickFilterFacets,
        quickHierarchyFacets: this.quickHierarchyFacets,
        quickMinMaxFacets: this.quickMinMaxFacets,
        objectIdList: this.objectIdList,
        fieldnames: this.fieldNames,
        overviewTable: this.overviewTable,
      });

    this.checkFacetFilter();
  }
  getFieldTypeMap() {
    this.formService.getForm(this.localStorageService.getProject().project, this.dataType).subscribe((metaForm) => {
      this.overviewTable.forms = metaForm.forms;
      if (this.overviewTable.forms)
        this.overviewTable.forms.forEach((el) => {
          if (el.fields)
            el.fields.forEach((field) => {
              if (field?.path) {
                this.allFieldType.push({
                  path: field?.path,
                  type: field?.type,
                });
              }
            });
        });
    });
  }

  isAppointmentList(pathKey: string) {
    return isAppointmentList(pathKey);
  }

  getType(path: string): string {
    let fieldType = '';
    this.allFieldType.forEach((element) => {
      if (element?.type && path === element?.path) {
        fieldType = element?.type;
      }
    });
    return fieldType;
  }

  reorderFacets(backEndFacets: any) {
    //Keys from the backend facets
    this.unOrderedFacetsKeys = Object.keys(backEndFacets);
    //Filter ordered facets since there is filters which does not exist in backend facets
    this.orderedFacetsKeys = this.facets.filter((item) => {
      if (this.unOrderedFacetsKeys && this.unOrderedFacetsKeys.indexOf(item) !== -1) {
        return item;
      }
      return;
    });
    const facets: Map<string, Map<string, number>> = new Map();
    //Loop at ordered facets array and create a map with the right order
    for (const facetKey of this.orderedFacetsKeys) {
      const temp: Map<string, number> = new Map();
      for (const key of Object.keys(backEndFacets[facetKey])) {
        //instead of [for (const key of backEndFacets[facetKey])]
        temp.set(key, backEndFacets[facetKey][key]);
      }
      facets.set(facetKey, temp);
    }

    return facets;
  }

  getFields(): void {
    this.apiClientService.getFullFieldsWithDeepNamming(this.dataType).subscribe((data) => {
      this.fieldNames = data;
    });
  }

  checkFacetFilter(): boolean {
    return this.sessionStorageService.checkFilters(this.dataType);
  }

  openHierarchyDialog(hierarchyElement: any, hierarchyKey: string, hierarchyName: string, resultsLength: number) {
    const dialogRef = this.dialog.open(FilterDialogComponent, {
      data: {
        hierarchyElement,
        dataType: this.dataType,
        hierarchyKey,
        hierarchyName,
        resultsLength,
      },

      height: 'auto',
      width: '560px',
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (Object.keys(hierarchyElement?.value).length > 0) {
          this.sessionStorageService.setHierarchyStructure(hierarchyKey, hierarchyElement);
        }
        this.pageIndex = 0;
        this.sessionStorageService.savePageIndex(this.dataType, this.pageIndex);
      }
      this.backendcall.emit();
      this.emitFilterChanged();
    });
  }

  toggleSaveDateFilter(): boolean {
    return this.hasFilter;
  }

  dateRangeChange(dateRangeStart: Date, dateRangeEnd: Date, facetKey: string): void {
    if (dateRangeStart && dateRangeEnd && facetKey) {
      const minVal = new Date(dateRangeStart).getTime();
      const maxVal = new Date(dateRangeEnd).getTime();
      if (this.minMaxFilters) {
        switch (facetKey) {
          case '_meta_CREATEAt':
            this.minMaxFilters['_meta_CREATEAt'] = {
              min: minVal,
              max: maxVal + 24 * 60 * 60 * 1000 - 1,
            };
            this.rangesDate['_meta_CREATEAt'].patchValue({
              start: new Date(minVal),
              end: new Date(maxVal),
            });
            break;
          case '_meta_UPDATEAt':
            this.minMaxFilters['_meta_UPDATEAt'] = {
              min: minVal,
              max: maxVal + 24 * 60 * 60 * 1000 - 1,
            };
            this.rangesDate['_meta_UPDATEAt'].patchValue({
              start: new Date(minVal),
              end: new Date(maxVal),
            });
            break;
          case 'basis_start_datestring':
            this.minMaxFilters['basis_start_datestring'] = {
              min: minVal,
              max: maxVal + 24 * 60 * 60 * 1000 - 1,
            };
            this.rangesDate['basis_start_datestring'].patchValue({
              start: new Date(minVal),
              end: new Date(maxVal),
            });
            break;
          case 'basis_end_datestring':
            this.minMaxFilters['basis_end_datestring'] = {
              min: minVal,
              max: maxVal + 24 * 60 * 60 * 1000 - 1,
            };
            this.rangesDate['basis_end_datestring'].patchValue({
              start: new Date(minVal),
              end: new Date(maxVal),
            });
            break;
          default:
            this.logger.debug('default !');
            break;
        }
      }
      this.sessionStorageService.saveMinMaxFilters(this.dataType, this.minMaxKey, this.minMaxFilters);
      this.pageIndex = 0;
      this.sessionStorageService.savePageIndex(this.dataType, this.pageIndex);
      this.backendcall.emit();
      this.emitFilterChanged();
    }
  }

  unsorted(): number {
    return 0;
  }
  /* used for keyValue Pipe, since the standard order is alphabetical */
  originalOrder = (): number => 0;

  facetPanelShouldBeExpanded(facetCategory: string): boolean {
    const isMinMaxFacet = this.minMaxFilters?.[facetCategory]?.min || this.minMaxFilters?.[facetCategory]?.max;
    const isQuickFiltersFacet = facetCategory && Object.keys(this.quickFilterReq?.[facetCategory] || {}).length > 0;
    if (isMinMaxFacet || isQuickFiltersFacet) {
      this.panelExpandedState[facetCategory] = true;
    }

    return this.panelExpandedState[facetCategory];
  }

  facetDateRangeFilterShouldBeExpanded(facetKey: string): boolean {
    return !!this.minMaxFilters?.[facetKey];
  }

  checkDimensionType(facet: string): boolean {
    if (facet.includes('dimensions')) {
      return true;
    }
    return false;
  }
  resetDimensionFromSession(key: string): void {
    this.sessionStorageService.getMinMaxFilters(this.dataType, this.minMaxKey).forEach((element: any) => {
      if (Object.keys(element).toString() === key) {
        alert('go to delete');
        delete this.sessionStorageService.getMinMaxFilters(this.dataType, this.minMaxKey)[element];
      }
    });
    this.sessionStorageService.saveMinMaxFilters(
      this.dataType,
      this.minMaxKey,
      this.sessionStorageService.getMinMaxFilters(this.dataType, this.minMaxKey),
    );
  }
  /**
   * @param key :facet meant to be de deleted from list of quickfilter
   * resetDim delet facet from list of quickfilter
   */
  resetDimension(facet: string, extremum: string, value: string, secondValue: string): void {
    const numValue = parseFloat(value);
    const num2ndValue = parseFloat(secondValue);
    if (this.minMaxFilters) {
      if (extremum === 'min') {
        this.minMaxFilters[facet] = { ...this.minMaxFilters[facet], max: numValue };
        if (!num2ndValue) {
          delete this.minMaxFilters[facet];
        }
      }
      if (extremum === 'max') {
        this.minMaxFilters[facet] = { ...this.minMaxFilters[facet], min: numValue };
        if (!num2ndValue) {
          delete this.minMaxFilters[facet];
        }
      }
    }

    this.sessionStorageService.saveMinMaxFilters(this.dataType, this.minMaxKey, this.minMaxFilters);
    this.backendcall.emit();
    this.emitFilterChanged();
  }

  public checkFacetValueInStorage(facet: string) {
    return this.minMaxFilters && this.minMaxFilters[facet];
  }

  getMinMaxValue(facet: string, min: any, max: any): void {
    if (!this.minMaxFilters) return;
    if (min && !max) {
      this.minMaxFilters[facet] = { ...this.minMaxFilters[facet], min };
    } else if (!min && max) {
      this.minMaxFilters[facet] = { ...this.minMaxFilters[facet], max };
    } else if (min && max) {
      if (Number(min) < Number(max)) {
        this.minMaxFilters[facet] = { min, max };
      } else {
        this.alert.infoAlertDialog(
          '',
          this.translationHelp.translate('minIsNotSmallerThanMax'),
          this.translationHelp.translate('ok'),
        );
        return;
      }
    }

    this.sessionStorageService.saveMinMaxFilters(this.dataType, this.minMaxKey, this.minMaxFilters);
    this.pageIndex = 0;
    this.sessionStorageService.savePageIndex(this.dataType, this.pageIndex);
    this.backendcall.emit();
    this.emitFilterChanged();
  }

  isFacetSelected(facetCategory: string, facetValue: string): boolean {
    if (this.quickFilterReq[facetCategory]) {
      return !!this.quickFilterReq[facetCategory].includes(facetValue);
    } else {
      return false;
    }
  }

  quickFilterByFacet(filterCategory: string, facet: string): void {
    const categoryArray = this.quickFilterReq[filterCategory];

    if (!categoryArray) {
      // If category does not exist, create category and add the first facet to filter
      this.quickFilterReq[filterCategory] = [facet];
    } else {
      const facetIndex = categoryArray.indexOf(facet);

      if (facetIndex !== -1) {
        // If facet in category already exists, delete the facet
        categoryArray.splice(facetIndex, 1);

        if (categoryArray.length < 1) {
          // If it was the last facet of the category, delete the category
          delete this.quickFilterReq[filterCategory];
        }
      } else {
        // If facet in category does not exist, add facet to category array
        categoryArray.push(facet);
      }
    }

    // Clone the deep object after modifying it
    this.quickFilterReq = _.cloneDeep(this.quickFilterReq);

    // Save quick filter request to sessionStorage
    this.sessionStorageService.saveQuickfilterReq(this.dataType, this.quickFilterReq);

    // Reset page before triggering the action and save the page index
    this.pageIndex = 0;
    this.sessionStorageService.savePageIndex(this.dataType, this.pageIndex);

    // Emit backend call and filter changed events
    this.backendcall.emit();
    this.emitFilterChanged();

    // Clear selected item
    this.selectedItem.clear();
  }

  getQuickFilterFacets(facetKey: string): string {
    switch (facetKey.trim()) {
      case '! {  }':
        return this.translateService.instant('LABELS.na');
      case 'green':
        return this.translateService.instant('TRAFFICLIGHT.available');
      case 'orange':
        return this.translateService.instant('TRAFFICLIGHT.reserved');
      case 'red':
        return this.translateService.instant('TRAFFICLIGHT.unavailable');
      default:
        return facetKey;
    }
  }
  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  getObjectLength(object: any) {
    return object ? Object.keys(object)?.length : null;
  }
  removeKeyValueFromNestedObject(obj: any, keyToRemove: string): void {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        if (prop === keyToRemove) {
          delete obj[prop];
        } else if (typeof obj[prop] === 'object') {
          this.removeKeyValueFromNestedObject(obj[prop], keyToRemove);
        }
      }
    }
  }
  remove(obj: any, keyToRemove: string): void {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        if (prop.includes(keyToRemove)) {
          delete obj[prop];
        } else if (typeof obj[prop] === 'object') {
          this.removeKeyValueFromNestedObject(obj[prop], keyToRemove);
        }
      }
    }
  }
  clearDate(dateRangeFilterKey: string, isResetBtnClicked = false): void {
    this.removeKeyValueFromNestedObject(this.minMaxFilters, dateRangeFilterKey);
    this.pageIndex = 0; // reset page before triggering the action
    this.sessionStorageService.savePageIndex(this.dataType, this.pageIndex);
    this.rangesDate[dateRangeFilterKey].reset();
    this.sessionStorageService.saveMinMaxFilters(this.dataType, this.minMaxKey, this.minMaxFilters);
    if (!isResetBtnClicked) this.backendcall.emit();
    this.emitFilterChanged();
  }
  clearDimension(isResetBtnClicked = false): void {
    this.remove(this.minMaxFilters, 'dimension');
    this.pageIndex = 0; // reset page before triggering the action
    this.sessionStorageService.savePageIndex(this.dataType, this.pageIndex);
    this.sessionStorageService.deleteDimRangeFilter(this.dataType, this.minMaxKey);
    if (!isResetBtnClicked) this.backendcall.emit();
    this.emitFilterChanged();
  }
  clearFacetFilter(): void {
    this.sessionStorageService.deleteHierarchyQuery(this.dataType);
    const createAtFilter = this.sessionStorageService.getMinMaxFilters(this.dataType, this.minMaxKey)?._meta_CREATEAt;
    const updateAtFilter = this.sessionStorageService.getMinMaxFilters(this.dataType, this.minMaxKey)?._meta_UPDATEAt;
    const startAtFilter = this.sessionStorageService.getMinMaxFilters(
      this.dataType,
      this.minMaxKey,
    )?.basis_start_datestring;
    const endAtFilter = this.sessionStorageService.getMinMaxFilters(
      this.dataType,
      this.minMaxKey,
    )?.basis_end_datestring;
    const minMaxDim = this.sessionStorageService.getMinMaxFilters(this.dataType, this.minMaxKey);
    if (createAtFilter || createAtFilter === undefined) {
      this.clearDate('_meta_CREATEAt', true);
    }
    if (updateAtFilter || updateAtFilter === undefined) {
      this.clearDate('_meta_UPDATEAt', true);
    }
    if (startAtFilter || startAtFilter === undefined) {
      this.clearDate('basis_start_datestring', true);
    }
    if (endAtFilter || endAtFilter === undefined) {
      this.clearDate('basis_end_datestring', true);
    }
    if (minMaxDim || minMaxDim === undefined) {
      this.clearDimension(true);
    }
    this.clearFilter.emit();
  }

  orderASC(a: KeyValue<string, any>, b: KeyValue<string, any>): number {
    return a.value - b.value;
  }

  get FieldTypes() {
    return FieldTypes;
  }

  getColor(colorKey: any): TColor {
    return colorKey;
  }

  getLabelFromColor(statusColor: string) {
    return getTrafficLightLabelFromColor(statusColor);
  }

  isQuickFilterReqLength(facetCategory: any) {
    const { quickFilterReq } = this;
    if (quickFilterReq && quickFilterReq[facetCategory.key]) {
      return quickFilterReq[facetCategory.key].length;
    }

    return 0;
  }
}
