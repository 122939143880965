import { Injectable } from '@angular/core';
import { CmnLocalStorageService } from './../storage/cmn-local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class PathService {
  private environmentApiUrl!: string;

  constructor(private localStorageService: CmnLocalStorageService) {}

  public setEnvironmentApiUrl(value: string) {
    this.environmentApiUrl = value;
  }

  public getBasePath() {
    return this.environmentApiUrl;
  }

  public getFilePath() {
    return `${this.environmentApiUrl}/file/` + this.localStorageService.getProject()?.project;
  }

  public getFilePathWithProject(project: string) {
    return `${this.environmentApiUrl}/file/` + project;
  }
}
