<button
  [ngClass]="fullWidth ? 'button-full-width' : ''"
  [matTooltip]="tooltipLabel"
  [matTooltipPosition]="toolTipPosition"
  [matTooltipHideDelay]="toolTipHideDelay"
  matBadgePosition="before"
  [matBadgeColor]="badgeColor"
  matBadgeSize="medium"
  [matBadge]="badgeValue"
  [matBadgePosition]="badgePosition"
  [attr.aria-label]="ariaLabel"
  mat-button
  [disabled]="disabled"
  [color]="buttonColor"
  (click)="clickButton.emit($event)">
  <mat-icon fontSet="material-icons-outlined" class="material-symbols-outlined">{{ icon }}</mat-icon>
  <mat-label>{{ label }}</mat-label>
</button>
