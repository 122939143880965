import { Injectable } from '@angular/core';

import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { ObjectData } from '@frontends/commons';
import { RequestObject, ResponseObjectConcreteData, SaveStatus } from '@frontends/exp-apiclient';
import { Observable, tap } from 'rxjs';
import { DataType } from '../../model/types/DataTypes';
import { ApiClientService } from '../api-client.service';
import { TranslateHelpService } from '../translate-help.service';

@Injectable({
  providedIn: 'root',
})
export class ObjectDataService {
  constructor(
    private apiClient: ApiClientService,
    private translationHelp: TranslateHelpService,
    private snackBar: MatSnackBar,
  ) {}

  public getObject(projectIdentifier: string, id: string, dataType: DataType): Observable<ObjectData> {
    return this.apiClient.getDataByObjectId(projectIdentifier, dataType, id);
  }

  public getObjectByQuery(request: RequestObject, dataType: DataType): Observable<ResponseObjectConcreteData> {
    return this.apiClient.getRequestForCurrentePrj(dataType, request);
  }

  public updateObject(object: ObjectData, dataType: DataType, projectIdentifier?: string): Observable<SaveStatus> {
    if (projectIdentifier) {
      return this.apiClient.updateObjectInAnotherProject(projectIdentifier, dataType, object).pipe(
        tap(() => {
          this.openSnackBar(this.translationHelp.translate('SUCCESS_MESSAGE.' + dataType, 'update'), '');
        }),
      );
    } else {
      return this.apiClient.updateObjectInCurrenteProject(dataType, object).pipe(
        tap(() => {
          this.openSnackBar(this.translationHelp.translate('SUCCESS_MESSAGE.' + dataType, 'update'), '');
        }),
      );
    }
  }

  public createObject(object: ObjectData, dataType: DataType, projectIdentifier?: string): Observable<SaveStatus> {
    if (projectIdentifier) {
      return this.apiClient.saveObjectInAnotherProject(projectIdentifier, dataType, object).pipe(
        tap(() => {
          this.openSnackBar(this.translationHelp.translate('SUCCESS_MESSAGE.' + dataType, 'create'), '');
        }),
      );
    } else {
      return this.apiClient.saveObjectInCurrenteProject(dataType, object).pipe(
        tap(() => {
          this.openSnackBar(this.translationHelp.translate('SUCCESS_MESSAGE.' + dataType, 'create'), '');
        }),
      );
    }
  }
  public deleteObject(id: string, dataType: DataType): Observable<boolean> {
    return this.apiClient.deleteObjectInCurrenteProject(dataType, id).pipe(
      tap(() => {
        this.openSnackBar(this.translationHelp.translate('SUCCESS_MESSAGE.' + dataType, 'delete'), '');
      }),
    );
  }

  private openSnackBar(
    message: string,
    action: string,
    showingDuration: number = 3500,
    horizontalPosition: MatSnackBarHorizontalPosition = 'center',
    verticalPosition: MatSnackBarVerticalPosition = 'bottom',
  ) {
    this.snackBar.open(message, action, {
      duration: showingDuration,
      horizontalPosition,
      verticalPosition,
    });
  } // TODO use the class from faktura
}
