/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { BaseFieldComponent } from '../Base/base-field.component';

@Component({
  selector: 'ds-radiobutton',
  templateUrl: './radiobutton.component.html',
  styleUrls: ['./radiobutton.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadiobuttonComponent),
      multi: true,
    },
  ],
})
export class RadiobuttonComponent extends BaseFieldComponent {
  @Input() checked = false;
  @Output() changeValue = new EventEmitter<MatRadioChange>();
  @Output()
  clickRadioButton = new EventEmitter<MouseEvent>();
  radioChange(event: MatRadioChange) {
    this.onChange(event.value);
    this.changeValue.emit(event.value);
  }

  // Implement the ControlValueAccessor methods
  protected override onChange: (value: any) => void = () => {};

  override writeValue(value: any): void {
    this.value = value;
  }
  override registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }
}
