import { Injectable } from '@angular/core';

import { NGXLogger } from 'ngx-logger';
import { ProjectData, SelectOption } from '../../model';
import { CmnSessionStorageService } from './cmn-session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class StewardService {
  constructor(private cmnSessionStorage: CmnSessionStorageService, private logger: NGXLogger) {}

  public generateProjectSelectOptions(projects: ProjectData[]): SelectOption {
    const projectSelectOptions: SelectOption = {};
    projects.forEach((_project) => {
      projectSelectOptions[_project.project] = { value: _project.name };
    });

    return projectSelectOptions;
  }

  public findProjectInSessionStorage(project: string): ProjectData {
    const projects: ProjectData[] = this.cmnSessionStorage.getProjects() || [];
    this.logger.debug('PROJECT LIST', projects);
    let foundProject: ProjectData = {
      docId: '',
      project: '',
      name: '',
      active: false,
    };
    for (const _project of projects) {
      if (_project.project === project) {
        foundProject = _project;
        break;
      }
    }
    return foundProject;
  }
}
