import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { ThemeColors } from '../../types/colors';
import { ThemeService } from './../../services/theme/theme.service';

@Component({
  template: '',
})
export abstract class BaseButtonComponent {
  @Input() public tooltipLabel = '';
  @Input() public toolTipPosition: TooltipPosition = 'below';
  @Input() public toolTipHideDelay = '';
  @Input() public label = '';
  @Input() public disabled = false;
  @Input() public color: ThemeColors = 'accent';
  @Input() public fullWidth = false;
  @Input() public ariaLabel = '';

  @Output()
  public clickButton = new EventEmitter<MouseEvent>();

  constructor(protected themeService: ThemeService) {
  }

}
