import { DatePipe, formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { ObjectData } from '@frontends/commons';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { isNumber } from 'lodash';
import { dateFormats } from '../model/DateFormats';
import { ReservationNames } from '../model/Enums/ReservationTypesEnum';
import { Appointment } from '../model/Object/appointment';
import { Tuple } from '../model/Util/Tuple';
import { convertNumFormatToDE, formatAppointment, isAppointmentField } from '../utils/helperFunctions';
import { LocalStorageService } from './storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ExportService {
  constructor(
    private localStorageService: LocalStorageService,
    private datePipe: DatePipe,
    private translateService: TranslateService,
  ) {}

  // eslint-disable-next-line @typescript-eslint/member-ordering
  currentDate = this.datePipe.transform(new Date(), dateFormats.dayMonthFormat, 'en-US');

  downloadFile(data: ObjectData | ObjectData[], headerFields: Tuple[], selectedFilterName?: string) {
    const csvRows = data.map((selectedObjectValues: ObjectData) => {
      const formattedValues = headerFields.map((filedName) =>
        this.getFormattedFieldValue(selectedObjectValues, filedName),
      );
      return formattedValues.join(';');
    });

    const translatedHeaders = headerFields.map((key) => key.k);
    csvRows.unshift(translatedHeaders.join(';'));
    const csvString = csvRows.join('\r\n');

    const blob = new Blob(['\ufeff', csvString], { type: 'text/csv' });

    saveAs(blob, this.getDownloadingFileName(selectedFilterName || ''));
  }
  private getRecursiveValue(obj: ObjectData, fieldValue: string): any {
    if (!obj) {
      return;
    }
    if (fieldValue.includes('.')) {
      const [firstPart, restOfField] = fieldValue.split('.', 2);
      return this.getRecursiveValue(obj[firstPart], restOfField);
    } else {
      if (obj[fieldValue] instanceof Object) {
        const hierarchyValue = [] as any[];
        Object.values(obj[fieldValue]).forEach((element) => {
          hierarchyValue.push(element);
        });
        return hierarchyValue;
      } else {
        return obj[fieldValue];
      }
    }
  }

  private stringifyAptArray(apts: Appointment[]) {
    const stringifyAppointmentsArray = apts.map((apt: Appointment) => {
      const copiedApt = { ...apt };
      copiedApt.status = this.translateService.instant(ReservationNames[apt.status as keyof typeof ReservationNames]);

      return formatAppointment(copiedApt);
    });

    return `"${stringifyAppointmentsArray.join(';\n')};"`;
  }

  private jsonStringifyReplacer(_: string, value: string[] | number | undefined) {
    if (Array.isArray(value)) {
      return value.join(',');
    } else if (isNumber(value) && Number(value) > 1577833200000) {
      return formatDate(value, 'dd-MM-yyyy', 'en-US');
    }
    return !value ? '' : value;
  }

  private getFormattedFieldValue(objectValues: ObjectData, fieldName: Tuple) {
    const fieldPath = fieldName?.v;
    if (!fieldPath) return;
    if (isAppointmentField(fieldPath)) {
      const aptFieldValue: Appointment[] = objectValues[fieldPath];
      if (!aptFieldValue || !aptFieldValue.length) {
        return;
      }
      return this.stringifyAptArray(aptFieldValue);
    }
    if (fieldPath.includes('dimension')) {
      const dimension = objectValues[fieldPath];
      if (dimension) {
        if (this.translateService.currentLang === 'de') {
          return JSON.stringify(convertNumFormatToDE(dimension));
        }
        return JSON.stringify(this.getRecursiveValue(objectValues, fieldPath), this.jsonStringifyReplacer).replace(
          /\\"/gi,
          '""',
        );
      }
      return;
    }
    return JSON.stringify(this.getRecursiveValue(objectValues, fieldPath), this.jsonStringifyReplacer).replace(
      /\\"/gi,
      '""',
    );
  }

  private getDownloadingFileName(selectedFilterName: string) {
    let fileName = 'Exp_' + this.localStorageService.getProject().name + '_';
    if (selectedFilterName) {
      fileName += selectedFilterName + '_' + this.currentDate;
    } else {
      fileName += this.currentDate;
    }
    return (fileName += '.csv');
  }
}
