import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private readonly chatServiceURL: string = `${environment.chatServiceURL}`;

  constructor(private http: HttpClient) {}

  initializeChat(projectName: string, cauth: string): Observable<any> {
    return this.http.post(`${this.chatServiceURL}/initializeChat`, {
      projectName,
      cauth,
    });
  }

  sendMessageToServer(payload: any): Observable<any> {
    return this.http.post(`${this.chatServiceURL}/chatService/chat`, payload, {
      responseType: 'text', // Since you're dealing with a readStream and potentially non-JSON data, this responseType is safer.
    });
  }
}
