import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { SearchDialogComponent } from '../../../dialogs/search-dialog/search-dialog.component';
import { SearchPanelFields } from '../../../model/Object/search-panel';
import { DataType } from '../../../model/types/DataTypes';
import { ApiClientService } from '../../../services/api-client.service';

@Component({
  selector: 'exp-field-search',
  templateUrl: './field-search.component.html',
  styleUrls: ['./field-search.component.scss'],
})
export class FieldSearchComponent implements OnInit {
  dataType!: DataType;
  fieldsData?: SearchPanelFields[];
  @Output() searchTermEmitter = new EventEmitter<string>();

  constructor(private apiClientService: ApiClientService, private route: ActivatedRoute, private dialog: MatDialog) {}

  ngOnInit() {
    this.dataType = this.route.snapshot.params['type'];
    this.apiClientService.getSearchPanelFields(this.dataType).subscribe((res) => {
      this.fieldsData = res.fields;
    });
  }

  openSearchFilterDialog() {
    const dialogRef = this.dialog.open(SearchDialogComponent, {
      data: {
        fields: this.fieldsData,
        dataType: this.dataType,
      },
      width: '645px',
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.searchTermEmitter.emit(result);
      }
    });
  }
}
