<main class="flex-col settings-container">
  <header>
    <h2 mat-dialog-title>
      <!-- {{ 'LABELS.clone' | translate }} -->
      Neue Filtervorlage
      <button mat-button mat-dialog-close=""><mat-icon>close</mat-icon></button>
    </h2>
  </header>
  <form #form="ngForm" class="flex-col">
    <mat-dialog-content>
      <div class="flex-col password-form-container">
        <mat-form-field class="formField" appearance="outline">
          <mat-label [ngClass]="{requiredField: true}">
            <!-- {{ 'LABELS.numberToClone' | translate }} -->
            Den Namen der Vorlage eingeben
          </mat-label>
          <input placeholder="neue Vorlage" #input matInput type="text" />
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <footer class="flex-row dialog-footer smallFlexGap">
      <button mat-button (click)="saveFilter(input.value)" class="main-btn">
        {{ 'BUTTONS.save' | translate }}
      </button>
      <button mat-button mat-dialog-close class="abort-btn">{{ 'BUTTONS.cancel' | translate }}</button>
    </footer>
  </form>
</main>
<!-- <app-spinner-overlay *ngIf="showLoadingScreen" (aborting)="abortLoadingScreen()"></app-spinner-overlay> -->
