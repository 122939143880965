import { Injectable } from '@angular/core';
import { LocalStorageService } from '../storage/local-storage.service';

import { DataType, HierarchyEntry, MinMax, ResponseObject } from '@frontends/commons';
import { ObjectDataControllerService, RequestObject, ResponseObjectConcreteData } from '@frontends/exp-apiclient';
import { NGXLogger } from 'ngx-logger';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataApiClientService {
  private currentProject: string;

  constructor(
    private dataController: ObjectDataControllerService,
    private localStorageService: LocalStorageService,
    private logger: NGXLogger,
  ) {
    this.currentProject = '';
    this.setCurrentProject();
  }

  private setCurrentProject() {
    this.currentProject = this.localStorageService.getProject().project;
    if (!this.currentProject) {
      this.localStorageService.projectData$.subscribe((data) => {
        this.currentProject = data?.project;
      });
    }
  }

  public getRequestForCurrentProject(dataType: DataType, request: RequestObject): Observable<ResponseObject> {
    this.logger.trace(
      'GET REQUEST FOR CURRENT PROJECT (LS)',
      this.localStorageService.getProject(),
      dataType,
      request,
      this,
    );
    return this.dataController.getByRequestData(this.currentProject, dataType, request).pipe(
      map((beResponse: ResponseObjectConcreteData) => ({
        matches: beResponse.matches ?? 0,
        minMaxFacets: beResponse.minMaxFacets ? this.mapMinMaxFacets(beResponse.minMaxFacets) : {},
        hierarchyFacets: beResponse.hierarchyFacets ? this.mapHierarchyFacets(beResponse.hierarchyFacets) : {},
        facets: beResponse.facets ?? {},
        results: beResponse.results ?? [],
        flatResults: beResponse.flatResults ?? [],
        ids: beResponse.ids ?? [],
      })),
    );
  }

  private mapHierarchyFacets(hierarchyFacets: {
    [key: string]: { [key: string]: import('@frontends/exp-apiclient').HierarchyEntry };
  }): { [key: string]: { [key: string]: HierarchyEntry } } {
    return Object.fromEntries(
      Object.entries(hierarchyFacets).map(([outerKey, value]) => [
        outerKey,
        Object.fromEntries(
          Object.entries(value).map(([innerKey, innerValue]) => [innerKey, this.mapHierarchyEntry(innerValue)]),
        ),
      ]),
    );
  }

  private mapHierarchyEntry(beHierarchyEntry: import('@frontends/exp-apiclient').HierarchyEntry): HierarchyEntry {
    return {
      path: beHierarchyEntry.path ?? 'default-path',
      count: beHierarchyEntry.count ?? 0,
      children: beHierarchyEntry.childs
        ? Object.fromEntries(
            Object.entries(beHierarchyEntry.childs).map(([key, childEntry]) => [
              key,
              this.mapHierarchyEntry(childEntry),
            ]),
          )
        : {},
    };
  }

  private mapMinMaxFacets(beMinMax: { [key: string]: { min?: number; max?: number } }): { [key: string]: MinMax } {
    return Object.entries(beMinMax).reduce(
      (accumulator, [key, value]) => {
        const min: number = value.min ?? 0;
        const max: number = value.max ?? 0;
        accumulator[key] = { min, max };
        return accumulator;
      },
      {} as { [key: string]: MinMax },
    );
  }
}
