import { Component, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { filter, take } from 'rxjs';
import { ReservationNames, ReservationTypesEnum } from '../../model/Enums/ReservationTypesEnum';
import { Appointment } from '../../model/Object/appointment';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'exp-reservation-dialog',
  templateUrl: './reservation-dialog.component.html',
  styleUrls: ['./reservation-dialog.component.scss'],
})
export class ReservationDialogComponent {
  public form!: FormGroup;
  public title?: string;

  public statusLabels = {
    red: this.translateService.instant(ReservationNames.RED),
    orange: this.translateService.instant(ReservationNames.ORANGE),
  };

  private reservations?: Appointment[];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Appointment,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ReservationDialogComponent>,
    private translateService: TranslateService,
    private alertService: AlertService
  ) {
    const { status, name, from, to, title, reservations } = data;
    const initialStatus = status && status !== 'green' && status;
    this.form = this.formBuilder.group({
      title: [name || '', Validators.required],
      range: [{ min: from || null, max: to || null }, [Validators.required, this.rangeValidator]],
      status: initialStatus || ReservationTypesEnum.RESERVED,
    });
    this.title = title;
    this.reservations = reservations;

    this.setupCustomDialogOnCloseEvent();
  }

  private setupCustomDialogOnCloseEvent(): void {
    this.dialogRef.disableClose = true;
    this.dialogRef.backdropClick().subscribe(() => this.onModalClose());
    this.dialogRef
      .keydownEvents()
      .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
      .subscribe(() => this.onModalClose());
  }

  private onModalClose(): void {
    if (!this.form.touched) {
      return this.dialogRef.close();
    }

    const alertDialogRef = this.alertService.confirmDiscardDialogChanges();
    alertDialogRef.componentInstance.save.pipe(take(1)).subscribe((isCanDiscard) => {
      if (isCanDiscard) {
        this.dialogRef.close();
      }
      alertDialogRef.close();
    });
  }

  private rangeValidator(control: AbstractControl): ValidationErrors | null {
    const range = control.value;
    if (!range.min || !range.max) {
      return { invalidRange: true };
    }

    return null;
  }

  public getControlStatusByName(controlName: string): boolean {
    const control = this.form.get(controlName);
    if (control) return control.touched && !control.valid ? true : false;
    return false;
  }

  private isNewReservationOverlap(): boolean {
    const rangeControl = this.form.get('range') as FormControl;
    const { min, max } = rangeControl.value;
    if (this.reservations)
      return this.reservations.some((reservation) => {
        const existingFromDate = reservation.from;
        const existingToDate = reservation.to;
        return (min >= existingFromDate && min <= existingToDate) || (max >= existingFromDate && max <= existingToDate);
      });
    return false;
  }

  public getErrorMessage(): string {
    return `${this.translateService.instant('PLACEHOLDER.title')} ${this.translateService.instant('ERROR.required')}`;
  }

  public submitForm(): void {
    const { form } = this;
    const rangeControl = form.get('range') as FormControl;
    rangeControl.updateValueAndValidity();
    form.markAllAsTouched();

    if (form.valid && form.touched && this.isNewReservationOverlap()) {
      return this.alertService.infoAlertDialog(
        '',
        `${this.translateService.instant('MESSAGE.ERROR_MESSAGE.timeRangeOccupied')}`,
        'OK'
      );
    }

    if (form.valid) {
      return this.dialogRef.close(form.value);
    }
  }
}
