import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { VerticalDividerComponent } from './vertical-divider/vertical-divider.component';
import { HorizontalDividerComponent } from './horizontal-divider/horizontal-divider.component';

@NgModule({
  imports: [CommonModule, MatDividerModule],
  declarations: [VerticalDividerComponent, HorizontalDividerComponent],
  exports: [VerticalDividerComponent, HorizontalDividerComponent],
})
export class DividerModule {}
