<div class="container" *ngIf="!valueOnly; else showReadOnly">
  <ds-icon-badge *ngIf="internal" [iconClass]="iconClass" [tooltip]="tooltip"></ds-icon-badge>
  <mat-form-field [class.ng-invalid]="showError" [color]="color" appearance="outline">
    <mat-label>{{ label }}</mat-label>
    <textarea
      *ngIf="!formControlName; else hasFormControl"
      [attr.aria-label]="ariaLabel"
      cdkTextareaAutosize="true"
      matInput
      [placeholder]="placeholder"
      [(ngModel)]="value"
      [disabled]="disabled"
      (change)="onChangeInput()"
      [required]="required"
      data-testid="input"></textarea>
    <ng-template #hasFormControl>
      <textarea
        [formControl]="control"
        cdkTextareaAutosize="true"
        matInput
        [(ngModel)]="value"
        [placeholder]="placeholder"
        [attr.aria-label]="ariaLabel"
        (blur)="onBlur()"
        data-testid="input"></textarea>
    </ng-template>
    <mat-hint [attr.aria-label]="hintAriaLabel" *ngIf="showHint">{{ hintMessage }}</mat-hint>
    <mat-error [attr.aria-label]="errorAriaLabel" *ngIf="showError">{{ errorMessage }}</mat-error>
    <mat-hint align="end" [attr.aria-label]="counterAriaLabel" *ngIf="showCounter">
      {{ value ? value.length : 0 }} {{ 'LABELS.characters' | translate }}
    </mat-hint>
  </mat-form-field>
</div>
<ng-template #showReadOnly>
  <div class="marginButtom">
    <span class="mat-subtitle-2">{{ label }}</span>
    <div class="mat-body-2">
      {{ value && value.trim() !== undefined && value && value.trim() !== '' ? value : '-' }}
    </div>
  </div>
</ng-template>
