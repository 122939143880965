import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ProjectData } from '../../model/Data/ProjectData';
import { AuthToken } from '../../model/auth';
import { BaseStorageService, StorageType } from '../base/base-storage.service';

const NOT_SET = 'NOT SET';
//KEY CONSTANTS
const CAUTH = 'cauth';
const PROJECT = 'project';
const LOCALE = 'locale';
const VERSION = 'version';

@Injectable({
  providedIn: 'root',
})
export class CmnLocalStorageService extends BaseStorageService {
  private authToken?: AuthToken;
  private project!: ProjectData;
  private locale!: string;

  constructor(logger: NGXLogger) {
    super(logger, StorageType.LOCAL);
  }

  // --------- CAUTH ---------

  public setAuthToken(authToken: AuthToken): void {
    this.authToken = authToken;
    this.setItem(CAUTH, authToken);
  }

  public getAuthToken(): AuthToken {
    if (this.authToken) {
      return this.authToken;
    } else {
      const token = this.getItem(CAUTH);
      return token ? JSON.parse(token) : '';
    }
  }

  public removeAuthToken(): void {
    this.authToken = undefined;
    this.removeItem(CAUTH);
  }

  // --------- PROJECT STORAGE ---------
  public setProject(project: ProjectData): void {
    this.project = project;
    if (project === undefined) {
      this.setItem(PROJECT, '');
    } else {
      this.setItem(PROJECT, project);
    }
  }

  public getProject(): ProjectData {
    if (this.project) {
      return this.project;
    } else {
      const tempProject = this.getItem(PROJECT);
      if (tempProject) {
        this.project = JSON.parse(tempProject);
        return JSON.parse(tempProject);
      } else {
        this.logger.warn(`NO PROJECT SET`);
        return { docId: NOT_SET, project: NOT_SET, name: NOT_SET, active: false };
      }
    }
  }

  // -------- LANGUAGE STORAGE ---------
  public setLocale(locale: string): void {
    this.locale = locale;
    this.setItem(LOCALE, locale);
  }

  public getLocale(): string {
    if (this.locale) {
      return this.locale;
    } else {
      const tempLocale = this.getItem(LOCALE);
      if (tempLocale) {
        this.locale = JSON.parse(tempLocale);
        return this.locale;
      } else {
        this.logger.warn('NO LOCALE SET');
        return 'en';
      }
    }
  }

  /* ------ VERSION -------- */
  public setVersion(version: string) {
    this.setItem(VERSION, JSON.stringify(version));
  }

  public getVersion(): string {
    if (this.getItem(VERSION)) {
      return JSON.parse(this.getItem(VERSION));
    } else {
      return '';
    }
  }
}
