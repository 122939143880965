import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface SelectDialogData {
  selectOptions: SelectOption;
  title: string;
  closeBtnText: string;
  previousSelection: string;
}

export interface SelectOption {
  [key: string]: { value: string };
}

@Component({
  selector: 'ds-select-dialog',
  templateUrl: './select-dialog.component.html',
  styleUrls: ['./select-dialog.component.scss'],
})
export class SelectDialogComponent {
  @Input() public previousSelection = '';
  @Input() public options: SelectOption = {};

  @Input() public title = '';
  @Input() public closeBtnText = '';

  @Output() public selectedItem: EventEmitter<{ key: string }> = new EventEmitter();

  public selectedKey = '';

  constructor(
    public dialogRef: MatDialogRef<SelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SelectDialogData
  ) {
    this.options = data.selectOptions;
    this.title = data.title;
    this.closeBtnText = data.closeBtnText;
    this.previousSelection = data.previousSelection;
  }

  public selectItem(key: string) {
    this.selectedKey = key;
    this.selectedItem.emit({ key: key });
    this.onClose();
  }

  public onClose(): void {
    this.dialogRef.close(this.selectedKey);
  }
}
