

import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {DOCUMENT} from "@angular/common";


@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private isLightTheme = new BehaviorSubject<boolean>(true);
  public isLightTheme$: Observable<boolean> = this.isLightTheme.asObservable();
    private renderer: Renderer2;
    constructor(rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document:Document) {
        this.renderer = rendererFactory.createRenderer(null, null);
        this.initializedTheme();
    }

  public setLightTheme(isLight: boolean): void {
      this.setUpTheme(isLight);
  }

  public initializedTheme() {
    if(localStorage.getItem('theme')==null||localStorage.getItem('theme')==undefined){
      this.setLightTheme(true);
    }else {
      this.getTheme();
    }}

  public getTheme() {
    if(localStorage.getItem('theme')=="light"){
        this.setThemeToBody("light")
        this.isLightTheme.next(true);
    }else {
        this.setThemeToBody("dark")
        this.isLightTheme.next(false);
    }
  }
    private setUpTheme(isLight: boolean) {
        if (isLight) {
            this.setThemeToBody("light")
            localStorage.setItem('theme', 'light');
            this.isLightTheme.next(true);
        } else {
          this.setThemeToBody("dark")
            localStorage.setItem('theme', 'dark');
            this.isLightTheme.next(false);
        }
    }
    private  setThemeToBody(theme:string){
        this.renderer.removeClass(this.document.body, "dark");
        this.renderer.removeClass(this.document.body, "light");
        this.renderer.addClass(this.document.body, theme);
    }

}

