<a
  [ngClass]="fullWidth ? 'button-full-width' : ''"
  mat-button
  #tooltip="matTooltip"
  [matTooltip]="tooltipLabel"
  [matTooltipPosition]="toolTipPosition"
  [matTooltipHideDelay]="toolTipHideDelay"
  [attr.aria-label]="ariaLabel"
  [color]="color"
  [disabled]="disabled"
  (click)="clickButton.emit($event)"
  [href]="href">
  <mat-icon class="material-symbols-outlined">{{ icon }}</mat-icon>
  <mat-label>{{ label }} </mat-label>
</a>
