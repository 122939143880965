import { Injectable } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FieldTypes } from '../../../model/Enums/FieldTypesEnum';
import { BatchField, FieldName, OptionPath } from '../../../model/Form/FieldIndex';
import { ObjectData } from '@frontends/commons';

@Injectable({
  providedIn: 'root'
})
export class BatchEditService {
  selectedObjects!: ObjectData[];
  optionPathArray: OptionPath[] = [];
  formArray!: FormArray | null;
  formValues: ObjectData = {};
  editedObjects!: ObjectData[];
  editedFields!: BatchField[];
  fieldNames!: FieldName;

  constructor(private translateService: TranslateService) {
  }

  getFormValues(): ObjectData {
    this.formArray &&
    this.formArray.controls.forEach((formGroup) => {
      const formValue = formGroup.value;
      Object.keys(formValue).forEach((key) => {
        this.formValues[key] = formValue[key];
      });
    });
    return this.formValues;
  }

  setOptionPathArray(optionPathArray: OptionPath[]) {
    this.optionPathArray = optionPathArray;
  }

  setFormArray(formArray: FormArray) {
    this.formArray = formArray;
  }

  getOptionPathArray(): OptionPath[] {
    return this.optionPathArray;
  }

  getEditedObjects(): ObjectData[] {
    return this.editedObjects;
  }

  setSelectedObjects(selected: ObjectData[]) {
    this.selectedObjects = selected;
  }

  getSelectedObjects(): ObjectData[] {
    return this.selectedObjects;
  }

  getEditedFields(): BatchField[] {
    return this.editedFields;
  }

  setFieldNames(fieldNames: any) {
    this.fieldNames = fieldNames;
  }

  getFieldNames(): FieldName {
    return this.fieldNames;
  }

  updateEditedObjects() {
    this.selectedObjects.forEach((object) => {
      Object.keys(object).forEach((key) => {
        if (object[key] instanceof Object) {
          delete object[key];
        }
      });
    });
    this.editedObjects = this.selectedObjects.map((object) => JSON.parse(JSON.stringify(object)));
    this.editedObjects.forEach((object) => {
      this.updateObjectValues(object);
    });
    this.editedObjects.forEach((object) => {
      Object.keys(object).forEach((key) => {
        if (key !== 'docId' && key !== 'dataType') {
          if (this.editedFields.find((editedField) => editedField.fieldOfPath.path === key) === undefined) {
            delete object[key];
          }
        }
      });
    });
  }

  clearAll() {
    this.selectedObjects = [];
    this.optionPathArray = [];
    this.formArray = null;
    this.formValues = {};
    this.editedObjects = [];
    this.editedFields = [];
    this.fieldNames = {};
  }

  private updateObjectValues(object: ObjectData) {
    this.editedFields = [];
    this.optionPathArray.forEach((optionPath) => {
      if (optionPath.path === 'documents' || optionPath.path === 'images') {
        delete object[optionPath.path];
        return;
      }

      const optionTranslation = this.translateService.instant(optionPath.option);
      const formValue = this.formValues[optionPath.path];

      if (optionTranslation === this.translateService.instant('MESSAGE.ACTION.clear')) {
        this.handleClearAction(optionPath, object);
      } else if (optionTranslation === this.translateService.instant('MESSAGE.ACTION.overwrite')) {
        this.handleOverwriteAction(optionPath, object, formValue);
      } else if (optionTranslation === this.translateService.instant('MESSAGE.ACTION.prefix')) {
        this.handlePrefixAction(optionPath, object, formValue);
      } else if (optionTranslation === this.translateService.instant('MESSAGE.ACTION.postfix')) {
        this.handlePostfixAction(optionPath, object, formValue);
      }
    });
  }

  private handleClearAction(optionPath: OptionPath, object: ObjectData) {
    this.editedFields.push({
      fieldName: this.fieldNames[optionPath.path],
      fieldOfPath: optionPath,
      fieldValue: this.translateService.instant('MESSAGE.ACTION.clear')
    });
    object[optionPath.path] = null;
  }

  private handleOverwriteAction(optionPath: OptionPath, object: ObjectData, formValue: any) {
    if (
      formValue !== undefined &&
      formValue !== null &&
      (formValue.length > 0 ||
        formValue instanceof Date ||
        typeof formValue === 'boolean' ||
        Object.keys(formValue).length > 0)
    ) {
      if (optionPath.field !== undefined && optionPath.field.type === FieldTypes.HIERARCHY) {
        if (formValue.l1 === '' || formValue.l1 === undefined) {
          delete object[optionPath.path];
          return;
        } else {
          //loop through all the levels and delete the ones that are empty
          Object.keys(formValue).forEach((key) => {
            if (formValue[key] === '' || formValue[key] === undefined) {
              delete formValue[key];
            }
          });
        }
      }
      this.editedFields.push({
        fieldName: this.fieldNames[optionPath.path],
        fieldOfPath: optionPath,
        fieldValue: formValue
      });
      object[optionPath.path] = formValue;
    } else if (formValue === undefined || formValue === null || Object.keys(formValue).length === 0) {
      delete object[optionPath.path];
    }
  }

  private handlePrefixAction(optionPath: OptionPath, object: ObjectData, formValue: any) {
    if (formValue !== undefined && formValue !== null) {
      this.editedFields.push({
        fieldName: this.fieldNames[optionPath.path],
        fieldOfPath: optionPath,
        fieldValue: formValue
      });
      object[optionPath.path] = formValue + (object[optionPath.path] || '');
    }
  }

  private handlePostfixAction(optionPath: OptionPath, object: ObjectData, formValue: any) {
    if (formValue !== undefined && formValue !== null) {
      this.editedFields.push({
        fieldName: this.fieldNames[optionPath.path],
        fieldOfPath: optionPath,
        fieldValue: formValue
      });
      object[optionPath.path] = (object[optionPath.path] || '') + formValue;
    }
  }
}
