import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

export enum StorageType {
  LOCAL = 'local',
  SESSION = 'session',
}

@Injectable({
  providedIn: 'root',
})
export class BaseStorageService {
  private storage: Storage = sessionStorage;
  constructor(protected logger: NGXLogger, protected storageType: StorageType) {
    this.storage = this.getStorage();
  }

  protected setItem(key: string, item: any): void {
    this.logger.trace(`[${BaseStorageService.name}]: SET ITEM: ${key}`, JSON.stringify(item));
    this.storage.setItem(key, JSON.stringify(item));
  }

  protected getItem(key: string): string {
    const item = this.storage.getItem(key);
    if (item) {
      this.logger.trace(`[${BaseStorageService.name}]: FETCH ITEM: ${key} `, JSON.parse(item));
      return item;
    }
    return '';
  }

  protected removeItem(key: string): void {
    this.logger.trace(`[${BaseStorageService.name}]: REMOVE ITEM: ${key}`);
    this.storage.removeItem(key);
  }

  private getStorage(): Storage {
    switch (this.storageType) {
      case StorageType.LOCAL:
        return localStorage;
      case StorageType.SESSION:
        return sessionStorage;
      default:
        return sessionStorage;
    }
  }
}
