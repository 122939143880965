/**
 * Explanner Service Documentation
 * Explanner Service Documentation
 *
 * The version of the OpenAPI document: 1.7_landesstelle{20240611@153537}
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GenericFormular } from './genericFormular';

export interface GenericMasterFormular {
  docId?: string;
  title?: string;
  forms?: Array<GenericFormular>;
  typedForms?: { [key: string]: GenericFormular };
  dataType?: GenericMasterFormular.DataTypeEnum;
  headerFields?: Array<string>;
  imageField?: string;
  titleField?: string;
  formsToSync?: Array<string>;
}

export namespace GenericMasterFormular {
  export type DataTypeEnum = 'ADDRESS' | 'INSTITUTE' | 'OBJECT';
  export const DataTypeEnum = {
    Address: 'ADDRESS' as DataTypeEnum,
    Institute: 'INSTITUTE' as DataTypeEnum,
    Object: 'OBJECT' as DataTypeEnum,
  };
}
