import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiClientService } from '../../../app/services/api-client.service';
import { TranslateHelpService } from '../../../app/services/translate-help.service';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'exp-password-change-dialog',
  templateUrl: './password-change-dialog.component.html',
  styleUrls: ['./password-change-dialog.component.scss'],
})
export class PasswordChangeDialogComponent {
  public oldPassword!: string;
  public newPasswordRepeat!: string;
  public showError = true;
  public matchError = false;

  private newPassword!: string;
  private newPasswordVisibility = false;
  private newPasswordRepeatVisibility = false;
  /*
  At least one upper case English letter, (?=.*?[A-Z])
  At least one lower case English letter, (?=.*?[a-z])
  At least one digit, (?=.*?[0-9])
  At least one special character, (?=.*?[#?!@$%^&*-])
  Minimum eight in length .{8,} (with the anchors) */
  public passwordRegex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$');

  constructor(
    public dialogRef: MatDialogRef<PasswordChangeDialogComponent>,
    private translationHelp: TranslateHelpService,
    private apiClientService: ApiClientService,
    private alertService: AlertService
  ) {}

  public setPassword(value: string): void {
    this.newPassword = value;
    this.checkPassword(this.newPassword);
  }

  public setRepeatPassword(value: string): void {
    this.newPasswordRepeat = value;
    if (this.newPassword) this.validatePassword(this.newPassword, this.newPasswordRepeat);
  }

  private checkPassword(password: string): void {
    if (password === '' || !password.match(this.passwordRegex) || password.length < 8) {
      {
        this.showError = true;
      }
    } else {
      {
        this.showError = false;
      }
    }
  }

  private validatePassword(password: string, passwordRepeat: string): void {
    if (password === '' || !password.match(this.passwordRegex) || password.length < 8) {
      {
        this.showError = true;
      }
    } else {
      {
        this.showError = false;
      }
    }
    this.confirm(password, passwordRepeat);
  }

  private confirm(password: string, repeat: string): void {
    if (password !== repeat) {
      {
        this.matchError = true;
      }
    } else {
      {
        this.matchError = false;
      }
    }
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public changePassword(): void {
    if (
      this.newPassword?.localeCompare(this.newPasswordRepeat) === 0 &&
      this.newPasswordRepeat?.length >= 8 &&
      !!this.newPassword.match(this.passwordRegex)
    ) {
      const passChangeRequest = {
        oldPass: this.oldPassword,
        newPass: this.newPassword,
        newPass2: this.newPasswordRepeat,
      };
      this.apiClientService.updatePassword(passChangeRequest).subscribe(() => {
        this.alertService.openSnackBar(this.translationHelp.translate('SUCCESS_MESSAGE.' + 'password', 'update'), '');
        this.dialogRef.close();
      });
    }
  }
}
