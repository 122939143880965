<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<table class="exp-table" mat-table [dataSource]="dataSourceOT" matSort>
  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->
  <!-- Columns loaded from backend -->
  @for (column of displayedColumns; track $index) {
    <span>
      <ng-container [matColumnDef]="column">
        <th
          [ngClass]="{ smallCol: getType(column) === FieldTypes.RADIO || column === FieldTypes.SELECT }"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          [disabled]="column === FieldTypes.CHECKBOX || showImage(column)">
          @if (column === FieldTypes.CHECKBOX) {
            <mat-checkbox
              id="checkAll"
              [disabled]="!dataSourceOT.data.length"
              [ngClass]="{ selectrow: column === FieldTypes.CHECKBOX }"
              (change)="$event ? toggleAllRows() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [matTooltip]="'A11Y.TOOLTIP.selectAllCheckbox' | translate"
              [aria-label]="checkboxLabel()" />
          }
          @if (showHeaderLabel(column) && !isFieldTypeApt(column) && fieldNames[column]) {
            <span>
              {{ fieldNames[column] }}
            </span>
          }
          @if (isFieldTypeApt(column)) {
            <div class="table-range-wrapper">
              <span>{{ dateRangeValue ? dateRangeValue : fieldNames[column] }}</span>
              <div class="table-range">
                <ds-datepicker-range
                  (pickerRef)="onPickerRef($event)"
                  (dateRangeOutput)="onDateRangeChange($event, column)" />
              </div>
              <ds-icon-button
                icon="date_range"
                color="primary"
                (click)="onRangeBtnClick($event)"
                tooltipLabel="{{ 'BUTTONS.setDateRange' | translate }}" />
            </div>
          }
        </th>
        <td mat-cell *matCellDef="let element">
          @if (showDefaultLabel(column)) {
            @if (!column.includes('.')) {
              <span>{{ element[column] }}</span>
            } @else {
              <span>
                {{ element | propertyAccess: 'loan_owner.institution' }}
              </span>
            }
          }
          @if (column === '') {
            <span></span>
          }
          @if (column === FieldTypes.CHECKBOX) {
            <mat-checkbox
              class="checkOne"
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(element) : null"
              [checked]="selection.isSelected(element)"
              [matTooltip]="'A11Y.TOOLTIP.selectObject' | translate"
              [aria-label]="checkboxLabel(element)" />
          }
          @if (showImage(column)) {
            <figure class="center-items small-image-container">
              @if (element[column]?.length) {
                <img
                  [src]="getSmallImage(project, element[column][0])"
                  [alt]="('A11Y.ALT.imagePreview' | translate) + ':' + (element[column][0].description ?? '')"
                  (click)="displayImage($event, element[column][0])"
                  (keydown.code.enter)="displayImage($event, element[column][0])" />
              }
            </figure>
          }
          @if (isFieldTypeApt(column)) {
            @if (element[column]) {
              <ds-chips
                [color]="element[column] === 'mixed' ? 'gray' : element[column]"
                label="{{ getLabelFromColor(element[column]) | translate }}" />
            } @else {
              <span>{{ 'TABLE.setDateRange' | translate }}</span>
            }
          }
          @if (showTrafficLight(column)) {
            @if (element[column]) {
              <ds-chips [color]="element[column]" label="{{ 'LABELS.Trafficlight_' + element[column] | translate }}" />
            }
          }
        </td>
      </ng-container>
    </span>
  }
  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: false"></tr>
  @if (!isLoadingResults) {
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns; let i = index"
      [class.active]="row.docId === activeRow"
      [ngClass]="{ 'core-soft-delete': isSoftDeleteCore(row) }"
      [attr.id]="row.docId"
      class="clickable select-row"
      (click)="goToDetail(row, i)"
      (keydown.code.enter)="goToDetail(row, i)"
      tabindex="0"></tr>
  }
</table>
@if (isLoadingResults) {
  <mat-progress-bar mode="query" />
}
