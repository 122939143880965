/* eslint-disable @typescript-eslint/member-ordering */
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { AuthService, DataType, FieldName, GenericForm, GenericMetaForm, ObjectData } from '@frontends/commons';
import * as _ from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, forkJoin, map, Observable, of } from 'rxjs';
import { FieldTypes } from '../../model/Enums/FieldTypesEnum';
import { AlertService } from '../alert.service';

import { ObjectDataService } from '../data/object-data.service';
import { TranslateHelpService } from '../translate-help.service';
import { FieldService } from './field.service';
import { FormGroupBuilderService } from './form-builder.service';
import { FormService } from './form.service';

export interface FormData {
  formArray: FormArray;
  workingObject: ObjectData;
  forms: GenericForm[];
  fieldNames: FieldName;
}

@Injectable()
export class FormManagerService {
  private submittedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private touchedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);

  public submitted$: Observable<boolean> = this.submittedSubject.asObservable();
  public formTouched$: Observable<boolean> = this.touchedSubject.asObservable();
  public loading$: Observable<boolean> = this.loadingSubject.asObservable();

  private workingObject!: ObjectData;
  private origObject!: ObjectData;

  private form$!: Observable<GenericMetaForm>;
  private formArray: FormArray;

  private forms!: GenericForm[];
  private fieldNames!: FieldName;

  constructor(
    private authService: AuthService,
    private objectDataService: ObjectDataService,
    private formService: FormService,
    private fieldService: FieldService,
    private formBuilder: FormBuilder,
    private formGroupBuilder: FormGroupBuilderService,
    private alert: AlertService,
    private translateHelp: TranslateHelpService,
    private logger: NGXLogger,
    private datePipe: DatePipe,
  ) {
    this.formArray = this.formBuilder.array([]);
  }

  public prepareFormData(project: string, dataType: DataType, objectId: string): void {
    this.logger.debug(`[FORM-MANAGER]: prepare form data`, this);
    this.retrieveForm(project, dataType);
    const object$ = objectId ? this.objectDataService.getObject(project, objectId, dataType) : of({});
    const form$ = this.formService.getForm(project, dataType);
    const fieldNames$ = this.fieldService.getFields(project, dataType);
    this.setupForm(forkJoin({ object$, form$, fieldNames$ }), dataType);
  }

  private retrieveForm(project: string, dataType: DataType): void {
    this.form$ = this.formService.getForm(project, dataType);
  }

  private setupForm(
    formObjectJoin: Observable<{ object$: ObjectData; form$: GenericMetaForm; fieldNames$: FieldName }>,
    dataType: DataType,
  ): void {
    this.logger.debug(`[FORM-MANAGER]: Start form setup`, this);

    formObjectJoin.subscribe({
      next: (join) => {
        const userHasWritePermission = this.authService.hasWritePermissionsForDatatype(dataType);
        this.workingObject = join.object$;
        this.origObject = _.cloneDeep(join.object$);
        this.forms = join.form$.forms;
        this.fieldNames = join.fieldNames$;
        for (const gForm of this.forms) {
          for (const field of gForm.fields) {
            //fields of lookups not required for user that has only rights for edit objects
            if (
              field.type === FieldTypes.LOOKUP &&
              !this.authService.hasReadPermissionsForDatatype(field.lookupTo ?? 'OBJECT', field.lookupToProject)
            ) {
              field.required = false;
            }
          }
          this.formArray.push(
            this.formGroupBuilder.createFormGroup(gForm.fields, this.workingObject, userHasWritePermission),
          );
        }
      },
      complete: () => {
        this.loadingSubject.next(false);
        this.logger.debug(`[FORM-MANAGER]: Form setup complete`);
      },
    });
  }

  public formTouched(touched: boolean): void {
    this.touchedSubject.next(touched);
  }

  public submit(mode: 'EDIT' | 'CREATE', dataType: DataType, projectIdentifier?: string) {
    this.logger.debug(`[FORM-MANAGER]: SUBMIT FORM`, this.formArray);
    if (this.formArray.valid) {
      this.logger.debug(`[FORM-MANAGER]: FORM VALID .... SUBMITTING`);
      if (mode === 'CREATE') {
        this.objectDataService.createObject(this.getDelta(), dataType, projectIdentifier).subscribe((status) => {
          this.logger.debug(`[FORM-MANAGER]: RESPONSE STATUS`, status);
          if (status.success) {
            this.submittedSubject.next(true);
          }
        });
      } else {
        this.objectDataService.updateObject(this.getDelta(), dataType, projectIdentifier).subscribe((status) => {
          this.logger.debug(`[FORM-MANAGER]: RESPONSE STATUS`, status);
          if (status.success) {
            this.updateWorkingObject();
            this.updateOriginObject();
            this.submittedSubject.next(true);
            this.touchedSubject.next(false);
          }
        });
      }
    } else {
      this.logger.debug(`[FORM-MANAGER]: FORM INVALID`);
      this.formArray.controls.forEach((control) => {
        control.markAllAsTouched();
      });
      this.submittedSubject.next(false);
      this.alert.infoAlertDialog(
        '',
        this.translateHelp.translate('WARNING_MESSAGE.incorrectForm'),
        this.translateHelp.translate('ok'),
      );
    }
  }

  private updateWorkingObject(): void {
    this.workingObject = { ...this.workingObject, ...this.getFilledValues(this.formArray) };
  }

  private updateOriginObject(): void {
    this.origObject = _.cloneDeep(this.workingObject);
  }

  private getDelta(): ObjectData {
    const deltaObject: ObjectData = {
      docId: this.origObject.docId,
      dataType: this.origObject.dataType,
    };
    const formValues: { [key: string]: any } = this.getFilledValues(this.formArray);
    const changingObject: ObjectData = { ...this.workingObject, ...formValues };
    const keys = new Set(Object.keys(this.origObject).concat(Object.keys(changingObject)));

    keys.forEach((key) => {
      if (changingObject[key] !== undefined) {
        if (!_.isEqual(this.origObject[key], changingObject[key])) {
          if (this.isOnlyInitializedValue(this.origObject[key], changingObject[key])) {
            return;
          }
          this.logger.debug('NON EQUALS', this.origObject[key], changingObject[key]);
          this.logger.debug(
            `IS NOT EQUAL ${key} : ORIG: ${JSON.stringify(this.origObject[key])} - CUR: ${JSON.stringify(
              changingObject[key],
            )}`,
            this.origObject[key] === changingObject[key],
            _.isEqual(this.origObject[key], changingObject[key]),
          );
          if (this.emptyChecker(changingObject[key])) {
            deltaObject[key] = null;
            return;
          }
          deltaObject[key] = changingObject[key];
        }
      }
    });
    return deltaObject;
  }

  private isPrimitive(changingValue: any): boolean {
    if (typeof changingValue === 'boolean' || typeof changingValue === 'string' || typeof changingValue === 'number') {
      return true;
    }
    return false;
  }

  private isOnlyInitializedValue(origValue: any, changingValue: any): boolean {
    if ((!this.isPrimitive(changingValue) && origValue === undefined) || origValue === null) {
      if (Array.isArray(changingValue) && changingValue.length === 0) {
        return true;
      }

      if (
        typeof changingValue === 'object' &&
        !(changingValue instanceof Date) &&
        Object.keys(changingValue).length === 0
      ) {
        return true;
      }

      if (this.isEmptyHierarchy(changingValue)) {
        return true;
      }
    }

    return false;
  }

  private emptyChecker(changingValue: any): boolean {
    if (typeof changingValue === 'boolean') return false;
    return (
      changingValue === '' ||
      (_.isObject(changingValue) && _.isEmpty(changingValue)) ||
      (_.isArray(changingValue) && changingValue.length === 0) ||
      (_.isObject(changingValue) && this.isEmptyHierarchy(changingValue))
    );
  }

  private isEmptyHierarchy(changingObject: any): boolean {
    if (_.isObject(changingObject)) {
      return false;
    }
    for (const key in changingObject) {
      if (Object.hasOwn(changingObject, key) && changingObject[key] !== undefined && changingObject[key] !== '') {
        return false;
      }
    }
    return true;
  }

  private getFilledValues(formArray: FormArray): { [key: string]: any } {
    const groups = formArray.controls;
    const filledValues: { [key: string]: any } = {};

    groups.forEach((group) => {
      const value = group.value;
      for (const key in value) {
        if (Object.hasOwn(value, key)) {
          if (value[key] !== undefined) {
            if (typeof value[key] === 'object' && !Array.isArray(value[key])) {
              filledValues[key] = _.omitBy(value[key], _.isUndefined);
            } else {
              filledValues[key] = value[key];
            }
          }
          if (value[key] instanceof Date) {
            const stringifyDate = this.datePipe.transform(value[key], 'dd.MM.yyyy') || '';
            filledValues[key] = stringifyDate;
          }
        }
      }
    });
    return filledValues;
  }

  public isFormValid(): boolean {
    return this.formArray.valid;
  }

  public getFormData(): FormData {
    return {
      formArray: this.formArray,
      workingObject: this.workingObject,
      forms: this.forms,
      fieldNames: this.fieldNames,
    };
  }

  public getHeaderFields(): Observable<string[]> {
    return this.form$.pipe(map((genericForm) => genericForm.headerFields));
  }

  public getTitleField(): Observable<string> {
    return this.form$.pipe(map((genericForm) => genericForm.titleField));
  }

  public getWorkingObject(): ObjectData {
    return this.workingObject;
  }

  public getFieldNames(): FieldName {
    return this.fieldNames;
  }

  public getChangeObject(): ObjectData {
    return this.getDelta();
  }
}
