<div class="flex-col">
  <div class="dialog-header">
    <h2 mat-dialog-title class="dialog-header-label">
      {{ data.object['basis_humanId'] }} {{ 'LABELS.clone' | translate }}
    </h2>
    <ds-icon-button class="close-button" icon="close" color="primary" mat-dialog-close />
  </div>
  <div mat-dialog-content class="dialog-content">
    <ds-input
      class="clone-input"
      value="1"
      label="{{ 'LABELS.numberToClone' | translate }}"
      type="number"
      #inputDuplicatedNumber />
  </div>
  <ds-horizontal-divider />
  <div class="dialog-actions" mat-dialog-actions>
    <ds-basic-button color="accent" (clickButton)="closeDialog()" label="{{ 'BUTTONS.cancel' | translate }}" />
    <ds-primary-button
      color="accent"
      (clickButton)="duplicateObjet(inputDuplicatedNumber.value)"
      [disabled]="!inputDuplicatedNumber.value"
      label="{{ 'LABELS.clone' | translate }}" />
  </div>
</div>
