import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, finalize, tap } from 'rxjs';

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  constructor(private logger: NGXLogger) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let status: string;
    let xID: string;

    return next.handle(req).pipe(
      tap({
        // Succeeds when there is a response; ignore other events
        next: (event) => {
          if (event instanceof HttpResponse) {
            status = 'succeeded';
            xID = event.headers.get('wxi_xid') ?? '';
          }
          status = '';
        },
        // Operation failed; error is an HttpErrorResponse
        error: (error) => (status = 'failed'),
      }),
      finalize(() => {
        const log = `[HTTP]: CALL - ${req.method}:${req.url} - ${status} - xID:${xID} `;
        this.logger.log(log, req.headers);
      })
    );
  }
}
