import { DataTypes } from '../Enums';
import { DataType } from '../Types';

export const EMPTY_MENU_DATA: MenuData = {
  name: '',
  title: '',
  dataType: DataTypes.OBJECT,
  defaultView: '',
  writePermission: false,
  deletePermission: false,
  createPermission: false,
  criteria: [],
};

export const EMPTY_VIEW_DATA: ViewData = {
  name: '',
  fields: [],
  facets: [],
};

export interface TupleMenuViewData {
  key: MenuData;
  value: ViewData[];
}

export interface MenuData {
  name: string;
  title: string;
  dataType: DataType;
  defaultView: string;
  writePermission: boolean;
  deletePermission: boolean;
  createPermission: boolean;
  criteria: EnhancedCriteria[];
  blacklistedFields?: Set<string>;
  whitelistedFields?: Set<string>;
}

export interface ViewData {
  name: string;
  fields: string[];
  facets: string[];
}

export interface EnhancedCriteria {
  type?: 'MUST' | 'MUSTNOT';
  key?: string;
  values?: Array<string>;
}
