<div style="display: none" id="print">
  <!-- Header of the pdf -->
  <table>
    <tr>
      <td>
        <div class="title bolder-lg">{{ 'LABELS.project' | translate }}: {{ currentProject?.name }}</div>
        <div class="sub-title add-space">
          {{ 'LABELS.status' | translate }}: {{ currentDate | date : 'dd-MM-yyyy h:m:s' }}
        </div>
      </td>
    </tr>
    <tr *ngIf="activeSearch">
      <td>
        <div class="title">{{ 'LABELS.activeSearch' | translate }}</div>
        <div class="sub-title">{{ activeSearch }}</div>
      </td>
    </tr>
    <tr *ngIf="existFilter">
      <td>
        <ng-container>
          <div class="title">{{ 'LABELS.activeFilter' | translate }}</div>
          <span class="sub-title" *ngFor="let item of activeQuickFilter | keyvalue">
            {{ fieldNames[item.key] + ':' + item.value }},
          </span>
        </ng-container>
        <ng-container *ngIf="activeHierarchyFilter">
          <span class="sub-title inline" *ngFor="let item of activeHierarchyFilter">
            <ng-container *ngFor="let element of regroupHierarchy(item) | keyvalue">
              {{ fieldNames[element.key + ''] }}:<span *ngFor="let el of displayValue(element.value)"
                >{{ checkEpmtyValue(el.name) ? ('LABELS.na' | translate) : el.name }},</span
              >
            </ng-container>
          </span>
        </ng-container>
        <ng-container *ngIf="activeMinMax">
          <span *ngFor="let item of activeMinMax | keyvalue" class="sub-title inline">
            {{ fieldNames[item.key + ''] + ': ' + item.value['min'] + ' -- ' + item.value['max'] }},</span
          >
        </ng-container>
      </td>
    </tr>
    <tr>
      <td>
        <div class="title">{{ 'LABELS.objectNumber' | translate }}</div>
        <span class="sub-title">{{ objects?.length }} </span>
      </td>
    </tr>
  </table>
  <hr class="header-divider {{ pageBreak ? 'only-header' : '' }}" />
  <!-- fields and images  -->
  <table>
    <tr *ngFor="let document of objects; index as i" class="{{ pageBreak ? 'page-break' : '' }} bottom-border">
      <td class="display-fields">
        <div class="sub-title">{{ i + 1 + '/' + objects?.length }}</div>
        <div class="title bolder-lg gap-fields">{{ document['basis_humanId'] }}</div>
        <br />
        <ng-container *ngFor="let fieldsToExport of exportFields">
          <div class="title">{{ fieldsToExport.k }}</div>
          <ng-container *ngIf="fieldsToExport.v && checkType(fieldsToExport.v, pathType) !== FieldTypes.LABEL">
            <!-- Other types-->
            <ng-container
              *ngIf="
                !isExtraType(checkType(fieldsToExport.v, pathType)) &&
                !isExtraType(checkType(fieldsToExport.v.split('.')[0], pathType))
              ">
              <div class="sub-title gap-fields">
                {{ document[fieldsToExport.v] ? document[fieldsToExport.v] : '-' }}
              </div>
            </ng-container>
            <!-- extra types-->
            <!-- HIERARCHY-->
            <ng-container *ngIf="checkType(fieldsToExport.v, pathType) === FieldTypes.HIERARCHY">
              <div
                class="sub-title"
                *ngIf="
                  !checkEmptyObject(document[fieldsToExport.v]) || document[fieldsToExport.v] === null;
                  else emptyHIERARCHY
                ">
                <ng-container *ngFor="let hierarchyItem of document[fieldsToExport.v] | keyvalue">
                  <span>{{ hierarchyItem.value }},</span>
                </ng-container>
              </div>
              <ng-template #emptyHIERARCHY>
                <div class="sub-title">-</div>
              </ng-template>
            </ng-container>

            <!-- APPOINTMENT_LIST -->
            <ng-container *ngIf="checkType(fieldsToExport.v, pathType) === FieldTypes.APPOINTMENT_LIST">
              <div class="sub-title" *ngIf="document[fieldsToExport.v]; else emptyAPPOINTMENT">
                <ng-container *ngFor="let appointmentItem of document[fieldsToExport.v]">
                  <div>{{ getFormatAppointment(appointmentItem) }}</div>
                </ng-container>
              </div>
              <ng-template #emptyAPPOINTMENT>
                <div class="sub-title">-</div>
              </ng-template>
            </ng-container>

            <!-- LOOKUP -->
            <ng-container *ngIf="checkType(fieldsToExport.v.split('.')[0], pathType) === FieldTypes.LOOKUP">
              <div
                class="sub-title"
                *ngIf="lookUpValue(document[fieldsToExport.v.split('.')[0]], fieldsToExport.v); else emptyLOOKUP">
                {{ lookUpValue(document[fieldsToExport.v.split('.')[0]], fieldsToExport.v) }}
              </div>
              <ng-template #emptyLOOKUP>
                <div class="sub-title">-</div>
              </ng-template>
            </ng-container>

            <!-- Date -->
            <ng-container *ngIf="checkType(fieldsToExport.v, pathType) === FieldTypes.DATE">
              <div class="sub-title">
                {{ document[fieldsToExport.v] }}
              </div>
            </ng-container>
          </ng-container>
          <br />
        </ng-container>
      </td>
      <td class="display-images">
        <ng-container *ngFor="let image of document['images']">
          <div class="image add-space-img">
            <img class="img" [src]="getMediumImage(image)" alt="Image " />
          </div>
        </ng-container>
      </td>
    </tr>
  </table>
</div>
