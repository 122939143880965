export enum FieldTypes {
  STRING = 'STRING',
  TRAFFICLIGHT = 'TRAFFICLIGHT',
  TEXTAREA = 'TEXTAREA',
  SELECT = 'SELECT',
  MULTISELECT = 'MULTISELECT',
  CHECKBOX = 'CHECKBOX',
  RADIO = 'RADIO',
  ADDRESS = 'ADDRESS',
  DOCUMENTS = 'DOCUMENTS',
  IMAGES = 'IMAGES',
  LABEL = 'LABEL',
  DATE = 'DATE',
  HIERARCHY = 'HIERARCHY',
  DIMENSION = 'DIMENSION',
  LOOKUP = 'LOOKUP',
  MULTILOOKUP = 'MULTILOOKUP',
  NUMBER = 'NUMBER',
  KVMAP = 'KVMAP',
  STRINGLIST = 'STRINGLIST',
  APPOINTMENT_LIST = 'APPOINTMENT_LIST',
}
