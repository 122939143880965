import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '@frontends/designsystem';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NgIf } from '@angular/common';

@Component({
  selector: 'cmn-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.scss'],
  standalone: true,
  imports: [MatIconModule, TranslateModule, ButtonsModule, NgxDocViewerModule, NgIf],
})
export class DocumentPreviewComponent {
  constructor(public dialogRef: MatDialogRef<DocumentPreviewComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  onClose(): void {
    this.dialogRef.close();
  }
}
