import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstLetterCapital',
  standalone: true,
})
export class FirstLetterCapitalPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '-';
    }
    return value.replace(/\b\w/g, (first) => first.toLocaleUpperCase());
  }
}
