import { SelectOption } from '@frontends/designsystem';

export const languages: SelectOption = {
  de: { value: 'Deutsch' },
  en: { value: 'English' },
  fr: { value: 'Français' },
};

export const SUPPORT_TYPE_OPTIONS = [
  {
    value: 'Bug / Incident',
    viewValue: 'SUPPORT.type.bugOrIncident',
  },
  {
    value: 'Change Request',
    viewValue: 'SUPPORT.type.changeRequest',
  },
  {
    value: 'Service Request',
    viewValue: 'SUPPORT.type.serviceRequest',
  },
];
export const SUPPORT_PRIORITY_OPTIONS = [
  {
    value: 'Blocker',
    viewValue: 'SUPPORT.priority.blocker',
  },
  {
    value: 'Critical',
    viewValue: 'SUPPORT.priority.critical',
  },
  {
    value: 'Major',
    viewValue: 'SUPPORT.priority.major',
  },
  {
    value: 'Minor',
    viewValue: 'SUPPORT.priority.minor',
  },
  {
    value: 'Trivial',
    viewValue: 'SUPPORT.priority.trivial',
  },
];
