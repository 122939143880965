<div class="container" *ngIf="!valueOnly; else showValueOnlyState">
  <div class="label-container">
    <ds-icon-badge *ngIf="internal" [iconClass]="iconClass" [tooltip]="tooltip"></ds-icon-badge>
    <span class="mat-subtitle-2"> {{ label }}{{ required ? '*' : '' }} </span>
  </div>
  <mat-radio-group [formControl]="control" (change)="radioChange($event)" [color]="color" [disabled]="disabled">
    <mat-radio-button
      [checked]="checkedValue === option"
      (click)="clickRadioButton.emit($event)"
      *ngFor="let option of options"
      [value]="option"
      [required]="required">
      {{ option }}
    </mat-radio-button>
  </mat-radio-group>
</div>
<ng-template #showValueOnlyState>
  <div class="margin-bottom">
    <span class="mat-subtitle-2">{{ label }}</span>
    <div class="mat-body-2">
      {{ checkedValue ? checkedValue : '-' }}
    </div>
  </div>
</ng-template>
