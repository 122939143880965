import { Component, Input } from '@angular/core';
import { ThemeColors } from '../../../types/colors';
import { BaseButtonComponent } from '../../Base/base-button.component';

@Component({
  selector: 'ds-basic-icon-button',
  templateUrl: './basic-icon-button.component.html',
  styleUrls: ['./basic-icon-button.component.scss'],
})
export class BasicIconButtonComponent extends BaseButtonComponent {
  @Input() public icon = '';
  @Input() public fontColor: ThemeColors = 'primary';
  @Input() public iconBefore = false;
}
