/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFieldComponent } from '../../Base/base-field.component';

@Component({
  selector: 'ds-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiselectComponent),
      multi: true,
    },
  ],
})
export class MultiselectComponent extends BaseFieldComponent {
  @Input() options: string[] = [];
  @Input() selectedValues: Array<string> = [];

  @Output() selectedValuesChange = new EventEmitter<Array<string>>();
  @Output() selectChanged = new EventEmitter<void>();
  @Output() clear = new EventEmitter<MouseEvent>();

  selectionChanged() {
    this.selectedValuesChange.emit(this.selectedValues);
    this.selectChanged.emit();
    this.onChange(this.selectedValues);
    this.onTouched();
  }
  reset(event: Event) {
    event.stopPropagation();
    this.selectedValues = [];
    this.clear.emit();
    this.onChange(this.selectedValues);
    this.onTouched();
  }

  override onBlur() {
    this.onChange(this.selectedValues);
    this.onTouched();
  }

  // Implement the ControlValueAccessor methods
  protected override onChange: (value: Array<string>) => void = () => {};

  override writeValue(value: Array<string>): void {
    if (value && value != null) {
      this.selectedValues = value;
    } else {
      this.selectedValues = [];
    }
  }
  override registerOnChange(fn: (value: Array<string>) => void): void {
    this.onChange = fn;
  }
}
