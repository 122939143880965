/* eslint-disable @typescript-eslint/no-empty-function */
import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ButtonsModule, InputFieldsModule } from '@frontends/designsystem';
import { FileControllerService } from '@frontends/exp-apiclient';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { CmnLocalStorageService } from '../../../services';
import { AlertService } from '../../../services/alert/alert.service';
import { LoaderService } from '../../../services/util/file-uploader/loader.service';
import { OverlayInteractionService } from '../../../services/util/file-uploader/overlay-interaction.service';
import { PathService } from '../../../services/util/path.service';
import { TranslateHelpService } from '../../../services/util/translate-help.service';
import { DocumentPreviewComponent } from '../document-preview/document-preview.component';
import { ImagePreviewComponent } from '../image-preview/image-preview.component';
import { FirstLetterCapitalPipe } from '../../../pipes';

@Component({
  selector: 'cmn-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploaderComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    ButtonsModule,
    TranslateModule,
    InputFieldsModule,
    CdkDropList,
    NgClass,
    MatIconModule,
    CdkDrag,
    NgIf,
    NgForOf,
    FirstLetterCapitalPipe,
  ],
})
export class FileUploaderComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @ViewChild('fileInput') private fileInputRef!: ElementRef;
  @Output() public fileUploading = new EventEmitter<string>();
  @Input() public type!: 'IMAGE' | 'FILE';
  @Input() public valueOnly = false;
  @Input() public hasWritePermission?: boolean;
  @Input() public hasDownloadButton = true;
  @Input() public project!: string;
  @Input() public files?: Array<any>;
  @Input() public environmentApiUrl!: string;
  @Input() public options?: Array<string>;
  @Output() private filesChange = new EventEmitter<any>();
  @Output() private onfilesChange = new EventEmitter<any>();

  public docDescription = '';
  private subscribe: any;
  public disabled!: boolean;
  public isFileInputFocused = false;

  private overlayButtonClickedSubscription: Subscription;
  isDragging!: boolean;

  constructor(
    private fileController: FileControllerService,
    private pathService: PathService,
    private localStorageService: CmnLocalStorageService,
    private translateHelp: TranslateHelpService,
    private dialog: MatDialog,
    private logger: NGXLogger,
    private loaderService: LoaderService,
    private overlayInteractionService: OverlayInteractionService,
    private alertService: AlertService,
    private translateService: TranslateService,
  ) {
    this.overlayButtonClickedSubscription = this.overlayInteractionService.overlayButtonClicked$.subscribe(() => {
      this.abortLoadingScreen();
    });
  }

  ngOnInit() {
    if (!this.files) {
      this.files = [];
    }

    if (!this.project) {
      this.project = this.localStorageService.getProject().project;
    }

    if (this.environmentApiUrl) {
      this.pathService.setEnvironmentApiUrl(this.environmentApiUrl);
    } else {
      this.logger.warn(`[${FileUploaderComponent.name}]: No environment API url set`);
    }

    this.setupDragEvents();
  }

  ngOnDestroy() {
    this.overlayButtonClickedSubscription.unsubscribe();
    this.teardownDragEvents();
  }

  private handleDragEvent = (event: DragEvent): void => {
    this.isDragging = true;
    if (event.type === 'drop') this.isDragging = false;
    const hasRole = event.target instanceof HTMLElement && event.target.getAttribute('data-role');
    if (!hasRole) event.preventDefault();
  };

  private setupDragEvents(): void {
    window.addEventListener('dragover', this.handleDragEvent);
    window.addEventListener('drop', this.handleDragEvent);
  }

  private teardownDragEvents(): void {
    window.removeEventListener('dragover', this.handleDragEvent);
    window.removeEventListener('drop', this.handleDragEvent);
  }

  /**
   * handle file from browsing
   */
  public fileBrowseHandler(event: Event): void {
    this.isDragging = false;
    const fileInput = event.target as HTMLInputElement;
    const file = fileInput.files;
    const fileSizeInMB = file ? this.convertBytesInMB(file[0].size) : 0;

    if (fileSizeInMB > 6) {
      this.showFileSizeErrorDialog();
      this.clearFileInputValue();
      return;
    }
    this.loaderService.showLoader('BUTTONS.upload');
    this.convertFilesToList(file);
  }

  private convertFilesToList(files: any): void {
    for (const item of files) {
      item.progress = 0;
      this.uploadFile(item, item?.name);
    }
  }

  private clearFileInputValue(): void {
    const inputElement = this.fileInputRef.nativeElement as HTMLInputElement;
    inputElement.value = '';
  }

  private showFileSizeErrorDialog(): void {
    this.alertService.showRedirectConfirmationDialog(
      this.translateService.instant('LABELS.toLargeFile'),
      this.translateService.instant('ERROR.selectSmallerFile'),
      true,
      '',
      this.translateService.instant('MESSAGE.ok'),
      '',
    );
  }

  private convertBytesInMB(size: number): number {
    return size / (1024 * 1024);
  }

  public deleteFile(index: number): void {
    if (this.hasWritePermission) {
      const dialogRef = this.alertService.confirmationDialog(
        '',
        this.translateHelp.translate('DELETE_MESSAGE.' + 'file'),
        this.translateHelp.translate('DELETE_MESSAGE.deleteYes'),
        this.translateHelp.translate('cancel'),
      );
      dialogRef.subscribe((confirmed) => {
        if (confirmed && this.files) {
          (document.getElementById('fileDropRef') as HTMLInputElement).value = '';
          if (this.files.length === 1) {
            this.files = [];
            this.filesChange.emit(this.files);
            this.onfilesChange.emit();
            this.onChange(this.files);
            this.onTouched();
          } else {
            this.files.splice(index, 1);
            this.filesChange.emit(this.files);
            this.onfilesChange.emit();
            this.onChange(this.files);
            this.onTouched();
          }
        }
      });
    }
  }

  private uploadFile(file: any, name: string): void {
    if (!this.files) {
      this.files = [];
    }
    this.docDescription = name;
    this.fileController.saveFile(this.project, file).subscribe({
      next: (response) => {
        this.logger.debug(`[${FileUploaderComponent.name}]: Response`, response);
        if (response) {
          this.files?.push(response);
          this.filesChange.emit(this.files);
        }
      },
      error: (error) => {
        this.logger.error(`[${FileUploaderComponent.name}]: UPLOAD FAILED`, error);
      },
      complete: () => {
        this.loaderService.hideLoader();
        this.onfilesChange.emit();
        this.onChange(this.files ?? []);
        this.onTouched();
      },
    });
  }

  public formatBytes(bytes: number): number | string {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }

  public isImage(file: any): boolean {
    return String(file.contentType).includes('image');
  }

  public getDocumentPath(document: any): string {
    if (this.project === 'supp0rt') {
      return this.pathService.getFilePathWithProject(this.project) + '/' + document?.docId + '?full=true';
    } else {
      if (document?.docId) {
        return this.pathService.getFilePath() + '/' + document?.docId + '?full=true';
      }
    }
    return '';
  }

  public getDocumentPathSmall(document: any): string {
    if (this.project === 'supp0rt') {
      return this.pathService.getFilePathWithProject(this.project) + '/' + document?.docId + '?full=true';
    } else {
      if (document?.docId) {
        return this.pathService.getFilePath() + '/' + document?.docId;
      }
    }

    return '';
  }

  public getDownloadPath(document: any): void {
    if (this.project === 'supp0rt') {
      window.location.href =
        this.pathService.getFilePathWithProject(this.project) + '/' + document?.docId + '?full=true';
    } else {
      if (document?.docId) {
        window.location.href =
          this.pathService.getFilePath() + '/' + document?.docId + '?full=true' + '&download=false';
      }
    }
  }

  private abortLoadingScreen(): void {
    this.clearFileInputValue();
    this.loaderService.hideLoader();
  }

  public changeDescription(): void {
    this.filesChange.emit(this.files);
    this.onfilesChange.emit();
    this.onChange(this.files ?? []);
    this.onTouched();
  }

  public openImagePreviewDialog(imageUrl: string): void {
    this.dialog.open(ImagePreviewComponent, {
      data: {
        imageUrl,
      },
      panelClass: 'previewImageContainer',
    });
  }

  public openDocumentPreviewDialog(file: any): void {
    const docUrl: string = this.getDocumentPath(file);
    const docType: string = file.contentType;

    this.dialog.open(DocumentPreviewComponent, {
      data: {
        docUrl,
        docType,
      },
      panelClass: 'previewDocumentContainer',
    });
  }

  public placeAsTitleImage(element: any): void {
    if (this.files) {
      this.logger.debug(element);
      this.files = this.files?.filter((document) => document.chash !== element.chash);
      this.files.unshift(element);
      this.filesChange.emit(this.files);
      this.onfilesChange.emit();
      this.onChange(this.files);
      this.onTouched();
    }
  }

  public drop(event: CdkDragDrop<string[]>): void {
    if (this.files) {
      moveItemInArray(this.files, event.previousIndex, event.currentIndex);
      this.filesChange.emit(this.files);
      this.onfilesChange.emit();
      this.onChange(this.files);
      this.onTouched();
      this.logger.debug(this.files);
    }
  }

  // Implement the ControlValueAccessor methods
  private onChange: (value: Array<any>) => void = () => {};
  private onTouched: () => void = () => {};

  public writeValue(value: Array<any>): void {
    this.files = value;
  }

  public registerOnChange(fn: (value: Array<any>) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
