<form [formGroup]="formGroup" class="kv-form-container">
  @for (option of field.options; track $index) {
    <div class="kv-form-item flex-row">
      <span class="kv-form-label">{{ option }}</span>
      <ds-number-field
        [valueOnly]="field.valueOnly"
        class="dialog-content"
        [formControlName]="option"
        label="{{ graphOptions.yAxisName || 'LABELS.amount' | translate }}"
        type="number"
        [isDecimal]="true"
        [internal]="internal"
        [required]="required"
        [showError]="true"
        [errorMessage]="errorMessage"
        [ariaLabel]="(graphOptions.yAxisName || 'LABELS.amount' | translate) + ' ' + option" />
    </div>
  }
</form>
