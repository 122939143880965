/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService, GenericFormField, ObjectData } from '@frontends/commons';
import { EditDialogComponent } from '../../../../app/dialogs/edit-dialog/edit-dialog.component';
import { LookupDialogComponent } from '../../../../app/dialogs/lookup-dialog/lookup-dialog.component';
import { FieldName } from '../../../../app/model/Form/FieldIndex';

import { DataType } from '../../../../app/model/types/DataTypes';
import { getAddressField } from '../../../../app/utils/helperFunctions';
import { BatchEditService } from '../../../services/utils/batch-edit/batch-edit.service';

@Component({
  selector: 'exp-look-up',
  templateUrl: './look-up.component.html',
  styleUrls: ['./look-up.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LookUpComponent),
      multi: true,
    },
  ],
})
export class LookUpComponent implements ControlValueAccessor {
  @Input() public field!: GenericFormField;
  @Input() public fieldNames!: FieldName;
  @Input() public dataType!: DataType;
  public object: ObjectData = {};
  public displayValues = true;

  protected readonly getAddressField = getAddressField;

  constructor(
    public authService: AuthService,
    private dialog: MatDialog,
    public batchService: BatchEditService,
  ) {}

  public openLookupDialog(lookupToDataType: DataType, lookupToProject: string): void {
    const dialogRef = this.dialog.open(LookupDialogComponent, {
      data: {
        lookupToDataType,
        lookupToProject,
      },
      height: '560px auto',
      width: ' 980px',
      minWidth: '200px',
      autoFocus: false,
      minHeight: '200px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.onTouched();
      if (result) {
        this.object = result;
        this.onChange(result);
        this.onTouched();
        this.displayValues = true;
      }
    });
  }

  public openEditLookupDialog(lookupToProject: string, lookupToDataType: DataType): void {
    if (this.object) {
      const lookupToObjectId = this.object.docId;
      const dialogRef = this.dialog.open(EditDialogComponent, {
        data: {
          lookupToDataType,
          lookupToProject,
          lookupToObjectId,
        },
        height: 'auto',
        width: ' 980px',
        minWidth: '200px',
        minHeight: '200px',
      });
      dialogRef.afterClosed().subscribe((result) => {
        const changeObject = result;
        for (const key of Object.keys(this.object)) {
          if (key in changeObject && this.object[key] !== changeObject[key]) {
            this.object[key] = changeObject[key];
          }
        }
        this.onTouched();
        this.onChange(this.object);
      });
    }
  }

  public deleteAssignedObject(): void {
    this.onTouched();
    this.onChange({});
    this.object = {};
    this.displayValues = false;
  }

  public isSoftDeleted(): boolean {
    return this.object['core_softdelete'] && !this.authService.isMgmtUser$;
  }

  public isSoftDeletedOnly(): boolean {
    return this.object['core_softdelete'];
  }

  public isRequired(field: GenericFormField) {
    return this.batchService.getOptionPathArray().length > 0 ? false : field.required;
  }

  // Implement the ControlValueAccessor methods

  private onChange: (value: { [key: string]: any }) => void = () => {};
  private onTouched: () => void = () => {};

  writeValue(value: { [key: string]: any }): void {
    const existElement = [];
    this.object = value;
    if (this.object) {
      this.field.options?.forEach((element) => {
        if (this.object[element] || getAddressField(this.field.lookupTo ?? '', this.object)) {
          existElement.push(element);
        }
      });
    }
    if (existElement.length === 0) {
      this.displayValues = false;
    } else {
      this.displayValues = true;
    }
  }

  registerOnChange(fn: (value: { [key: string]: any }) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
