import { Component, Input } from '@angular/core';
import { BaseButtonComponent } from '../../Base/base-button.component';

@Component({
  selector: 'ds-primary-button',
  templateUrl: './primary-button.component.html',
  styleUrls: ['./primary-button.component.scss'],
})
export class PrimaryButtonComponent extends BaseButtonComponent {
  @Input() public icon = '';
}
