import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { ButtonsModule, DividerModule, SelectDialogComponent, ThemeService } from '@frontends/designsystem';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { NavType, UserInfo } from '../../../model';
import { languages } from '../../../model/config';
import { BaseAuthService, BreakpointsService, CmnLocalStorageService } from '../../../services';
import { PasswordChangeDialogComponent } from '../../dialogs';
import { getNavType } from '../../../HelperFunctions/helperFunctions';

@Component({
  selector: 'cmn-toolbar',
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatListModule,
    MatToolbarModule,
    TranslateModule,
    DividerModule,
    ButtonsModule,
    MatButtonModule,
    MatSelectModule,
    MatDialogModule,
    MatTooltipModule,
    MatSlideToggleModule,
    FormsModule,
  ],
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarComponent implements OnInit {
  @Input() public version!: string;
  @Input() public title = 'NOT SET';
  @Input() public navType: NavType = 'ITEM';
  @Input() public hasDarkMode = false;
  @Input() public hasTranslation = false;
  @Output() public sidenavToggled = new EventEmitter<MouseEvent>();
  public sidenavToggle = false;
  public userInfo: UserInfo = {
    firstName: 'NOT SET',
    lastName: 'NOT SET',
    userName: 'NOT SET',
    userEmail: 'NOT SET',
    mandator: '',
    isAdminUser: false,
    isMgmtUser: false,
  };
  public isLightMode = true;

  constructor(
    public translateService: TranslateService,
    public authService: BaseAuthService,
    private ref: ChangeDetectorRef,
    private breakpointService: BreakpointsService,
    private dialog: MatDialog,
    private router: Router,
    private logger: NGXLogger,
    public themeService: ThemeService,
    private localStorageService: CmnLocalStorageService,
  ) {
    this.initializeTitle();
  }

  ngOnInit(): void {
    this.authService.userInfo$.subscribe((userInfo: UserInfo) => {
      this.logger.debug(`[TOOLBAR]: Subscribe to userInfos: ${JSON.stringify(userInfo)}`);
      this.userInfo = userInfo;
      this.ref.detectChanges();
    });
    this.logger.debug(`[TOOLBAR]: INITIALIZED`, this);
    this.themeService.initializedTheme();
    this.themeService.isLightTheme$.subscribe((isLight) => (this.isLightMode = isLight));
  }

  public initializeTitle(): void {
    const { name, productType } = this.localStorageService.getProject();
    if (productType) {
      const navType = getNavType(this.navType, this.localStorageService);
      this.translateService.get('NAVIGATION.' + productType).subscribe((translatedTitle) => {
        this.title = navType !== 'MENU' ? translatedTitle : translatedTitle + ' - ' + name;
      });
    }
  }

  public openPasswordChangeDialog() {
    const dialogRef = this.dialog.open(PasswordChangeDialogComponent, {
      height: 'auto',
      width: '560px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.logger.debug(`[TOOLBAR]: Password change dialog closed - result: ${result}`);
    });
  }

  public toggleSidenav(): void {
    this.sidenavToggled.emit();
    this.breakpointService.onSideNavToggle(!this.breakpointService.isSideNavOpen);
  }

  public logOut(): void {
    this.authService.logout();
  }

  public translateLanguageTo(lang: string): void {
    this.translateService.use(lang);
    localStorage.setItem('lang', lang);
  }

  public openLanguageChangeDialog() {
    const dialogRef = this.dialog.open(SelectDialogComponent, {
      role: 'dialog',
      autoFocus: 'dialog',
      data: {
        title: this.translateService.instant('LABELS.change_languages'),
        closeBtnText: this.translateService.instant('BUTTONS.cancel'),
        selectOptions: languages,
      },
    });

    dialogRef.afterClosed().subscribe((selected) => {
      if (selected) {
        this.translateLanguageTo(selected);
      }
    });
  }

  public toggleTheme(event?: MatSlideToggleChange) {
    event ? (this.isLightMode = event.checked) : (this.isLightMode = !this.isLightMode);
    this.themeService.setLightTheme(this.isLightMode);
  }
}
