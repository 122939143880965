/* eslint-disable no-underscore-dangle */
import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ConcreteData, RequestObject } from '@frontends/exp-apiclient';
import { TranslateService } from '@ngx-translate/core';
import _, { isNumber } from 'lodash';

import { dateFormats } from '../../../model/DateFormats';
import { FieldTypes } from '../../../model/Enums/FieldTypesEnum';
import { ReservationNames } from '../../../model/Enums/ReservationTypesEnum';
import { Appointment } from '../../../model/Object/appointment';
import { PathType } from '../../../model/Object/search-panel';

import { MinMax, ObjectData } from '@frontends/commons';
import { ImageSize } from '../../../model/Enums/size-image';
import { KeyArray } from '../../../model/KeyArray';
import { Tuple } from '../../../model/Util/Tuple';
import { DataType } from '../../../model/types/DataTypes';
import { ApiClientService } from '../../../services/api-client.service';
import { PathService } from '../../../services/path.service';
import { LocalStorageService } from '../../../services/storage/local-storage.service';
import { SessionStorageService } from '../../../services/storage/session-storage.service';
import { formatAppointment } from '../../../utils/helperFunctions';
const MINMAX_KEY = '_MinMax';

@Component({
  selector: 'exp-print-layout',
  templateUrl: './print-layout.component.html',
  styleUrls: ['./print-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintLayoutComponent implements OnInit {
  @Input() dataType?: DataType;
  @Input() selectedObjects?: ObjectData[];
  @Input() reqObject?: RequestObject = {};
  @Input() id?: string;
  @Input() pageBreak?: boolean;
  @Input() exportFields!: Tuple[];
  currentProject: any;
  objects?: Array<ConcreteData> = [];
  currentDate = new Date();
  activeSearch?: string;
  activeQuickFilter: Array<KeyArray> = [];
  activeHierarchyFilter: any = [];
  activeMinMax!: { [key: string]: MinMax };
  existFilter?: boolean;
  fieldNames!: ObjectData;
  pathType: Array<PathType> = [];
  constructor(
    private pathService: PathService,
    public sessionStorageService: SessionStorageService,
    private apiClient: ApiClientService,
    private localStorageService: LocalStorageService,
    private datePipe: DatePipe,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.getTypeByPath();
    this.currentProject = this.localStorageService.getProject();
    this.getActiveSearchFromSessionStorage();
    if (this.dataType && this.sessionStorageService.getFilterLength(this.dataType)) {
      this.existFilter = true;
      this.filtersFromSessionStorage();
    }
    if (this.selectedObjects && this.selectedObjects.length === 1) {
      this.objects = this.selectedObjects;
      this.formatAdapter(this.objects);
    } else {
      this.multiObject();
    }
  }

  getActiveSearchFromSessionStorage() {
    if (this.dataType) this.activeSearch = this.sessionStorageService.getSearch(this.dataType);
  }

  filtersFromSessionStorage(): void {
    this.getNameByPath();
    if (this.dataType)
      this.activeHierarchyFilter = Object.values(this.sessionStorageService.getAllSelectedChecklistData(this.dataType));
    if (this.dataType) this.activeQuickFilter = this.sessionStorageService.getQuickFilterReq(this.dataType);
    if (this.dataType) this.activeMinMax = this.sessionStorageService.getMinMaxFilters(this.dataType, MINMAX_KEY);
  }

  multiObject(): void {
    if (this.selectedObjects && this.selectedObjects.length) {
      this.objects = this.selectedObjects;
      this.formatAdapter(this.objects);
    } else {
      if (this.reqObject) this.reqObject.page = 0;
      if (this.dataType && this.reqObject)
        this.apiClient.getObjectData(this.dataType, this.reqObject).subscribe((data: any) => {
          this.objects = data.results;
          this.formatAdapter(this.objects);
        });
    }
  }

  getNameByPath(): void {
    if (this.dataType)
      this.apiClient.getFieldsWithDeepNamming(this.dataType).subscribe((data) => {
        this.fieldNames = data;
      });
  }
  checkEpmtyValue(value: string): boolean {
    return value.trim() === '! {  }';
  }
  displayValue(element: any): any {
    return element;
  }
  regroupHierarchy(hierarchyObject: any): { [key: string]: any } {
    const groupedData: any = {};
    hierarchyObject.forEach((item: any) => {
      const commonProperty = this.getHierarchyPath(item.path);
      if (!groupedData[commonProperty]) {
        groupedData[commonProperty] = [];
      }
      groupedData[commonProperty].push(item);
    });
    return groupedData;
  }
  getHierarchyPath(hierarchy: string) {
    const path = hierarchy.split('.')[0];
    return path;
  }
  isExtraType(type: FieldTypes): boolean {
    const extraType = [
      FieldTypes.HIERARCHY,
      FieldTypes.APPOINTMENT_LIST,
      FieldTypes.LOOKUP,
      FieldTypes.MULTILOOKUP,
      FieldTypes.DIMENSION,
      FieldTypes.ADDRESS,
      FieldTypes.DATE,
    ];
    return extraType.includes(type) ? true : false;
  }
  getTypeByPath() {
    if (this.dataType)
      this.apiClient.getFormularFromBackend(this.dataType).subscribe((data: any) => {
        data.forms.forEach((form: any) => {
          form.fields.forEach((field: any) => {
            this.pathType.push({
              path: field?.path,
              type: field?.type,
            });
          });
        });
      });
  }

  checkType(path: string, pathType: Array<PathType>): any {
    return pathType.find((field) => field.path === path)?.type;
  }
  getDocumentPath(document: any): string {
    if (document?.docId) {
      return this.pathService.getFilePath() + '/' + document?.docId + '?full=false';
    } else return '';
  }

  getMediumImage(image: any) {
    return this.apiClient.getImageBySize(image?.docId, this.currentProject?.project, ImageSize.MEDIUM);
  }

  formatAdapter(objs: any) {
    objs.forEach((value: any) => {
      if (isNumber(value._meta_CREATEAt) || isNumber(value._meta_UPDATEAt)) {
        if (Number(value._meta_CREATEAt) > 1577833200000) {
          value._meta_CREATEAt = this.datePipe.transform(value._meta_CREATEAt, dateFormats.dayMonthFormat, 'en-US');
        }
        if (Number(value._meta_UPDATEAt) > 1577833200000) {
          value._meta_UPDATEAt = this.datePipe.transform(value._meta_UPDATEAt, dateFormats.dayMonthFormat, 'en-US');
        }
      }
    });
  }

  getFormatAppointment(appointment: Appointment) {
    const copiedApt = { ...appointment };
    copiedApt.status = this.translateService.instant(
      ReservationNames[copiedApt.status as keyof typeof ReservationNames]
    );
    return formatAppointment(copiedApt);
  }

  lookUpValue(objLookUp: any, path: string) {
    const subPath = path?.split('.')[1];
    if (objLookUp !== undefined) {
      return objLookUp[subPath];
    } else {
      return '-';
    }
  }

  checkEmptyObject(object: any): boolean {
    return _.isEmpty(object);
  }

  get FieldTypes() {
    return FieldTypes;
  }
}
