import { Injectable } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthControllerService } from '@frontends/exp-apiclient';
import { Observable, from, map, of, switchMap } from 'rxjs';
import { AuthToken, PassResetRequest } from '../../model';

@Injectable({
  providedIn: 'root',
})
export class AuthApiClientService {
  constructor(private authController: AuthControllerService) {}

  public login(userPass: { user: string; pass: string }): Observable<AuthToken> {
    return this.authController.loginpublic(undefined, undefined, undefined, undefined, userPass).pipe(
      switchMap((beResponse) => {
        if (beResponse instanceof Blob) {
          return from(beResponse.text()).pipe(
            map((text) => {
              const parsedToken = JSON.parse(text);
              return {
                name: parsedToken.name ?? '',
                value: parsedToken.value ?? '',
                domain: parsedToken.domain ?? '',
                maxAge: parsedToken.maxAge ?? 0,
                ...parsedToken,
              };
            }),
          );
        } else {
          return of({
            name: beResponse.name ?? '',
            value: beResponse.value ?? '',
            domain: beResponse.domain ?? '',
            maxAge: beResponse.maxAge ?? 0,
          });
        }
      }),
    );
  }

  public logout(): Observable<{ [key: string]: object }> {
    return this.authController.logoutpublic();
  }

  public reset(resetRequest: PassResetRequest): Observable<{ [key: string]: object }> {
    return this.authController.resetpublic(resetRequest);
  }
}
