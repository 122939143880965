/**
 * Explanner Service Documentation
 * Explanner Service Documentation
 *
 * The version of the OpenAPI document: 1.7_landesstelle{20240611@153537}
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient,
    HttpContext,
    HttpEvent,
    HttpHeaders,
    HttpParameterCodec,
    HttpParams,
    HttpResponse
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

// @ts-ignore
import { IdResponse } from '../model/idResponse';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root',
})
export class ExtensionControllerService {
  protected basePath = '/service';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration,
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach((elem) => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k)),
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Get counter
   * @param project
   * @param dataType
   * @param miscId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCounterExtensionController(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    miscId?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<number>;
  public getCounterExtensionController(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    miscId?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<number>>;
  public getCounterExtensionController(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    miscId?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<number>>;
  public getCounterExtensionController(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    miscId?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<any> {
    if (project === null || project === undefined) {
      throw new Error('Required parameter project was null or undefined when calling getCounterExtensionController.');
    }
    if (dataType === null || dataType === undefined) {
      throw new Error('Required parameter dataType was null or undefined when calling getCounterExtensionController.');
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (miscId !== undefined && miscId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>miscId, 'miscId');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json;charset=utf-8'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/extend/counter/${this.configuration.encodeParam({
      name: 'project',
      value: project,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/${this.configuration.encodeParam({
      name: 'dataType',
      value: dataType,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: "'ADDRESS' | 'INSTITUTE' | 'OBJECT'",
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<number>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Inc counter
   * @param project
   * @param dataType
   * @param miscId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incCounterExtensionController(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    miscId?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<number>;
  public incCounterExtensionController(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    miscId?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<number>>;
  public incCounterExtensionController(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    miscId?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<number>>;
  public incCounterExtensionController(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    miscId?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<any> {
    if (project === null || project === undefined) {
      throw new Error('Required parameter project was null or undefined when calling incCounterExtensionController.');
    }
    if (dataType === null || dataType === undefined) {
      throw new Error('Required parameter dataType was null or undefined when calling incCounterExtensionController.');
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (miscId !== undefined && miscId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>miscId, 'miscId');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json;charset=utf-8'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/extend/counter/${this.configuration.encodeParam({
      name: 'project',
      value: project,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/${this.configuration.encodeParam({
      name: 'dataType',
      value: dataType,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: "'ADDRESS' | 'INSTITUTE' | 'OBJECT'",
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<number>('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Inc counter with pattern
   * @param project
   * @param dataType
   * @param miscId
   * @param pattern
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incCounterWithPatternExtensionController(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    miscId?: string,
    pattern?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<IdResponse>;
  public incCounterWithPatternExtensionController(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    miscId?: string,
    pattern?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<IdResponse>>;
  public incCounterWithPatternExtensionController(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    miscId?: string,
    pattern?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<IdResponse>>;
  public incCounterWithPatternExtensionController(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    miscId?: string,
    pattern?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<any> {
    if (project === null || project === undefined) {
      throw new Error(
        'Required parameter project was null or undefined when calling incCounterWithPatternExtensionController.',
      );
    }
    if (dataType === null || dataType === undefined) {
      throw new Error(
        'Required parameter dataType was null or undefined when calling incCounterWithPatternExtensionController.',
      );
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (miscId !== undefined && miscId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>miscId, 'miscId');
    }
    if (pattern !== undefined && pattern !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pattern, 'pattern');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['*/*'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/extend/counterPattern/${this.configuration.encodeParam({
      name: 'project',
      value: project,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/${this.configuration.encodeParam({
      name: 'dataType',
      value: dataType,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: "'ADDRESS' | 'INSTITUTE' | 'OBJECT'",
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<IdResponse>('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Set counter
   * @param project
   * @param dataType
   * @param number
   * @param miscId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setCounterExtensionController(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    number: number,
    miscId?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<number>;
  public setCounterExtensionController(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    number: number,
    miscId?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<number>>;
  public setCounterExtensionController(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    number: number,
    miscId?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<number>>;
  public setCounterExtensionController(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    number: number,
    miscId?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<any> {
    if (project === null || project === undefined) {
      throw new Error('Required parameter project was null or undefined when calling setCounterExtensionController.');
    }
    if (dataType === null || dataType === undefined) {
      throw new Error('Required parameter dataType was null or undefined when calling setCounterExtensionController.');
    }
    if (number === null || number === undefined) {
      throw new Error('Required parameter number was null or undefined when calling setCounterExtensionController.');
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (miscId !== undefined && miscId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>miscId, 'miscId');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json;charset=utf-8'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/extend/counter/${this.configuration.encodeParam({
      name: 'project',
      value: project,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/${this.configuration.encodeParam({
      name: 'dataType',
      value: dataType,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: "'ADDRESS' | 'INSTITUTE' | 'OBJECT'",
      dataFormat: undefined,
    })}/${this.configuration.encodeParam({
      name: 'number',
      value: number,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: 'int32',
    })}`;
    return this.httpClient.request<number>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
