<div class="container" *ngIf="!valueOnly; else showReadonly">
  <ds-icon-badge *ngIf="internal" [iconClass]="iconClass" [tooltip]="tooltip"></ds-icon-badge>
  <mat-form-field [color]="color" appearance="outline">
    <mat-label>{{ label }}</mat-label>
    <mat-select
      *ngIf="!formControlName; else hasFormControl"
      [required]="required"
      matNativeControl
      [(ngModel)]="value"
      [disabled]="disabled"
      (selectionChange)="selectionChanged($event)"
      (blur)="onBlur()">
      <mat-option *ngIf="!selectWithOutEmptyValue" value="" selected></mat-option>
      <mat-option *ngFor="let option of options" [value]="option">{{ option }}</mat-option>
    </mat-select>
    <ng-template #hasFormControl>
      <mat-select [formControl]="control"  [(ngModel)]="value" (blur)="onBlur()">
        <mat-option *ngIf="!selectWithOutEmptyValue" value="" selected></mat-option>
        <mat-option *ngFor="let option of options" [value]="option">{{ option }}</mat-option>
      </mat-select>
    </ng-template>
    <mat-hint aria-label="Input Hint" *ngIf="showHint"> {{ hintMessage }}</mat-hint>
    <mat-error aria-label="Error Hint" *ngIf="showError">{{ errorMessage }}</mat-error>
  </mat-form-field>
</div>
<ng-template #showReadonly>
  <ng-container *ngIf="!isHierarchy">
    <div class="marginButtom">
      <span class="mat-subtitle-2">{{ label }}</span>
      <div class="mat-body-2">{{ value !== undefined && value && value !== '' ? value : '-' }}</div>
    </div>
  </ng-container>
  <ng-container *ngIf="isHierarchy">
    <span class="mat-body-2">{{ label }}: {{ value ? value : '-' }}</span>
  </ng-container>
</ng-template>
