<div class="flex-col look-up-container">
  <div class="flex-col">
    <ds-label
      [valueOnly]="field.valueOnly"
      class="lookup-label"
      typography="mat-subtitle-2"
      iconClass="security"
      tooltip="{{ 'LABELS.confidential' | translate }}"
      [internal]="this.batchService.getOptionPathArray().length > 0 ? false : field.internal"
      text="{{ fieldNames[field.path] }}"
      [isFieldRequired]="isRequired(field)" />
    <ds-primary-button
      *ngIf="!field.valueOnly"
      label="{{ 'BUTTONS.lookup' | translate }}"
      [ariaLabel]="'BUTTONS.lookup' | translate"
      color="accent"
      [disabled]="
        field.disabled ||
        !authService.hasReadPermissionsForDatatype(field.lookupTo!, field.lookupToProject) ||
        !authService.hasWritePermissionsForDatatype(dataType)
      "
      (clickButton)="openLookupDialog(field.lookupTo!, field.lookupToProject!)" />
  </div>

  <div
    class="flex-row"
    *ngIf="object && displayValues"
    [ngClass]="
      this.batchService.getOptionPathArray().length > 0
        ? 'batch-edit-institute-look-up-container'
        : 'institute-look-up-container'
    ">
    <mat-card>
      <mat-card-content>
        <div class="card-content-container">
          <h4 *ngFor="let fields of field.options" class="mat-subtitle-2 card-content-item">
            {{ fieldNames[field.path + '.' + fields] }}:
            <span class="boldless">
              {{
                fields === 'address' ? getAddressField(field.lookupTo!, object) : object[fields] ? object[fields] : ''
              }}
            </span>
          </h4>
        </div>
      </mat-card-content>
      <mat-card-actions *ngIf="!field.valueOnly">
        <ds-basic-button
          label="{{ 'BUTTONS.edit' | translate }}"
          [ariaLabel]="'BUTTONS.edit' | translate"
          color="accent"
          [hidden]="isSoftDeleted()"
          [disabled]="!object || !authService.hasReadPermissionsForDatatype(field.lookupTo!, field.lookupToProject)"
          (clickButton)="openEditLookupDialog(field.lookupToProject!, field.lookupTo!)" />

        <ds-basic-button
          label="{{ 'BUTTONS.remove' | translate }}"
          [ariaLabel]="'BUTTONS.remove' | translate"
          color="accent"
          [disabled]="!object || !authService.hasWritePermissionsForDatatype(dataType)"
          (clickButton)="deleteAssignedObject()" />

        <ds-label
          [hidden]="!isSoftDeletedOnly()"
          class="soft-delete-label"
          typography="mat-subtitle-2"
          text="{{ 'LABELS.softdelete' | translate }} " />
      </mat-card-actions>
    </mat-card>
  </div>
</div>
