import { Component, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'exp-validator-error',
  templateUrl: './validator-error.component.html',
  styleUrls: ['./validator-error.component.scss'],
})
export class ValidatorErrorComponent {
  @Input() title!: string;
  @Input() type?: string;
  @Input() text?: string;
  @Input() length?: number;
}
