export enum FieldStyles {
  FULL = 'full',
  DEEP = 'deep',
}

export enum FieldWidth {
  FULL = 'full',
  HALF = 'half',
  THIRD = 'third',
  QUARTER = 'quarter',
}
