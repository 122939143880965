<div class="select-dialog-container">
  <div class="select-dialog-header">
    <h2 mat-dialog-title>{{ title }}</h2>
    <ds-icon-button
      fontColor="primary"
      color="primary"
      id="dialog-close-icon-btn"
      icon="close"
      mat-dialog-close></ds-icon-button>
  </div>
  <mat-dialog-content class="select-dialog-content">
    <mat-selection-list
      class="options-list"
      [attr.aria-label]="title + ' option'"
      [multiple]="false"
      [hideSingleSelectionIndicator]="true">
      <mat-list-option
        class="options-list-item"
        aria-label="option"
        *ngFor="let item of options | keyvalue"
        (selectedChange)="selectItem(item.key)">
        {{ options[item.key].value }}
      </mat-list-option>
    </mat-selection-list>
  </mat-dialog-content>
  <ds-horizontal-divider></ds-horizontal-divider>
  <mat-dialog-actions align="end">
    <ds-basic-button mat-dialog-close [label]="closeBtnText" color="primary" [aria-label]="closeBtnText" />
  </mat-dialog-actions>
</div>
