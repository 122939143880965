/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFieldComponent } from '../../Base/base-field.component';

@Component({
  selector: 'ds-input-fields-upload-file',
  templateUrl: './input-fields-upload-file.component.html',
  styleUrls: ['./input-fields-upload-file.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFieldsUploadFileComponent),
      multi: true,
    },
  ],
})
export class InputFieldsUploadFileComponent extends BaseFieldComponent {
  @Input() placeholder = '';

  @Input() files?: Array<any>;
  @Output() filesChange = new EventEmitter<any>();

  onChangeInput() {
    this.onChange(this.files);
    this.filesChange.emit(this.files);
    this.onTouched();
  }

  // Implement the ControlValueAccessor methods
  protected override onChange: any = () => {};

  override writeValue(value: Array<any>): void {
    this.files = value;
  }
  override registerOnChange(fn: any): void {
    this.onChange = fn;
  }
}
