/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable no-underscore-dangle */
import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, GenericForm } from '@frontends/commons';
import { ConcreteData } from '@frontends/exp-apiclient';
import { LookupDialogComponent } from '../../../dialogs/lookup-dialog/lookup-dialog.component';

import { FieldTypes } from '../../../model/Enums/FieldTypesEnum';
import { ImageSize } from '../../../model/Enums/size-image';
import { FieldName, Fieldtype } from '../../../model/Form/FieldIndex';
import { StampDateRange } from '../../../model/Object/stampDateRange';
import { ApiClientService } from '../../../services/api-client.service';
import { FormService } from '../../../services/form/form.service';
import { LocalStorageService } from '../../../services/storage/local-storage.service';
import { SessionStorageService } from '../../../services/storage/session-storage.service';
import { ScrollPosition } from '../../../services/utils/scrollPosition.service';
import { getTrafficLightLabelFromColor, isAppointmentList } from '../../../utils/helperFunctions';
import { ImagePreviewComponent } from '../image-preview/image-preview.component';

@Component({
  selector: 'exp-overview-table',
  templateUrl: './overview-table.component.html',
  styleUrls: ['./overview-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OverviewTableComponent implements OnInit {
  @ViewChild(MatSort) public sort!: MatSort;
  @Output() public backEndcall = new EventEmitter<string>();
  @Output() public dateRange = new EventEmitter<any>();
  @Output() public scheduleCriteriafieldName = new EventEmitter<any>();
  @Input() public pageIndex!: number;
  @Input() public pageSize!: number;
  @Input() public lookupDialogRef!: MatDialogRef<LookupDialogComponent>;
  @Input() public fieldNames: FieldName = {};
  @Input() public displayedColumns!: string[];
  @Input() public objectIdList!: Array<string>;
  @Input() public selection = new SelectionModel<any>(true, []);
  @Input() public project!: string;
  @Input() public dataType!: ConcreteData.DataTypeEnum;
  @Input() public isLoadingResults = false;
  @Input() public overviewScrollParent!: ElementRef;
  @Input() public isLookup!: boolean;

  @Input() set dataSourceOT(value: MatTableDataSource<any>) {
    this._dataSourceOT = value;
    setTimeout(() => {
      this.moveToSection();
    });
  }

  public dateRangeValue!: string;
  public forms!: GenericForm[];

  private dateRangePickerRef?: MatDateRangePicker<Date>;
  private _dataSourceOT!: MatTableDataSource<any>;
  private allFieldType: Array<Fieldtype> = [];
  private scheduleCriteriaDateRange: any;
  private scheduleCriteriaDateRangeKey = '_ScheduleCriteriaMinMax';
  private scheduleCriteriaDateRangeKeyForToolbox = '_ScheduleCriteriaMinMaxForToolbox';

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(
    private sharedScrollInfo: ScrollPosition,
    private route: ActivatedRoute,
    private router: Router,
    public localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private dialog: MatDialog,
    public authService: AuthService,
    private apiClientService: ApiClientService,
    private formService: FormService,
    private datePipe: DatePipe,
  ) {}

  get dataSourceOT(): MatTableDataSource<any> {
    return this._dataSourceOT;
  }

  ngOnInit(): void {
    if (this.isLookup) {
      if (
        this.dataType &&
        this.sessionStorageService.getScheduleCriteriaDateRangeFilter(
          this.dataType,
          this.scheduleCriteriaDateRangeKeyForToolbox,
        )
      ) {
        this.scheduleCriteriaDateRange = this.sessionStorageService.getMinMaxFilters(
          this.dataType,
          this.scheduleCriteriaDateRangeKeyForToolbox,
        );
        const from = this.datePipe.transform(this.scheduleCriteriaDateRange.min, 'dd.MM.yyyy');
        const to = this.datePipe.transform(this.scheduleCriteriaDateRange.max, 'dd.MM.yyyy');
        this.dateRangeValue = `${from} - ${to}`;
      }
    } else {
      if (
        this.dataType &&
        this.sessionStorageService.getScheduleCriteriaDateRangeFilter(this.dataType, this.scheduleCriteriaDateRangeKey)
      ) {
        this.scheduleCriteriaDateRange = this.sessionStorageService.getMinMaxFilters(
          this.dataType,
          this.scheduleCriteriaDateRangeKey,
        );
        const from = this.datePipe.transform(this.scheduleCriteriaDateRange.min, 'dd.MM.yyyy');
        const to = this.datePipe.transform(this.scheduleCriteriaDateRange.max, 'dd.MM.yyyy');
        this.dateRangeValue = `${from} - ${to}`;
      }
    }

    this.route.params.subscribe(() => {
      this.getFieldTypeMap();
    });
  }

  private getFieldTypeMap(): void {
    if (this.project && this.dataType)
      this.formService.getForm(this.project, this.dataType).subscribe((metaForm) => {
        this.forms = metaForm.forms;
        this.forms.forEach((form) => {
          if (form.fields)
            form.fields.forEach((field) => {
              if (field.path) {
                this.allFieldType.push({
                  path: field.path,
                  type: field.type,
                });
              }
            });
        });
      });
  }

  public getType(path: string): string {
    let fieldType = '';
    this.allFieldType.forEach((element) => {
      if (element.type && path === element?.path) {
        fieldType = element?.type;
      }
    });
    return fieldType;
  }

  public showTrafficLight(path: string): boolean {
    if (this.getType(path) === FieldTypes.TRAFFICLIGHT) {
      return true;
    }
    return false;
  }

  public showImage(path: string): boolean {
    if (this.getType(path) === FieldTypes.DOCUMENTS || this.getType(path) === FieldTypes.IMAGES) {
      return true;
    }
    return false;
  }

  private showRadio(path: string): boolean {
    if (this.getType(path) === FieldTypes.RADIO) {
      return true;
    }
    return false;
  }

  public showHeaderLabel(path: string): boolean {
    if (!this.showRadio(path)) {
      return true;
    }
    return false;
  }

  public showDefaultLabel(path: string): boolean {
    if (!this.showTrafficLight(path) && !this.showImage(path) && !this.showRadio(path) && !isAppointmentList(path)) {
      return true;
    }
    return false;
  }

  public isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceOT.data.length;
    return numSelected === numRows;
  }

  public toggleAllRows(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSourceOT.data.forEach((row: any) => this.selection.select(row));
  }

  public checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  public displayImage(event: any, imgUrl: string) {
    event.stopPropagation();
    if (this.project) this.openImagePreviewDialog(this.getFullImage(this.project, imgUrl));
    return;
  }

  private getFullImage(project: string, image: any) {
    return this.apiClientService.getImageBySize(image?.docId, project, ImageSize.FULL);
  }

  public getSmallImage(project: string, image: any) {
    return this.apiClientService.getImageBySize(image?.docId, project, ImageSize.SMALL);
  }

  private openImagePreviewDialog(imageUrl: string): void {
    this.dialog.open(ImagePreviewComponent, {
      data: {
        imageUrl,
      },
      panelClass: 'previewImageContainer',
    });
  }

  private moveToSection(): void {
    const currentDataType = this.sharedScrollInfo.currentScrollDataType;
    if (this.overviewScrollParent && currentDataType === this.dataType) {
      this.overviewScrollParent.nativeElement.scrollTop = this.sharedScrollInfo.currentScrollPos;
    }
  }

  public isSoftDeleteCore(event: any): boolean {
    return !!((this.authService.isMgmtUser$ || this.authService.isAdminUser$) && event?.core_softdelete);
  }

  public goToDetail(event: any, index: number): void {
    this.setClickedRow(event.docId);
    const cellText = document.getSelection();
    if (this.route.snapshot.params['type']) {
      if (cellText && cellText.type !== 'Range') {
        if (this.pageSize && this.pageIndex && this.pageIndex !== 0) {
          index = index + this.pageIndex * this.pageSize;
        }
        if (this.dataType) {
          this.sessionStorageService.saveObjectIDList(this.dataType, this.objectIdList);
          this.sessionStorageService.saveCurrentObjectIndex(this.dataType, index);
        }

        if (event.docId) {
          this.router.navigate([this.dataType, event.docId]);
        }
      }
    } else {
      if (this.lookupDialogRef) this.lookupDialogRef.close(event);
    }
  }

  get FieldTypes() {
    return FieldTypes;
  }

  public onRangeBtnClick(evt: any) {
    evt.stopPropagation();
    if (this.dateRangePickerRef) this.dateRangePickerRef.open();
  }

  public onDateRangeChange(dates: StampDateRange, fieldName: string): void {
    if (!dates?.min || !dates?.max) {
      return;
    }
    const from = this.datePipe.transform(dates.min, 'dd.MM.yyyy');
    const to = this.datePipe.transform(dates.max, 'dd.MM.yyyy');
    this.dateRangeValue = `${from} - ${to}`;

    this.scheduleCriteriafieldName.emit(fieldName);
    this.dateRange.emit(dates);
  }

  public getLabelFromColor(statusColor: string): string {
    return getTrafficLightLabelFromColor(statusColor);
  }

  public onPickerRef(pickerRef: MatDateRangePicker<Date>): void {
    this.dateRangePickerRef = pickerRef;
  }

  private setClickedRow(docId: string): void {
    this.sharedScrollInfo.activeRow = docId;
  }

  get activeRow(): string {
    const currentVisitedRow = this.sharedScrollInfo.activeRow;
    return currentVisitedRow ? currentVisitedRow : '';
  }

  public isFieldTypeApt(path: string): boolean {
    return isAppointmentList(path);
  }
}
