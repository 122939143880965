import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';
import { ChartOptions } from '../../model/Object/chart';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'exp-key-value-chart',
  templateUrl: './key-value-chart.html',
  styleUrl: './key-value-chart.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyValueChart implements OnInit {
  @Input() public graphData!: { [key: string]: number };
  @Input() public graphOptions!: ChartOptions;
  private options!: EChartsOption;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    const xAxisData = Object.keys(this.graphData);
    const yAxisData = Object.values(this.graphData);
    const { xAxisName = '', yAxisName = '', lineStyle = 'line', markLineValue = undefined } = this.graphOptions;

    this.options = {
      aria: {
        enabled: true,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
          label: {
            show: true,
          },
        },
      },
      calculable: false,
      xAxis: [
        {
          type: 'category',
          name: xAxisName,
          data: xAxisData,
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: yAxisName || this.translateService.instant('LABELS.amount'),
        },
      ],
      dataZoom: [
        {
          id: 'dataZoomX',
          type: 'slider',
          xAxisIndex: [0],
          filterMode: 'filter',
        },
      ],
      series: [
        {
          name: yAxisName || this.translateService.instant('LABELS.amount'),
          type: lineStyle,
          markLine: markLineValue
            ? {
                silent: true,
                lineStyle: {
                  color: 'rgba(0, 180, 120, 1)',
                  width: 2,
                },
                data: [
                  {
                    yAxis: markLineValue,
                  },
                ],
              }
            : undefined,
          data: yAxisData.map((number) => number || 0),
        },
      ],
    };
  }

  public getOptions(): EChartsOption {
    return this.options;
  }
}
