/**
 * Explanner Service Documentation
 * Explanner Service Documentation
 *
 * The version of the OpenAPI document: 1.7_landesstelle{20240611@153537}
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SaveStatus { 
    success?: boolean;
    version?: number;
    id?: string;
    statusResult?: SaveStatus.StatusResultEnum;
    restStatus?: SaveStatus.RestStatusEnum;
    seqNo?: number;
    misc?: string;
}
export namespace SaveStatus {
    export type StatusResultEnum = 'CREATED' | 'UPDATED' | 'DELETED' | 'NOT_FOUND' | 'NOOP';
    export const StatusResultEnum = {
        Created: 'CREATED' as StatusResultEnum,
        Updated: 'UPDATED' as StatusResultEnum,
        Deleted: 'DELETED' as StatusResultEnum,
        NotFound: 'NOT_FOUND' as StatusResultEnum,
        Noop: 'NOOP' as StatusResultEnum
    };
    export type RestStatusEnum = 'CONTINUE' | 'SWITCHING_PROTOCOLS' | 'OK' | 'CREATED' | 'ACCEPTED' | 'NON_AUTHORITATIVE_INFORMATION' | 'NO_CONTENT' | 'RESET_CONTENT' | 'PARTIAL_CONTENT' | 'MULTI_STATUS' | 'MULTIPLE_CHOICES' | 'MOVED_PERMANENTLY' | 'FOUND' | 'SEE_OTHER' | 'NOT_MODIFIED' | 'USE_PROXY' | 'TEMPORARY_REDIRECT' | 'BAD_REQUEST' | 'UNAUTHORIZED' | 'PAYMENT_REQUIRED' | 'FORBIDDEN' | 'NOT_FOUND' | 'METHOD_NOT_ALLOWED' | 'NOT_ACCEPTABLE' | 'PROXY_AUTHENTICATION' | 'REQUEST_TIMEOUT' | 'CONFLICT' | 'GONE' | 'LENGTH_REQUIRED' | 'PRECONDITION_FAILED' | 'REQUEST_ENTITY_TOO_LARGE' | 'REQUEST_URI_TOO_LONG' | 'UNSUPPORTED_MEDIA_TYPE' | 'REQUESTED_RANGE_NOT_SATISFIED' | 'EXPECTATION_FAILED' | 'MISDIRECTED_REQUEST' | 'UNPROCESSABLE_ENTITY' | 'LOCKED' | 'FAILED_DEPENDENCY' | 'TOO_MANY_REQUESTS' | 'INTERNAL_SERVER_ERROR' | 'NOT_IMPLEMENTED' | 'BAD_GATEWAY' | 'SERVICE_UNAVAILABLE' | 'GATEWAY_TIMEOUT' | 'HTTP_VERSION_NOT_SUPPORTED' | 'INSUFFICIENT_STORAGE';
    export const RestStatusEnum = {
        Continue: 'CONTINUE' as RestStatusEnum,
        SwitchingProtocols: 'SWITCHING_PROTOCOLS' as RestStatusEnum,
        Ok: 'OK' as RestStatusEnum,
        Created: 'CREATED' as RestStatusEnum,
        Accepted: 'ACCEPTED' as RestStatusEnum,
        NonAuthoritativeInformation: 'NON_AUTHORITATIVE_INFORMATION' as RestStatusEnum,
        NoContent: 'NO_CONTENT' as RestStatusEnum,
        ResetContent: 'RESET_CONTENT' as RestStatusEnum,
        PartialContent: 'PARTIAL_CONTENT' as RestStatusEnum,
        MultiStatus: 'MULTI_STATUS' as RestStatusEnum,
        MultipleChoices: 'MULTIPLE_CHOICES' as RestStatusEnum,
        MovedPermanently: 'MOVED_PERMANENTLY' as RestStatusEnum,
        Found: 'FOUND' as RestStatusEnum,
        SeeOther: 'SEE_OTHER' as RestStatusEnum,
        NotModified: 'NOT_MODIFIED' as RestStatusEnum,
        UseProxy: 'USE_PROXY' as RestStatusEnum,
        TemporaryRedirect: 'TEMPORARY_REDIRECT' as RestStatusEnum,
        BadRequest: 'BAD_REQUEST' as RestStatusEnum,
        Unauthorized: 'UNAUTHORIZED' as RestStatusEnum,
        PaymentRequired: 'PAYMENT_REQUIRED' as RestStatusEnum,
        Forbidden: 'FORBIDDEN' as RestStatusEnum,
        NotFound: 'NOT_FOUND' as RestStatusEnum,
        MethodNotAllowed: 'METHOD_NOT_ALLOWED' as RestStatusEnum,
        NotAcceptable: 'NOT_ACCEPTABLE' as RestStatusEnum,
        ProxyAuthentication: 'PROXY_AUTHENTICATION' as RestStatusEnum,
        RequestTimeout: 'REQUEST_TIMEOUT' as RestStatusEnum,
        Conflict: 'CONFLICT' as RestStatusEnum,
        Gone: 'GONE' as RestStatusEnum,
        LengthRequired: 'LENGTH_REQUIRED' as RestStatusEnum,
        PreconditionFailed: 'PRECONDITION_FAILED' as RestStatusEnum,
        RequestEntityTooLarge: 'REQUEST_ENTITY_TOO_LARGE' as RestStatusEnum,
        RequestUriTooLong: 'REQUEST_URI_TOO_LONG' as RestStatusEnum,
        UnsupportedMediaType: 'UNSUPPORTED_MEDIA_TYPE' as RestStatusEnum,
        RequestedRangeNotSatisfied: 'REQUESTED_RANGE_NOT_SATISFIED' as RestStatusEnum,
        ExpectationFailed: 'EXPECTATION_FAILED' as RestStatusEnum,
        MisdirectedRequest: 'MISDIRECTED_REQUEST' as RestStatusEnum,
        UnprocessableEntity: 'UNPROCESSABLE_ENTITY' as RestStatusEnum,
        Locked: 'LOCKED' as RestStatusEnum,
        FailedDependency: 'FAILED_DEPENDENCY' as RestStatusEnum,
        TooManyRequests: 'TOO_MANY_REQUESTS' as RestStatusEnum,
        InternalServerError: 'INTERNAL_SERVER_ERROR' as RestStatusEnum,
        NotImplemented: 'NOT_IMPLEMENTED' as RestStatusEnum,
        BadGateway: 'BAD_GATEWAY' as RestStatusEnum,
        ServiceUnavailable: 'SERVICE_UNAVAILABLE' as RestStatusEnum,
        GatewayTimeout: 'GATEWAY_TIMEOUT' as RestStatusEnum,
        HttpVersionNotSupported: 'HTTP_VERSION_NOT_SUPPORTED' as RestStatusEnum,
        InsufficientStorage: 'INSUFFICIENT_STORAGE' as RestStatusEnum
    };
}


