<div class="flex-col">
  <div class="dialog-header">
    <h2 mat-dialog-title class="dialog-header-label">
      {{ 'LABELS.search' + dataType | translate }}
    </h2>
    <ds-icon-button class="close-button" mat-dialog-close color="primary" icon="close" />
  </div>

  <div class="dialog-content" mat-dialog-content>
    <div class="flex-row search-and-pagination-container">
      <div class="flex-row">
        <ds-searchfield
          class="search-field"
          [value]="searchValue"
          (submitSearching)="startSearch($event)"
          placeholder="{{ 'PLACEHOLDER.search' + dataType | translate }}" />
      </div>
      <mat-paginator [length]="resultsLength" [pageSizeOptions]="[10, 20, 30, 50, 100]" showFirstLastButtons />
    </div>
    <div class="table-container">
      <div class="generic-container">
        <exp-overview-table
          [isLoadingResults]="isLoadingResults"
          [dataType]="dataType"
          [project]="project"
          [lookupDialogRef]="lookupDialogRef"
          [pageIndex]="pageIndex"
          [pageSize]="pagination"
          [fieldNames]="fieldnames"
          [displayedColumns]="displayedColumns"
          [dataSourceOT]="dataSource"
          [objectIdList]="objectIdList"
          [isLookup]="isLookup"
          (scheduleCriteriafieldName)="returnScheduleCriteriafieldName($event)"
          (dateRange)="onNewDateRange($event)" />
        <div *ngIf="isLoadingResults" class="flex-row no-data-search-div">{{ 'LABELS.searchItRun' | translate }}</div>
        <div *ngIf="!dataSource?.filteredData?.length && !isLoadingResults" class="flex-row no-data-search-div">
          <div class="no-result-container">
            <div>
              <mat-icon class="material-symbols-outlined">search_off</mat-icon>
            </div>
            <span class="mat-body-2">{{ 'LABELS.noSearchfind' | translate }} ‘{{ searchValue }}’</span>
          </div>
        </div>
        <div *ngIf="viewCantBeLoaded && !resultsLength" class="error-message-container">
          {{ 'ERROR.tableViewNotLoading' | translate }}
        </div>
      </div>
    </div>
  </div>
  <ds-horizontal-divider />
  <div class="flex-row dialog-actions" mat-dialog-actions>
    <ds-basic-icon-button
      icon="add"
      fontColor="accent"
      *ngIf="
        (dataType === 'ADDRESS' || dataType === 'INSTITUTE') && authService.hasCreatePermissionsForDatatype(dataType)
      "
      (clickButton)="openAddDialog()"
      label="{{ 'LABELS.lookupAdd' + dataType | translate }}" />
    <ds-basic-button color="accent" mat-dialog-close label="{{ 'BUTTONS.cancel' | translate }}" />
  </div>
</div>
