<div class="loader">
  <div class="loader-top-bar">
    <h2>{{ 'BUTTONS.upload' | translate }}</h2>
    <ds-icon-button (click)="hideTheOverlay()" icon="close" color="primary" class="close-button"></ds-icon-button>
  </div>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  <div class="loader-divider">
    <ds-horizontal-divider></ds-horizontal-divider>
  </div>
  <div class="loader-bottom-btn">
    <ds-basic-button color="accent" label="{{ 'BUTTONS.cancel' | translate }}" (click)="hideTheOverlay()" />
  </div>
</div>
