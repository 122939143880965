import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InitData, Permissions } from '../../model';
import { BaseAuthService } from './base-auth.service';

@Injectable({
  providedIn: 'root',
})
export abstract class BaseExpAuthService extends BaseAuthService {
  constructor() {
    super();
  }

  abstract isLoggedIn(): boolean;

  abstract setUserInfo(initData: InitData): void;

  abstract patchUserInfo(projectIdentifier: string): void;

  abstract checkAuth(projectIdentifier: string): Observable<any>;

  abstract setPermissionsOnAppInit(permissions: Permissions): void;
}
