<button
  [ngClass]="fullWidth ? 'button-full-width' : '' || iconBefore ? 'label-icon' : ''"
  #tooltip="matTooltip"
  [matTooltip]="tooltipLabel"
  [matTooltipPosition]="toolTipPosition"
  [matTooltipHideDelay]="toolTipHideDelay"
  [attr.aria-label]="ariaLabel"
  mat-button
  [color]="fontColor"
  [disabled]="disabled"
  (click)="clickButton.emit($event)"
  [style.color]="(themeService.isLightTheme$|async) ?'':'white'"
>
  <mat-icon class="material-symbols-outlined iconBefore">{{ icon }}</mat-icon>
  <mat-label> {{ label }}</mat-label>
</button>
