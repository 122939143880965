<main class="support-dialog-container flex-col">
  <div class="dialog-header">
    <h2 mat-dialog-title class="dialog-header-label">{{ 'LABELS.supportTitle' | translate }}</h2>
    <ds-icon-button class="close-button" icon="close" color="primary" mat-dialog-close></ds-icon-button>
  </div>
  <div mat-dialog-content class="dialog-content">
    <div class="flex-row">
      <main class="flex-col">
        <!-- user message for read only users -->
        <div
          *ngIf="!authService.hasWritePermissionsForDatatype(dataType)"
          class="flex-row titile-noWritePermissionLabel">
          <!-- user message for read only users -->
          <label>{{ 'LABELS.noWritePermissionLabel' + dataType | translate }} </label>
        </div>

        <exp-form-tabs *ngIf="(formManager.loading$ | async) === false; else showLoading" [dataType]="dataType" />
        <ng-template #showLoading>
          <mat-progress-bar class="loading-indicator" mode="indeterminate" />
          <span class="flex-col"> {{ 'MESSAGE.loadingObjectData' | translate }}</span>
        </ng-template>
      </main>
    </div>
  </div>
  <ds-horizontal-divider></ds-horizontal-divider>
  <div class="dialog-actions" mat-dialog-actions>
    <ds-basic-button
      color="accent"
      (clickButton)="abort()"
      label="{{ 'BUTTONS.cancel' | translate }}"></ds-basic-button>
    <ds-primary-button
      [disabled]="!isFormTouched || !authService.hasWritePermissionsForDatatype(dataType)"
      color="accent"
      (clickButton)="submitForm()"
      label="{{ 'BUTTONS.addTo' | translate }}"></ds-primary-button>
  </div>
</main>
