/**
 * Explanner Service Documentation
 * Explanner Service Documentation
 *
 * The version of the OpenAPI document: 1.7_landesstelle{20240611@153537}
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UploadedFile { 
    docId?: string;
    filename?: string;
    contentType?: string;
    size?: number;
    author?: string;
    description?: string;
    copyright?: string;
    license?: string;
    version?: string;
    chash?: string;
}

