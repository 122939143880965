export interface UserInfo {
  firstName: string;
  lastName: string;
  userName: string;
  mandator: string;
  userEmail: string;
  isMgmtUser: boolean;
  isAdminUser: boolean;
  userRole?: string;
  userData?: any;
}

export const EMPTY_USER_INFO: UserInfo = {
  firstName: 'NOT SET',
  lastName: 'NOT SET',
  userName: 'NOT SET',
  userEmail: 'NOT SET',
  mandator: '',
  isAdminUser: false,
  isMgmtUser: false,
};
