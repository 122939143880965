import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { DataType } from '../../../app/model/types/DataTypes';

import { AuthService } from '@frontends/commons';
import { FormManagerService } from '../../../app/services/form/form-manager.service';
import { LocalStorageService } from '../../../app/services/storage/local-storage.service';
import { SUPPORT_NAME } from '../../../app/utils/constants';
@Component({
  selector: 'exp-support-dialog',
  templateUrl: './support-dialog.component.html',
  styleUrls: ['./support-dialog.component.scss'],
  providers: [FormManagerService],
})
export class SupportDialogComponent implements OnInit {
  public dataType!: DataType;
  public projectIdentifier?: string;
  public isFormTouched = false;

  private isFirstClick = true;

  constructor(
    public formManager: FormManagerService,
    private dialogRef: MatDialogRef<SupportDialogComponent>,
    public localStorageService: LocalStorageService,
    public authService: AuthService,
    private logger: NGXLogger
  ) {}

  ngOnInit(): void {
    this.initializer();
    this.formManager.formTouched$.subscribe((touched) => {
      this.isFormTouched = touched;
    });
    this.formManager.submitted$.subscribe((submitted) => {
      this.logger.debug(`[SUPPORT-DIALOG]: FORM SUBMITTED: ${submitted}`);
      if (submitted) {
        this.dialogRef.close();
      } else {
        this.isFirstClick = true;
      }
    });

    this.logger.debug('[SUPPORT-DIALOG]: INITIALIZED', this);
  }

  private getSupportProject(): string {
    return SUPPORT_NAME;
  }

  private initializer(): void {
    this.projectIdentifier = this.getSupportProject();
    this.dataType = 'OBJECT';

    this.formManager.prepareFormData(this.projectIdentifier, this.dataType, '');
    this.formManager.loading$.subscribe((loading) => {
      if (!loading) {
        this.dialogRef.updateSize('762px', '90vh');
        this.logger.debug(`[SUPPORT-DIALOG]: FROM MANAGER PREPARED DATA`);
      }
    });
  }

  public submitForm(): void {
    this.logger.debug(`[SUPPORT-DIALOG]: ATTEMPT TO SUBMIT: ${this.isFirstClick}`);
    if (this.isFirstClick) {
      this.isFirstClick = false;
      this.formManager.submit('CREATE', this.dataType, this.projectIdentifier);
    }
  }

  public abort(): void {
    this.dialogRef.close();
  }
}
