export enum ReservationTypesEnum {
  AVAILABLE = 'green',
  RESERVED = 'orange',
  NOT_AVAILABLE = 'red',
  PARTIALLY = 'gray',
}

export enum ReservationNames {
  GREEN = 'TRAFFICLIGHT.available',
  ORANGE = 'TRAFFICLIGHT.reserved',
  RED = 'TRAFFICLIGHT.unavailable',
  MIXED = 'TRAFFICLIGHT.mixed',
}
