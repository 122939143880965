@if (hasWritePermission) {
  <div #fileInputWrapper class="file-upload-wrapper mat-elevation-z1" [ngClass]="{ 'input-on-drag': isDragging }">
    <input
      #fileInput
      id="fileDropRef"
      type="file"
      [disabled]="!hasWritePermission"
      (input)="fileBrowseHandler($event)"
      (focus)="isFileInputFocused = true"
      (blur)="isFileInputFocused = false"
      class="file-upload-input"
      data-role="drag-drop-target" />
    <div class="drag-n-drop-title">
      <span class="mat-subtitle-1">
        {{ (type === 'IMAGE' ? 'LABELS.selectImage' : 'LABELS.selectFile') | translate }}
      </span>
      <label
        class="file-upload-add-btn"
        color="accent"
        for="fileDropRef"
        [ngClass]="{
          clickable: hasWritePermission,
          'disabled-label': !hasWritePermission,
          notAllowed: !hasWritePermission,
          'file-upload-label-focused': isFileInputFocused
        }">
        {{ (type === 'IMAGE' ? 'LABELS.browseImage' : 'LABELS.browseFile') | translate }}
      </label>
    </div>

    <p class="label-file-size">{{ 'LABELS.maxFileSize' | translate }}</p>
  </div>
}
@if (files?.length) {
  <div cdkDropList (cdkDropListDropped)="drop($event)" class="files-list flex-col">
    @for (file of files; track $index) {
      <div cdkDrag class="flex-row drag-and-drop-element">
        <div
          class="flex-row single-file mat-elevation-z1"
          [ngClass]="{ 'title-image-file': $index === 0 && type === 'IMAGE' }">
          <div class="flex-row image-size-container">
            <div class="flex-row image-container">
              @if (isImage(file)) {
                <img
                  [src]="getDocumentPathSmall(file)"
                  class="image-icon"
                  alt=""
                  (click)="openImagePreviewDialog(getDocumentPath(file))" />
              } @else {
                <div class="file-icon" style="width: 50px">
                  <mat-icon class="material-symbols-outlined document-icon" (click)="openDocumentPreviewDialog(file)">
                    description
                  </mat-icon>
                </div>
              }
            </div>
            <div class="flex-row size-row">
              <p class="size">
                {{ formatBytes(file?.size) }}
              </p>
            </div>
          </div>

          <div class="flex-col info">
            @for (option of options; track $index) {
              <div class="flex-row input-space">
                <ds-input-fields-upload-file
                  [valueOnly]="valueOnly"
                  color="accent"
                  iconClass="security"
                  tooltip="{{ 'LABELS.confidential' | translate }}"
                  label="{{ 'PLACEHOLDER.file' + (option | firstLetterCapital) | translate }}"
                  [disabled]="!hasWritePermission"
                  [(files)]="file[option]"
                  (filesChange)="changeDescription()" />
              </div>
            }
            <div class="flex-row delete-and-download">
              @if (!valueOnly) {
                <div class="flex-row move-up-button">
                  @if ($index > 0 && type === 'IMAGE') {
                    <ds-basic-icon-badge-button
                      [disabled]="!hasWritePermission"
                      [tooltipLabel]="'LABELS.setTitleImage' | translate"
                      label="{{ 'LABELS.moveToTop' | translate }}"
                      icon="keyboard_double_arrow_up"
                      (clickButton)="placeAsTitleImage(file)" />
                  }
                </div>
              }

              <div class="flex-row download-delete-buttons">
                @if (hasDownloadButton) {
                  <ds-icon-button
                    download
                    tooltipLabel="{{ 'EXPORT.download' | translate }}"
                    color="primary"
                    icon="download"
                    (clickButton)="getDownloadPath(file)" />
                }
                @if (!valueOnly) {
                  <ds-icon-button
                    tooltipLabel="{{ 'BUTTONS.delete' | translate }}"
                    color="warn"
                    [disabled]="!hasWritePermission"
                    icon="delete_forever"
                    (clickButton)="deleteFile($index)" />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    }
  </div>
} @else {
  <div class="flex-row no-files-label-container mat-elevation-z1">
    {{ (type === 'IMAGE' ? 'LABELS.noImages' : 'LABELS.noFiles') | translate }}
  </div>
}
