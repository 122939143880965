import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideLuxonDateAdapter } from '@angular/material-luxon-adapter';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IconBadgeModule } from '../icon-badge/icon-badge.module';
import { DatepickerComponent } from './datepicker-date/datepicker.component';
import { DatepickerRangeComponent } from './datepickerrange/datepicker-range.component';

@NgModule({
  declarations: [DatepickerComponent, DatepickerRangeComponent],
  imports: [
    MatTooltipModule,
    MatIconModule,
    CommonModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    IconBadgeModule,
  ],
  providers: [DatePipe, { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }, provideLuxonDateAdapter()],
  exports: [DatepickerComponent, DatepickerRangeComponent],
})
export class DatepickerModule {}
