<ng-container *ngIf="resultsLength > 0; else whenThereIsNoResult">
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
      <button mat-icon-button disabled class="expand-btn"></button>
      <mat-checkbox
        color="accent"
        class="checklist-leaf-node"
        [checked]="checklistSelection.isSelected(node)"
        (change)="leafItemSelectionToggle(node)"
        >{{ getNodeName(node.name) }} ({{ node.count }})</mat-checkbox
      >
    </mat-tree-node>
    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
      <button mat-icon-button matTreeNodeToggle class="expand-btn" [attr.aria-label]="'Toggle ' + node.name">
        <mat-icon class="mat-icon-rtl-mirror">
          {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
        </mat-icon>
      </button>
      <mat-checkbox
        color="accent"
        [checked]="checklistSelection.isSelected(node)"
        [indeterminate]="descendantsPartiallySelected(node)"
        (change)="itemSelectionToggle(node)"
        >{{ getNodeName(node.name) }} ({{ node.count }})</mat-checkbox
      >
    </mat-tree-node>
  </mat-tree>
</ng-container>
<ng-template #whenThereIsNoResult>
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node
      *matTreeNodeDef="let node"
      matTreeNodeToggle
      matTreeNodePadding
      [style.display]="isNodeSelectedOrIndeterminated(node) ? 'flex' : 'none'">
      <button mat-icon-button disabled class="expand-btn"></button>
      <mat-checkbox
        color="accent"
        class="checklist-leaf-node"
        [checked]="checklistSelection.isSelected(node)"
        (change)="leafItemSelectionToggle(node)"
        >{{ getNodeName(node.name) }} ({{ 0 }})</mat-checkbox
      >
    </mat-tree-node>

    <mat-tree-node
      *matTreeNodeDef="let node; when: hasChild"
      matTreeNodePadding
      [style.display]="isNodeSelectedOrIndeterminated(node) ? 'flex' : 'none'">
      <button mat-icon-button matTreeNodeToggle class="expand-btn" [attr.aria-label]="'Toggle ' + node.name">
        <mat-icon class="mat-icon-rtl-mirror">
          {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
        </mat-icon>
      </button>
      <mat-checkbox
        color="accent"
        [checked]="checklistSelection.isSelected(node)"
        [indeterminate]="descendantsPartiallySelected(node)"
        (change)="itemSelectionToggle(node)"
        >{{ getNodeName(node.name) }} ({{ 0 }})</mat-checkbox
      >
    </mat-tree-node>
  </mat-tree>
</ng-template>
