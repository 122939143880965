/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { filter, take } from 'rxjs';
import { DataType } from '../../../app/model/types/DataTypes';
import { AlertService } from '../../../app/services/alert.service';

import { AuthService } from '@frontends/commons';
import { FormManagerService } from '../../../app/services/form/form-manager.service';
import { LocalStorageService } from '../../../app/services/storage/local-storage.service';
@Component({
  selector: 'exp-add-dialog',
  templateUrl: './add-dialog.component.html',
  styleUrls: ['./add-dialog.component.scss'],
  providers: [FormManagerService],
})
export class AddDialogComponent implements OnInit {
  isFormTouched = false;
  dataType!: DataType;
  isFirstClick = true;
  projectIdentifier: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { projectIdentifier: string; dataType: DataType; objectId: string },
    public dialogRef: MatDialogRef<AddDialogComponent>,
    public dialog: MatDialog,
    public authService: AuthService,
    public formManager: FormManagerService,
    private logger: NGXLogger,
    private localStorageService: LocalStorageService,
    private alert: AlertService
  ) {
    this.projectIdentifier = this.localStorageService.getProject()?.project || '';
  }

  ngOnInit(): void {
    this.initializer();
    this.formManager.formTouched$.subscribe((touched) => {
      this.isFormTouched = touched;
    });
    this.formManager.submitted$.subscribe((submitted) => {
      this.logger.debug(`[ADD-DIALOG]: FORM SUBMITTED: ${submitted}`);
      if (submitted) {
        this.dialogRef.close(submitted);
      } else {
        this.isFirstClick = true;
      }
    });
    this.setupCustomDialogOnCloseEvent();
    this.logger.debug('[ADD-DIALOG]: INITIALIZED', this);
  }

  private initializer(): void {
    this.dataType = this.data.dataType;
    this.formManager.prepareFormData(this.projectIdentifier, this.dataType, '');
    this.formManager.loading$.subscribe((loading) => {
      if (!loading) {
        this.dialogRef.updateSize('760px', '90vh');
        this.logger.debug(`[ADD-DIALOG]: FROM MANAGER PREPARED DATA`);
      }
    });
  }

  private setupCustomDialogOnCloseEvent(): void {
    this.dialogRef.disableClose = true;
    this.dialogRef.backdropClick().subscribe(() => this.onModalClose());
    this.dialogRef
      .keydownEvents()
      .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
      .subscribe(() => this.onModalClose());
  }

  public onModalClose(): void {
    if (!this.isFormTouched) {
      return this.dialogRef.close();
    }

    const alertDialogRef = this.alert.confirmDiscardDialogChanges();
    alertDialogRef.componentInstance.save.pipe(take(1)).subscribe((isCanDiscard) => {
      if (isCanDiscard) {
        this.dialogRef.close();
      }
      alertDialogRef.close();
    });
  }

  public submitForm(): void {
    this.logger.debug(`[ADD-DIALOG]: ATTEMPT TO SUBMIT: ${this.isFirstClick}`);
    if (this.isFirstClick) {
      this.isFirstClick = false;
      this.formManager.submit('CREATE', this.dataType, this.projectIdentifier);
    }
  }
}
