<div class="wrapper">
  <ds-icon-badge *ngIf="internal" />
  <mat-form-field appearance="outline" [color]="color" [class.ng-invalid]="showError">
    <mat-label>{{ label }}</mat-label>
    <mat-date-range-input [rangePicker]="picker" [min]="min" [max]="max" [disabled]="disabled" [required]="required">
      <input
        matStartDate
        (dateInput)="inputStartDate($event)"
        [(ngModel)]="start"
        [placeholder]="placeholderStart"
        [required]="required" />
      <input
        matEndDate
        (dateInput)="inputEndDate($event)"
        [(ngModel)]="end"
        [placeholder]="placeholderEnd"
        [required]="required" />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix *ngIf="hasCheck && isCheckBtnShown && start && end" (click)="dateRangeChange()">
      <mat-icon class="datepicker-icon-btn" matDatepickerToggleIcon>check</mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker-toggle matSuffix *ngIf="hasClear" (click)="dateRangeClear()">
      <mat-icon class="datepicker-icon-btn" matDatepickerToggleIcon>close</mat-icon>
    </mat-datepicker-toggle>

    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
    <mat-hint aria-label="Input Hint" *ngIf="showHint">{{ hintMessage }}</mat-hint>
    <mat-error aria-label="Error Hint" *ngIf="showError">{{ errorMessage }}</mat-error>
  </mat-form-field>
</div>
