 <!-- Search Field -->
 <div class="flex-row search-field-container center-row-verticaly">
  <mat-form-field appearance="outline">
    <mat-label>{{ 'PLACEHOLDER.search' + dataType | translate }}</mat-label>
    <mat-icon matPrefix (click)="startSearching()">search</mat-icon>
    <input
      name="search"
      [(ngModel)]="searchValue"
      matInput
      expKeyupDebounce
      (keyup.enter)="startSearching()"
      placeholder="{{ 'PLACEHOLDER.searchExample' + dataType | translate }}"
      #input />
  </mat-form-field>
</div>
