import { Component, Input } from '@angular/core';

@Component({
  selector: 'ds-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
})
export class LabelComponent {
  @Input() for = '';
  @Input() text = '';
  @Input() isFieldRequired = false;
  @Input() typography = '';
  @Input() tooltip = '';
  @Input() iconClass = 'security';
  @Input() internal = false;
  @Input() valueOnly = false;
}
