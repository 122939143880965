<div class="flex-col">
  <div class="dialog-header">
    <h2 mat-dialog-title class="dialog-header-label">
      {{ ('LABELS.modificationHistoryof' | translate) + ' ' + id }}
    </h2>
    <ds-icon-button class="close-button" mat-dialog-close color="primary" icon="close"></ds-icon-button>
  </div>

  <div class="dialog-content" mat-dialog-content>
    <div class="table-container">
      <div class="generic-container">
        <button
          #print
          styleSheetFile="assets/print-pdf.css"
          printTitle="{{ 'LABELS.changes' | translate }}"
          printSectionId="print-section-parent"
          ngxPrint
          hidden="true"></button>
        <div *ngIf="listOfChanges.length > 0 && !loading">
          <div class="flex-col" id="print-section-parent">
            <div class="flex-col" id="header-info" style="display: none">
              <label id="exp-title">{{ 'LABELS.changes' | translate }}</label
              ><br />
            </div>
            <table class="main-table" mat-table [dataSource]="dataSource" matSort>
              <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

              <!-- Columns loaded from backend -->
              <span *ngFor="let column of displayedColumns">
                <ng-container [matColumnDef]="column">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isSortingDisabled(column)">
                    <label *ngIf="column === 'data'"> {{ 'LABELS.changed_data' | translate }} </label>
                    <label *ngIf="column === 'timestamp'"> {{ 'LABELS.timestamp' | translate }} </label>
                    <label *ngIf="column === 'causingUser'"> {{ 'LABELS.causing_user' | translate }} </label>
                  </th>
                  <td mat-cell *matCellDef="let changeEntry">
                    <label *ngIf="column === 'data'">{{ getKeysForChangeEntry(changeEntry) }}</label>
                    <label *ngIf="column === 'timestamp'">{{
                      changeEntry[column] | date : dateFormat.dayMonthHourFormat
                    }}</label>
                    <label *ngIf="column !== 'data' && column !== 'timestamp'">{{ changeEntry[column] }}</label>
                  </td>
                </ng-container>
              </span>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <span>
                <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index" [attr.id]="row.docId"></tr>
              </span>
            </table>
          </div>
        </div>
        <div *ngIf="listOfChanges?.length === 0 && !loading">
          <div class="flex-row mat-elevation-z1 no-history-label-container">
            <label>{{ 'LABELS.noHistory' | translate }}</label>
          </div>
        </div>
        <div *ngIf="loading">
          <mat-progress-bar class="loading-indicator" mode="indeterminate" />
          <span class="flex-col"> {{ 'MESSAGE.loadingObjectData' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
  <ds-horizontal-divider></ds-horizontal-divider>
  <div class="flex-row dialog-actions" mat-dialog-actions>
    <ds-basic-icon-button
      *ngIf="listOfChanges?.length"
      icon="print"
      fontColor="accent"
      (click)="triggerPrint()"
      label="{{ 'BUTTONS.print' | translate }}"></ds-basic-icon-button>
    <ds-basic-button color="accent" mat-dialog-close label="{{ 'BUTTONS.close' | translate }}"></ds-basic-button>
  </div>
</div>
