import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { BasicButtonComponent } from './basic-button/basic-button.component';
import { BasicIconBadgeButtonComponent } from './basic-icon-badge-button/basic-icon-badge-button.component';
import { BasicIconButtonComponent } from './basic-icon-button/basic-icon-button.component';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { PrimaryButtonComponent } from './primary-button/primary-button.component';
import { PrimaryIconButtonComponent } from './primary-icon-button/primary-icon-button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BasicLinkButtonComponent } from './basic-link-button/basic-link-button.component';
@NgModule({
  imports: [CommonModule, MatBadgeModule, MatButtonModule, MatIconModule, MatFormFieldModule, MatTooltipModule],
  declarations: [
    BasicLinkButtonComponent,
    PrimaryButtonComponent,
    PrimaryIconButtonComponent,
    BasicButtonComponent,
    BasicIconBadgeButtonComponent,
    BasicIconButtonComponent,
    IconButtonComponent,
  ],
  exports: [
    BasicLinkButtonComponent,
    PrimaryButtonComponent,
    PrimaryIconButtonComponent,
    BasicButtonComponent,
    BasicIconBadgeButtonComponent,
    BasicIconButtonComponent,
    IconButtonComponent,
  ],
})
export class ButtonsModule {}
