<div class="reservation-dialog">
  <div class="reservation-dialog-header flex-space-between">
    <h1 class="reservation-dialog-title">{{ title || '' | translate }}</h1>
    <ds-icon-button [mat-dialog-close]="" color="primary" icon="close" />
  </div>
  <form [formGroup]="form">
    <div class="reservation-dialog-form">
      <ds-input
        formControlName="title"
        label="{{ 'PLACEHOLDER.title' | translate }}"
        [errorMessage]="getErrorMessage()"
        [showError]="true"
        [required]="true" />
      <ds-datepicker-range
        formControlName="range"
        label="{{ 'PLACEHOLDER.dateRange' | translate }}"
        [required]="true"
        [initialStarDate]="data.from || null"
        [initialEndDate]="data.to || null"
        errorMessage="{{ 'MESSAGE.ERROR_MESSAGE.invalidDateRange' | translate }}"
        [showHint]="true"
        [showError]="getControlStatusByName('range')"
        hintMessage="{{ 'HINTS.dateRangeFormat' | translate }}"
        [isCheckBtnShown]="false" />
      <div class="reservation-dialog-status-wrapper">
        <ds-traffic-light
          label="Status"
          formControlName="status"
          [chipColors]="['red', 'orange']"
          [chipLabels]="statusLabels"
          errorMessage="Invalid status"
          [showError]="getControlStatusByName('status')"
          [required]="true" />
      </div>
    </div>

    <div class="reservation-dialog-divider-wrapper">
      <ds-horizontal-divider />
    </div>

    <div class="dialog-actions" mat-dialog-actions>
      <ds-basic-button [mat-dialog-close] color="accent" label="{{ 'BUTTONS.cancel' | translate }}" />
      <ds-primary-button
        icon="add"
        color="accent"
        (clickButton)="submitForm()"
        label=" {{ 'BUTTONS.save' | translate }}" />
    </div>
  </form>
</div>
