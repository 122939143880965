import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataType } from '@frontends/commons';
import { ResponseObjectTrackingData, TrackingData } from '@frontends/exp-apiclient';
import { dateFormats } from '../../../model/DateFormats';
import { ApiClientService } from '../../../services/api-client.service';

@Component({
  selector: 'exp-modification-history',
  templateUrl: './modification-history.component.html',
  styleUrls: ['./modification-history.component.scss'],
})
export class ModificationHistoryComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('print') print!: ElementRef;
  docId?: string;
  dataType?: DataType;
  currentSelectedProject?: string;
  fieldNames: any;
  dateFormat = dateFormats;
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['causingUser', 'timestamp', 'data'];
  resultsLength!: number;
  listOfChanges!: Array<TrackingData>;
  dataTypeShowing?: string;
  currentProject: any;
  id?: string;
  loading: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private apiClientService: ApiClientService
  ) {
    this.loading = true;
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.initialization();
  }

  initialization() {
    this.getHistory();
    this.currentProject = this.data.project;
    this.dataType = this.data.dataType;
    this.docId = this.data.docId;
    this.id = this.data.id;
    this.fieldNames = this.data.fieldnames;
  }

  triggerPrint(): void {
    this.print.nativeElement.click();
  }

  getHistory(): void {
    this.apiClientService
      .getHistByIdData(this.data.project, this.data.dataType, this.data.docId)
      .subscribe((historyResult: ResponseObjectTrackingData) => {
        if (historyResult.matches) this.resultsLength = historyResult.matches;
        if (historyResult.results) this.listOfChanges = historyResult.results;
        this.dataSource = new MatTableDataSource<any>(historyResult.results);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.loading = false;
      });
  }

  isSortingDisabled(column: string) {
    return column === 'data';
  }

  getKeysForChangeEntry(changeEntry: any) {
    const changedKeys = [];
    // eslint-disable-next-line guard-for-in
    for (const key in changeEntry.data) {
      changedKeys.push(this.data.fieldnames[key]);
    }
    return changedKeys;
  }
}
