import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseButtonComponent } from '../../Base/base-button.component';

@Component({
  selector: 'ds-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss'],
})
export class DialogHeaderComponent extends BaseButtonComponent {
  @Input() public title!: string;
  @Output() public closeBtnClick: EventEmitter<void> = new EventEmitter<void>();

  public onClose() {
    this.closeBtnClick.emit();
  }
}
