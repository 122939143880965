/**
 * Explanner Service Documentation
 * Explanner Service Documentation
 *
 * The version of the OpenAPI document: 1.7_landesstelle{20240611@153537}
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient,
    HttpContext,
    HttpEvent,
    HttpHeaders,
    HttpParameterCodec,
    HttpParams,
    HttpResponse
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

// @ts-ignore
import { GenericMasterFormular } from '../model/genericMasterFormular';
// @ts-ignore
import { SaveStatus } from '../model/saveStatus';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root',
})
export class MgmtFormularControllerService {
  protected basePath = '/service';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration,
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach((elem) => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k)),
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Clears options from Formular!
   * @param project
   * @param dataType
   * @param cauth
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public clearOptionsFormular(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    cauth?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<GenericMasterFormular>;
  public clearOptionsFormular(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    cauth?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<GenericMasterFormular>>;
  public clearOptionsFormular(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    cauth?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<GenericMasterFormular>>;
  public clearOptionsFormular(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    cauth?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<any> {
    if (project === null || project === undefined) {
      throw new Error('Required parameter project was null or undefined when calling clearOptionsFormular.');
    }
    if (dataType === null || dataType === undefined) {
      throw new Error('Required parameter dataType was null or undefined when calling clearOptionsFormular.');
    }

    let localVarHeaders = this.defaultHeaders;
    if (cauth !== undefined && cauth !== null) {
      localVarHeaders = localVarHeaders.set('cauth', String(cauth));
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json;charset=utf-8'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/mgmt/form/clearOptions/${this.configuration.encodeParam({
      name: 'project',
      value: project,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/dt/${this.configuration.encodeParam({
      name: 'dataType',
      value: dataType,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: "'ADDRESS' | 'INSTITUTE' | 'OBJECT'",
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<GenericMasterFormular>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Delete object
   * @param project
   * @param docId
   * @param cauth
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteFormular(
    project: string,
    docId: string,
    cauth?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<boolean>;
  public deleteFormular(
    project: string,
    docId: string,
    cauth?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<boolean>>;
  public deleteFormular(
    project: string,
    docId: string,
    cauth?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<boolean>>;
  public deleteFormular(
    project: string,
    docId: string,
    cauth?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<any> {
    if (project === null || project === undefined) {
      throw new Error('Required parameter project was null or undefined when calling deleteFormular.');
    }
    if (docId === null || docId === undefined) {
      throw new Error('Required parameter docId was null or undefined when calling deleteFormular.');
    }

    let localVarHeaders = this.defaultHeaders;
    if (cauth !== undefined && cauth !== null) {
      localVarHeaders = localVarHeaders.set('cauth', String(cauth));
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['*/*'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/mgmt/form/${this.configuration.encodeParam({
      name: 'project',
      value: project,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/${this.configuration.encodeParam({
      name: 'docId',
      value: docId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<boolean>('delete', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Get all objects
   * @param project
   * @param cauth
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAllFormular(
    project: string,
    cauth?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<Array<GenericMasterFormular>>;
  public getAllFormular(
    project: string,
    cauth?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<Array<GenericMasterFormular>>>;
  public getAllFormular(
    project: string,
    cauth?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<Array<GenericMasterFormular>>>;
  public getAllFormular(
    project: string,
    cauth?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<any> {
    if (project === null || project === undefined) {
      throw new Error('Required parameter project was null or undefined when calling getAllFormular.');
    }

    let localVarHeaders = this.defaultHeaders;
    if (cauth !== undefined && cauth !== null) {
      localVarHeaders = localVarHeaders.set('cauth', String(cauth));
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json;charset=utf-8'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/mgmt/form/${this.configuration.encodeParam({
      name: 'project',
      value: project,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<Array<GenericMasterFormular>>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Get Formular by its datatype
   * @param project
   * @param dataType
   * @param cauth
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getByDtFormular(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    cauth?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<GenericMasterFormular>;
  public getByDtFormular(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    cauth?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<GenericMasterFormular>>;
  public getByDtFormular(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    cauth?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<GenericMasterFormular>>;
  public getByDtFormular(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    cauth?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<any> {
    if (project === null || project === undefined) {
      throw new Error('Required parameter project was null or undefined when calling getByDtFormular.');
    }
    if (dataType === null || dataType === undefined) {
      throw new Error('Required parameter dataType was null or undefined when calling getByDtFormular.');
    }

    let localVarHeaders = this.defaultHeaders;
    if (cauth !== undefined && cauth !== null) {
      localVarHeaders = localVarHeaders.set('cauth', String(cauth));
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json;charset=utf-8'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/mgmt/form/${this.configuration.encodeParam({
      name: 'project',
      value: project,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/dt/${this.configuration.encodeParam({
      name: 'dataType',
      value: dataType,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: "'ADDRESS' | 'INSTITUTE' | 'OBJECT'",
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<GenericMasterFormular>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Get object by its id
   * @param project
   * @param docId
   * @param cauth
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getByIdFormular(
    project: string,
    docId: string,
    cauth?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<GenericMasterFormular>;
  public getByIdFormular(
    project: string,
    docId: string,
    cauth?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<GenericMasterFormular>>;
  public getByIdFormular(
    project: string,
    docId: string,
    cauth?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<GenericMasterFormular>>;
  public getByIdFormular(
    project: string,
    docId: string,
    cauth?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<any> {
    if (project === null || project === undefined) {
      throw new Error('Required parameter project was null or undefined when calling getByIdFormular.');
    }
    if (docId === null || docId === undefined) {
      throw new Error('Required parameter docId was null or undefined when calling getByIdFormular.');
    }

    let localVarHeaders = this.defaultHeaders;
    if (cauth !== undefined && cauth !== null) {
      localVarHeaders = localVarHeaders.set('cauth', String(cauth));
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json;charset=utf-8'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/mgmt/form/${this.configuration.encodeParam({
      name: 'project',
      value: project,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/${this.configuration.encodeParam({
      name: 'docId',
      value: docId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<GenericMasterFormular>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Special: Save multiple objects
   * @param project
   * @param genericMasterFormular
   * @param cauth
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public saveAllFormular(
    project: string,
    genericMasterFormular: Array<GenericMasterFormular>,
    cauth?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<Array<SaveStatus>>;
  public saveAllFormular(
    project: string,
    genericMasterFormular: Array<GenericMasterFormular>,
    cauth?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<Array<SaveStatus>>>;
  public saveAllFormular(
    project: string,
    genericMasterFormular: Array<GenericMasterFormular>,
    cauth?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<Array<SaveStatus>>>;
  public saveAllFormular(
    project: string,
    genericMasterFormular: Array<GenericMasterFormular>,
    cauth?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<any> {
    if (project === null || project === undefined) {
      throw new Error('Required parameter project was null or undefined when calling saveAllFormular.');
    }
    if (genericMasterFormular === null || genericMasterFormular === undefined) {
      throw new Error('Required parameter genericMasterFormular was null or undefined when calling saveAllFormular.');
    }

    let localVarHeaders = this.defaultHeaders;
    if (cauth !== undefined && cauth !== null) {
      localVarHeaders = localVarHeaders.set('cauth', String(cauth));
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json;charset=utf-8'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/mgmt/form/${this.configuration.encodeParam({
      name: 'project',
      value: project,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/load`;
    return this.httpClient.request<Array<SaveStatus>>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: genericMasterFormular,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Save object
   * @param project
   * @param genericMasterFormular
   * @param cauth
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public saveFormular(
    project: string,
    genericMasterFormular: GenericMasterFormular,
    cauth?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<SaveStatus>;
  public saveFormular(
    project: string,
    genericMasterFormular: GenericMasterFormular,
    cauth?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<SaveStatus>>;
  public saveFormular(
    project: string,
    genericMasterFormular: GenericMasterFormular,
    cauth?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<SaveStatus>>;
  public saveFormular(
    project: string,
    genericMasterFormular: GenericMasterFormular,
    cauth?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<any> {
    if (project === null || project === undefined) {
      throw new Error('Required parameter project was null or undefined when calling saveFormular.');
    }
    if (genericMasterFormular === null || genericMasterFormular === undefined) {
      throw new Error('Required parameter genericMasterFormular was null or undefined when calling saveFormular.');
    }

    let localVarHeaders = this.defaultHeaders;
    if (cauth !== undefined && cauth !== null) {
      localVarHeaders = localVarHeaders.set('cauth', String(cauth));
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json;charset=utf-8'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/mgmt/form/${this.configuration.encodeParam({
      name: 'project',
      value: project,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<SaveStatus>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: genericMasterFormular,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Update object
   * @param project
   * @param genericMasterFormular
   * @param cauth
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateFormular(
    project: string,
    genericMasterFormular: GenericMasterFormular,
    cauth?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<SaveStatus>;
  public updateFormular(
    project: string,
    genericMasterFormular: GenericMasterFormular,
    cauth?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<SaveStatus>>;
  public updateFormular(
    project: string,
    genericMasterFormular: GenericMasterFormular,
    cauth?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<SaveStatus>>;
  public updateFormular(
    project: string,
    genericMasterFormular: GenericMasterFormular,
    cauth?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<any> {
    if (project === null || project === undefined) {
      throw new Error('Required parameter project was null or undefined when calling updateFormular.');
    }
    if (genericMasterFormular === null || genericMasterFormular === undefined) {
      throw new Error('Required parameter genericMasterFormular was null or undefined when calling updateFormular.');
    }

    let localVarHeaders = this.defaultHeaders;
    if (cauth !== undefined && cauth !== null) {
      localVarHeaders = localVarHeaders.set('cauth', String(cauth));
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['*/*'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/mgmt/form/${this.configuration.encodeParam({
      name: 'project',
      value: project,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<SaveStatus>('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: genericMasterFormular,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
