import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { SearchComponent } from './components/_INPUT COMPONENTS/search/search.component';
import { DocumentPreviewComponent } from './components/document-preview/document-preview.component';
import { ModificationHistoryComponent } from './components/modification-history/modification-history.component';
import { OverviewTableComponent } from './components/overview-table/overview-table.component';
import { TreeCheckFilterComponent } from './components/tree-check-filter/tree-check-filter.component';
import { ValidatorErrorComponent } from './components/validator-error/validator-error.component';
import { getPaginatorIntl } from './paginatorIntl/german-paginator';

import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatBadgeModule } from '@angular/material/badge';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgImageSliderModule } from 'ng-image-slider';
import { NgxPrintModule } from 'ngx-print';
import { SavefilterComponent } from './components/_INPUT COMPONENTS/savefilter/savefilter.component';

import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  ButtonsModule,
  CheckboxModule,
  ChipsModule,
  DatepickerModule,
  DividerModule,
  InputFieldsModule,
  LabelModule,
  LinksModule,
  RadiobuttonModule,
  SearchfieldModule,
  SelectModule,
} from '@frontends/designsystem';
import { AiDialogComponent } from '../dialogs/ai-dialog/ai-dialog.component';
import { PropertyAccessPipe } from '../pipes/property-access.pipe';
import { AlertComponent } from './components/alert/alert/alert.component';
import { FacetsFilterComponent } from './components/facets-filter/facets-filter.component';
import { FieldSearchComponent } from './components/field-search/field-search.component';
import { FileUploadingOverlayComponent } from './components/file-uploading-overlay/file-uploading-overlay.component';
import { FilterDialogComponent } from './components/filter-dialog/filter-dialog.component';
import { PrintLayoutComponent } from './components/print-layout/print-layout.component';

@NgModule({
  declarations: [
    SearchComponent,
    ValidatorErrorComponent,
    TreeCheckFilterComponent,
    ModificationHistoryComponent,
    DocumentPreviewComponent,
    OverviewTableComponent,
    SavefilterComponent,
    AlertComponent,
    FacetsFilterComponent,
    FilterDialogComponent,
    AiDialogComponent,
    FileUploadingOverlayComponent,
    FieldSearchComponent,
    PrintLayoutComponent,
    PropertyAccessPipe,
  ],
  imports: [
    ReactiveFormsModule,
    MatDatepickerModule,
    SelectModule,
    LabelModule,
    DividerModule,
    ButtonsModule,
    SearchfieldModule,
    LinksModule,
    InputFieldsModule,
    DatepickerModule,
    ButtonsModule,
    ChipsModule,
    RadiobuttonModule,
    CheckboxModule,
    ButtonsModule,
    CheckboxModule,
    InputFieldsModule,
    DividerModule,
    CheckboxModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatTooltipModule,
    NgImageSliderModule,
    NgxPrintModule,
    CommonModule,
    MatCheckboxModule,
    RouterModule,
    MatTableModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSortModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatInputModule,
    FormsModule,
    DragDropModule,
    MatListModule,
    MatRadioModule,
    MatSelectModule,
    MatTreeModule,
    MatIconModule,
    MatMenuModule,
    MatBadgeModule,
    TranslateModule.forChild(),
    NgxDocViewerModule,
    MatTabsModule,
    CdkAccordionModule,
    MatCardModule,
    MatNativeDateModule,
  ],
  exports: [
    FacetsFilterComponent,
    SearchComponent,
    ValidatorErrorComponent,
    TreeCheckFilterComponent,
    ModificationHistoryComponent,
    DocumentPreviewComponent,
    OverviewTableComponent,
    AlertComponent,
    FileUploadingOverlayComponent,
    FieldSearchComponent,
    PrintLayoutComponent,
  ],
  providers: [{ provide: MatPaginatorIntl, useValue: getPaginatorIntl() }, DatePipe],
})
export class SharedModule {}
