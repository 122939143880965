/**
 * Explanner Service Documentation
 * Explanner Service Documentation
 *
 * The version of the OpenAPI document: 1.7_landesstelle{20240611@153537}
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ScheduleCriteria } from './scheduleCriteria';
import { MinMax } from './minMax';
import { StoredRequestObjectCriteria } from './storedRequestObjectCriteria';

export interface StoredRequestObject {
  size?: number;
  page?: number;
  sort?: string;
  order?: StoredRequestObject.OrderEnum;
  textsearchOperator?: StoredRequestObject.TextsearchOperatorEnum;
  criteria?: StoredRequestObjectCriteria;
  minMaxcriteria?: { [key: string]: MinMax };
  logicalcriteria?: { [key: string]: string };
  scheduleCriteria?: { [key: string]: ScheduleCriteria };
  facets?: Array<string>;
  fieldList?: Array<string>;
  returnIdList?: boolean;
  flatResponse?: boolean;
  includeNull?: boolean;
  exportSelection?: Array<string>;
  exportthumbs?: boolean;
  type?: string;
  name?: string;
  uiControlData?: object;
  savedAt?: number;
}

export namespace StoredRequestObject {
  export type OrderEnum = 'ASC' | 'DESC';
  export const OrderEnum = {
    Asc: 'ASC' as OrderEnum,
    Desc: 'DESC' as OrderEnum,
  };
  export type TextsearchOperatorEnum = 'AND' | 'OR';
  export const TextsearchOperatorEnum = {
    And: 'AND' as TextsearchOperatorEnum,
    Or: 'OR' as TextsearchOperatorEnum,
  };
}
