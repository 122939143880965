import { Injectable } from '@angular/core';
import { LocalStorageService } from './storage/local-storage.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PathService {
  constructor(private localStorageService: LocalStorageService) {}

  getBasePath() {
    return environment.apiUrl;
  }

  getFilePath() {
    return `${environment.apiUrl}/file/` + this.localStorageService.getProject()?.project;
  }

  getFilePathWithProject(project: string) {
    return `${environment.apiUrl}/file/` + project;
  }
}
