import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@frontends/commons';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../services/storage/local-storage.service';

export const authGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const logger = inject(NGXLogger);
  const authService = inject(AuthService);
  const router = inject(Router);

  const localStorageService = inject(LocalStorageService);

  const projectIdentifier = localStorageService.getProject().project;
  return new Promise<boolean>((resolve) => {
    authService.checkAuth(projectIdentifier).subscribe({
      next: (data) => {
        logger.trace(`[GUARD]: CHECK AUTH`, data);
        if (data) resolve(true);
      },
      error: (error) => {
        if (error.status === 200) {
          resolve(true);
        } else {
          logger.warn(`[GUARD]: NOT AUTHORIZED RETURN TO LOGIN`, error);
          authService.logout();
          resolve(router.navigateByUrl('auth/login'));
        }
      },
    });
  });
};
