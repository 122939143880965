@if (!valueOnly) {
  <div class="container">
    @if (internal) {
      <ds-icon-badge [iconClass]="iconClass" [tooltip]="tooltip" />
    }
    <mat-form-field [class.ng-invalid]="showError" [color]="color" appearance="outline">
      <mat-label>{{ label }}</mat-label>
      @if (!formControlName) {
        <input
          #inputRef
          (input)="onChangeInput()"
          [(ngModel)]="value"
          [attr.aria-label]="ariaLabel"
          [disabled]="disabled"
          [inputMode]="mode"
          [maxlength]="maxLength"
          [placeholder]="placeholder"
          [required]="required"
          [type]="type"
          data-testid="input"
          matInput
          (blur)="onInputBlur()" />
      } @else {
        <input
          #inputRef
          [(ngModel)]="value"
          [attr.aria-label]="ariaLabel"
          [formControl]="control"
          [inputMode]="mode"
          [maxlength]="maxLength"
          [placeholder]="placeholder"
          [type]="type"
          data-testid="input"
          matInput
          (blur)="onInputBlur()" />
      }
      @if (subType === 'link') {
        <button
          mat-icon-button
          matSuffix
          [attr.aria-label]="ariaLabelBtn"
          [disabled]="isSubTypeDisabled()"
          (click)="openExternalLink()"
          (keydown.enter)="openExternalLink()"
        >
          <mat-icon [style.color]="(themeService!.isLightTheme$|async) ?'':'white'">{{ 'open_in_new' }}
          </mat-icon>
        </button>
      }
      @if (showHint) {
        <mat-hint aria-label="Input Hint">{{ hintMessage }}</mat-hint>
      }
      @if (showError) {
        <mat-error aria-label="Error Hint">{{ errorMessage }}</mat-error>
      }
    </mat-form-field>
  </div>
} @else {
  <div class="column-container">
    <span class="mat-subtitle-2">{{ label }}</span>
    <span class="mat-body-2">{{ value ? value : '-' }}</span>
  </div>
}
