/* eslint-disable @typescript-eslint/no-empty-function */
import {
  AfterContentInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatOption, MatOptionSelectionChange } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { SelectType } from '../../../types/select';
import { BaseFieldComponent } from '../../Base/base-field.component';

@Component({
  selector: 'ds-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchSelectComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class SearchSelectComponent extends BaseFieldComponent implements OnChanges, AfterContentInit {
  @Input() public options: any[] = [];
  @Input() public selectWithOutEmptyValue = false;
  @Input() public selectedValues: Array<string> | string = [];
  @Input() public type: SelectType = 'multiselect';
  @Input() public isHierarchy = false;
  @Output() private selectedValuesChange = new EventEmitter<Array<string> | string>();
  @Output() private selectChanged = new EventEmitter<void>();
  @Output() private selectOpenChange = new EventEmitter<boolean>();
  public selectControl: FormControl = new FormControl();
  public searchTerm = '';
  public filteredOptions: string[] = [];
  public isChecked: undefined | 'all' | 'some' = undefined;
  public stringifyValues = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes['options']) {
      this.filteredOptions = this.options;
    }

    if (changes['selectedValues']) {
      this.getStringifySelectedValues();
    }
  }

  ngAfterContentInit(): void {
    this.getStringifySelectedValues();
  }

  private emitSelectionChange() {
    this.getStringifySelectedValues();
    this.onChange(this.selectedValues);
    this.onTouched();
    this.selectedValuesChange.emit(this.selectedValues);
    this.selectChanged.emit();
  }

  public reset() {
    this.searchTerm = '';
    this.filteredOptions = this.options;
  }

  override writeValue(value: Array<string>): void {
    if (value) {
      this.selectedValues = value;
    } else {
      this.selectedValues = [];
    }
  }

  private getCheckboxStatus(select: MatSelect) {
    const selectedOptsLength = select._selectionModel.selected.length;
    const optsLength = select.options.length;

    if (!select?.options || optsLength === 0) {
      this.isChecked = undefined;
    } else if (selectedOptsLength === optsLength) {
      this.isChecked = 'all';
    } else if (selectedOptsLength > 0 && selectedOptsLength < optsLength) {
      this.isChecked = 'some';
    } else {
      this.isChecked = undefined;
    }

    return this.isChecked;
  }

  public toggleSelectAllOptions(select: MatSelect) {
    const isAllOptsSelected = this.getCheckboxStatus(select) !== 'all';
    const selectedOptions = this.selectControl.value;
    const allFilteredOptions = select.options.map((option) => option.value);
    let updatedValues: string[];

    if (isAllOptsSelected) {
      const uniqueSet = new Set([...selectedOptions, ...allFilteredOptions]);
      updatedValues = [...uniqueSet];
    } else {
      updatedValues = selectedOptions.filter((value: MatOption) => !allFilteredOptions.includes(value));
    }
    this.selectControl.setValue(updatedValues);

    this.emitSelectionChange();
  }

  public onFilterChange() {
    const search = this.searchTerm.toLowerCase();
    this.filteredOptions = this.options.filter((option) => option.toLocaleLowerCase().includes(search));
  }

  public handleFilterInputBlur() {
    if (this.filteredOptions.length === 0) {
      this.searchTerm = '';
      this.filteredOptions = this.options;
    }
  }

  public stopKeydownPropagation(event: KeyboardEvent) {
    event.stopPropagation();
  }

  public onSelectionChange(event: MatOptionSelectionChange, select: MatSelect) {
    if (event.isUserInput) {
      const selectedValue = event.source.value ?? '';
      if (this.type === 'multiselect' && Array.isArray(this.selectedValues)) {
        if (event.source.selected) {
          this.selectedValues.push(selectedValue);
        } else {
          this.selectedValues = this.selectedValues.filter((value) => value !== selectedValue);
        }
      }

      if (this.type === 'select') {
        this.selectedValues = selectedValue;
      }

      this.selectControl.setValue(this.selectedValues);
      this.emitSelectionChange();
    }

    if (this.type === 'multiselect') this.getCheckboxStatus(select);
  }

  public onSelectOpen(isOpen: boolean) {
    this.selectOpenChange.emit(isOpen);
  }

  private getStringifySelectedValues() {
    if (this.type !== 'multiselect') return;
    if (Array.isArray(this.selectedValues)) {
      this.stringifyValues = this.selectedValues.join(', ');
    } else {
      this.stringifyValues = this.selectedValues;
    }
  }
}
