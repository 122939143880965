/**
 * Explanner Service Documentation
 * Explanner Service Documentation
 *
 * The version of the OpenAPI document: 1.7_landesstelle{20240611@153537}
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RoomplannerLink } from './roomplannerLink';

export interface ProjectData {
  docId?: string;
  project?: string;
  name?: string;
  description?: string;
  mandant?: string;
  active?: boolean;
  startDate?: number;
  endDate?: number;
  referenceable?: boolean;
  productType?: ProjectData.ProductTypeEnum;
  roomplannerLink?: RoomplannerLink;
  hideForDomains?: Set<string>;
  showForDomains?: Set<string>;
  onChangeMailTo?: { [key: string]: string };
  onCreateMailTo?: { [key: string]: string };
  options?: { [key: string]: object };
  externalId?: string;
  /**
   * Messageformat of Id generation
   */
  idPattern?: string;
  order?: number;
  /**
   * Artefact to show in ui
   */
  artefact?: string;
}

export namespace ProjectData {
  export type ProductTypeEnum = 'EXPLANNER' | 'TOOLBOX' | 'ROOMS' | 'GALAXY' | 'ASSETS' | 'MUSDB';
  export const ProductTypeEnum = {
    Explanner: 'EXPLANNER' as ProductTypeEnum,
    Toolbox: 'TOOLBOX' as ProductTypeEnum,
    Rooms: 'ROOMS' as ProductTypeEnum,
    Galaxy: 'GALAXY' as ProductTypeEnum,
    Assets: 'ASSETS' as ProductTypeEnum,
    Musdb: 'MUSDB' as ProductTypeEnum,
  };
}
