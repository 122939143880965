/**
 * Explanner Service Documentation
 * Explanner Service Documentation
 *
 * The version of the OpenAPI document: 1.7_landesstelle{20240611@153537}
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Reference } from './reference';
import { ProjectData } from './projectData';

export interface ReferenceContainer {
  projectData?: ProjectData;
  projectId?: string;
  dataType?: ReferenceContainer.DataTypeEnum;
  references?: Array<Reference>;
}

export namespace ReferenceContainer {
  export type DataTypeEnum = 'ADDRESS' | 'INSTITUTE' | 'OBJECT';
  export const DataTypeEnum = {
    Address: 'ADDRESS' as DataTypeEnum,
    Institute: 'INSTITUTE' as DataTypeEnum,
    Object: 'OBJECT' as DataTypeEnum,
  };
}
