import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import * as semver from 'semver';
import { environment } from '../environments/environment';
import { UiApiClientService } from './services/api/ui-api-client.service';

import { AuthService, InitData } from '@frontends/commons';
import { BreakpointsService } from './services/breakpoints.service';
import { LocalStorageService } from './services/storage/local-storage.service';
import { SessionStorageService } from './services/storage/session-storage.service';
import { ScrollPosition } from './services/utils/scrollPosition.service';

@Component({
  selector: 'exp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public version = environment.version;

  constructor(
    public translateService: TranslateService,
    public authService: AuthService,
    public breakpointsService: BreakpointsService,
    private localStorageService: LocalStorageService,
    private shareRowIndex: ScrollPosition,
    private uiApiClientService: UiApiClientService,
    private sessionStorageService: SessionStorageService,
    private router: Router,
    private logger: NGXLogger,
  ) {
    this.initializeApp();
  }

  public destroyFocus() {
    const sharedRow = this.shareRowIndex.activeRow;
    if (sharedRow && sharedRow !== '') {
      this.shareRowIndex.activeRow = '';
    }
  }

  private initializeApp() {
    if (this.hasNewerVersion()) {
      this.logger.debug(`[APP]: NEWER VERSION - CLEAR LOCAL STORAGE`);
      this.localStorageService.clearLocalStorage();
      this.authService.logout();
      this.localStorageService.setVersion(this.version);
      this.router.navigate(['auth']);
    }

    if (!this.authService.isLoggedIn()) {
      this.router.navigate(['auth']);
    }

    if (this.authService.isLoggedIn()) {
      this.uiApiClientService.getInitData().subscribe((results: InitData) => {
        const initData = results;
        if (initData.permissions) this.authService.setPermissionsOnAppInit(initData.permissions);
        if (initData.projects) this.sessionStorageService.saveProjects(initData.projects);
        if (initData.type) this.sessionStorageService.setProductType(initData.type);
        if (initData.tiles) this.sessionStorageService.saveTools(initData.tiles);
        this.authService.setUserInfo(initData);
      });
    }
    this.logger.debug('INITIALIZE APP', this);
    this.translateService.addLangs(['de', 'en']);
    this.translateService.getLangs().forEach((lang) => {
      this.translateService.reloadLang(lang);
    });
    this.translateService.onLangChange.subscribe((ev: any) => {
      const lang = ev.lang;
      this.translateService.setDefaultLang(lang);
      switch (lang) {
        case 'de':
          registerLocaleData(localeDe);
          break;
        default:
          registerLocaleData(localeEn);
          break;
      }
    });
    if (navigator.language !== 'en' && navigator.language !== 'de') {
      this.translateService.use('de');
      this.localStorageService.saveLang('de');
    } else {
      this.translateService.use(navigator.language);
      this.localStorageService.saveLang(navigator.language);
    }
  }

  public onBackdropClick() {
    this.breakpointsService.onSideNavToggle(false);
  }

  private hasNewerVersion(): boolean {
    const currentVersion = this.localStorageService.getVersion();
    this.logger.debug(`[APP]: currentVer: ${currentVersion} - targetVersion: ${this.version}`);

    if (!currentVersion || semver.compare(this.version, currentVersion) === 1) {
      return true;
    }
    return false;
  }

}
