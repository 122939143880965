<div class="flex-row hier-label-row">
  <h2>
    <ds-label [valueOnly]="!field.valueOnly" text=" {{ fieldName }}:"></ds-label>
  </h2>
</div>
<ds-basic-icon-badge-button
  *ngIf="!field.valueOnly"
  buttonColor="accent"
  [label]="'BUTTONS.addReservation' | translate"
  icon="add"
  (click)="openAddDialog()" />
<form [formGroup]="formGroup" class="apts-list">
  <mat-list formArrayName="reservation" *ngFor="let row of appointmentFields?.controls; let i = index">
    <div class="apt-item">
      <div class="apt-text-wrapper center-items">
        <div class="center-items">
          <span class="apt-text">{{ convertStampDateToString(row.get('from')?.value) }}</span>
          <span>&nbsp;-&nbsp;</span>
          <span class="apt-text">{{ convertStampDateToString(row.get('to')?.value) }}</span>
        </div>
        <mat-list-item>
          <span class="apt-text">{{ row.get('name')?.value }}</span>
        </mat-list-item>
      </div>
      <div class="apt-chip-wrraper">
        <ds-chips
          [color]="row.get('status')?.value"
          label="{{ getLabelFromColor(row.get('status')?.value) | translate }}" />
      </div>
      <div class="flex-row">
        <ds-icon-button
          *ngIf="!field.valueOnly || !authService.hasWritePermissionsForDatatype(dataType)"
          (click)="openEditDialog(row, i)"
          icon="edit_outlined"
          color="primary"
          tooltipLabel="{{ 'BUTTONS.edit' | translate }}" />
        <ds-icon-button
          *ngIf="!field.valueOnly || !authService.hasWritePermissionsForDatatype(dataType)"
          (click)="removeAppointment(row.get('name')?.value, i)"
          icon="close"
          color="primary"
          tooltipLabel="{{ 'BUTTONS.remove' | translate }}" />
      </div>
    </div>
    <mat-divider />
  </mat-list>
</form>
