import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SearchManipulatorService {
  public manipulateSearchToFullText(searchValue: string): string {
    if (!searchValue) {
      return '';
    }
    const search = searchValue
      .replace(
        /"([^"]+)"|\[([^\]]+)\]|(\b(?:id)\s*:\s*\d+\b)|(\w+)(?=:| :)|(\s:\s)|(:\s)|(:)|(\*)|(\d+\.*,*\d+)|([a-zäüö0-9!?]+)/gi,
        (match, quoteGroup, squareBracketGroup, idGroup, colonGroup) => {
          if (quoteGroup) {
            // If the match is within quotes, preserve it as is
            return `*"${quoteGroup}"*`;
          } else if (squareBracketGroup) {
            // If the match is within square brackets, preserve it and do not add asterisks
            return `[${squareBracketGroup}]`;
          } else if (idGroup) {
            // If the match is id: number
            return idGroup;
          } else if (colonGroup) {
            // If the match is followed by :, preserve it as is
            return `${colonGroup}: `;
          }
          if (match === 'AND' || match === 'OR') {
            return match;
          }
          if (!match.includes(':') && !match.includes('*')) {
            return `*${match}*`;
          }
          if (match.includes('*')) {
            return match;
          }
          return '';
        },
      )
      .replace(/\*\*/gi, '*');

    return search;
  }
}
