import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import {
  ButtonsModule,
  CheckboxModule,
  ChipsModule,
  DatepickerModule,
  DividerModule,
  InputFieldsModule,
  LabelModule,
  LinksModule,
  RadiobuttonModule,
  SelectModule,
} from '@frontends/designsystem';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { AppointmentListComponent } from './appointments-list/appointments-list.component';

import { FileUploaderComponent, ToFormGroupPipe } from '@frontends/commons';
import { FormTabsComponent } from './form/form-tabs/form-tabs.component';
import { GenericFormComponent } from './form/generic-form/generic-form.component';
import { HierarchyComponent } from './form/hierarchy/hierarchy.component';
import { LookUpComponent } from './form/look-up/look-up.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { KVFormGroupComponent } from './form/kv-form-group/kv-form-group.component';
import { KeyValueChart } from './key-value-chart/key-value-chart';

@NgModule({
  declarations: [
    HierarchyComponent,
    AppointmentListComponent,
    FormTabsComponent,
    GenericFormComponent,
    LookUpComponent,
    KeyValueChart,
    KVFormGroupComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    ReactiveFormsModule,
    LayoutModule,
    RouterModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    MatListModule,
    MatIconModule,
    MatDialogModule,
    MatMenuModule,
    MatCardModule,
    MatTabsModule,
    MatProgressBarModule,
    MatFormFieldModule,
    DragDropModule,
    DividerModule,
    SelectModule,
    ButtonsModule,
    InputFieldsModule,
    RadiobuttonModule,
    CheckboxModule,
    DatepickerModule,
    LinksModule,
    LabelModule,
    //TODO this will have to go
    SharedModule,
    ChipsModule,
    FileUploaderComponent,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    ToFormGroupPipe,
  ],
  exports: [FormTabsComponent, GenericFormComponent, LookUpComponent],
})
export class ComponentsModule {}
