/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ViewValue } from '../../../model/view-value';
import { BaseFieldComponent } from '../../Base/base-field.component';


@Component({
  selector: 'ds-view-value-multiselect',
  templateUrl: './view-value-multiselect.component.html',
  styleUrls: ['./view-value-multiselect.component.scss'],
})
export class ViewValueMultiSelectComponent extends BaseFieldComponent {
  @Input() options: ViewValue[] = [];
  @Input() selectedValues: string[] = [];

  @Output() selectedValuesChange = new EventEmitter<string[]>();
  @Output() selectChanged = new EventEmitter<void>();
  @Output() clear = new EventEmitter<MouseEvent>();

  selectionChanged() {
    this.selectedValuesChange.emit(this.selectedValues);
    this.selectChanged.emit();
    this.onChange(this.selectedValues);
    this.onTouched();
  }
  reset(event: Event) {
    event.stopPropagation();
    this.selectedValues = [];
    this.clear.emit();
    this.onChange(this.selectedValues);
    this.onTouched();
  }

  override onBlur() {
    this.onChange(this.selectedValues);
    this.onTouched();
  }

  // Implement the ControlValueAccessor methods
  protected override onChange: (value:  string[]) => void = () => {};

  override writeValue(value:  string[]): void {
    if (value && value != null) {
      this.selectedValues = value;
    } else {
      this.selectedValues = [];
    }
  }
  override registerOnChange(fn: (value:  string[]) => void): void {
    this.onChange = fn;
  }
}
