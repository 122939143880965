import { Component, EventEmitter, forwardRef, Host, Input, Optional, Output, SkipSelf } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { BaseFieldComponent } from '../../Base/base-field.component';

@Component({
  selector: 'ds-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
  ],
})
export class DatepickerComponent extends BaseFieldComponent {
  @Input() public placeholder = '';
  @Input() public override value!: Date | null;

  @Output() public dateChange: EventEmitter<Date | null> = new EventEmitter();

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    controlContainer: ControlContainer,
  ) {
    super(controlContainer);
  }

  public onDateChange(event: MatDatepickerInputEvent<any | null>) {
    const timestamp: number = (event.value?.['ts'] as number) ?? 0;
    const date = new Date(timestamp);
    this.onChange(date);
    this.dateChange.emit(date);
  }

  // Implement the ControlValueAccessor methods

  override writeValue(value: Date): void {
    this.value = value;
  }

  override registerOnChange(fn: (value: Date) => void): void {
    this.onChange = fn;
  }
}
