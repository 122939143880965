import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataType } from '@frontends/commons';

@Component({
  selector: 'exp-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
  @Output() submitSearching = new EventEmitter<{ searchValueSC: string }>();
  @Input() dataType!: DataType;
  @Input() searchValue!: string;

  startSearching() {
    this.submitSearching.emit({ searchValueSC: this.searchValue });
  }
}
