import { formatDate } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StoredRequestObject, UserProfile } from '@frontends/exp-apiclient';
import { AlertService } from '../../../../services/alert.service';
import { ApiClientService } from '../../../../services/api-client.service';
import { SessionStorageService } from '../../../../services/storage/session-storage.service';
import { TranslateHelpService } from '../../../../services/translate-help.service';

@Component({
  selector: 'exp-savefilter',
  templateUrl: './savefilter.component.html',
  styleUrls: ['./savefilter.component.scss'],
})
export class SavefilterComponent {
  newFilterTemplate!: StoredRequestObject;
  newTemplateName!: string;

  private hierarchyKey = 'basis_exhibitionunit_tree';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translationHelp: TranslateHelpService,
    private dialogRef: MatDialogRef<SavefilterComponent>,
    private apiClientService: ApiClientService,
    private sessionStorageService: SessionStorageService,
    private alertService: AlertService
  ) {
    this.newTemplateName = '';
    this.newFilterTemplate = {};
  }

  saveFilter(input: string): void {
    this.apiClientService.getProfileFromBackend(this.data.dataType).subscribe((userProfile: UserProfile) => {
      if (!userProfile.requests) {
        userProfile.requests = [];
      }

      this.newFilterTemplate = {};

      if (this.data.quickfilterReq) {
        this.newFilterTemplate = this.data.reqObject;
      }

      this.newFilterTemplate.logicalcriteria = this.sessionStorageService.getHierarchyQueryReq(this.data.dataType);
      this.newFilterTemplate.uiControlData = this.sessionStorageService.getSelectedChecklistData(
        this.data.dataType,
        this.hierarchyKey
      );

      if (this.data.reqObject.criteria) {
        this.newFilterTemplate.criteria = this.sessionStorageService.getQuickFilterReq(this.data.dataType);
      }

      this.newFilterTemplate.minMaxcriteria = this.data.minMaxReq;

      if (input === '' || input === null) {
        const date = formatDate(new Date(), 'M/d/yy,h:mm:ss', 'en');
        this.newFilterTemplate.name = 'NewTemplate_' + date;
      } else {
        this.newFilterTemplate.name = input;
      }
      userProfile.requests.push(this.newFilterTemplate);

      this.apiClientService.updateProfile(this.data.dataType, userProfile).subscribe();
      this.alertService.openSnackBar(
        this.translationHelp.translate('SUCCESS_MESSAGE.' + this.data.datatype, 'create'),
        ''
      );
      this.dialogRef.close();
    });
  }
}
