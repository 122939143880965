import { Component, Input } from '@angular/core';
import { MatBadgePosition } from '@angular/material/badge';
import { ThemeColors } from '../../../types/colors';
import { BaseButtonComponent } from '../../Base/base-button.component';

@Component({
  selector: 'ds-basic-icon-badge-button',
  templateUrl: './basic-icon-badge-button.component.html',
  styleUrls: ['./basic-icon-badge-button.component.scss'],
})
export class BasicIconBadgeButtonComponent extends BaseButtonComponent {
  @Input() public icon = '';
  @Input() public badgeValue!: string;
  @Input() public buttonColor: ThemeColors = 'primary';
  @Input() public badgeColor: ThemeColors = 'primary';
  @Input() public badgePosition: MatBadgePosition = 'after';
}
