import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, FormGroup, ValidationErrors } from '@angular/forms';
import { Observable, catchError, distinctUntilChanged, map, of } from 'rxjs';
import { UniqueValidatorService } from '../util/unique-validator.service';

const HUMAN_ID = 'basis_humanId';

@Injectable({ providedIn: 'root' })
export class UniqueFieldValidator implements AsyncValidator {
  constructor(private validatorService: UniqueValidatorService) {}

  validate(control: AbstractControl<any, any>): Observable<ValidationErrors | null> {
    return this.validatorService.validateUnique(control).pipe(
      distinctUntilChanged(),
      map((response) => {
        if (response.matches > 0) {
          const formGroup = control.parent as FormGroup;
          const humanIdControl = formGroup.controls[HUMAN_ID];
          if (response.matches === 1 && response.results[0]['basis_humanId'] === humanIdControl.value) {
            return null;
          }
          return { uniqueField: true };
        }
        return null;
      }),
      catchError(() => of(null))
    );
  }
}
