import { Injectable } from '@angular/core';
import { DataType, FieldIndex, FieldName } from '@frontends/commons';
import { Observable } from 'rxjs';
import { UiApiClientService } from '../api/ui-api-client.service';
import { LocalStorageService } from '../storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class FieldService {
  private fields: FieldIndex = {};

  constructor(private localStorageService: LocalStorageService, private uiApiClient: UiApiClientService) {}

  public getFields(projectIdentifier: string, dataType: DataType): Observable<FieldName> {
    const storingKey = dataType + '_' + projectIdentifier;
    if (this.localStorageService.getProject()?.project === projectIdentifier) {
      if (this.fields && this.fields[storingKey]) {
        return this.fields[storingKey];
      }
    }
    this.fields[storingKey] = this.fetchFields(projectIdentifier, dataType);
    return this.fields[storingKey];
  }

  private fetchFields(
    project: string,
    dataType: DataType,
  ): Observable<{
    [key: string]: string;
  }> {
    return this.uiApiClient.getLabels(project, dataType);
  }
}
