/**
 * Explanner Service Documentation
 * Explanner Service Documentation
 *
 * The version of the OpenAPI document: 1.7_landesstelle{20240611@153537}
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient,
    HttpContext,
    HttpEvent,
    HttpHeaders,
    HttpParameterCodec,
    HttpParams,
    HttpResponse
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

// @ts-ignore
import { LoginDataRequest } from '../model/loginDataRequest';
// @ts-ignore
import { Loginpublic200Response } from '../model/loginpublic200Response';
// @ts-ignore
import { ResetOldpublic200Response } from '../model/resetOldpublic200Response';
// @ts-ignore
import { ResetPwdRequest } from '../model/resetPwdRequest';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root',
})
export class AuthControllerService {
  protected basePath = '/service';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration,
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach((elem) => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k)),
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * @param deep
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public healthzpublic(
    deep?: boolean,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<ResetOldpublic200Response>;
  public healthzpublic(
    deep?: boolean,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<ResetOldpublic200Response>>;
  public healthzpublic(
    deep?: boolean,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<ResetOldpublic200Response>>;
  public healthzpublic(
    deep?: boolean,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<any> {
    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (deep !== undefined && deep !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>deep, 'deep');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['*/*'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/public/healthz`;
    return this.httpClient.request<ResetOldpublic200Response>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param username
   * @param password
   * @param target
   * @param redir
   * @param loginDataRequest
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public loginpublic(
    username?: string,
    password?: string,
    target?: string,
    redir?: boolean,
    loginDataRequest?: LoginDataRequest,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<Loginpublic200Response>;
  public loginpublic(
    username?: string,
    password?: string,
    target?: string,
    redir?: boolean,
    loginDataRequest?: LoginDataRequest,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<Loginpublic200Response>>;
  public loginpublic(
    username?: string,
    password?: string,
    target?: string,
    redir?: boolean,
    loginDataRequest?: LoginDataRequest,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<Loginpublic200Response>>;
  public loginpublic(
    username?: string,
    password?: string,
    target?: string,
    redir?: boolean,
    loginDataRequest?: LoginDataRequest,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<any> {
    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (username !== undefined && username !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>username, 'username');
    }
    if (password !== undefined && password !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>password, 'password');
    }
    if (target !== undefined && target !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>target, 'target');
    }
    if (redir !== undefined && redir !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>redir, 'redir');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['*/*'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/public/login`;
    return this.httpClient.request<Loginpublic200Response>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: loginDataRequest,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param username
   * @param password
   * @param target
   * @param redir
   * @param loginDataRequest
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public loginpublic_1(
    username?: string,
    password?: string,
    target?: string,
    redir?: boolean,
    loginDataRequest?: LoginDataRequest,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<Loginpublic200Response>;
  public loginpublic_1(
    username?: string,
    password?: string,
    target?: string,
    redir?: boolean,
    loginDataRequest?: LoginDataRequest,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<Loginpublic200Response>>;
  public loginpublic_1(
    username?: string,
    password?: string,
    target?: string,
    redir?: boolean,
    loginDataRequest?: LoginDataRequest,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<Loginpublic200Response>>;
  public loginpublic_1(
    username?: string,
    password?: string,
    target?: string,
    redir?: boolean,
    loginDataRequest?: LoginDataRequest,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<any> {
    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (username !== undefined && username !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>username, 'username');
    }
    if (password !== undefined && password !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>password, 'password');
    }
    if (target !== undefined && target !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>target, 'target');
    }
    if (redir !== undefined && redir !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>redir, 'redir');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['*/*'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/public/loginDirect`;
    return this.httpClient.request<Loginpublic200Response>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: loginDataRequest,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public logoutpublic(
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<{ [key: string]: object }>;
  public logoutpublic(
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<{ [key: string]: object }>>;
  public logoutpublic(
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<{ [key: string]: object }>>;
  public logoutpublic(
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<any> {
    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['*/*'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/public/logout`;
    return this.httpClient.request<{ [key: string]: object }>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param username
   * @param cauth
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @deprecated
   */
  public resetOldpublic(
    username: string,
    cauth?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<ResetOldpublic200Response>;
  public resetOldpublic(
    username: string,
    cauth?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<ResetOldpublic200Response>>;
  public resetOldpublic(
    username: string,
    cauth?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<ResetOldpublic200Response>>;
  public resetOldpublic(
    username: string,
    cauth?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<any> {
    if (username === null || username === undefined) {
      throw new Error('Required parameter username was null or undefined when calling resetOldpublic.');
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (username !== undefined && username !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>username, 'username');
    }

    let localVarHeaders = this.defaultHeaders;
    if (cauth !== undefined && cauth !== null) {
      localVarHeaders = localVarHeaders.set('cauth', String(cauth));
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['*/*'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/public/reset`;
    return this.httpClient.request<ResetOldpublic200Response>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param resetPwdRequest
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public resetpublic(
    resetPwdRequest?: ResetPwdRequest,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<ResetOldpublic200Response>;
  public resetpublic(
    resetPwdRequest?: ResetPwdRequest,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<ResetOldpublic200Response>>;
  public resetpublic(
    resetPwdRequest?: ResetPwdRequest,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<ResetOldpublic200Response>>;
  public resetpublic(
    resetPwdRequest?: ResetPwdRequest,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<any> {
    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['*/*'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/public/reset`;
    return this.httpClient.request<ResetOldpublic200Response>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: resetPwdRequest,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
