/* eslint-disable @typescript-eslint/no-empty-function */
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputType } from '../../../types/InputType';
import { BaseFieldComponent } from '../../Base/base-field.component';

@Component({
  selector: 'ds-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true,
    },
  ],
})
export class TextareaComponent extends BaseFieldComponent {
  @ViewChild('autosize') public autosize!: CdkTextareaAutosize;
  @Input() public placeholder = '';
  @Input() public override value = '';

  @Input() public showCounter = false;
  @Input() public counterAriaLabel = '';
  @Input() public type: InputType = 'text';

  @Input() public autoResize = true;

  @Output() public inputChange = new EventEmitter<string>();

  public onChangeInput() {
    this.onChange(this.value);
    this.inputChange.emit(this.value);
  }

  // Implement the ControlValueAccessor methods
  protected override onChange: (value: string) => void = () => {};

  override writeValue(value: string): void {
    this.value = value;
  }
  override registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }
}
