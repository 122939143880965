import { Injectable } from '@angular/core';
import { ChangePassRequest } from '@frontends/commons';
import {
  AuthControllerService,
  ObjectDataControllerService,
  RequestObject,
  SaveStatus,
  UiControllerService,
} from '@frontends/exp-apiclient';
import { NGXLogger } from 'ngx-logger';
import { from, map, Observable, of, switchMap } from 'rxjs';
import { environment } from '../../environments/environment';
import { ImageSize } from '../model/Enums/size-image';
import { DataType } from '../model/types/DataTypes';
import { LocalStorageService } from './storage/local-storage.service';
const API_URL = environment.apiUrl;
@Injectable({
  providedIn: 'root',
})
export class ApiClientService {
  private currentProject: string;
  constructor(
    private uiController: UiControllerService,
    private localStorageService: LocalStorageService,
    private dataController: ObjectDataControllerService,
    private authControllerService: AuthControllerService,
    private objectDataController: ObjectDataControllerService,
    private logger: NGXLogger,
  ) {
    this.currentProject = '';
    this.setCurrentProject();
  }

  private setCurrentProject() {
    this.currentProject = this.localStorageService.getProject().project;
    if (!this.currentProject) {
      this.localStorageService.projectData$.subscribe((data) => {
        this.currentProject = data?.project;
      });
    }
  }

  public updatePassword(pwChange: ChangePassRequest) {
    this.logger.trace('UPDATE PASSWORD', this);
    return this.uiController.setPass(pwChange);
  }

  /** Ui Controller  EndPoint */

  public getFieldsFrombackend(dataType: DataType) {
    this.logger.trace('GET FIELDS', dataType, this);
    return this.uiController.getFieldsFull(this.currentProject, dataType);
  }

  public getFieldsForSpecificProject(projectIdentifier: string, dataType: DataType) {
    this.logger.trace('GET FIELDS FOR PROJECT', projectIdentifier, dataType, this);
    return this.uiController.getFieldsFull(projectIdentifier, dataType);
  }

  public getFieldsWithDeepNamming(dataType: DataType) {
    this.logger.trace('GET FIELDS WITH DEEPNAMING', dataType, this);
    return this.uiController.getFieldsFull(this.currentProject, dataType);
  }

  public getFullFieldsWithDeepNamming(dataType: DataType) {
    this.logger.trace('GET FULL FIELDS WITH DEEPNAMING', dataType, this);
    return this.uiController.getFieldsFull(this.currentProject, dataType);
  }

  public getFieldsForLoukup(project: string, dataType: DataType) {
    this.logger.trace('GET FIELDS FOR LOOKUP', dataType, this);
    return this.uiController.getFieldsSimple(project, dataType);
  }

  public getProfileFromBackend(dataType: DataType) {
    this.logger.trace('GET PROFILE', dataType, this);
    return this.uiController.getProfile(this.currentProject, dataType);
  }

  public getFormularFromBackend(dataType: DataType) {
    this.logger.trace('GET FORMULAR', dataType, this);
    return this.uiController.getFormular(this.currentProject, dataType);
  }

  public getFormularforLookup(project: string, dataType: DataType) {
    this.logger.trace('GET FORMULAR FOR LOOKUP', project, dataType, this);
    return this.uiController.getFormular(project, dataType);
  }

  public getExportMapping(dataType: DataType) {
    this.logger.trace('GET EXPORT MAPPING', dataType, this);
    return this.uiController.getExportMapping(this.currentProject, dataType);
  }

  public getMenu() {
    this.logger.trace('GET MENU', this);
    return this.uiController.getMenue(this.currentProject);
  }

  public getMenuForLookup(project: string) {
    this.logger.trace('GET MENU FOR LOOKUP', project, this);
    return this.uiController.getMenue(project);
  }

  public getObjectById(dataType: DataType, objectId: string) {
    this.logger.trace('GET OBJECT BY ID', dataType, objectId, this);
    return this.dataController.getByIdData(this.currentProject, dataType, objectId);
  }

  public getObjectForLookupById(project: string, dataType: DataType, objectId: string) {
    this.logger.trace('GET OBJECT BY ID FOR LOOKUP', project, dataType, objectId, this);
    return this.dataController.getByIdData(project, dataType, objectId);
  }

  public getRequestForCurrentePrj(dataType: DataType, request: RequestObject) {
    this.logger.trace(
      'GET REQUEST FOR CURRENT PROJECT (LS)',
      this.localStorageService.getProject(),
      dataType,
      request,
      this,
    );
    return this.dataController.getByRequestData(this.currentProject, dataType, request);
  }

  public getRequestForPrj(project: string, dataType: DataType, request: RequestObject) {
    this.logger.trace('GET REQUEST FOR PROJECT', project, dataType, request, this);
    return this.dataController.getByRequestData(project, dataType, request);
  }

  public getObjectData(dataType: DataType, request: RequestObject) {
    this.logger.trace('GET OBJECT DATA', dataType, request, this);
    return this.objectDataController.getByRequestData(this.currentProject, dataType, request);
  }

  public getDataByObjectId(projectIdentifier: string, dataType: DataType, objectId: string) {
    this.logger.trace('GET OBJECT DATA', dataType, objectId, this);
    return this.objectDataController.getByIdData(projectIdentifier, dataType, objectId);
  }

  public getHistByIdData(currentSelectedProject: string, dataType: DataType, docId: string) {
    this.logger.trace('GET HISTORY BY ID', currentSelectedProject, dataType, docId, this);
    return this.objectDataController.getHistByIdData(currentSelectedProject, dataType, docId);
  }

  public saveObjectInAnotherProject(project: string, dataType: DataType, object: any): Observable<SaveStatus> {
    this.logger.trace('SAVE OBJECT IN DIFFERENT PROJECT', project, dataType, object, this);
    return this.dataController.saveData(project, dataType, object).pipe(
      switchMap((response) => {
        return this.handleResponse(response);
      }),
    );
  }

  public saveObjectInCurrenteProject(dataType: DataType, object: any): Observable<SaveStatus> {
    this.logger.trace('SAVE OBJECT IN CURRENT PROJECT', dataType, object, this);
    return this.dataController.saveData(this.currentProject, dataType, object).pipe(
      switchMap((response) => {
        return this.handleResponse(response);
      }),
    );
  }

  public updateObjectInCurrenteProject(dataType: DataType, object: any): Observable<SaveStatus> {
    this.logger.trace('UPDATE OBJECT IN CURRENT PROJECT', dataType, object, this);
    return this.dataController.updateData(this.currentProject, dataType, object).pipe(
      switchMap((response) => {
        return this.handleResponse(response);
      }),
    );
  }

  public updateObjectInAnotherProject(project: string, dataType: DataType, object: any): Observable<SaveStatus> {
    this.logger.trace('UPDATE OBJECT IN DIFFERENT PROJECT', project, dataType, object, this);
    return this.dataController.updateData(project, dataType, object).pipe(
      switchMap((response) => {
        return this.handleResponse(response);
      }),
    );
  }

  public deleteObjectInCurrenteProject(dataType: DataType, objectId: string) {
    this.logger.trace('DELETE OBJECT IN CURRENT PROJECT', dataType, objectId, this);
    return this.dataController.deleteData(this.currentProject, dataType, objectId);
  }

  public updateProfile(dataType: DataType, exportSelection: any) {
    this.logger.trace('UPDATE PROFILE', dataType, exportSelection, this);
    return this.uiController.setProfile(this.currentProject, dataType, exportSelection);
  }

  public getSearchPanelFields(dataType: DataType) {
    this.logger.trace('GET SEARCH PANEL FIELDS', this);
    return this.uiController.getSearchPanelData(this.currentProject, dataType);
  }

  public getImageBySize(img: string, project: string, size: string = ImageSize.FULL) {
    return `${API_URL}/file/` + project + '/' + img + '?size=' + size;
  }

  private handleResponse(response: any): Observable<SaveStatus> {
    if (response instanceof Blob) {
      return from(response.text()).pipe(
        map((text) => {
          const parsedText = JSON.parse(text);
          return {
            ...parsedText,
          };
        }),
      );
    } else {
      return of(response);
    }
  }
}
