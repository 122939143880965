/* eslint-disable no-underscore-dangle */
import { DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ObjectData } from '@frontends/commons';
import { GenericFormularField, RequestObject, StoredExportSelection, UserProfile } from '@frontends/exp-apiclient';
import _, { isNumber } from 'lodash';
import { EditingInterface } from '../../../app/interfaces/editing-interface';
import { dateFormats } from '../../../app/model/DateFormats';
import { ExportType } from '../../../app/model/Enums/export-type';
import { KeyTuple, Tuple } from '../../../app/model/Util/Tuple';
import { DataType } from '../../../app/model/types/DataTypes';
import { ApiClientService } from '../../../app/services/api-client.service';
import { ExportService } from '../../../app/services/export.service';
import { SessionStorageService } from '../../../app/services/storage/session-storage.service';
import { TranslateHelpService } from '../../../app/services/translate-help.service';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'exp-export-dialog',
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.scss'],
})
export class ExportDialogComponent implements OnInit {
  @ViewChild('printBtn') printBtn!: ElementRef;

  public radioGroupForm: FormGroup;
  public exportFields!: Tuple[];
  public dataType!: DataType;
  public reqObject: RequestObject = {};
  public editingFilterNames: EditingInterface = {};
  public exportType: ExportType = ExportType.PDF;
  public exportSelection: UserProfile = {};
  public options: string[] = [];
  public selectedValues: string[] = [];
  public pageBreak = false;

  private formFields!: GenericFormularField[];
  private fieldKeys: KeyTuple[] = [];
  private selectedFieldKeys: KeyTuple[] = [];
  private selectedFields: Tuple[] = [];
  private temporaryName!: string;
  private selectedFilterName!: string;
  private isExistNameTemplate = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { dataType: DataType; objects: ObjectData[]; reqObject?: RequestObject },
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private exportService: ExportService,
    public sessionStorageService: SessionStorageService,
    private translateHelp: TranslateHelpService,
    private apiClientService: ApiClientService,
    private dialogRef: MatDialogRef<ExportDialogComponent>,
    private translationHelp: TranslateHelpService,
    private datePipe: DatePipe,
    private alertService: AlertService,
  ) {
    this.radioGroupForm = this.formBuilder.group({
      selectedOption: new FormControl(null),
    });
  }

  ngOnInit(): void {
    this.dataType = this.data.dataType;
    const requestObject = this.data.reqObject;
    if (requestObject) {
      this.reqObject = requestObject;
    }
    this.getProfileFromBackend();
    this.getFieldsFromBackend();
  }

  public addPageBreak(): boolean {
    this.pageBreak = !this.pageBreak;
    return this.pageBreak;
  }

  public selectedFieldsToExport(): void {
    const selectControl = this.radioGroupForm.get('selectedOption');
    selectControl && selectControl.setValue(null);
    this.prepareExportData();
  }

  private getOptionsFromFieldKeys(fieldKeys: KeyTuple[]) {
    return fieldKeys.map(({ k }) => k);
  }

  private getFieldsFromOptions(selectedOptions: string[]): Tuple[] {
    return (this.fieldKeys || []).reduce((accumulator, { k, v }) => {
      if (typeof k === 'string' && selectedOptions.includes(k)) {
        if (v) accumulator.push(...v);
      }
      return accumulator;
    }, [] as Tuple[]);
  }

  private getProfileFromBackend(): void {
    this.apiClientService.getProfileFromBackend(this.dataType).subscribe((result) => {
      this.exportSelection = result;
    });
  }

  private getFieldsFromBackend(): void {
    this.apiClientService.getExportMapping(this.dataType).subscribe((mappingResponse) => {
      this.fieldKeys = mappingResponse;
      if (mappingResponse) {
        const options = this.getOptionsFromFieldKeys(mappingResponse) as string[];
        if (options.length) {
          this.options = options;
        }
      }
    });
  }

  private updateSelectedOptsOnTemplateSwitch(): void {
    this.selectedFields = [];
    this.selectedFieldKeys.forEach((fieldKey) => {
      if (!fieldKey.v) return;
      for (const field of fieldKey.v) {
        this.selectedFields.push(field);
      }
    });
  }

  public deleteExportTemplateByKey(key: string | undefined): void {
    const dialogRef = this.alertService.conformationDialog(
      '',
      this.translateHelp.translate('DELETE_MESSAGE.' + 'template'),
      this.translateHelp.translate('DELETE_MESSAGE.deleteYes'),
      this.translateHelp.translate('cancel'),
    );
    dialogRef.componentInstance.save.subscribe((confirmed) => {
      if (confirmed && this.exportSelection.exports) {
        _.remove(this.exportSelection.exports, (template: StoredExportSelection) => template.name === key);
        this.apiClientService.updateProfile(this.dataType, this.exportSelection).subscribe();
      }
      dialogRef.close();
    });
  }

  public saveSelectedOptsAsTemplate(): void {
    if (!this.exportSelection) {
      this.exportSelection = {};
    } else {
      if (!this.exportSelection.exports) {
        this.exportSelection.exports = [];
      }
    }

    if (this.exportSelection.exports) {
      const newTemplate = this.generateUniqueTemplateName();
      this.exportSelection.exports.unshift({
        name: newTemplate,
        fields: [],
      });
      this.exportSelection.exports.forEach((element) => {
        if (element.name === newTemplate) {
          this.selectedValues?.forEach((fieldKey) => {
            element.fields && element.fields.push(fieldKey);
          });
        }
      });
      this.updateTemplate(this.dataType, this.exportSelection);
    }
  }

  private generateUniqueTemplateName(): string {
    let maxCounter = 0;
    if (this.exportSelection.exports) {
      this.exportSelection.exports.forEach((element) => {
        if (element.name === this.selectedFilterName && element.fields) {
          this.selectedValues = element.fields;
        }
      });
      this.exportSelection.exports.forEach((template) => {
        if (template.name && this.isTemplateNameExists(template.name)) {
          const counter = parseInt(template.name.replace('ExportFilterTemplate', ''), 10);
          if (!isNaN(counter) && counter > maxCounter) {
            maxCounter = counter;
          }
        }
      });
    }

    return `ExportFilterTemplate${maxCounter + 1}`;
  }

  private isTemplateNameExists(templateName: string): boolean {
    const exports = this.exportSelection.exports;
    return exports ? exports.some((template: StoredExportSelection) => template.name === templateName) : false;
  }

  public radioChanged(event: MatRadioChange): void {
    const savedOptions = event?.value.split(',');
    this.selectedFieldKeys = this.fieldKeys.filter((field) => savedOptions.includes(field.k));
    const options = this.getOptionsFromFieldKeys(this.selectedFieldKeys) as string[];
    this.selectedValues = options;
    this.updateSelectedOptsOnTemplateSwitch();
    this.prepareExportData();
  }

  public makeEditable(filterKey: string): void {
    this.editingFilterNames = {};
    this.editingFilterNames[filterKey] = true;
  }

  public filterNameChanged(event: Event): void {
    this.temporaryName = (event.target as HTMLInputElement).value;
  }

  private isExist(profile: UserProfile, newNameTemplate: string): boolean {
    this.isExistNameTemplate = false;
    profile.exports &&
      profile.exports.forEach((element) => {
        if (newNameTemplate === element.name) {
          this.isExistNameTemplate = true;
        }
      });
    return this.isExistNameTemplate;
  }

  public updateName(fieldKey: string | undefined): void {
    if (this.temporaryName === undefined) {
      this.editingFilterNames = {};
      return;
    }
    const trimmedName = this.temporaryName.trim();
    if (trimmedName === undefined || trimmedName === fieldKey) {
      this.editingFilterNames = {};
    } else {
      if (trimmedName !== '') {
        if (this.exportSelection.exports && !this.isExist(this.exportSelection, trimmedName)) {
          this.exportSelection.exports.forEach((field, index) => {
            if (field.name === fieldKey) {
              if (this.exportSelection?.exports?.[index]?.name) this.exportSelection.exports[index].name = trimmedName;
              this.updateSelectedOptsOnTemplateSwitch();
              this.temporaryName = '';
            }
          });
          this.updateTemplate(this.dataType, this.exportSelection);
          const selectedValues = this.exportSelection.exports[0].fields;
          if (selectedValues) {
            this.selectedValues = selectedValues;
          }
          this.prepareExportData();
          this.editingFilterNames = {};
        } else {
          this.alert.infoAlertDialog(
            '',
            this.translationHelp.translate('alreadyExist'),
            this.translationHelp.translate('ok'),
          );
        }
      } else {
        this.alert.infoAlertDialog(
          '',
          this.translationHelp.translate('inputEmpty'),
          this.translationHelp.translate('ok'),
        );
      }
    }
  }

  private updateTemplate(datatype: DataType, exportSelection: UserProfile): void {
    this.apiClientService.updateProfile(datatype, exportSelection).subscribe();
  }

  public download(): void {
    if (this.selectedFields.length) {
      switch (this.exportType) {
        case ExportType.CSV:
          this.export();
          break;
        case ExportType.PDF:
          this.printBtn.nativeElement.click();
          break;
        default:
          break;
      }
      this.closeDialog();
    }
  }

  private formatAdapter(objects: ObjectData[]): void {
    objects.forEach((value) => {
      if (value && (isNumber(value['_meta_CREATEAt']) || isNumber(value['_meta_UPDATEAt']))) {
        if (Number(value['_meta_CREATEAt']) > 1577833200000) {
          value['_meta_CREATEAt'] = this.datePipe.transform(
            value['_meta_CREATEAt'],
            dateFormats.dayMonthFormat,
            'en-US',
          );
        }
        if (Number(value['_meta_UPDATEAt']) > 1577833200000) {
          value['_meta_UPDATEAt'] = this.datePipe.transform(
            value['_meta_UPDATEAt'],
            dateFormats.dayMonthFormat,
            'en-US',
          );
        }
      }
    });
  }

  private export(): void {
    let objects = [];
    if (this.data.objects.length === 1) {
      objects = this.data.objects;
      this.exportService.downloadFile(objects, this.selectedFields, this.selectedFilterName);
      this.formatAdapter(objects);
    } else {
      this.apiClientService.getObjectData(this.dataType, this.reqObject).subscribe((data) => {
        if (!data.results) return;
        if (!this.data.objects.length) {
          objects = data.results;
          this.exportService.downloadFile(data.results, this.selectedFields, this.selectedFilterName);
        } else {
          objects = data.results.filter((item) =>
            this.data.objects.some((selectedItem) => item.docId === selectedItem.docId),
          );
          this.exportService.downloadFile(objects, this.selectedFields, this.selectedFilterName);
        }
      });
    }
  }

  public templateLength(object: any): number {
    return object ? Object.keys(object)?.length : 0;
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  private prepareExportData(): void {
    if (this.selectedValues.length) {
      this.selectedFields = this.getFieldsFromOptions(this.selectedValues);
      this.getExportedFields();
    }
  }

  private getExportedFields(): Tuple[] {
    const labels: Set<string> = new Set<string>();
    const exportedFields: Tuple[] = this.selectedFields;
    for (const entry of exportedFields) {
      const label = this.findLabel(entry.v || '');
      if (label !== '') {
        if (labels.has(label)) {
          continue;
        }
        labels.add(label);
        const index = exportedFields.indexOf(entry);
        exportedFields.splice(index, 0, { k: label, v: 'LABEL' });
      }
    }
    this.exportFields = exportedFields;
    return exportedFields;
  }

  private findLabel(jsonPath: string): string {
    if (jsonPath) {
      if (jsonPath.includes('basis_dimensions')) {
        jsonPath = 'basis_dimensions';
      } else if (jsonPath.includes('basis_frame_dimensions')) {
        jsonPath = 'basis_frame_dimensions';
      }
      let index = this.formFields?.findIndex((field) => field.path === jsonPath);
      for (index; index >= 0; index--) {
        if (this.formFields[index]?.type === 'LABEL') {
          return this.formFields[index].name || '';
        }
      }
    }
    return '';
  }
}
