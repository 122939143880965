<div class="traffic-light-container" *ngIf="!valueOnly; else showValueOnlyState">
  <div class="label-container">
    <ds-icon-badge *ngIf="internal" [iconClass]="iconClass" [tooltip]="tooltip"></ds-icon-badge>
    <span class="mat-subtitle-2"> {{ label }}{{ required ? '*' : '' }} </span>
  </div>
  <mat-radio-group [formControl]="control" (change)="radioChange($event)" [color]="color" [disabled]="disabled">
    <mat-radio-button
      [checked]="checkedColor === color"
      (click)="clickRadioButton.emit($event)"
      *ngFor="let color of chipColors"
      [value]="color"
      [required]="required">
      <ds-chips [color]="color" [label]="chipLabels[color]"></ds-chips>
    </mat-radio-button>
  </mat-radio-group>
</div>
<div class="custom-error-wrapper">
  <mat-error class="error" *ngIf="showError">{{ errorMessage }}</mat-error>
</div>
<ng-template #showValueOnlyState>
  <div>
    <div *ngIf="checkedColor">
      <span class="mat-subtitle-2">{{ label }}</span>
      <div class="mat-body-2">
        <ds-chips [color]="checkedColor" [label]="chipLabels[checkedColor]"></ds-chips>
      </div>
    </div>
    <div *ngIf="!checkedColor">
      <span class="mat-subtitle-2">{{ label }}</span>
      <div class="mat-body-2">
        <div>-</div>
      </div>
    </div>
  </div>
</ng-template>
