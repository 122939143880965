import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OverlayInteractionService {
  private overlayButtonClickedSubject = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/member-ordering
  overlayButtonClicked$ = this.overlayButtonClickedSubject.asObservable();

  closeBtnClicked() {
    this.overlayButtonClickedSubject.next();
  }
}
