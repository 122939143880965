import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { FieldTypes } from '../../../../app/model/Enums/FieldTypesEnum';

import {
  AuthService,
  FieldName,
  GenericForm,
  GenericFormField,
  ObjectData,
  OptionPath,
  PathAdditions,
} from '@frontends/commons';
import { DataType } from '../../../../app/model/types/DataTypes';
import { FormManagerService } from '../../../../app/services/form/form-manager.service';
import { environment } from '../../../../environments/environment';
import { EditOptionsEnum } from '../../../model/Enums/EditOptionsEnum';
import { colors } from '../../../model/types/Colors';
import { FieldType } from '../../../model/types/FieldTypes';
import { FieldWidth } from '../../../model/Enums/FieldStylesEnum';

@Component({
  selector: 'exp-generic-form',
  templateUrl: './generic-form.component.html',
  styleUrls: ['./generic-form.component.scss'],
})
export class GenericFormComponent implements OnInit {
  @Input() public formDefinition!: GenericForm;
  @Input() public formGroup!: FormGroup;
  @Input() public fieldNames: FieldName = {};
  @Input() public object!: ObjectData;
  @Input() public dataType!: DataType;
  @Input() public optionPathArray: OptionPath[] = [];
  @Output() public formDirty: EventEmitter<boolean> = new EventEmitter<boolean>();
  public isBatchDialogOptions!: number;
  public trafficLightLabels: { [color: string]: string } = {};
  public objectId!: string | undefined;
  public pathAdditions: PathAdditions = new PathAdditions();
  public editOptions: string[] = Object.values(EditOptionsEnum).map((value) => this.translateService.instant(value));
  public envApiUrl = environment.apiUrl;
  public fieldWidth = FieldWidth;

  constructor(
    public authService: AuthService,
    private translateService: TranslateService,
    private formManager: FormManagerService,
    private logger: NGXLogger,
  ) {
    colors.forEach((color) => {
      this.trafficLightLabels[color] = translateService.instant('LABELS.Trafficlight_' + color);
    });
  }

  ngOnInit() {
    this.logger.debug(`[GENFORM - ${this.formDefinition.title}]: START INITIALIZING`, this);
    this.objectId = this.object.docId;
    this.formGroup.valueChanges.subscribe(() => {
      this.formManager.formTouched(this.formGroup.dirty);
    });
    this.isBatchDialogOptions = this.optionPathArray.length;
  }

  public getErrorMessage(field: GenericFormField): string {
    const { name, required, unique, path } = field;
    const fieldValue = this.formGroup.get(path)?.value;

    if (required && (!fieldValue || fieldValue.length === 0)) {
      return `${name} ${this.translateService.instant('ERROR.required')}`;
    }

    if (unique && fieldValue) {
      return `${name} ${this.translateService.instant('ERROR.unique')}`;
    }
    if (path && path.includes('dimension')) {
      return `${this.translateService.instant('ERROR.decimal')}`;
    }

    return '';
  }

  public getFieldLabel(field: GenericFormField): string {
    return this.fieldNames[field.path]
      ? this.fieldNames[field?.path]
      : this.translateService.instant(this.dataType + '.' + field?.path);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  get FieldTypes() {
    return FieldTypes;
  }

  public changeEditOption($event: string | string[], field: GenericFormField): void {
    if (typeof $event === 'string') {
      this.formGroup.markAsDirty();
      if (field.type === FieldTypes.DIMENSION) {
        this.pathAdditions.dimensionPathAddition.forEach((dimensionPathAddition) => {
          const dimensionPath = field.path + '_' + dimensionPathAddition;
          const dimensionIndex = this.optionPathArray.findIndex((optionPath) => optionPath.path === dimensionPath);
          if (dimensionIndex !== -1) {
            this.optionPathArray[dimensionIndex].option = $event;
            if ($event === this.translateService.instant(EditOptionsEnum.CLEAR)) {
              this.formGroup.get(dimensionPath)?.disable();
              this.formGroup.get(dimensionPath)?.setValue('');
            } else {
              this.formGroup.get(dimensionPath)?.enable();
            }
          }
        });
      } else if (field.type === FieldTypes.ADDRESS) {
        this.pathAdditions.addressPathAddition.forEach((addressPathAddition) => {
          const addressPath = field.path + '_' + addressPathAddition;
          const addressIndex = this.optionPathArray.findIndex((optionPath) => optionPath.path === addressPath);
          if (addressIndex !== -1) {
            this.optionPathArray[addressIndex].option = $event;
            if ($event === this.translateService.instant(EditOptionsEnum.CLEAR)) {
              this.formGroup.get(addressPath)?.disable();
              this.formGroup.get(addressPath)?.setValue('');
            } else {
              this.formGroup.get(addressPath)?.enable();
            }
          }
        });
      } else if (field.type === FieldTypes.HIERARCHY) {
        const childFormGroup = this.formGroup.get(field.path) as FormGroup;
        let index = 1;
        Object.keys(childFormGroup.controls).forEach((key) => {
          const hierarchyIndex = this.optionPathArray.findIndex((optionPath) => optionPath.path === field.path);
          if (hierarchyIndex !== -1) {
            this.optionPathArray[hierarchyIndex].option = $event;
            if ($event === this.translateService.instant(EditOptionsEnum.CLEAR)) {
              childFormGroup.get(key)?.disable();
              childFormGroup.get(key)?.setValue(undefined);
            } else {
              childFormGroup.get(key)?.enable();
            }
          }
          index++;
        });
      } else {
        const index = this.optionPathArray.findIndex((optionPath) => optionPath.path === field.path);
        if (index !== -1) {
          this.optionPathArray[index].option = $event;
          if ($event === this.translateService.instant(EditOptionsEnum.CLEAR)) {
            this.formGroup.get(field.path)?.disable();
            if (field.type === FieldTypes.MULTISELECT) {
              this.formGroup.get(field.path)?.setValue('');
            } else {
              this.formGroup.get(field.path)?.setValue(null);
            }
          } else {
            this.formGroup.get(field.path)?.enable();
          }
        }
      }
    }
  }

  public setAppointmentValue(path: string, event: any) {
    this.formManager.formTouched(true);
    this.formGroup.value[path] = event;
  }

  public filterEditOptions(type: FieldType, required: boolean) {
    const clearOption = this.translateService.instant(EditOptionsEnum.CLEAR);
    const overwriteOption = this.translateService.instant(EditOptionsEnum.OVERWRITE);

    return this.editOptions.filter((option) => {
      if (type === FieldTypes.STRING || type === FieldTypes.NUMBER || type === FieldTypes.TEXTAREA) {
        return required ? option !== clearOption : true;
      } else {
        return required ? option === overwriteOption : option === clearOption || option === overwriteOption;
      }
    });
  }

  public getFormGroup(controlName: string): FormGroup<any> {
    return this.formGroup.get(controlName) as FormGroup;
  }

  public isRequired(field: GenericFormField): boolean {
    return this.isBatchDialogOptions ? false : field.required;
  }

  public isRequiredOrUnique(field: GenericFormField): boolean {
    return this.isBatchDialogOptions ? false : field.required || (field.unique ?? false);
  }

  public isInternal(field: GenericFormField): boolean {
    return this.isBatchDialogOptions ? false : field.internal;
  }
}
