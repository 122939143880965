import { NavType } from '../model/Types/NavType';
import { CmnLocalStorageService } from '../services/storage/cmn-local-storage.service';
import { ProjectData } from '@frontends/exp-apiclient';
import { NavTypesEnum } from '../model/Enums/NavTypesEnum';
import ProductTypeEnum = ProjectData.ProductTypeEnum;

export function getNavType(defaultType: NavType, localStorageService: CmnLocalStorageService): NavType {
  const { productType } = localStorageService.getProject();
  if (productType) {
    switch (productType) {
      case ProductTypeEnum.Explanner:
        return NavTypesEnum.MENU;
      case ProductTypeEnum.Toolbox:
        return NavTypesEnum.PROJECT;
      case ProductTypeEnum.Assets:
        return NavTypesEnum.PROJECT;
      case ProductTypeEnum.Rooms:
        return NavTypesEnum.PROJECT;
      case ProductTypeEnum.Musdb:
        return NavTypesEnum.PROJECT;
      default:
        return NavTypesEnum.MENU;
    }
  }
  return defaultType;
}
