<div class="sidenav-container flex-col sidenav-flex space-between">
  <div class="sidenav-logo-panel flex-row">
    <img src="./assets/logo.svg" alt="{{ 'A11Y.ALT.logo' | translate }}" />
  </div>
  <!-- Tools -->
  <ng-container *ngIf="hasTools">
    <div class="sidenav-tools-panel flex-row">
      <button
        class="tools-menu-button"
        mat-button
        [matMenuTriggerFor]="toolsMenu"
        [class.disabled]="!(tools | keyvalue)?.length">
    <span class="tools-menu-button-label">
      <span>{{ 'NAVIGATION.' + selectedTool | translate }}</span>
      <mat-icon *ngIf="(tools | keyvalue)?.length">arrow_drop_down</mat-icon>
    </span>
      </button>
      <mat-menu [class]="'tools-mat-menu'" #toolsMenu="matMenu">
        <button
          class="tools-menu-list-item"
          mat-menu-item
          *ngFor="let tool of tools | keyvalue"
          (click)="navigateToTool(tool.key)">
          {{ 'NAVIGATION.' + tool.key | translate }}
        </button>
      </mat-menu>
    </div>

  </ng-container>
  <!-- Projects -->
  <ng-container *ngIf="hasProjects">
    <ds-horizontal-divider />
    <div class="sidenav-project-info-panel flex-col">
      <div class="project-info flex-row">
        <span class="project-name-label mat-body-2">{{ selectedProject.name }}</span>
        <ng-container *ngIf="selectedProject.startDate && selectedProject.endDate; else dateFallback">
          <span class="project-duration-label mat-caption">{{ 'LABELS.exhibitionPeriod' | translate }}:</span>
          <span class="project-duration-label mat-caption">
            {{ selectedProject.startDate | date: 'dd.MM.yyyy' }} - {{ selectedProject.endDate | date: 'dd.MM.yyyy' }}
          </span>
        </ng-container>
        <ng-template #dateFallback>
          <span class="project-duration-label mat-caption">{{ 'LABELS.exhibitionPeriodPlaceholder' | translate }}</span>
        </ng-template>
      </div>
      <div class="switch-project-button-panel flex-row">
        <ds-basic-button
          class="switch-project-button"
          color="accent"
          label="{{ 'BUTTONS.switch' | translate }}"
          (clickButton)="switchProject()"></ds-basic-button>
      </div>
    </div>
  </ng-container>
  <!-- Navigation -->
  <ds-horizontal-divider />
  <div class="sidenav-navlist-panel">
    <!-- Exhibition planner and toolbox -->
    <mat-nav-list class="sidenav-list flex-col" *ngIf="navType === 'MENU'">
      <mat-list-item
        class="sidenav-item-container"
        *ngFor="let item of menuItems"
        (click)="switchToRoute(item.key.dataType)"
        [ngClass]="{ 'sidenav-active': isNavItemActive(item.key.dataType) }">
        <a [style.color]="(themeService.isLightTheme$ | async) ? 'rgba(0, 0, 0, 0.6)' : ''" class="sidenav-item">
          <ng-container [ngSwitch]="item.key.dataType">
            <ng-container *ngSwitchCase="'OBJECT'">
              <mat-icon>image</mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="'ADDRESS'">
              <mat-icon>import_contacts</mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="'INSTITUTE'">
              <mat-icon>volunteer_activism</mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="'ISSUE'">
              <mat-icon>help_center</mat-icon>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <mat-icon>diamond</mat-icon>
            </ng-container>
          </ng-container>
          <span class="sidenav-label">{{ item.key.name }}</span>
        </a>
      </mat-list-item>
    </mat-nav-list>

    <mat-nav-list *ngIf="navType === 'PROJECT'" class="sidenav-list flex-col">
      <mat-list-item
        *ngFor="let project of projects"
        (click)="switchToProject(project)"
        [ngClass]="{ 'sidenav-active': isProjectNavItemActive(project) }">
        <a [style.color]="(themeService.isLightTheme$ | async) ? 'rgba(0, 0, 0, 0.6)' : ''" class="sidenav-item">
          <mat-icon>{{ getItemIcon(project.project) }}</mat-icon>
          <span class="sidenav-label">{{ project.name }}</span>
        </a>
      </mat-list-item>
    </mat-nav-list>

    <!-- other tools -->
    <mat-nav-list *ngIf="navType === 'ITEM'" class="sidenav-list flex-col">
      <mat-list-item
        *ngFor="let item of sidenavItems"
        (click)="switchToRoute(item.route)"
        [ngClass]="{ 'sidenav-active': isNavItemActive(item.route) }">
        <a [style.color]="(themeService.isLightTheme$ | async) ? 'rgba(0, 0, 0, 0.6)' : ''" class="sidenav-item">
          <mat-icon class="material-symbols-outlined">{{ item.icon }}</mat-icon>
          <span class="sidenav-label">{{ item.label | translate }}</span>
        </a>
      </mat-list-item>
    </mat-nav-list>
  </div>
  <div class="sidenav-projects-panel flex-col" *ngIf="hasSpecialRole">
    <ds-horizontal-divider />
    <mat-nav-list>
      <mat-list-item disabled>
        <span
          [style.color]="(themeService.isLightTheme$ | async) ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255,255, 255, 1)'"
          class="sidenav-label">
          {{ specialRoleTitle }}
        </span>
      </mat-list-item>
      <mat-list-item
        *ngFor="let item of specialRoleItems"
        (click)="switchToRoute(item.route)"
        [ngClass]="{ 'sidenav-active': isNavItemActive(item.route) }">
        <a [style.color]="(themeService.isLightTheme$ | async) ? 'rgba(0, 0, 0, 0.6)' : ''" class="sidenav-item">
          <mat-icon class="material-symbols-outlined">{{ item.icon }}</mat-icon>
          <span class="sidenav-label">{{ item.label | translate }}</span>
        </a>
      </mat-list-item>
    </mat-nav-list>
  </div>

  <!-- User and Managment -->
  <ng-container *ngIf="hasSettings">
    <ds-horizontal-divider />

    <div class="sidenav-admin-panel pull-bottom">
      <mat-nav-list class="sidenav-list flex-col">
        <mat-list-item (click)="navigateToSite(settingRoute)">
          <a
            [style.color]="(themeService.isLightTheme$ | async) ? 'rgba(0, 0, 0, 0.6)' : ''"
            class="sidenav-item"
            [attr.aria-label]="'A11Y.LINK.management' | translate">
            <mat-icon class="material-symbols-outlined">settings</mat-icon>
            <span class="sidenav-label">{{ 'BUTTONS.adminView' | translate }}</span>
          </a>
        </mat-list-item>
      </mat-nav-list>
    </div>
  </ng-container>
</div>
