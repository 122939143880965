@if (!valueOnly) {
  <div class="search-select-wrapper">
    @if (internal) {
      <ds-icon-badge [iconClass]="iconClass" [tooltip]="tooltip" />
    }
    <div class="search-select" [ngClass]="{ multiselect: type === 'multiselect' }">
      @if (type === 'multiselect') {
        <div class="search-select-custom-trigger">
          <span>{{ stringifyValues }}</span>
        </div>
      }
      <mat-form-field [class.ng-invalid]="showError" appearance="outline" [color]="color">
        <mat-icon class="search-select-empty-icon" matPrefix />
        <mat-icon class="search-select-empty-icon" matSuffix />
        <mat-label>{{ label }}</mat-label>
        <mat-select
          #select
          [formControl]="selectControl"
          [required]="required"
          [disabled]="disabled"
          [multiple]="type === 'multiselect' ? true : false"
          [(ngModel)]="selectedValues"
          (openedChange)="onSelectOpen($event)">
          <mat-select-trigger [style.visibility]="type === 'multiselect' ? 'hidden' : 'visible'">
            {{ selectedValues }}
          </mat-select-trigger>
          @if (options.length > 20) {
            <div class="search-select-filter-wrapper">
              <mat-form-field appearance="outline" [color]="color">
                <mat-icon matPrefix>search</mat-icon>
                <input
                  (blur)="handleFilterInputBlur()"
                  (input)="onFilterChange()"
                  (keydown)="stopKeydownPropagation($event)"
                  matInput
                  [(ngModel)]="searchTerm"
                  placeholder="Search" />
                @if (searchTerm !== '') {
                  <button data-testid="reset-btn" matSuffix mat-icon-button aria-label="Clear" (click)="reset()">
                    <mat-icon class="material-icons-outlined">close</mat-icon>
                  </button>
                }
              </mat-form-field>
            </div>
          }
          <div class="search-select-options-wrapper">
            @if (filteredOptions.length > 0 && type === 'multiselect') {
              <mat-checkbox
                matRipple
                [checked]="isChecked === 'all'"
                [indeterminate]="isChecked === 'some'"
                [color]="color"
                class="mat-option"
                (change)="toggleSelectAllOptions(select)">
                Alles auswählen
              </mat-checkbox>
            }
            @if (filteredOptions.length <= 0) {
              <p class="search-select-no-results-text">Keine Ergebnisse für diese Suchanfrage</p>
            }
            @if (!selectWithOutEmptyValue && type !== 'multiselect' && !required) {
              <mat-option (onSelectionChange)="onSelectionChange($event, select)"></mat-option>
            }
            <mat-option
              (onSelectionChange)="onSelectionChange($event, select)"
              *ngFor="let option of filteredOptions"
              [value]="option">
              {{ option }}
            </mat-option>
          </div>
        </mat-select>
        @if (showHint) {
          <mat-hint aria-label="Input Hint">{{ hintMessage }}</mat-hint>
        }
        <mat-error aria-label="Error Hint" [class.ng-invalid]="showError">{{ errorMessage }}</mat-error>
      </mat-form-field>
    </div>
  </div>
} @else {
  @if (!isHierarchy) {
    <div class="marginButtom">
      <span class="mat-subtitle-2">{{ label }}</span>
      <div class="mat-body-2">
        {{ selectedValues || '-' }}
      </div>
    </div>
  }
}
