<div class="image-container flex-row">
  <img [src]="data.imageUrl" alt="" />
  <svg
    class="close-svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    (click)="onClose()">
    <path
      d="M9.64231 7.64645L9.28864 8.00011L9.64242 8.35366L15.2324 13.9401C15.2324 13.9401 15.2324 13.9401 15.2325 13.9402C15.4038 14.1115 15.5 14.3439 15.5 14.5862C15.5 14.8286 15.4037 15.061 15.2324 15.2324C15.061 15.4037 14.8286 15.5 14.5862 15.5C14.3439 15.5 14.1114 15.4037 13.9401 15.2324L8.35355 9.64583L8 9.29228L7.64645 9.64583L2.05992 15.2324C1.88855 15.4037 1.65613 15.5 1.41378 15.5C1.17143 15.5 0.939007 15.4037 0.76764 15.2324C0.596273 15.061 0.5 14.8286 0.5 14.5862C0.5 14.3439 0.596273 14.1114 0.76764 13.9401L6.35417 8.35355L6.70772 8L6.35417 7.64645L0.76764 2.05992C0.596273 1.88855 0.5 1.65613 0.5 1.41378C0.5 1.17143 0.596273 0.939008 0.76764 0.76764C0.939008 0.596273 1.17143 0.5 1.41378 0.5C1.65613 0.5 1.88855 0.596273 2.05992 0.76764L7.64645 6.35417L8 6.70772L8.35355 6.35417L13.9398 0.767953C13.9399 0.767854 13.94 0.767756 13.9401 0.767658C14.1113 0.59686 14.3434 0.501034 14.5853 0.501248C14.8273 0.501461 15.0593 0.597808 15.2303 0.769092C15.4013 0.940376 15.4972 1.17257 15.497 1.41459C15.4968 1.65661 15.4004 1.88863 15.2291 2.05961L15.2288 2.05992L9.64231 7.64645Z" />
  </svg>
</div>
