import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SessionStorageService } from '../../../services/storage/session-storage.service';

@Component({
  selector: 'exp-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss'],
})
export class FilterDialogComponent {
  selectedChecklistData: any;
  hierarchyQuery: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FilterDialogComponent>,
    public sessionStorageService: SessionStorageService
  ) {
    this.hierarchyQuery = '';
  }

  hierarchyQueryChanged(hierarchyEventResult: any): void {
    this.hierarchyQuery = hierarchyEventResult;
  }

  selectedHierarchyDataChanged(selectedHierarchyEventResult: any): void {
    this.selectedChecklistData = selectedHierarchyEventResult;
  }
  isHierarchyTreeDataEmpty(nodesObj: any): boolean {
    return Object.keys(nodesObj).length > 0;
  }
  safeChecklistSelection(): void {
    this.hierarchyQuery = this.hierarchyQuery ? this.hierarchyQuery : '';
    this.selectedChecklistData = this.selectedChecklistData ? this.selectedChecklistData : {};
    this.sessionStorageService.saveSelectedChecklistData(
      this.data?.dataType,
      this.data.hierarchyKey,
      this.selectedChecklistData
    );
    this.sessionStorageService.saveHierarchyQueryReq(this.data?.dataType, this.data?.hierarchyKey, this.hierarchyQuery);
    this.dialogRef.close(this.hierarchyQuery);
  }
}
