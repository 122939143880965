/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { BaseFieldComponent } from '../Base/base-field.component';

@Component({
  selector: 'ds-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent extends BaseFieldComponent {
  @Input() checked = false;
  @Input() labelPosition: 'before' | 'after' = 'after';

  @Output() state = new EventEmitter<boolean>();
  @Output() clickcheckbox = new EventEmitter<MouseEvent>();

  checkedChange(event: MatCheckboxChange) {
    this.onChange(event.checked);
    this.state.emit(event.checked);
    this.checked = event.checked;
  }

  protected override onChange: (value: boolean) => void = () => {};

  override writeValue(value: boolean): void {
    this.checked = value;
  }
  override registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }
}
