import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BreakpointsService {
  isSmallScreen!: boolean;
  isMediumScreen!: boolean;
  isLargeScreen!: boolean;
  isSideNavOpen!: boolean;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.observeBreakpoints();
  }
  observeBreakpoints(): void {
    this.breakpointObserver
      .observe(['(max-width: 959px)', '(min-width: 960px) and (max-width: 1240px)', '(min-width: 1241px)'])
      .subscribe((result) => {
        this.isSmallScreen = result.breakpoints['(max-width: 959px)'];
        this.isMediumScreen = result.breakpoints['(min-width: 960px) and (max-width: 1240px)'];
        this.isLargeScreen = result.breakpoints['(min-width: 1241px)'];
        this.isSideNavOpen = this.isLargeScreen;
      });
  }

  onSideNavToggle(isOpen: boolean) {
    this.isSideNavOpen = isOpen;
  }
}
