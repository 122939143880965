import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CheckboxComponent } from './checkbox.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { IconBadgeModule } from '../icon-badge/icon-badge.module';
import { ReactiveFormsModule } from '@angular/forms';
import { LabelModule } from '../labels/label.module';
@NgModule({
  declarations: [CheckboxComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatIconModule,
    IconBadgeModule,
    LabelModule,
  ],
  exports: [CheckboxComponent],
})
export class CheckboxModule {}
