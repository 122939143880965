<div class="flex-col exp-dialog-container">
  <div *ngIf="data.objects.length === 1; else noIdHeader" class="dialog-header">
    <h2 class="header-label">{{ 'PRINT.title' | translate }} : {{ data.objects[0]['basis_humanId'] }}</h2>
    <ds-icon-button icon="close" color="primary" mat-dialog-close />
  </div>
  <ng-template #noIdHeader>
    <div class="dialog-header">
      <h2 class="header-label">{{ 'EXPORT.title' | translate }}</h2>
      <div>
        <ds-icon-button icon="close" color="primary" mat-dialog-close />
      </div>
    </div>
  </ng-template>

  <div class="selectable-container">
    <ds-search-select
      label="{{ 'EXPORT.fields' | translate }}"
      [(selectedValues)]="selectedValues"
      [options]="options"
      (selectedValuesChange)="selectedFieldsToExport()" />
    <div class="save-btn">
      <ds-basic-icon-button
        [disabled]="!selectedValues.length"
        label="{{ 'LABELS.saveTemplate' | translate }}"
        icon="save"
        color="accent"
        (clickButton)="saveSelectedOptsAsTemplate()" />
    </div>
  </div>
  <ds-horizontal-divider class="exp-dialog-divider" />

  <ng-container *ngIf="templateLength(exportSelection?.exports) > 0">
    <div class="flex-row setting-label">
      <span class="mat-body-strong title-label">{{ 'LABELS.selectTemplate' | translate }}</span>
    </div>
    <div class="template-list" [ngClass]="{ scrolling: templateLength(exportSelection.exports) >= 4 }">
      <form [formGroup]="radioGroupForm">
        <mat-radio-group formControlName="selectedOption" (change)="radioChanged($event)">
          <span class="flex-row radio-span export-dialog-input" *ngFor="let fieldsTemplate of exportSelection.exports">
            <mat-radio-button
              color="accent"
              *ngIf="fieldsTemplate.name && !editingFilterNames[fieldsTemplate.name]"
              value="{{ fieldsTemplate?.fields }}"
              >{{ fieldsTemplate.name }}</mat-radio-button
            >
            <ds-input
              *ngIf="fieldsTemplate.name && editingFilterNames[fieldsTemplate.name]"
              [value]="fieldsTemplate.name"
              [label]="fieldsTemplate.name"
              (change)="filterNameChanged($event)"
              (window:keyup.enter)="updateName(fieldsTemplate.name)" />
            <div class="flex-row flex-gap-s">
              <ds-icon-button
                (clickButton)="updateName(fieldsTemplate.name)"
                *ngIf="fieldsTemplate.name && editingFilterNames[fieldsTemplate.name]"
                icon="done"
                color="primary" />
              <ds-icon-button
                *ngIf="fieldsTemplate.name && !editingFilterNames[fieldsTemplate.name]"
                (clickButton)="makeEditable(fieldsTemplate.name)"
                icon="edit"
                color="primary" />
              <ds-icon-button
                (clickButton)="deleteExportTemplateByKey(fieldsTemplate.name)"
                icon="delete_forever"
                color="primary" />
            </div>
          </span>
        </mat-radio-group>
      </form>
    </div>
    <ds-horizontal-divider class="exp-dialog-divider" />
  </ng-container>

  <div class="flex-row setting-label">
    <span class="mat-body-strong">{{ 'LABELS.setting' | translate }}</span>
  </div>
  <div class="flex-row export-option">
    <mat-radio-group class="flex-col radio-btn" [(ngModel)]="exportType">
      <ds-radiobutton label="{{ 'LABELS.pdf' | translate }}" [checked]="true" color="accent" value="PDF" />
      <ds-radiobutton label="{{ 'LABELS.csv' | translate }}" color="accent" value="CSV" />
    </mat-radio-group>
    <ds-checkbox
      label="{{ 'LABELS.breckPage' | translate }}"
      color="accent"
      [disabled]="exportType === 'CSV'"
      (state)="addPageBreak()"
      [checked]="pageBreak"
      value="pagebreaker" />
  </div>
  <ds-horizontal-divider class="exp-dialog-divider" />
  <div class="dialog-actions" mat-dialog-actions>
    <div class="flex-row center-items medium-flex-gap">
      <ds-basic-button color="accent" (clickButton)="closeDialog()" label="{{ 'BUTTONS.cancel' | translate }}" />
      <ds-primary-button
        [disabled]="!selectedValues.length"
        color="accent"
        (clickButton)="download()"
        label="{{ 'LABELS.export' | translate }}" />
    </div>
  </div>

  <button
    #printBtn
    styleSheetFile="assets/print-pdf.scss"
    printTitle="{{ 'PRINT.pdfTitle' | translate }}"
    printSectionId="print"
    ngxPrint
    hidden="true"></button>
  <exp-print-layout
    [selectedObjects]="data.objects"
    [reqObject]="reqObject"
    [dataType]="dataType"
    [exportFields]="exportFields"
    [pageBreak]="pageBreak" />
</div>
