<div class="container" *ngIf="!valueOnly; else showReadOnly">
  <ds-icon-badge *ngIf="internal" [iconClass]="iconClass" [tooltip]="tooltip"></ds-icon-badge>
  <mat-form-field [class.ng-invalid]="showError" appearance="outline" [color]="color">
    <mat-label>{{ label }}</mat-label>
    <button
      data-testid="reset-btn"
      *ngIf="selectedValues.length > 0"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="reset($event)">
      <mat-icon class="material-icons-outlined">close</mat-icon>
    </button>
    <mat-select
      data-testid="multi-select"
      dsMatSelectBlur
      *ngIf="!formControlName; else hasFormControl"
      [required]="required"
      [disabled]="disabled"
      multiple
      [(ngModel)]="selectedValues"
      (selectionChange)="selectionChanged()"
      (blur)="onBlur()">
      <mat-option *ngFor="let option of options" [value]="option">{{ option }}</mat-option>
    </mat-select>
    <ng-template #hasFormControl>
      <mat-select
        dsMatSelectBlur
        data-testid="multi-select"
        [formControl]="control"
        multiple
        [(ngModel)]="selectedValues"
        (blur)="onBlur()">
        <mat-option *ngFor="let option of options" [value]="option">{{ option }}</mat-option>
      </mat-select>
    </ng-template>
    <mat-hint aria-label="Input Hint" *ngIf="showHint"> {{ hintMessage }}</mat-hint>
    <mat-error aria-label="Error Hint" *ngIf="showError">{{ errorMessage }}</mat-error>
  </mat-form-field>
</div>
<ng-template #showReadOnly>
  <div class="column-container">
    <span class="mat-subtitle-2">{{ label }}</span>
    <span class="mat-body-2">{{ selectedValues.length > 0 ? selectedValues.join(', ') : '-' }}</span>
  </div>
</ng-template>
