/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { FormArray } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DataType } from '../model/types/DataTypes';
import { AlertComponent, AlertDialogModel } from '../shared/components/alert/alert/alert.component';
import { ObjectDataService } from './data/object-data.service';
import { TranslateHelpService } from './translate-help.service';
@Injectable({
  providedIn: 'root',
})
export class AlertService {
  result!: boolean;

  constructor(
    private objectDataService: ObjectDataService,
    public dialog: MatDialog,
    private router: Router,
    private translationHelp: TranslateHelpService,
    private snackBar: MatSnackBar
  ) {}
  /**
   * @desc Function for display alert type warning delete confirmation and information
   *
   * @param title : title of dialog
   * @param message : message in dialog content
   * @param isInfo : if the alert is for information else is for warning delete confirmation
   */
  alertDialog(
    title: string,
    message: string,
    isInfo: boolean,
    subUrl: string,
    buttonYes: string,
    buttonNo: string
  ): void {
    const dialogData = new AlertDialogModel(title, message, isInfo, buttonYes, buttonNo);
    const dialogRef: MatDialogRef<AlertComponent> = this.dialog.open(AlertComponent, {
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe();
    dialogRef.componentInstance.save.subscribe((res: boolean) => {
      if (res) {
        this.router.navigateByUrl(subUrl);
        this.dialog.closeAll();
      } else {
        this.dialog.closeAll();
      }
    });
  }

  confirmDiscardDialogChanges() {
    const dialogData = new AlertDialogModel(
      '',
      this.translationHelp.translate('DELETE_MESSAGE.discardAsk'),
      false,
      this.translationHelp.translate('DELETE_MESSAGE.discardYes'),
      this.translationHelp.translate('cancel')
    );
    const dialogRef = this.dialog.open(AlertComponent, {
      data: dialogData,
    });

    return dialogRef;
  }

  deleteConfirmationDialog(
    title: string,
    message: string,
    isInfo: boolean,
    dataType: DataType,
    id: string,
    buttonYes: string,
    buttonNo: string
  ): void {
    const dialogData = new AlertDialogModel(title, message, isInfo, buttonYes, buttonNo, dataType);
    const dialogRef: MatDialogRef<AlertComponent> = this.dialog.open(AlertComponent, {
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe();
    dialogRef.componentInstance.save.subscribe((res) => {
      if (res) {
        this.objectDataService.deleteObject(id, dataType).subscribe((success) => {
          if (success) {
            this.router.navigate([dataType]);
          }
        });
      } else {
        this.dialog.closeAll();
      }
      this.dialog.closeAll();
    });
  }

  deleteAppointmentConfirmationDialog(
    title: string,
    message: string,
    isInfo: boolean,
    appointmentFields: FormArray,
    index: number,
    buttonYes: string,
    buttonNo: string
  ): any {
    const dialogData = new AlertDialogModel(title, message, isInfo, buttonYes, buttonNo);
    const dialogRef: MatDialogRef<AlertComponent> = this.dialog.open(AlertComponent, {
      data: dialogData,
    });

    dialogRef.componentInstance.save.subscribe((res: boolean) => {
      if (res) {
        appointmentFields.removeAt(index);
        dialogRef.close();
      } else {
        dialogRef.close();
      }
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      this.result = dialogResult;
    });
  }

  infoAlertDialog(title: string, message: string, buttonYes: string): void {
    const dialogData = new AlertDialogModel(title, message, true, buttonYes);
    const dialogRef: MatDialogRef<AlertComponent> = this.dialog.open(AlertComponent, {
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe();
    dialogRef.componentInstance.save.subscribe();
  }

  conformationDialog(
    title: string,
    message: string,
    buttonYes: string = this.translationHelp.translate('DELETE_MESSAGE.removeYes'),
    buttonNo: string = this.translationHelp.translate('cancel'),
    isInfo: boolean = false
  ): MatDialogRef<AlertComponent> {
    const dialogData = new AlertDialogModel(title, message, isInfo, buttonYes, buttonNo);
    const dialogRef = this.dialog.open(AlertComponent, {
      data: dialogData,
    });

    return dialogRef;
  }

  public openSnackBar(
    message: string,
    action: string,
    showingDuration: number = 3500,
    horizontalPosition: MatSnackBarHorizontalPosition = 'center',
    verticalPosition: MatSnackBarVerticalPosition = 'bottom'
  ) {
    this.snackBar.open(message, action, {
      duration: showingDuration,
      horizontalPosition,
      verticalPosition,
    });
  } // TODO use the class from faktura
}
