<div class="edit-dialog-container flex-col">
  <div class="dialog-header">
    <h2 mat-dialog-title class="dialog-header-label">
      {{ 'LABELS.edit' + dataType | translate }}
    </h2>
    <ds-icon-button class="close-button" (clickButton)="onModalClose()" color="primary" icon="close" />
  </div>
  <div class="flex-row detail-container dialog-content" mat-dialog-content>
    <main class="flex-col">
      <exp-form-tabs *ngIf="(formManager.loading$ | async) === false; else showLoading" [dataType]="dataType" />
      <ng-template #showLoading>
        <mat-progress-bar class="loading-indicator" mode="indeterminate" />
        <span class="flex-col"> {{ 'MESSAGE.loadingObjectData' | translate }}</span>
      </ng-template>
    </main>
  </div>
  <ds-horizontal-divider />

  <div class="dialog-actions" mat-dialog-actions>
    <ds-basic-button
      (clickButton)="onModalClose()"
      color="accent"
      [label]="hasWritePermissions ? ('BUTTONS.cancel' | translate) : ('BUTTONS.close' | translate)" />
    <ds-primary-button
      *ngIf="hasWritePermissions"
      expThrottleClick
      [attr.throttleTime]="2000"
      icon="add"
      color="accent"
      [disabled]="!isFormTouched"
      (clickButton)="submitForm()"
      label=" {{ 'BUTTONS.save' | translate }}" />
  </div>
</div>
