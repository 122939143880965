<ds-dialog-header title=" {{ 'LABELS.filter' | translate }}" (clickButton)="onModalClose()" />
<exp-facets-filter
  class="facets-filter"
  [dataType]="data.dataType"
  [resultsLength]="data.resultsLength"
  [tableComponent]="data.tableComponent"
  [pageIndex]="data.pageIndex"
  [overviewTable]="data.overviewTable"
  [fieldNames]="data.fieldnames"
  [quickHierarchyFacets]="data.quickHierarchyFacets"
  [quickMinMaxFacets]="data.quickMinMaxFacets"
  [minMaxFilters]="data.minMaxFilters"
  [quickFilterFacets]="data.quickFilterFacets"
  [quickFilterReq]="data.quickFilterReq"
  [facets]="data.facets"
  [facetsAreLoading]="data.facetsAreLoading"
  [isDialog]="true"
  (filterChanged)="sendChanges($event)" />
<ds-horizontal-divider />
<div class="dialog-actions" mat-dialog-actions>
  <ds-basic-icon-badge-button
    [hidden]="!hasChanges"
    buttonColor="accent"
    [label]="'BUTTONS.reset' | translate"
    icon="filter_alt_off"
    (clickButton)="resetFilter()" />
  <ds-basic-button
    class="facet-dialog-submit-btn"
    [disabled]="!hasChanges"
    color="accent"
    mat-dialog-close
    [ngClass]="{ 'reset-btn-hidden': true }"
    label="{{ 'BUTTONS.done' | translate }}" />
</div>
