import { Injectable } from '@angular/core';
import { ConcreteData } from '@frontends/exp-apiclient';

@Injectable({
  providedIn: 'root',
})
export class ScrollPosition {
  currentActiveRow?: string;
  currentScrollPosition?: number;
  dataType?: string;

  setCurrentScrollInfo(position: number, dataType: ConcreteData.DataTypeEnum): void {
    this.currentScrollPosition = position;
    this.dataType = dataType;
  }

  get currentScrollPos(): number {
    return this.currentScrollPosition ? this.currentScrollPosition : 0;
  }

  get currentScrollDataType(): string {
    return this.dataType ? this.dataType : '';
  }

  get activeRow(): string {
    return this.currentActiveRow ? this.currentActiveRow : '';
  }

  set activeRow(index: string) {
    this.currentActiveRow = index;
  }
}
