<div class="container" *ngIf="!valueOnly; else showValueOnly">
  <ds-icon-badge *ngIf="internal" [iconClass]="iconClass" [tooltip]="tooltip"></ds-icon-badge>
  <mat-checkbox
    [aria-label]="ariaLabel"
    [formControl]="control"
    [required]="required"
    [checked]="checked"
    (click)="clickcheckbox.emit($event)"
    (change)="checkedChange($event)"
    [disabled]="disabled"
    [labelPosition]="labelPosition"
    [color]="color">
    {{ label }}{{ required ? '*' : '' }}
  </mat-checkbox>
</div>
<ng-template #showValueOnly>
  <div>
    <ds-label [text]="label" typography="mat-subtitle-2"></ds-label>
    <div class="mat-body-2">
      {{ checked ? 'Ja' : 'Nein' }}
    </div>
  </div>
</ng-template>
