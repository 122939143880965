<main class="form-container">
  <ds-dialog-header title="{{ 'LABELS.changePassword' | translate }}"
                    (clickButton)="onModalClose()"
  />
  <form #form class="flex-col">
    <mat-dialog-content>
      <div class="password-form-container">
        <ds-password-field
          color="accent"
          [label]="'LABELS.currentPass' | translate"
          [placeholder]="'LABELS.currentPass' | translate"
          [required]="true"
          (inputChange)="oldPassword = $event.passwordValue"
          [showError]="showError"
          errorMessage="{{ 'LABELS.currentPass' | translate }} {{ 'ERROR.required' | translate }}" />
        <ds-horizontal-divider />
        <label class="mat-body-2 password-rules-header"> {{ 'LABELS.rulesPassword' | translate }} </label>
        <ul class="mat-body-2 list-rules">
          <li>{{ 'PASSWORD-RULES.characters' | translate }}</li>
          <li>{{ 'PASSWORD-RULES.capitalLetter' | translate }}</li>
          <li>{{ 'PASSWORD-RULES.lowercaseLetter' | translate }}</li>
          <li>{{ 'PASSWORD-RULES.specialCharacter' | translate }}</li>
          <li>{{ 'PASSWORD-RULES.number' | translate }}</li>
        </ul>
        <div class="flex-col gap-inputs">
          <ds-password-field
            #password
            [pattern]="passwordRegex"
            minlength="8"
            color="accent"
            [label]="'AUTH.password' | translate"
            [placeholder]="'AUTH.password' | translate"
            [required]="true"
            (inputChange)="validate(password.passwordValue, passwordRepeat.passwordValue)"
            [showError]="showError"
            errorMessage="{{ 'PASSWORD-RULES.error' | translate }}" />
          <ds-password-field
            #passwordRepeat
            color="accent"
            [label]="'LABELS.newPasswordRepeat' | translate"
            (inputChange)="confirm(password.passwordValue, passwordRepeat.passwordValue)"
            [placeholder]="'AUTH.password' | translate"
            [required]="true"
            [matchError]="matchError"
            errorMessage="{{ !matchError ? ('PASSWORD-RULES.error' | translate) : '' }}"
            matchPasswordMessage="{{ matchError ? ('PASSWORD-RULES.confirmPasswordNotCorrect' | translate) : '' }}" />
        </div>
      </div>
    </mat-dialog-content>
    <ds-horizontal-divider />
    <ds-dialog-actions
      closeBtnLabel="{{ 'BUTTONS.cancel' | translate }}"
      submitBtnLabel="{{ 'BUTTONS.changePassword' | translate }}"
      (closeBtnClicked)="onModalClose()"
      (clickButton)="changePassword()"
      [isSubmitDisabled]="
      matchError ||
      showError ||
      passwordRepeat.passwordValue === '' ||
      passwordRepeat.passwordValue !== password.passwordValue
    " />
  </form>
</main>
