import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FieldName, GenericForm, GenericFormField, ObjectData, OptionPath } from '@frontends/commons';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { FieldTypes } from '../../../../app/model/Enums/FieldTypesEnum';
import { DataType } from '../../../../app/model/types/DataTypes';
import { FormManagerService } from '../../../../app/services/form/form-manager.service';
import { EditOptionsEnum } from '../../../model/Enums/EditOptionsEnum';
import { BatchEditService } from '../../../services/utils/batch-edit/batch-edit.service';
import { GenericFormComponent } from '../generic-form/generic-form.component';

@Component({
  selector: 'exp-form-tabs',
  templateUrl: './form-tabs.component.html',
  styleUrls: ['./form-tabs.component.scss'],
})
export class FormTabsComponent implements OnInit {
  @ViewChild('gForm') public gForm!: GenericFormComponent;

  @Input() public dataType!: DataType;
  @Input() public selectedObjects: ObjectData[] = [];

  public fieldNames!: FieldName;
  public workingObject!: ObjectData;
  public formArray!: FormArray;
  public forms: GenericForm[] = [];
  public optionPathArray: OptionPath[] = [];
  public objectId!: string;

  private currentQueryParamTab: { [key: string]: string } = {};
  private indexOfActiveTab = 0;
  private allowTabChange = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formManager: FormManagerService,
    private batchEditService: BatchEditService,
    private translateService: TranslateService,
    private logger: NGXLogger,
  ) {}

  ngOnInit(): void {
    this.objectId = this.route?.snapshot?.params?.['id'];
    const formData = this.formManager.getFormData();

    this.workingObject = formData.workingObject;
    this.formArray = formData.formArray;
    this.forms = formData.forms;
    this.fieldNames = formData.fieldNames;
    this.logger.debug(`[FORM-TABS]: INITIALIZED`, this);
    if (this.selectedObjects.length > 0) {
      this.prepareOptionPathArray();
      this.batchEditService.setOptionPathArray(this.optionPathArray);
      this.batchEditService.setFormArray(this.formArray);
    }
  }

  public getFormGroup(index: number): FormGroup {
    return this.formArray.at(index) as FormGroup;
  }

  public getActiveTab(): number {
    this.route.queryParams.subscribe((param) => {
      if (!_.isEmpty(param)) {
        this.currentQueryParamTab = param;
        this.forms.forEach((tab) => {
          if (tab.title === this.currentQueryParamTab['tab']) {
            this.indexOfActiveTab = this.forms.indexOf(tab);
          }
        });
      }
    });
    return this.indexOfActiveTab;
  }

  public onTabChange(event: MatTabChangeEvent) {
    if (this.allowTabChange) {
      if (this.route?.snapshot?.params?.['id']) {
        const swTab = this.getTabTitle(event.index).split(' ')[0];
        this.switchTab(swTab);
      }
      this.allowTabChange = false;
      setTimeout(() => {
        this.allowTabChange = true;
      }, 100);
    }
  }

  private switchTab(swTab: string): void {
    const queryParams = { tab: swTab };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
    });
  }

  public getTabTitle(index: number): string {
    let tabTitle = this.forms[index].title ?? '';
    const docLength = this.getDocumentsLength(index);
    if (this.formArray.at(index).invalid) {
      tabTitle = tabTitle.concat(' ').concat('❗️');
    }
    if (docLength > 0) {
      tabTitle = tabTitle.concat('  ').concat('(' + docLength + ')');
    }
    return tabTitle;
  }

  private getDocumentsLength(index: number) {
    let sum = 0;
    this.forms[index].fields
      .filter((element) => element.type === FieldTypes.DOCUMENTS)
      .forEach((field: GenericFormField) => {
        if (this.workingObject[field?.path]?.length) {
          sum += this.workingObject[field?.path]?.length;
        }
      });
    return sum;
  }

  private prepareOptionPathArray() {
    this.formArray.controls.forEach((control: AbstractControl) => {
      const formGroup = control as FormGroup;
      Object.keys(formGroup.controls).forEach((key) => {
        const control = formGroup.get(key);
        if (control) {
          control.setValidators(null);
          control.updateValueAndValidity();
        }
      });

      const formValue = formGroup.value;
      Object.keys(formValue).forEach((key) => {
        let field = {};
        this.forms.forEach((form) => {
          form.fields.forEach((subForm) => {
            if (subForm.path === key) {
              field = subForm;
            }
          });
        });
        const optionPath: OptionPath = {
          path: key,
          option: this.translateService.instant(EditOptionsEnum.OVERWRITE),
          field: field as GenericFormField,
        };
        this.optionPathArray.push(optionPath);
      });
    });
  }
}
