import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import { FileUploadingOverlayComponent } from '../../../ui-components/file-handling';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  overlayRef = this.overlay.create({
    positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
    hasBackdrop: true,
  });

  constructor(private overlay: Overlay) {}

  public showLoader(inputValue: string) {
    const injector = Injector.create({ providers: [{ provide: 'message', useValue: inputValue }] });
    this.overlayRef.attach(new ComponentPortal(FileUploadingOverlayComponent, null, injector));
  }

  public hideLoader() {
    this.overlayRef.detach();
  }
}
