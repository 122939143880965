import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'propertyAccess',
})
export class PropertyAccessPipe implements PipeTransform {
  transform(value: { [key: string]: any }, args: string): string {
    if (!args.includes('.')) {
      return value[args];
    }
    const keys = args.split('.');

    if (value[keys[0]]) {
      return value[keys[0]][keys[1]];
    }

    return '';
  }
}
