/**
 * Explanner Service Documentation
 * Explanner Service Documentation
 *
 * The version of the OpenAPI document: 1.7_landesstelle{20240611@153537}
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ObjectCoordinates { 
    projectId?: string;
    objectId?: string;
    type?: ObjectCoordinates.TypeEnum;
    x?: number;
    y?: number;
    z?: number;
    angle?: number;
    panel?: ObjectCoordinates.PanelEnum;
}
export namespace ObjectCoordinates {
    export type TypeEnum = 'OBJECT' | 'ASSET';
    export const TypeEnum = {
        Object: 'OBJECT' as TypeEnum,
        Asset: 'ASSET' as TypeEnum
    };
    export type PanelEnum = 'LEFT' | 'RIGHT' | 'TOP' | 'DOWN' | 'FLOOR';
    export const PanelEnum = {
        Left: 'LEFT' as PanelEnum,
        Right: 'RIGHT' as PanelEnum,
        Top: 'TOP' as PanelEnum,
        Down: 'DOWN' as PanelEnum,
        Floor: 'FLOOR' as PanelEnum
    };
}


