import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectDialogComponent } from './select-dialog/select-dialog.component';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { ButtonsModule } from '../Buttons/buttons.module';
import { DividerModule } from '../divider/divider.module';
import { MatIconModule } from '@angular/material/icon';
import { DialogHeaderComponent } from './dialog-header/dialog-header.component';
import { DialogActionsComponent } from './dialog-actions/dialog-actions.component';

@NgModule({
  declarations: [DialogActionsComponent, SelectDialogComponent, DialogHeaderComponent],
  imports: [CommonModule, MatListModule, MatDialogModule, ButtonsModule, DividerModule, MatIconModule],
  exports: [DialogActionsComponent, SelectDialogComponent, DialogHeaderComponent],
})
export class DialogsModule {}
