import { NgxLoggerLevel } from 'ngx-logger';

import packages from '../../package.json';

export const environment = {
  production: false,
  localhost: false,
  apiUrl: './service',
  chatServiceURL: 'https://test.explanner.de/chatService',
  version: packages.version,
  logLevel: NgxLoggerLevel.DEBUG,
};
