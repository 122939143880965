/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { BaseFieldComponent } from '../../Base/base-field.component';

@Component({
  selector: 'ds-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioComponent),
      multi: true,
    },
  ],
})
export class RadioComponent extends BaseFieldComponent {
  @Input() colorChips = ['red', 'green', 'yellow'];
  @Input() hasChips = false;

  @Input() options: string[] = [];
  @Input() checked = false;

  @Input() checkedValue = '';

  @Output() changeValue = new EventEmitter<MatRadioChange>();
  @Output() clickRadioButton = new EventEmitter<MouseEvent>();

  radioChange(event: MatRadioChange) {
    this.onChange(event.value);
    this.checkedValue = event.value;
    this.changeValue.emit(event.value);
    this.onTouched();
  }

  // Implement the ControlValueAccessor methods
  protected override onChange: (value: string) => void = () => {};

  override writeValue(value: string): void {
    this.checkedValue = value;
  }
  override registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }
}
