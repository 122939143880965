<div
  [formGroup]="hierarchyFormGroup"
  class="flex-col small-row-gap flex-gap-s generic-form-hierarchy-wrapper"
  [ngClass]="{ 'value-only': field.valueOnly }">
  <div class="flex-row hier-label-row">
    <ds-label
      [valueOnly]="field.valueOnly"
      typography="mat-subtitle-2"
      tooltip="{{ 'LABELS.confidential' | translate }}"
      [isFieldRequired]="isRequired"
      [internal]="field.internal"
      text=" {{ fieldNames[field.path] }}:"></ds-label>
  </div>
  <div class="flex-col" *ngFor="let controlName of controlNames; index as i">
    <ds-search-select
      type="select"
      [formControlName]="controlName"
      *ngIf="options[controlName].length > 0"
      [valueOnly]="field.valueOnly"
      [isHierarchy]="true"
      tooltip="{{ 'LABELS.confidential' | translate }}"
      (selectChanged)="updateOptions(i)"
      label="{{ 'LABELS.layer' | translate }} {{ i + 1 }}"
      ariaLabel="{{ 'LABELS.layer' | translate }} {{ i + 1 }}"
      [options]="options[controlName]"
      [showError]="field.required"
      [errorMessage]="getErrorMessage(field)" />
  </div>
</div>
