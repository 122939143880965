import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ObjectData } from '@frontends/commons';
import { CloningData } from '../../../app/model/Object/CloningData';
import { DataType } from '../../../app/model/types/DataTypes';
import { ApiClientService } from '../../../app/services/api-client.service';
import { TranslateHelpService } from '../../../app/services/translate-help.service';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'exp-clone-dialog',
  templateUrl: './clone-dialog.component.html',
  styleUrls: ['./clone-dialog.component.scss'],
})
export class CloneDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CloningData,
    private router: Router,
    private translationHelp: TranslateHelpService,
    private dialogRef: MatDialogRef<CloneDialogComponent>,
    private apiClientService: ApiClientService,
    private alertService: AlertService
  ) {}

  public duplicateObjet(input: string): void {
    for (let i = 0; i < Number(input); i++) {
      this.createCloneObject(this.data.projectIdentifier, this.data.dataType, this.data.object);
    }
  }

  private createCloneObject(project: string, dataType: DataType, tempObject: ObjectData): void {
    this.apiClientService.saveObjectInAnotherProject(project, dataType, tempObject).subscribe((data) => {
      if (data) {
        this.alertService.openSnackBar(this.translationHelp.translate('SUCCESS_MESSAGE.' + dataType, 'create'), '');
        this.dialogRef.close();
        this.router.navigateByUrl(dataType);
      }
    });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}
