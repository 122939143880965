import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { IconBadgeModule } from '../icon-badge/icon-badge.module';
import { MSelectComponent } from './mat-select/mselect.component';
import { MultiselectComponent } from './multiselect/multiselect.component';
import { SelectComponent } from './native-select/select.component';
import { SearchSelectComponent } from './search-select/search-select.component';
import { ViewValueMultiSelectComponent } from './view-value-multiselect/view-value-multiselect.component';
import { ViewValueSelectComponent } from './view-value-select/view-value-select.component';
@NgModule({
  declarations: [
    MSelectComponent,
    SelectComponent,
    MultiselectComponent,
    SearchSelectComponent,
    ViewValueSelectComponent,
    ViewValueMultiSelectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MatRippleModule,
    MatTooltipModule,
    IconBadgeModule,
    TranslateModule.forChild()
  ],
  exports: [MSelectComponent, SelectComponent, MultiselectComponent, SearchSelectComponent, ViewValueSelectComponent,ViewValueMultiSelectComponent],
})
export class SelectModule {}
