import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService, BaseDialogComponent, ObjectData } from '@frontends/commons';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { FieldTypes } from '../../model/Enums/FieldTypesEnum';
import { BatchField, FieldName } from '../../model/Form/FieldIndex';

import { ObjectDataService } from '../../services/data/object-data.service';
import { FormManagerService } from '../../services/form/form-manager.service';
import { LocalStorageService } from '../../services/storage/local-storage.service';
import { BatchEditService } from '../../services/utils/batch-edit/batch-edit.service';
import { formatAppointment, getAddressField } from '../../utils/helperFunctions';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'exp-batch-edit-summary-dialog',
  templateUrl: './batch-edit-summary-dialog.component.html',
  styleUrls: ['./batch-edit-summary-dialog.component.scss'],
  providers: [FormManagerService]
})
export class BatchEditSummaryDialogComponent extends BaseDialogComponent {
  public projectIdentifier: string;
  public isLoading = false;
  public postfixTranslation = this.translateService.instant('MESSAGE.ACTION.postfix');
  public editedFields: BatchField[] = [];
  public editedObjects: ObjectData[] = [];
  public fieldNames!: FieldName;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected override dialogRef: MatDialogRef<BatchEditSummaryDialogComponent>,
    public dialog: MatDialog,
    public authService: AuthService,
    public formManager: FormManagerService,
    private logger: NGXLogger,
    private localStorageService: LocalStorageService,
    private batchEditService: BatchEditService,
    private objectDataService: ObjectDataService,
    private translateService: TranslateService
  ) {
    super(dialogRef);
    this.projectIdentifier = this.localStorageService.getProject()?.project || '';
    this.batchEditService.updateEditedObjects();
    this.editedObjects = this.batchEditService.getEditedObjects();
    this.editedFields = this.batchEditService.getEditedFields();
    this.fieldNames = this.batchEditService.getFieldNames();
  }

  public onConfirm(): void {
    this.isLoading = true;
    this.dialogRef.updateSize('421px', '90px');
    this.dialogRef.disableClose = true;
    const observables = this.batchEditService
      .getEditedObjects()
      .map((object) => this.objectDataService.updateObject(object, this.data.dataType, this.projectIdentifier));
    forkJoin(observables).subscribe(
      () => {
        this.dialogRef.close(true);
      },
      (error) => {
        this.logger.error(error);
        this.dialogRef.close();
      }
    );
  }

  public getIconByFieldOption(fieldOption: string): string {
    switch (fieldOption) {
      case this.translateService.instant('MESSAGE.ACTION.clear'):
        return 'backspace';
      case this.translateService.instant('MESSAGE.ACTION.overwrite'):
        return 'published_with_changes';
      case this.translateService.instant('MESSAGE.ACTION.prefix'):
        return 'keyboard_tab';
      case this.translateService.instant('MESSAGE.ACTION.postfix'):
        return 'keyboard_tab';
      default:
        return '';
    }
  }

  public customizeSummary(fieldObj: BatchField): string {
    const fieldValue = fieldObj.fieldValue;
    const field = fieldObj.fieldOfPath.field;
    if (fieldValue === this.translateService.instant('MESSAGE.ACTION.clear')) {
      return this.translateService.instant('MESSAGE.ACTION.clear');
    }
    if (field) {
      if (field.type === FieldTypes.LOOKUP) {
        let result = '';
        field.options &&
        field.options.forEach((option: string) => {
          if (fieldValue[option]) {
            if (option !== 'address') {
              result += `<div>${this.fieldNames[field.path + '.' + option]} : ${fieldValue[option]}</div>`;
            } else {
              result += `<div>${this.fieldNames[field.path + '.' + option]} :
               ${getAddressField(field.lookupTo || '', fieldValue)}</div>`;
            }
          } else {
            result += `<div>${this.fieldNames[field.path + '.' + option]} : </div>`;
          }
        });
        return result;
      } else if (field.type === FieldTypes.HIERARCHY) {
        let result = '';
        Object.keys(fieldValue).forEach((key) => {
          result += `<div>${key} : ${fieldValue[key]}</div>`;
        });
        return result;
      } else if (field.type === FieldTypes.DATE) {
        const dateValue = new Date(fieldValue);

        const day = dateValue.getDate().toString().padStart(2, '0');
        const month = (dateValue.getMonth() + 1).toString().padStart(2, '0');
        const year = dateValue.getFullYear().toString();

        return `${day}.${month}.${year}`;
      } else if (field.type === FieldTypes.APPOINTMENT_LIST) {
        return formatAppointment(fieldValue[0]);
      }
      return fieldValue;
    } else {
      return fieldValue;
    }
  }
}
