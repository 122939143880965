/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputType } from '../../../types/InputType';
import { ModeType } from '../../../types/ModeType';
import { BaseFieldComponent } from '../../Base/base-field.component';

@Component({
  selector: 'ds-input',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFieldComponent),
      multi: true,
    },
  ],
})
export class InputFieldComponent extends BaseFieldComponent {
  @Input() public override value = '';
  @Input() public placeholder = '';
  @Input() public type: InputType = 'text';
  @Input() public mode: ModeType = 'text';
  @Input() public maxLength = '';
  @Input() public subType = '';
  @Output() public inputChange = new EventEmitter<string>();
  public ariaLabelBtn = 'Open Link in new tab';

  public onInputBlur() {
    this.onBlur;
    this.inputChange.emit(this.value);
  }

  public onChangeInput() {
    this.onChange(this.value);
    this.inputChange.emit(this.value);
  }

  // Implement the ControlValueAccessor methods
  protected override onChange: (value: string) => void = () => {};

  override writeValue(value: string): void {
    this.value = value;
  }

  override registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  public openExternalLink() {
    let url = this.value.trim();
    if (!/^https?:\/\//i.test(url)) {
      url = '//' + url;
    }
    window.open(url, '_blank');
  }

  public isSubTypeDisabled(): boolean {
    return !this.value;
  }
}
