import { DialogModule } from '@angular/cdk/dialog';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoggerModule } from 'ngx-logger';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { DialogsModule } from './dialogs/dialogs.module';
import { LoggingInterceptor } from './http-interceptors/logging-interceptor';
import { SharedModule } from './shared/shared.module';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthService, BaseAuthService, SidenavComponent, ToolbarComponent } from '@frontends/commons';
import { BASE_PATH } from '@frontends/exp-apiclient';
import { AppRoutingModule } from './app-routing.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    // RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking', preloadingStrategy: PreloadAllModules }),
    LoggerModule.forRoot({
      level: environment.logLevel,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    MatSidenavModule,
    MatSnackBarModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    DialogModule,
    SharedModule,
    DialogsModule,
    ToolbarComponent,
    SidenavComponent,
  ],
  providers: [
    AuthService,
    { provide: BaseAuthService, useExisting: AuthService },
    { provide: BASE_PATH, useValue: environment.apiUrl },
    { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
