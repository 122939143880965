<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<form #genericForm [formGroup]="formGroup" class="form-container" [ngClass]="{ 'form-grid': !isBatchDialogOptions }">
  <ng-container *ngFor="let field of formDefinition.fields">
    @if (field.type !== FieldTypes.LABEL) {
      <div
        [ngClass]="{
          'batch-edit-container': isBatchDialogOptions > 0,
          'field-full': field.columnStyle === fieldWidth.FULL,
          'field-half': field.columnStyle === fieldWidth.HALF,
          'field-third': field.columnStyle === fieldWidth.THIRD,
          'field-quarter': field.columnStyle === fieldWidth.QUARTER
        }"
        class="form-flex-item">
        <!-- This code builds the Form field for each Tab-->
        <ng-container *ngIf="objectId || !field.disabled || !field.valueOnly">
          <div [ngClass]="isBatchDialogOptions > 0 ? 'generic-container' : ''">
            <ng-container class="flex-row">
              <!-- NUMBER -->
              <ds-number-field
                (keydown.enter)="$event.preventDefault()"
                [valueOnly]="field.valueOnly"
                [formControlName]="field.path"
                *ngIf="field.type === FieldTypes.NUMBER"
                [label]="fieldNames[field.path]"
                [ariaLabel]="('A11Y.INPUT.number' | translate) + fieldNames[field.path]"
                [required]="isRequired(field)"
                [showError]="isRequiredOrUnique(field)"
                [errorMessage]="getErrorMessage(field)" />

              <!-- TRAFICLIGHT -->
              <ng-container *ngIf="field.type === FieldTypes.TRAFFICLIGHT">
                <div class="generic-form-traffic-light-wrapper">
                  <div class="flex-row">
                    <ds-traffic-light
                      [valueOnly]="field.valueOnly"
                      [formControlName]="field.path"
                      [label]="fieldNames[field.path]"
                      [ariaLabel]="('A11Y.INPUT.trafficLight' | translate) + fieldNames[field.path]"
                      [chipLabels]="trafficLightLabels"
                      [required]="isRequired(field)" />
                  </div>
                  <div *ngIf="formGroup.get(field.path)?.hasError('required') && formGroup.get(field.path)?.touched">
                    <mat-error>{{ getErrorMessage(field) }}</mat-error>
                  </div>
                </div>
              </ng-container>

              <!-- DATE -->
              <ng-container *ngIf="field.type === FieldTypes.DATE">
                <ds-datepicker
                  (keydown.enter)="$event.preventDefault()"
                  [valueOnly]="field.valueOnly"
                  [formControlName]="field.path"
                  [showHint]="true"
                  hintMessage="TT.MM.JJJJ"
                  iconClass="security"
                  tooltip="{{ 'LABELS.confidential' | translate }}"
                  [internal]="this.isBatchDialogOptions > 0 ? false : isInternal(field)"
                  label="{{
                    fieldNames[field.path] ? fieldNames[field.path] : (dataType + '.' + field.path | translate)
                  }}"
                  [ariaLabel]="('A11Y.INPUT.date' | translate) + fieldNames[field.path]"
                  placeholder="TT.MM.JJJJ"
                  [required]="isRequired(field)"
                  [showError]="isRequired(field)"
                  [errorMessage]="getErrorMessage(field)" />
              </ng-container>

              <!-- CHECKBOX -->
              <ng-container *ngIf="field.type === FieldTypes.CHECKBOX">
                <div class="flex-row">
                  <div>
                    <ds-checkbox
                      [valueOnly]="field.valueOnly"
                      [formControlName]="field.path"
                      iconClass="security"
                      tooltip="{{ 'LABELS.confidential' | translate }}"
                      [internal]="isInternal(field)"
                      label="{{
                        fieldNames[field.path] ? fieldNames[field.path] : (dataType + '.' + field.path | translate)
                      }}:"
                      [ariaLabel]="('A11Y.INPUT.checkbox' | translate) + fieldNames[field.path]"
                      [required]="isRequired(field)"
                      [checked]="object[field.path]"
                      labelPosition="before"
                      [disabled]="field.disabled || !authService.hasWritePermissionsForDatatype(dataType)" />
                    <div class="generic-form-checkbox-error">
                      <mat-error
                        *ngIf="formGroup.get(field.path)?.hasError('required') && formGroup.get(field.path)?.touched">
                        {{ getErrorMessage(field) }}
                      </mat-error>
                    </div>
                  </div>
                </div>
              </ng-container>

              <!-- TEXTAREA -->
              <ds-textarea
                [valueOnly]="field.valueOnly"
                class="ds-textarea"
                [formControlName]="field.path"
                *ngIf="field.type === FieldTypes.TEXTAREA"
                [label]="getFieldLabel(field)"
                [counterAriaLabel]="'A11Y.LABELS.counter' | translate"
                [showCounter]="true"
                [ariaLabel]="('A11Y.INPUT.textArea' | translate) + fieldNames[field.path]"
                tooltip="{{ 'LABELS.confidential' | translate }}"
                [internal]="isInternal(field)"
                [required]="isRequired(field)"
                [showError]="isRequiredOrUnique(field)"
                [errorMessage]="getErrorMessage(field)" />

              <!-- STRING-->
              <ds-input
                (keydown.enter)="$event.preventDefault()"
                [valueOnly]="field.valueOnly"
                [formControlName]="field.path"
                tooltip="{{ 'LABELS.confidential' | translate }}"
                [internal]="isInternal(field)"
                *ngIf="field.type === FieldTypes.STRING"
                [label]="getFieldLabel(field)"
                [ariaLabel]="('A11Y.INPUT.input' | translate) + fieldNames[field.path]"
                [required]="isRequired(field)"
                [showError]="isRequiredOrUnique(field)"
                [errorMessage]="getErrorMessage(field)"
                [subType]="field.subType ?? ''" />

              <!-- ADDRESS -->
              <div *ngIf="field.type === FieldTypes.ADDRESS" class="flex-row address-container">
                <ds-input
                  (keydown.enter)="$event.preventDefault()"
                  [valueOnly]="field.valueOnly"
                  *ngFor="let pathAddition of pathAdditions.addressPathAddition"
                  [formControlName]="field.path + '_' + pathAddition"
                  tooltip="{{ 'LABELS.confidential' | translate }}"
                  [internal]="isInternal(field)"
                  label=" {{ 'LABELS.address_' + pathAddition | translate }}"
                  [ariaLabel]="('A11Y.INPUT.input' | translate) + ('LABELS.address_' + pathAddition | translate)"
                  [maxlength]="pathAddition === 'zipcode' ? '5' : '100'"
                  [required]="isRequired(field)"
                  [showError]="isRequiredOrUnique(field)"
                  [errorMessage]="getErrorMessage(field)" />
              </div>

              <!-- LOOKUP -->
              <div *ngIf="field.type === FieldTypes.LOOKUP" class="flex-col generic-form-look-up-wrapper">
                <exp-look-up
                  [formControlName]="field.path"
                  [field]="field"
                  [fieldNames]="fieldNames"
                  [dataType]="dataType" />
                <div *ngIf="formGroup.get(field.path)?.hasError('required') && formGroup.get(field.path)?.touched">
                  <mat-error>{{ getErrorMessage(field) }}</mat-error>
                </div>
              </div>

              <!-- DIMENSIONS -->
              <div
                *ngIf="field.type === 'DIMENSION'"
                class="flex-row dimensions-row"
                [class.dimension-gap]="!field.valueOnly">
                <ds-number-field
                  (keydown.enter)="$event.preventDefault()"
                  [valueOnly]="field.valueOnly"
                  [isDimension]="true"
                  *ngFor="let pathAddition of pathAdditions.dimensionPathAddition"
                  class="dimension-item"
                  [formControlName]="field.path + '_' + pathAddition"
                  label="{{ 'LABELS.' + pathAddition | translate }}"
                  [ariaLabel]="('A11Y.INPUT.number' | translate) + ('LABELS.' + pathAddition | translate)"
                  [isDecimal]="true"
                  [internal]="isInternal(field)"
                  [required]="isRequired(field)"
                  [showError]="true"
                  [errorMessage]="getErrorMessage(field)" />
              </div>

              <!-- HIERARCHY -->
              <div
                *ngIf="field.type === FieldTypes.HIERARCHY"
                [ngClass]="{ 'value-only': field.valueOnly }"
                class="flex-col generic-form-look-up-wrapper">
                <exp-hierarchy
                  [hierarchyFormGroup]="getFormGroup(field.path)"
                  [field]="field"
                  [fieldNames]="fieldNames"
                  [dataType]="dataType"
                  [isRequired]="isRequired(field)"></exp-hierarchy>
                <div *ngIf="formGroup.get(field.path)?.hasError('required') && formGroup.get(field.path)?.touched">
                  <mat-error>{{ getErrorMessage(field) }}</mat-error>
                </div>
              </div>

              <!-- RADIO BUTTON SINGLE CHOICE MULTIPLE OPTIONS -->
              <div
                [ngClass]="field.valueOnly ? null : 'generic-form-radio-wrapper'"
                *ngIf="field.type === FieldTypes.RADIO">
                <div>
                  <ds-label
                    [valueOnly]="field.valueOnly"
                    class="radio-label"
                    typography="mat-subtitle-2"
                    tooltip="{{ 'LABELS.confidential' | translate }}"
                    [internal]="isInternal(field)"
                    [isFieldRequired]="isRequired(field)"
                    text="{{
                      fieldNames[field.path] ? fieldNames[field.path] : (dataType + '.' + field.path | translate)
                    }}:" />
                  <div *ngIf="formGroup.get(field.path)?.hasError('required') && formGroup.get(field.path)?.touched">
                    <mat-error>{{ getErrorMessage(field) }}</mat-error>
                  </div>
                </div>
                <ds-radio
                  [valueOnly]="field.valueOnly"
                  [formControlName]="field.path"
                  [options]="field.options"
                  [ariaLabel]="('A11Y.INPUT.radio' | translate) + fieldNames[field.path]"
                  color="accent"
                  value="{{ object[field.path] }}" />
              </div>

              <!-- MULTISELECT DROPDOWN -->
              @if (field.type === FieldTypes.MULTISELECT) {
                <ds-search-select
                  type="multiselect"
                  [valueOnly]="field.valueOnly"
                  [formControlName]="field.path"
                  iconClass="security"
                  tooltip="{{ 'LABELS.confidential' | translate }}"
                  (clear)="object[field.path] = []"
                  [internal]="isInternal(field)"
                  [label]="fieldNames[field.path]"
                  [ariaLabel]="('A11Y.INPUT.multiSelect' | translate) + fieldNames[field.path]"
                  [options]="field.options"
                  [required]="isRequired(field)"
                  [showError]="isRequired(field)"
                  [errorMessage]="getErrorMessage(field)" />
              }

              <!-- SELECT DROPDOWN -->
              @if (field.type === FieldTypes.SELECT) {
                <ds-search-select
                  type="select"
                  [valueOnly]="field.valueOnly"
                  [formControlName]="field.path"
                  iconClass="security"
                  tooltip="{{ 'LABELS.confidential' | translate }}"
                  [internal]="isInternal(field)"
                  [label]="fieldNames[field.path]"
                  [ariaLabel]="('A11Y.INPUT.select' | translate) + fieldNames[field.path]"
                  [options]="field.options"
                  [required]="isRequired(field)"
                  [showError]="isRequired(field)"
                  [errorMessage]="getErrorMessage(field)" />
              }

              <!-- IMAGES UPLOAD -->
              <div *ngIf="field.type === FieldTypes.IMAGES" class="flex-col generic-form-file-upload">
                <cmn-file-uploader
                  *ngIf="this.isBatchDialogOptions === 0; else batchEditImage"
                  [valueOnly]="field.valueOnly"
                  [formControlName]="field.path"
                  type="IMAGE"
                  [(files)]="object[field.path]"
                  [environmentApiUrl]="envApiUrl"
                  [options]="field.options"
                  [hasWritePermission]="authService.hasWritePermissionsForFieldAndDatatype(dataType, field)" />
                <ng-template #batchEditImage>
                  <div class="flex-row upload-file-not-possible mat-elevation-z1">
                    {{ 'LABELS.noUploadImage' | translate }}
                  </div>
                </ng-template>
              </div>

              <!-- DOCUMENTS UPLOAD -->
              <div *ngIf="field.type === FieldTypes.DOCUMENTS" class="flex-col generic-form-file-upload">
                <cmn-file-uploader
                  *ngIf="this.isBatchDialogOptions === 0; else batchEdit"
                  [valueOnly]="field.valueOnly"
                  [formControlName]="field.path"
                  [type]="field.path === 'images' ? 'IMAGE' : 'FILE'"
                  [(files)]="object[field.path]"
                  [environmentApiUrl]="envApiUrl"
                  [options]="field.options"
                  [hasWritePermission]="authService.hasWritePermissionsForFieldAndDatatype(dataType, field)" />
                <ng-template #batchEdit>
                  <div class="flex-row upload-file-not-possible mat-elevation-z1">
                    {{ 'LABELS.noUploadFile' | translate }}
                  </div>
                </ng-template>
              </div>

              <!-- APPOINTMENT LIST -->
              <div
                class="flex-col small-row-gap flex-gap-s generic-form-appointments-wrapper"
                [ngClass]="{ 'value-only': field.valueOnly }"
                *ngIf="field.type === FieldTypes.APPOINTMENT_LIST">
                <exp-appointments-list
                  *ngIf="this.isBatchDialogOptions === 0; else batchEdit"
                  (selectionChange)="setAppointmentValue(field.path, $event)"
                  [field]="field"
                  [fieldName]="fieldNames[field.path]"
                  [appointments]="object[field.path]"
                  [dataType]="dataType" />
                <ng-template #batchEdit>
                  <h2>
                    <ds-label [valueOnly]="!field.valueOnly" text=" {{ fieldNames[field.path] }}:"></ds-label>
                  </h2>
                  <div class="flex-row upload-file-not-possible mat-elevation-z1">
                    {{ 'MESSAGE.INFO_MESSAGE.noAptEditInBatch' | translate }}
                  </div>
                </ng-template>
              </div>
              <!-- KEY VALUE LIST -->
              @if (field.type === FieldTypes.KVMAP && !this.isBatchDialogOptions) {
                <exp-key-value-chart
                  [graphData]="formGroup.get(field.path)?.value"
                  [graphOptions]="field?.properties?.['chartData'] || {}" />
                <exp-kv-form-group
                  [field]="field"
                  [formGroup]="formGroup.get(field.path) | toFormGroup"
                  [required]="isRequired(field)"
                  [showError]="isRequiredOrUnique(field)"
                  [internal]="isInternal(field)"
                  [errorMessage]="getErrorMessage(field)"
                  [graphOptions]="field?.properties?.['chartData'] || {}" />
              }
            </ng-container>
          </div>
        </ng-container>
        @if (objectId || !field.disabled || !field.valueOnly) {
          <div [ngClass]="optionPathArray.length > 0 ? 'select-container' : ''">
            @if (
              optionPathArray.length > 0 &&
              field.type !== FieldTypes.IMAGES &&
              field.type !== FieldTypes.DOCUMENTS &&
              field.type !== FieldTypes.APPOINTMENT_LIST &&
              field.type !== FieldTypes.KVMAP
            ) {
              <ds-search-select
                type="select"
                tooltip="{{ 'LABELS.confidential' | translate }}"
                label="Modus"
                [options]="filterEditOptions(field.type, field.required)"
                [selectedValues]="editOptions[1]"
                [required]="isRequired(field)"
                [showError]="isRequired(field)"
                [errorMessage]="getErrorMessage(field)"
                (selectedValuesChange)="changeEditOption($event, field)"
                [selectWithOutEmptyValue]="true" />
            }
          </div>
        }
      </div>
    } @else {
      <!-- LABEL -->
      <div class="generic-form-field-title-wrapper">
        <ng-container *ngIf="field.options; else noOptions">
          <ng-container [ngSwitch]="field.options[0]">
            <ng-container *ngSwitchCase="'h1'">
              <h1>{{ field.name }}</h1>
            </ng-container>
            <ng-container *ngSwitchCase="'h2'">
              <h2>{{ field.name }}</h2>
            </ng-container>
            <ng-container *ngSwitchCase="'h3'">
              <h3>{{ field.name }}</h3>
            </ng-container>
            <ng-container *ngSwitchCase="'h4'">
              <h4 class="mat-subtitle-2">{{ field.name }}</h4>
            </ng-container>
            <ng-container *ngSwitchCase="'h5'">
              <h5 class="mat-subtitle-2">{{ field.name }}</h5>
            </ng-container>
            <ng-container *ngSwitchCase="'h6'">
              <h6 class="mat-subtitle-2">{{ field.name }}</h6>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <h2 class="mat-h2">{{ field.name }}</h2>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-template #noOptions>
          <h2>{{ field.name }}</h2>
        </ng-template>
      </div>
    }
  </ng-container>
</form>
