import { Injectable, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SaveStatus, UiControllerService } from '@frontends/exp-apiclient';
import { CookieService } from 'ngx-cookie-service';
import { NGXLogger } from 'ngx-logger';
import {
  BehaviorSubject,
  catchError,
  concatMap,
  EMPTY,
  from,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import {
  ChangePassRequest,
  EMPTY_USER_INFO,
  PassResetRequest,
  Permissions,
  ProductTypes,
  UserInfo,
  UserPassTuple,
} from '../../model';
import { InitData } from '../../model/Data/InitData';
import { GenericFormField } from '../../model/Form/GenericFormField';
import { DataType } from '../../model/Types/DataType';
import { BaseExpAuthService } from '../base/base-exp-auth.service';
import { CmnLocalStorageService } from '../storage/cmn-local-storage.service';
import { CmnSessionStorageService } from '../storage/cmn-session-storage.service';
import { AuthApiClientService } from './auth-api-client.service';

const NOT_SET = 'Not set';
const CAUTH = 'cauth';

@Injectable()
export class AuthService extends BaseExpAuthService {
  private userInfo: UserInfo = EMPTY_USER_INFO;
  private userInfoSubject: BehaviorSubject<UserInfo> = new BehaviorSubject<UserInfo>(this.userInfo);
  private loggedInSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private managingUserSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private adminUserSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public userInfo$: Observable<UserInfo> = this.userInfoSubject.asObservable();
  public userIsLoggedIn$: Observable<boolean> = this.loggedInSubject.asObservable();

  public isMgmtUser$: Observable<boolean> = this.managingUserSubject.asObservable();
  public isAdminUser$: Observable<boolean> = this.adminUserSubject.asObservable();

  public permissions: Permissions = {};
  private project!: string;

  constructor(
    private router: Router,
    private authApiClient: AuthApiClientService,
    private uiController: UiControllerService,
    private cookieService: CookieService,
    private localStorageService: CmnLocalStorageService,
    private sessionStorageService: CmnSessionStorageService,
    private logger: NGXLogger,
  ) {
    super();
    this.logger.debug('[AUTH-SERVICE]: CHECK COOKIE', this.cookieService.check(CAUTH));
    this.loggedInSubject.next(this.cookieService.check(CAUTH));
    this.project = this.localStorageService.getProject().project;
  }

  public override login(userPass: UserPassTuple): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.authApiClient
        .login(userPass)
        .pipe(
          concatMap((authResponse) => {
            if (authResponse && authResponse.name && authResponse.value) {
              this.logger.debug(`[AUTH-SERVICE]: Login request successful`);
              return this.uiController.getInit().pipe(
                map(
                  (beInit: import('@frontends/exp-apiclient').InitData) =>
                    ({
                      type: beInit.type ?? ProductTypes.EXPLANNER,
                      projects:
                        beInit.projects?.map((beProject: import('@frontends/exp-apiclient').ProjectData) => ({
                          docId: beProject.docId ?? '',
                          project: beProject.project ?? '',
                          name: beProject.name ?? '',
                          active: beProject.active ?? false,
                          ...beProject,
                        })) ?? [],
                      tiles: beInit.tiles ?? [],
                      permissions: beInit.permissions ?? {},
                      userId: beInit.userId ?? '',
                      firstname: beInit.firstname ?? '',
                      lastname: beInit.lastname ?? '',
                      email: beInit.email ?? '',
                      language: beInit.language ?? '',
                    }) as InitData,
                ),
                shareReplay(),
              );
            }
            return EMPTY;
          }),
          shareReplay(),
          catchError((error) => {
            this.logger.error(`[AUTH-SERVICE]: LOGIN FAILED: ${JSON.stringify(error)}`);
            observer.error(`Sign-in failed`);
            return throwError(() => new Error('Sign-in failed.'));
          }),
        )
        .subscribe({
          next: (initData: InitData) => {
            if (initData) {
              const projectsToSave = initData.projects || [];
              if (initData?.projects && initData.projects.length > 0) {
                this.sessionStorageService.setProjects(projectsToSave);

                if (!this.project || this.project === 'EMPTY' || this.project === 'NOT SET') {
                  this.localStorageService.setProject(initData.projects[0]);
                  this.project = initData.projects[0].project;
                }
                this.userInfo.firstName = initData.firstname ?? NOT_SET;
                this.userInfo.lastName = initData.lastname ?? NOT_SET;
                this.userInfo.userEmail = initData.email ?? NOT_SET;
                this.userInfo.userName = initData.userId ?? NOT_SET;
                this.userInfoSubject.next(this.userInfo);
              }
              initData.permissions ? this.setPermissionsOnAppInit(initData.permissions) : '';
              initData.type ? this.sessionStorageService.setProductType(initData.type) : '';
              initData.tiles ? this.sessionStorageService.setTools(initData.tiles) : '';
            }
          },
          complete: () => {
            this.logger.debug('[AUTH-SERVICE]: COMPLETE LOGIN AND INIT', this.isLoggedIn, this.userInfo);
            const tiles = this.sessionStorageService.getTools();
            const projects = this.sessionStorageService.getProjects();
            this.loggedInSubject.next(true);

            if (projects.length === 0 && Object.keys(tiles).length > 0) {
              const toolProject = Object.keys(tiles)[0];
              const urlProject = tiles[toolProject];
              const protocol = 'https://';
              if (!urlProject.includes(protocol)) {
                this.logger.debug(`[AUTH-SERVICE]: Navigate to ${protocol}${urlProject}`);
                window.location.href = protocol + urlProject;
              } else {
                this.logger.debug(`[AUTH-SERVICE]: Navigate to ${urlProject}`);
                window.location.href = urlProject;
              }
            } else {
              this.logger.debug('[AUTH-SERVICE]: Navigate to default');
              this.router.navigate(['default']).then((success) => {
                if (success) {
                  this.logger.info('[AUTH-SERVICE]: routing successful');
                } else {
                  this.logger.warn('[AUTH-SERVICE]: routing failed');
                }
              });
            }
            observer.next(true);
            observer.complete();
          },
          error: (error: any) => {
            this.logger.error('[AUTH-SERVICE]: ERROR DURING INIT CALL', error);
          },
        });
    });
  }

  public logout() {
    this.authApiClient.logout().subscribe((response) => {
      if (response) {
        if (isDevMode()) this.localStorageService.removeAuthToken();
        this.cookieService.delete(CAUTH);
        this.sessionStorageService.clearSessionStorage();
        this.loggedInSubject.next(false);
        window.open('/auth/login', '_self');
      }
    });
  }

  public override isLoggedIn(): boolean {
    return this.cookieService.check(CAUTH);
  }

  public override setUserInfo(initData: InitData) {
    this.logger.debug('[AUTH-SERVICE]: SET USER INFO', initData);
    this.userInfo.firstName = initData.firstname ?? NOT_SET;
    this.userInfo.lastName = initData.lastname ?? NOT_SET;
    this.userInfo.userName = initData.userId ?? NOT_SET;
    this.userInfo.userEmail = initData.email ?? NOT_SET;
    this.userInfoSubject.next(this.userInfo);
  }

  public override patchUserInfo(projectIdentifier: string): void {
    this.uiController.check(projectIdentifier).subscribe((userRoleInfo) => {
      this.updateRole(userRoleInfo);
    });
  }

  public override resetPassword(resetRequest: PassResetRequest): Observable<{ [key: string]: object }> {
    return this.authApiClient.reset(resetRequest);
  }

  public updatePassword(passRequest: ChangePassRequest): Observable<SaveStatus> {
    return this.uiController.setPass(passRequest);
  }

  public override checkAuth(projectIdentifier?: string): Observable<{ [key: string]: object }> {
    return this.uiController.check(projectIdentifier).pipe(
      switchMap((data) => {
        if (data instanceof Blob) {
          return from(data.text()).pipe(
            map((text) => {
              const parsedToken = JSON.parse(text);
              return {
                ...parsedToken,
              };
            }),
          );
        } else {
          return of({
            ...data,
          });
        }
      }),
      tap((data) => this.updateRole(data)),
    );
  }

  private updateRole(roleInfo: { [key: string]: object }): void {
    this.logger.debug('CHECK DATA', roleInfo);
    if (roleInfo) {
      this.userInfo = {
        ...this.userInfo,
        userEmail: roleInfo['email'].toString(),
        userName: roleInfo['userId'].toString(),
        userRole: roleInfo['role'].toString(),
        isMgmtUser: roleInfo['manager'] as unknown as boolean,
        isAdminUser: roleInfo['admin'] as unknown as boolean,
      };
      this.userInfoSubject.next(this.userInfo);
      this.adminUserSubject.next(this.userInfo.isAdminUser);
      this.managingUserSubject.next(this.userInfo.isMgmtUser);
      this.logger.debug('[AUTH-SERVICE]: PATCHED USER INFO', this.isLoggedIn, this.userInfo);
    } else {
      this.logger.warn('[AUTH-SERVICE]: NO ROLE INFORMATION - PROBABLY COOKIE EXPIRED');
    }
  }

  public override setPermissionsOnAppInit(permissions: Permissions): void {
    this.permissions = permissions;
  }

  public hasCreatePermissionsForDatatype(dataType: DataType, project = this.project): boolean {
    const projectObject = this.permissions?.[project]?.[dataType];
    return !!projectObject?.createPermission;
  }

  public hasDeletePermissionsForDatatype(dataType: DataType, project = this.project): boolean {
    const projectObject = this.permissions?.[project]?.[dataType];
    return !!projectObject?.deletePermission;
  }

  public hasWritePermissionsForFieldAndDatatype(dataType: DataType, field: GenericFormField): boolean {
    return !field.disabled && this.hasWritePermissionsForDatatype(dataType);
  }

  public hasWritePermissionsForDatatype(dataType: DataType, project = this.project): boolean {
    const projectObject = this.permissions?.[project]?.[dataType];
    return !!projectObject?.writePermission;
  }

  public hasReadPermissionsForDatatype(dataType: DataType, project = this.project): boolean {
    const projectObject = this.permissions?.[project]?.[dataType];
    return !!projectObject;
  }
}
