import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { RadiobuttonComponent } from './radiobutton.component';
import { RadioComponent } from './radio/radio.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { ChipsModule } from '../chips/chips.module';
import { TrafficLightComponent } from './traffic-light/traffic-light.component';
import { IconBadgeModule } from '../icon-badge/icon-badge.module';
import { MatInputModule } from '@angular/material/input';
import { LabelModule } from '../labels/label.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [RadiobuttonComponent, RadioComponent, TrafficLightComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatRadioModule,
    MatTooltipModule,
    MatIconModule,
    ChipsModule,
    IconBadgeModule,
    LabelModule,
  ],
  exports: [RadiobuttonComponent, RadioComponent, TrafficLightComponent],
})
export class RadiobuttonModule {}
