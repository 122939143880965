import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { IconBadgeModule } from '../icon-badge/icon-badge.module';
import { InputFieldsUploadFileComponent } from './input-field-upload-file/input-fields-upload-file.component';
import { InputFieldComponent } from './input-field/input-field.component';
import { InputFileComponent } from './input-file/input-file.component';
import { InputFilterComponent } from './input-filter/input-filter.component';
import { NumberFieldComponent } from './number-field/number-field.component';
import { PasswordFieldComponent } from './password-field/password-field.component';
import { TextareaComponent } from './textarea/textarea.component';
@NgModule({
  declarations: [
    InputFilterComponent,
    InputFieldComponent,
    PasswordFieldComponent,
    TextareaComponent,
    InputFileComponent,
    InputFieldsUploadFileComponent,
    InputFieldsUploadFileComponent,
    NumberFieldComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TextFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    IconBadgeModule,
    TranslateModule,
  ],
  exports: [
    InputFilterComponent,
    InputFieldComponent,
    PasswordFieldComponent,
    TextareaComponent,
    InputFileComponent,
    InputFieldsUploadFileComponent,
    NumberFieldComponent,
  ],
})
export class InputFieldsModule {}
