import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LabelComponent } from './label/label.component';
import { LabelIconComponent } from './label-icon/label-icon.component';
import { IconBadgeModule } from '../icon-badge/icon-badge.module';

@NgModule({
  imports: [CommonModule, MatIconModule, MatFormFieldModule, MatTooltipModule, IconBadgeModule],
  declarations: [LabelComponent, LabelIconComponent],
  exports: [LabelComponent, LabelIconComponent],
})
export class LabelModule {}
PageTransitionEvent;
