/**
 * Explanner Service Documentation
 * Explanner Service Documentation
 *
 * The version of the OpenAPI document: 1.7_landesstelle{20240611@153537}
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Used to fix hidden filter
 */
export interface EnhancedCriteria { 
    type?: EnhancedCriteria.TypeEnum;
    key?: string;
    values?: Array<string>;
}
export namespace EnhancedCriteria {
    export type TypeEnum = 'MUST' | 'MUSTNOT';
    export const TypeEnum = {
        Must: 'MUST' as TypeEnum,
        Mustnot: 'MUSTNOT' as TypeEnum
    };
}


