/**
 * Explanner Service Documentation
 * Explanner Service Documentation
 *
 * The version of the OpenAPI document: 1.7_landesstelle{20240611@153537}
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserData { 
    docId?: string;
    userId?: string;
    firstname?: string;
    lastname?: string;
    email?: string;
    tel?: string;
    pass?: string;
    institution?: string;
    mandant?: string;
    team?: string;
    description?: string;
    internalUser?: boolean;
    managingUser?: boolean;
    lang?: string;
    projectsToRole?: { [key: string]: string; };
    lastLoginAt?: number;
}

