<main class="flex-col settings-container">
  <header class="dialog-header">
    <h2 mat-dialog-title class="dialog-header-label">{{ 'LABELS.changePassword' | translate }}</h2>
    <ds-icon-button class="close-button" icon="close" color="primary" mat-dialog-close></ds-icon-button>
  </header>

  <form #form="ngForm" class="flex-col">
    <div mat-dialog-content class="dialog-content">
      <div class="flex-col">
        <label class="mat-subtitle-2"> {{ 'LABELS.rolesPassword' | translate }} </label>
        <ul class="mat-subtitle-2 list-roles">
          <li>{{ 'PASSWORD-ROLES.characters' | translate }}</li>
          <li>{{ 'PASSWORD-ROLES.capitalLetter' | translate }}</li>
          <li>{{ 'PASSWORD-ROLES.lowercaseLetter' | translate }}</li>
          <li>{{ 'PASSWORD-ROLES.specialCharacter' | translate }}</li>
          <li>{{ 'PASSWORD-ROLES.number' | translate }}</li>
        </ul>
        <div class="flex-col">
          <ds-password-field
            color="accent"
            [label]="'LABELS.currentPass' | translate"
            [placeholder]="'LABELS.currentPass' | translate"
            [required]="true"
            [(ngModel)]="oldPassword"
            [ngModelOptions]="{ standalone: true }"
            [showError]="showError"
            errorMessage="{{ 'PASSWORD-RULES.error' | translate }}" />
          <ds-horizontal-divider />
          <p>
            <ds-password-field
              #password
              [pattern]="passwordRegex"
              minlength="8"
              color="accent"
              [label]="'AUTH.password' | translate"
              [placeholder]="'AUTH.password' | translate"
              [required]="true"
              (inputChange)="setPassword($event.passwordValue)"
              [showError]="showError"
              errorMessage="{{ 'PASSWORD-RULES.error' | translate }}" />
          </p>
          <p>
            <ds-password-field
              #passwordRepeat
              color="accent"
              [pattern]="passwordRegex"
              [label]="'LABELS.newPasswordRepeat' | translate"
              (inputChange)="setRepeatPassword($event.passwordValue)"
              [placeholder]="'AUTH.password' | translate"
              [required]="true"
              [matchError]="matchError"
              errorMessage="{{ !matchError ? ('PASSWORD-RULES.error' | translate) : '' }}"
              matchPasswordMessage="{{ matchError ? ('PASSWORD-RULES.confirmPasswordNotCorrect' | translate) : '' }}" />
          </p>
        </div>
      </div>
    </div>
    <ds-horizontal-divider></ds-horizontal-divider>
    <footer>
      <div class="dialog-actions" mat-dialog-actions>
        <ds-basic-button
          color="accent"
          (clickButton)="closeDialog()"
          label="{{ 'BUTTONS.cancel' | translate }}"></ds-basic-button>
        <ds-primary-button
          color="accent"
          [disabled]="
            matchError ||
            showError ||
            passwordRepeat.passwordValue === '' ||
            passwordRepeat.passwordValue !== password.passwordValue
          "
          (clickButton)="changePassword()"
          label="{{ 'BUTTONS.changePassword' | translate }}"></ds-primary-button>
      </div>
    </footer>
  </form>
</main>
