/**
 * Explanner Service Documentation
 * Explanner Service Documentation
 *
 * The version of the OpenAPI document: 1.7_landesstelle{20240611@153537}
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Resize200ResponseStatusInfo { 
    family?: Resize200ResponseStatusInfo.FamilyEnum;
    reasonPhrase?: string;
    statusCode?: number;
}
export namespace Resize200ResponseStatusInfo {
    export type FamilyEnum = 'INFORMATIONAL' | 'SUCCESSFUL' | 'REDIRECTION' | 'CLIENT_ERROR' | 'SERVER_ERROR' | 'OTHER';
    export const FamilyEnum = {
        Informational: 'INFORMATIONAL' as FamilyEnum,
        Successful: 'SUCCESSFUL' as FamilyEnum,
        Redirection: 'REDIRECTION' as FamilyEnum,
        ClientError: 'CLIENT_ERROR' as FamilyEnum,
        ServerError: 'SERVER_ERROR' as FamilyEnum,
        Other: 'OTHER' as FamilyEnum
    };
}


