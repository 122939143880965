<div class="flex-col settings-container">
  <h1 mat-dialog-title class="mat-subtitle-1" *ngIf="title">{{ title }}</h1>
  <div mat-dialog-content class="{{ title ? 'mat-body-1' : 'mat-subheading-2' }}" [innerHTML]="message"></div>
  <ds-horizontal-divider />
  <div class="dialog-actions" mat-dialog-actions align="end">
    <div class="flex-row center-items medium-flex-gap" *ngIf="!isInfo">
      <ds-basic-button color="accent" (click)="onCommand()" label="{{ buttonNo }}" />
      <ds-basic-button [color]="confirmBtnColor" (click)="handleSave()" *ngIf="buttonYes" label="{{ buttonYes }}" />
    </div>
    <div class="flex-row center-items" *ngIf="isInfo">
      <ds-basic-button
        label="Button"
        color="accent"
        *ngIf="buttonYes"
        (click)="onCancel()"
        label="{{ buttonYes }}"
        cdkFocusInitial />
    </div>
  </div>
</div>
