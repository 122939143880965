import { Component, Input } from '@angular/core';
import { ChipColors } from '../../types/colors';

@Component({
  selector: 'ds-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss'],
})
export class ChipsComponent {
  @Input() label = '';
  @Input() color: ChipColors | string = 'green';
  @Input() disableRipple = true;
}
