/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { ProjectData } from '@frontends/commons';
import { RequestObject } from '@frontends/exp-apiclient';
import { NGXLogger } from 'ngx-logger';

import { BehaviorSubject } from 'rxjs';

const DELIMITER = '_';
// CONSTANTS KEYS
const PROJECT = 'project';
const PROJECT_LIST = 'projectList';
const PAGE_SIZE = 'pageSize';
const REQUEST = 'request';
const RESULT_LENGTH = 'resultLength';
const ROOM_PROJECT = 'roomProject';
const CAUTH = 'cauth';
const LANG = 'lang';
const VERSION = 'version';

const EMPTY_PROJECT: ProjectData = {
  docId: 'EMPTY',
  project: 'EMPTY',
  name: 'EMPTY',
  active: false,
};

//TODO [REFACTORING] store values here in service only access browser storage when value changes
/**
 * Service that handles local storage.
 * CRUD operations on local storage.
 */
@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  /*   objectIds: Array<string>
  request: RequestObject = {};
  resultLength: number; */
  private projectDataSubject = new BehaviorSubject<ProjectData>(EMPTY_PROJECT);
  private projectDataListSubject = new BehaviorSubject<ProjectData[]>([]);

  public projectObject: ProjectData = EMPTY_PROJECT;

  public projectData$ = this.projectDataSubject.asObservable();
  public projectDataListSubject$ = this.projectDataListSubject.asObservable();

  constructor(private logger: NGXLogger) {}

  emitProject(project: ProjectData) {
    this.projectDataSubject.next(project);
  }

  emitListProjects(projectList: ProjectData[]) {
    this.projectDataListSubject.next(projectList);
  }

  clearLocalStorage() {
    const tempSavedProject = this.getProject();
    const tempVersion = this.getVersion();
    const tempSavedPagination = this.getItem(PAGE_SIZE);
    localStorage.clear();

    if (tempSavedPagination) {
      this.setItem(PAGE_SIZE, JSON.parse(tempSavedPagination));
    }
    this.setProject(tempSavedProject);
    this.setVersion(tempVersion);
  }

  /* ------CAUTH STORAGE ---------- */

  setCAuth(cauth: string) {
    this.setItem(CAUTH, JSON.stringify(cauth));
  }

  getCAuth(): string {
    if (this.getItem(CAUTH)) {
      return JSON.parse(this.getItem(CAUTH));
    } else {
      return '';
    }
  }

  /* ------ VERSION -------- */
  setVersion(version: string) {
    this.setItem(VERSION, JSON.stringify(version));
  }

  getVersion(): string {
    if (this.getItem(VERSION)) {
      return JSON.parse(this.getItem(VERSION));
    } else {
      return '';
    }
  }

  /* ------PROJECT STORAGE ---------- */

  /**
   * set the current Project (single)
   *
   * @param project
   *
   */
  setProject(project: ProjectData) {
    this.setItem(PROJECT, JSON.stringify(project));
    this.emitProject(project);
  }

  getProject(): ProjectData {
    if (this.getItem(PROJECT)) {
      return JSON.parse(this.getItem(PROJECT));
    } else {
      return EMPTY_PROJECT;
    }
    // if (this.projectDataSubject.getValue()) {
    //   return this.projectDataSubject.getValue();
    // } else {
    //   return {};
    // }
  }

  /**
   * on changing a project we need to clear some of the local storage
   * in order to keep the usable data clean
   */
  onChangeProject() {
    const tempProjectList = this.getItem(PROJECT_LIST);
    const tempVersion = this.getVersion();
    localStorage.clear();
    this.setItem(PROJECT_LIST, tempProjectList);
    this.setVersion(tempVersion);
  }

  /* ------PROJECT STORAGE END ---------- */

  /* ------LANG STORAGE ---------- */

  saveLang(lang: string): void {
    this.setItem(LANG, JSON.stringify(lang));
  }

  getLang(): string {
    return JSON.parse(this.getItem(LANG));
  }

  deleteLang(): void {
    this.removeItem(LANG);
  }

  /* ------LANG STORAGE ---------- */

  /* ------PAGESIZE STORAGE ---------- */

  savePageSize(pageSize: number): void {
    this.setItem(PAGE_SIZE, JSON.stringify(pageSize));
  }

  getPageSize(): number {
    return JSON.parse(this.getItem(PAGE_SIZE));
  }

  deletePageSize(): void {
    this.removeItem(PAGE_SIZE);
  }

  /* ------PAGESIZE STORAGE END ---------- */

  /* ---------- REQUEST STORAGE ------------------------ */
  saveRequest(passedRequest: RequestObject, position: number) {
    passedRequest.page = position;
    passedRequest.size = 1;

    this.setItem(REQUEST, JSON.stringify(passedRequest));
  }

  getRequest(): RequestObject {
    return JSON.parse(this.getItem(REQUEST));
  }

  getNext(): RequestObject {
    const tempRequest = this.getRequest();
    if (tempRequest.page !== undefined) {
      tempRequest.page += 1;
      this.saveRequest(tempRequest, tempRequest.page);
    }

    return tempRequest;
  }
  getPrevious(): RequestObject {
    const tempRequest = this.getRequest();
    if (tempRequest.page !== undefined) {
      tempRequest.page -= 1;
      this.saveRequest(tempRequest, tempRequest.page);
    }

    return tempRequest;
  }

  saveResultLength(resultLength: string): void {
    this.setItem(RESULT_LENGTH, resultLength);
  }

  getResultLength(): number {
    return Number(this.getItem(RESULT_LENGTH));
  }

  getCurrentElement(): number {
    const request: RequestObject = JSON.parse(this.getItem(REQUEST));
    if (request.page !== undefined) {
      return request?.page;
    } else {
      return 0;
    }
  }

  /* ---------- REQUEST STORAGE END------------------------ */

  /* -------------------- ROOMPLANNER STORAGE -------------------- */

  /**
   * set the current Room Project
   *
   * @param roomProject
   *
   */
  setRoomProject(roomProject: any) {
    this.setItem(ROOM_PROJECT, roomProject);
  }

  /**
   * get the current Room Project
   */
  getRoomProject(): any | null {
    if (this.getItem(ROOM_PROJECT)) {
      return JSON.parse(this.getItem(ROOM_PROJECT));
    } else {
      return null;
    }
  }

  /*
   * Private wrapper methods to crud localstorage items
   * Methods are private to enforce, that each item has its own crud methods
   * Also these wrappers provide logging
   */

  private setItem(key: string, item: string): void {
    this.logger.trace(`[LocalStorage]: SET ITEM: ${key}`, JSON.parse(item));
    localStorage.setItem(key, item);
  }

  private getItem(key: string): string {
    const item = localStorage.getItem(key) as any;
    try {
      JSON.parse(item);
    } catch (error) {
      this.logger.warn(`[LocalStorage]: UNABLE TO PARSE ITEM FOR: ${key} `);
      return JSON.stringify(item);
    }
    this.logger.trace(`[LocalStorage]: FETCH ITEM: ${key} `, JSON.parse(item));
    return item;
  }

  private removeItem(key: string): void {
    this.logger.trace(`[LocalStorage]: REMOVE ITEM: ${key}`);
    localStorage.removeItem(key);
  }
}
