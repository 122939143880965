import { Component, Input } from '@angular/core';
import { BaseButtonComponent } from '../../Base/base-button.component';

@Component({
  selector: 'ds-basic-link-button',
  templateUrl: './basic-link-button.component.html',
  styleUrls: ['./basic-link-button.component.scss'],
})
export class BasicLinkButtonComponent extends BaseButtonComponent {
  @Input() public href = '';
  @Input() public icon = '';
}
