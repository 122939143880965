/**
 * Explanner Service Documentation
 * Explanner Service Documentation
 *
 * The version of the OpenAPI document: 1.7_landesstelle{20240611@153537}
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient,
    HttpContext,
    HttpEvent,
    HttpHeaders,
    HttpParameterCodec,
    HttpParams,
    HttpResponse
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

// @ts-ignore
import { ChangeLoginRequest } from '../model/changeLoginRequest';
// @ts-ignore
import { GenericMasterFormular } from '../model/genericMasterFormular';
// @ts-ignore
import { InitData } from '../model/initData';
// @ts-ignore
import { ProjectData } from '../model/projectData';
// @ts-ignore
import { ResetOldpublic200Response } from '../model/resetOldpublic200Response';
// @ts-ignore
import { SaveStatus } from '../model/saveStatus';
// @ts-ignore
import { SearchPanelDataResponse } from '../model/searchPanelDataResponse';
// @ts-ignore
import { TupleMenueDataListViewData } from '../model/tupleMenueDataListViewData';
// @ts-ignore
import { TupleStringListTupleStringString } from '../model/tupleStringListTupleStringString';
// @ts-ignore
import { UserProfile } from '../model/userProfile';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root',
})
export class UiControllerService {
  protected basePath = '/service';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration,
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach((elem) => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k)),
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * @param project
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public check(
    project?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<ResetOldpublic200Response>;
  public check(
    project?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<ResetOldpublic200Response>>;
  public check(
    project?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<ResetOldpublic200Response>>;
  public check(
    project?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: '*/*';
      context?: HttpContext;
    },
  ): Observable<any> {
    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (project !== undefined && project !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>project, 'project');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['*/*'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/check`;
    return this.httpClient.request<ResetOldpublic200Response>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param project
   * @param dataType
   * @param cauth
   * @param lang
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAllLabel(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    cauth?: string,
    lang?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<{ [key: string]: string }>;
  public getAllLabel(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    cauth?: string,
    lang?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<{ [key: string]: string }>>;
  public getAllLabel(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    cauth?: string,
    lang?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<{ [key: string]: string }>>;
  public getAllLabel(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    cauth?: string,
    lang?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<any> {
    if (project === null || project === undefined) {
      throw new Error('Required parameter project was null or undefined when calling getAllLabel.');
    }
    if (dataType === null || dataType === undefined) {
      throw new Error('Required parameter dataType was null or undefined when calling getAllLabel.');
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (lang !== undefined && lang !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>lang, 'lang');
    }

    let localVarHeaders = this.defaultHeaders;
    if (cauth !== undefined && cauth !== null) {
      localVarHeaders = localVarHeaders.set('cauth', String(cauth));
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/labels/${this.configuration.encodeParam({
      name: 'project',
      value: project,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/${this.configuration.encodeParam({
      name: 'dataType',
      value: dataType,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: "'ADDRESS' | 'INSTITUTE' | 'OBJECT'",
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<{ [key: string]: string }>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param project
   * @param dataType
   * @param inclDocs
   * @param lang
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getExportMapping(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    inclDocs?: boolean,
    lang?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<Array<TupleStringListTupleStringString>>;
  public getExportMapping(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    inclDocs?: boolean,
    lang?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<Array<TupleStringListTupleStringString>>>;
  public getExportMapping(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    inclDocs?: boolean,
    lang?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<Array<TupleStringListTupleStringString>>>;
  public getExportMapping(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    inclDocs?: boolean,
    lang?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<any> {
    if (project === null || project === undefined) {
      throw new Error('Required parameter project was null or undefined when calling getExportMapping.');
    }
    if (dataType === null || dataType === undefined) {
      throw new Error('Required parameter dataType was null or undefined when calling getExportMapping.');
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (inclDocs !== undefined && inclDocs !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>inclDocs, 'inclDocs');
    }
    if (lang !== undefined && lang !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>lang, 'lang');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json;charset=utf-8'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/export_mapping/${this.configuration.encodeParam({
      name: 'project',
      value: project,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/${this.configuration.encodeParam({
      name: 'dataType',
      value: dataType,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: "'ADDRESS' | 'INSTITUTE' | 'OBJECT'",
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<Array<TupleStringListTupleStringString>>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * @param project
   * @param dataType
   * @param cauth
   * @param deep
   * @param deepNaming
   * @param full
   * @param lang
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @deprecated
   */
  public getFields(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    cauth?: string,
    deep?: boolean,
    deepNaming?: boolean,
    full?: boolean,
    lang?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<{ [key: string]: string }>;
  public getFields(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    cauth?: string,
    deep?: boolean,
    deepNaming?: boolean,
    full?: boolean,
    lang?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<{ [key: string]: string }>>;
  public getFields(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    cauth?: string,
    deep?: boolean,
    deepNaming?: boolean,
    full?: boolean,
    lang?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<{ [key: string]: string }>>;
  public getFields(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    cauth?: string,
    deep?: boolean,
    deepNaming?: boolean,
    full?: boolean,
    lang?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<any> {
    if (project === null || project === undefined) {
      throw new Error('Required parameter project was null or undefined when calling getFields.');
    }
    if (dataType === null || dataType === undefined) {
      throw new Error('Required parameter dataType was null or undefined when calling getFields.');
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (deep !== undefined && deep !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>deep, 'deep');
    }
    if (deepNaming !== undefined && deepNaming !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>deepNaming, 'deepNaming');
    }
    if (full !== undefined && full !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>full, 'full');
    }
    if (lang !== undefined && lang !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>lang, 'lang');
    }

    let localVarHeaders = this.defaultHeaders;
    if (cauth !== undefined && cauth !== null) {
      localVarHeaders = localVarHeaders.set('cauth', String(cauth));
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/fields/${this.configuration.encodeParam({
      name: 'project',
      value: project,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/${this.configuration.encodeParam({
      name: 'dataType',
      value: dataType,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: "'ADDRESS' | 'INSTITUTE' | 'OBJECT'",
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<{ [key: string]: string }>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param project
   * @param dataType
   * @param cauth
   * @param lang
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getFieldsFull(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    cauth?: string,
    lang?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<{ [key: string]: string }>;
  public getFieldsFull(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    cauth?: string,
    lang?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<{ [key: string]: string }>>;
  public getFieldsFull(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    cauth?: string,
    lang?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<{ [key: string]: string }>>;
  public getFieldsFull(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    cauth?: string,
    lang?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<any> {
    if (project === null || project === undefined) {
      throw new Error('Required parameter project was null or undefined when calling getFieldsFull.');
    }
    if (dataType === null || dataType === undefined) {
      throw new Error('Required parameter dataType was null or undefined when calling getFieldsFull.');
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (lang !== undefined && lang !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>lang, 'lang');
    }

    let localVarHeaders = this.defaultHeaders;
    if (cauth !== undefined && cauth !== null) {
      localVarHeaders = localVarHeaders.set('cauth', String(cauth));
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/fieldsFull/${this.configuration.encodeParam({
      name: 'project',
      value: project,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/${this.configuration.encodeParam({
      name: 'dataType',
      value: dataType,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: "'ADDRESS' | 'INSTITUTE' | 'OBJECT'",
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<{ [key: string]: string }>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param project
   * @param dataType
   * @param cauth
   * @param lang
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getFieldsSimple(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    cauth?: string,
    lang?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<{ [key: string]: string }>;
  public getFieldsSimple(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    cauth?: string,
    lang?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<{ [key: string]: string }>>;
  public getFieldsSimple(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    cauth?: string,
    lang?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<{ [key: string]: string }>>;
  public getFieldsSimple(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    cauth?: string,
    lang?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<any> {
    if (project === null || project === undefined) {
      throw new Error('Required parameter project was null or undefined when calling getFieldsSimple.');
    }
    if (dataType === null || dataType === undefined) {
      throw new Error('Required parameter dataType was null or undefined when calling getFieldsSimple.');
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (lang !== undefined && lang !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>lang, 'lang');
    }

    let localVarHeaders = this.defaultHeaders;
    if (cauth !== undefined && cauth !== null) {
      localVarHeaders = localVarHeaders.set('cauth', String(cauth));
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/fieldsSimple/${this.configuration.encodeParam({
      name: 'project',
      value: project,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/${this.configuration.encodeParam({
      name: 'dataType',
      value: dataType,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: "'ADDRESS' | 'INSTITUTE' | 'OBJECT'",
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<{ [key: string]: string }>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param project
   * @param dataType
   * @param cauth
   * @param lang
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getFormular(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    cauth?: string,
    lang?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<GenericMasterFormular>;
  public getFormular(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    cauth?: string,
    lang?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<GenericMasterFormular>>;
  public getFormular(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    cauth?: string,
    lang?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<GenericMasterFormular>>;
  public getFormular(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    cauth?: string,
    lang?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<any> {
    if (project === null || project === undefined) {
      throw new Error('Required parameter project was null or undefined when calling getFormular.');
    }
    if (dataType === null || dataType === undefined) {
      throw new Error('Required parameter dataType was null or undefined when calling getFormular.');
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (lang !== undefined && lang !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>lang, 'lang');
    }

    let localVarHeaders = this.defaultHeaders;
    if (cauth !== undefined && cauth !== null) {
      localVarHeaders = localVarHeaders.set('cauth', String(cauth));
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/form/${this.configuration.encodeParam({
      name: 'project',
      value: project,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/${this.configuration.encodeParam({
      name: 'dataType',
      value: dataType,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: "'ADDRESS' | 'INSTITUTE' | 'OBJECT'",
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<GenericMasterFormular>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param cauth
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getInit(
    cauth?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<InitData>;
  public getInit(
    cauth?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<InitData>>;
  public getInit(
    cauth?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<InitData>>;
  public getInit(
    cauth?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<any> {
    let localVarHeaders = this.defaultHeaders;
    if (cauth !== undefined && cauth !== null) {
      localVarHeaders = localVarHeaders.set('cauth', String(cauth));
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/init`;
    return this.httpClient.request<InitData>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param project
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getMenue(
    project: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<Array<TupleMenueDataListViewData>>;
  public getMenue(
    project: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<Array<TupleMenueDataListViewData>>>;
  public getMenue(
    project: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<Array<TupleMenueDataListViewData>>>;
  public getMenue(
    project: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<any> {
    if (project === null || project === undefined) {
      throw new Error('Required parameter project was null or undefined when calling getMenue.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/menue/${this.configuration.encodeParam({
      name: 'project',
      value: project,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<Array<TupleMenueDataListViewData>>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * @param project
   * @param dataType
   * @param cauth
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProfile(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    cauth?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<UserProfile>;
  public getProfile(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    cauth?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<UserProfile>>;
  public getProfile(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    cauth?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<UserProfile>>;
  public getProfile(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    cauth?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<any> {
    if (project === null || project === undefined) {
      throw new Error('Required parameter project was null or undefined when calling getProfile.');
    }
    if (dataType === null || dataType === undefined) {
      throw new Error('Required parameter dataType was null or undefined when calling getProfile.');
    }

    let localVarHeaders = this.defaultHeaders;
    if (cauth !== undefined && cauth !== null) {
      localVarHeaders = localVarHeaders.set('cauth', String(cauth));
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json;charset=utf-8'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/profile/${this.configuration.encodeParam({
      name: 'project',
      value: project,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/${this.configuration.encodeParam({
      name: 'dataType',
      value: dataType,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: "'ADDRESS' | 'INSTITUTE' | 'OBJECT'",
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<UserProfile>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param cauth
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @deprecated
   */
  public getProjects(
    cauth?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<Array<ProjectData>>;
  public getProjects(
    cauth?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<Array<ProjectData>>>;
  public getProjects(
    cauth?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<Array<ProjectData>>>;
  public getProjects(
    cauth?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<any> {
    let localVarHeaders = this.defaultHeaders;
    if (cauth !== undefined && cauth !== null) {
      localVarHeaders = localVarHeaders.set('cauth', String(cauth));
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/projects`;
    return this.httpClient.request<Array<ProjectData>>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Get project of type roomplanner (if available) for given explanner project
   * @param project
   * @param cauth
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRoomProject(
    project: string,
    cauth?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<ProjectData>;
  public getRoomProject(
    project: string,
    cauth?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<ProjectData>>;
  public getRoomProject(
    project: string,
    cauth?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<ProjectData>>;
  public getRoomProject(
    project: string,
    cauth?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<any> {
    if (project === null || project === undefined) {
      throw new Error('Required parameter project was null or undefined when calling getRoomProject.');
    }

    let localVarHeaders = this.defaultHeaders;
    if (cauth !== undefined && cauth !== null) {
      localVarHeaders = localVarHeaders.set('cauth', String(cauth));
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/roomProject/${this.configuration.encodeParam({
      name: 'project',
      value: project,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<ProjectData>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param project
   * @param dataType
   * @param cauth
   * @param lang
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSearchPanelData(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    cauth?: string,
    lang?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<SearchPanelDataResponse>;
  public getSearchPanelData(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    cauth?: string,
    lang?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<SearchPanelDataResponse>>;
  public getSearchPanelData(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    cauth?: string,
    lang?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<SearchPanelDataResponse>>;
  public getSearchPanelData(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    cauth?: string,
    lang?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<any> {
    if (project === null || project === undefined) {
      throw new Error('Required parameter project was null or undefined when calling getSearchPanelData.');
    }
    if (dataType === null || dataType === undefined) {
      throw new Error('Required parameter dataType was null or undefined when calling getSearchPanelData.');
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (lang !== undefined && lang !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>lang, 'lang');
    }

    let localVarHeaders = this.defaultHeaders;
    if (cauth !== undefined && cauth !== null) {
      localVarHeaders = localVarHeaders.set('cauth', String(cauth));
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/searchPanel/${this.configuration.encodeParam({
      name: 'project',
      value: project,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/${this.configuration.encodeParam({
      name: 'dataType',
      value: dataType,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: "'ADDRESS' | 'INSTITUTE' | 'OBJECT'",
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<SearchPanelDataResponse>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @deprecated
   */
  public getTiles(
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<{ [key: string]: string }>;
  public getTiles(
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<{ [key: string]: string }>>;
  public getTiles(
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<{ [key: string]: string }>>;
  public getTiles(
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<any> {
    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/tiles`;
    return this.httpClient.request<{ [key: string]: string }>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param changeLoginRequest
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setPass(
    changeLoginRequest: ChangeLoginRequest,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<SaveStatus>;
  public setPass(
    changeLoginRequest: ChangeLoginRequest,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<SaveStatus>>;
  public setPass(
    changeLoginRequest: ChangeLoginRequest,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<SaveStatus>>;
  public setPass(
    changeLoginRequest: ChangeLoginRequest,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<any> {
    if (changeLoginRequest === null || changeLoginRequest === undefined) {
      throw new Error('Required parameter changeLoginRequest was null or undefined when calling setPass.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json;charset=utf-8'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/user/pass`;
    return this.httpClient.request<SaveStatus>('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: changeLoginRequest,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param project
   * @param dataType
   * @param userProfile
   * @param cauth
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setProfile(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    userProfile: UserProfile,
    cauth?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<SaveStatus>;
  public setProfile(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    userProfile: UserProfile,
    cauth?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<SaveStatus>>;
  public setProfile(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    userProfile: UserProfile,
    cauth?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<SaveStatus>>;
  public setProfile(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    userProfile: UserProfile,
    cauth?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<any> {
    if (project === null || project === undefined) {
      throw new Error('Required parameter project was null or undefined when calling setProfile.');
    }
    if (dataType === null || dataType === undefined) {
      throw new Error('Required parameter dataType was null or undefined when calling setProfile.');
    }
    if (userProfile === null || userProfile === undefined) {
      throw new Error('Required parameter userProfile was null or undefined when calling setProfile.');
    }

    let localVarHeaders = this.defaultHeaders;
    if (cauth !== undefined && cauth !== null) {
      localVarHeaders = localVarHeaders.set('cauth', String(cauth));
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json;charset=utf-8'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/profile/${this.configuration.encodeParam({
      name: 'project',
      value: project,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/${this.configuration.encodeParam({
      name: 'dataType',
      value: dataType,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: "'ADDRESS' | 'INSTITUTE' | 'OBJECT'",
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<SaveStatus>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: userProfile,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param project
   * @param dataType
   * @param userProfile
   * @param cauth
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateProfile(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    userProfile: UserProfile,
    cauth?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<SaveStatus>;
  public updateProfile(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    userProfile: UserProfile,
    cauth?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<SaveStatus>>;
  public updateProfile(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    userProfile: UserProfile,
    cauth?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<SaveStatus>>;
  public updateProfile(
    project: string,
    dataType: 'ADDRESS' | 'INSTITUTE' | 'OBJECT',
    userProfile: UserProfile,
    cauth?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json;charset&#x3D;utf-8';
      context?: HttpContext;
    },
  ): Observable<any> {
    if (project === null || project === undefined) {
      throw new Error('Required parameter project was null or undefined when calling updateProfile.');
    }
    if (dataType === null || dataType === undefined) {
      throw new Error('Required parameter dataType was null or undefined when calling updateProfile.');
    }
    if (userProfile === null || userProfile === undefined) {
      throw new Error('Required parameter userProfile was null or undefined when calling updateProfile.');
    }

    let localVarHeaders = this.defaultHeaders;
    if (cauth !== undefined && cauth !== null) {
      localVarHeaders = localVarHeaders.set('cauth', String(cauth));
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json;charset=utf-8'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/profile/${this.configuration.encodeParam({
      name: 'project',
      value: project,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/${this.configuration.encodeParam({
      name: 'dataType',
      value: dataType,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: "'ADDRESS' | 'INSTITUTE' | 'OBJECT'",
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<SaveStatus>('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: userProfile,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
