<!-- eslint-disable @angular-eslint/template/no-call-expression -->

<div>
  <div *ngIf="!isDialog" class="flex-row filter-label-container">
    <span class="mat-body-strong">{{ 'LABELS.filter' | translate }}</span>
    <div *ngIf="checkFacetFilter()" class="filter">
      <ds-basic-icon-badge-button
        [label]="'BUTTONS.reset' | translate"
        icon="filter_alt_off"
        (click)="clearFacetFilter()"></ds-basic-icon-badge-button>
    </div>
  </div>
  <mat-accordion multi>
    <!-- Hierarchy filters -->
    <ng-container *ngFor="let hierarchyFilter of quickHierarchyFacets | keyvalue">
      <mat-expansion-panel
        #expansionPanel
        (keydown.code.space)="expansionPanel.toggle()"
        role="button"
        hideToggle
        *ngIf="
          resultsLength ||
          sessionStorageService.getSelectedChecklistData(this.dataType, hierarchyFilter.key)?.length > 0
        ">
        <mat-expansion-panel-header
          (keydown.code.enter)="
            expansionPanel.toggle();
            openHierarchyDialog(hierarchyFilter, hierarchyFilter.key, fieldNames[hierarchyFilter.key], resultsLength)
          "
          class="hierarchy-panel-header"
          (click)="
            expansionPanel.toggle();
            openHierarchyDialog(hierarchyFilter, hierarchyFilter.key, fieldNames[hierarchyFilter.key], resultsLength)
          ">
          <mat-panel-title>
            <mat-icon class="tree-icon material-symbols-outlined">account_tree</mat-icon>
            {{ fieldNames[hierarchyFilter.key] }}
          </mat-panel-title>
          <mat-panel-description
            *ngIf="sessionStorageService.getSelectedChecklistData(this.dataType, hierarchyFilter.key)?.length">
            <div
              class="flex-row badge-counter"
              matBadgeSize="small"
              matBadge="{{
                sessionStorageService.getSelectedChecklistData(this.dataType, hierarchyFilter.key)?.length
              }}"></div>
          </mat-panel-description>
        </mat-expansion-panel-header>
      </mat-expansion-panel>
    </ng-container>
    <!-- No Filter Available -->
    <div *ngIf="!resultsLength && !checkFacetFilter()" class="flex-row no-filter-container">
      <span class="mat-body-2">{{ 'LABELS.noDataForFilter' | translate }}</span>
    </div>
    <!-- Date filters -->
    <ng-container *ngFor="let dateRangeFilter of quickMinMaxFacets | keyvalue">
      <mat-expansion-panel
        [togglePosition]="'before'"
        class="flex-col"
        [expanded]="facetPanelShouldBeExpanded(dateRangeFilter.key)"
        (opened)="panelExpandedState[dateRangeFilter.key] = true"
        (closed)="panelExpandedState[dateRangeFilter.key] = false"
        *ngIf="
          (dateRangeFilter && (checkFacetValueInStorage(dateRangeFilter.key) ?? false)) ||
          resultsLength ||
          rangesDate[dateRangeFilter.key].get('start')?.value ||
          rangesDate[dateRangeFilter.key].get('end')?.value
        ">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ fieldNames[dateRangeFilter.key] }}
          </mat-panel-title>
          <mat-panel-description *ngIf="checkFacetValueInStorage(dateRangeFilter.key) ?? false">
            <div class="flex-row badge-counter" matBadgeSize="small" matBadge="1"></div>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="flex-row datapicker-and-checkBox">
          <div class="flex-row datapick-row">
            <mat-form-field color="accent" class="datepicker-formfield" appearance="outline">
              <mat-label class="margin-s">{{ 'PLACEHOLDER.enter_date_range' | translate }}</mat-label>
              <mat-date-range-input [formGroup]="rangesDate[dateRangeFilter.key]" [rangePicker]="picker">
                <input
                  matStartDate
                  formControlName="start"
                  (keydown.enter)="
                    dateRangeChange(
                      rangesDate[dateRangeFilter.key].get('start')?.value,
                      rangesDate[dateRangeFilter.key].get('end')?.value,
                      dateRangeFilter.key
                    )
                  "
                  placeholder="{{ 'PLACEHOLDER.start_date' | translate }}" />
                <input
                  matEndDate
                  formControlName="end"
                  (keydown.enter)="
                    dateRangeChange(
                      rangesDate[dateRangeFilter.key].get('start')?.value,
                      rangesDate[dateRangeFilter.key].get('end')?.value,
                      dateRangeFilter.key
                    )
                  "
                  placeholder="{{ 'PLACEHOLDER.end_date' | translate }}" />
              </mat-date-range-input>
              <mat-hint class="flex-row">TT.MM.JJJJ - TT.MM.JJJJ</mat-hint>
              <mat-datepicker-toggle
                matSuffix
                *ngIf="
                  rangesDate[dateRangeFilter.key].dirty ||
                  rangesDate[dateRangeFilter.key].get('start')?.value ||
                  rangesDate[dateRangeFilter.key].get('end')?.value
                "
                (click)="clearDate(dateRangeFilter.key)">
                <mat-icon matDatepickerToggleIcon>clear</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker-toggle
                *ngIf="!(breakpointsService.isMediumScreen && rangesDate[dateRangeFilter.key].dirty)"
                matSuffix
                [for]="picker" />
              <mat-date-range-picker #picker>
                <mat-date-range-picker-actions>
                  <ds-basic-button color="accent" matDateRangePickerCancel label="{{ 'BUTTONS.cancel' | translate }}" />
                  <ds-primary-button
                    mat-raised-button
                    matDateRangePickerApply
                    label=" {{ 'BUTTONS.save' | translate }}"
                    (click)="
                      dateRangeChange(
                        rangesDate[dateRangeFilter.key].get('start')?.value,
                        rangesDate[dateRangeFilter.key].get('end')?.value,
                        dateRangeFilter.key
                      )
                    "
                    color="accent">
                    Apply
                  </ds-primary-button>
                </mat-date-range-picker-actions>
              </mat-date-range-picker>
            </mat-form-field>
          </div>
        </div>
      </mat-expansion-panel>
    </ng-container>
    <!-- Other filters -->
    <ng-container *ngFor="let facetCategory of quickFilterFacets | keyvalue: unsorted; index as categoryIndex">
      <mat-expansion-panel
        [togglePosition]="'before'"
        [expanded]="facetPanelShouldBeExpanded(facetCategory.key)"
        (opened)="panelExpandedState[facetCategory.key] = true"
        (closed)="panelExpandedState[facetCategory.key] = false"
        *ngIf="
          resultsLength ||
          getObjectLength(facetCategory.value | keyvalue) ||
          isQuickFilterReqLength(facetCategory) ||
          (checkDimensionType(facetCategory.key) && (checkFacetValueInStorage(facetCategory.key) ?? false))
        "
        class="flex-col">
        <mat-expansion-panel-header
          *ngIf="
            getObjectLength(facetCategory.value | keyvalue) ||
            isQuickFilterReqLength(facetCategory) > 0 ||
            (checkFacetValueInStorage(facetCategory.key) ?? false)
          ">
          <mat-panel-title>
            {{ fieldNames[facetCategory.key] | translate }}
          </mat-panel-title>
          <mat-panel-description
            *ngIf="isQuickFilterReqLength(facetCategory) > 0 || checkFacetValueInStorage(facetCategory.key)">
            <div
              class="flex-row badge-counter"
              matBadgeSize="small"
              matBadge="{{
                checkFacetValueInStorage(facetCategory.key)
                  ? isQuickFilterReqLength(facetCategory) + 1
                  : isQuickFilterReqLength(facetCategory)
              }}"></div>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="dimension-filter" *ngIf="checkDimensionType(facetCategory.key)">
          <div class="flex-row input-filter">
            <ds-input-filter
              #min
              type="number"
              label="{{ 'LABELS.minimum' | translate }}"
              [value]="checkFacetValueInStorage(facetCategory.key)?.min?.toString() || ''"
              placeholder="{{ 'LABELS.minimum' | translate }}"
              (submitClear)="resetDimension(facetCategory.key, 'min', max.value, min.value)"
              (submitFilter)="getMinMaxValue(facetCategory.key, min.value, max.value)" />
            <span>－</span>
            <ds-input-filter
              #max
              label="{{ 'LABELS.maximum' | translate }}"
              [value]="checkFacetValueInStorage(facetCategory.key)?.max?.toString() || ''"
              type="number"
              placeholder="{{ 'LABELS.maximum' | translate }}"
              (submitClear)="resetDimension(facetCategory.key, 'max', min.value, max.value)"
              (submitFilter)="getMinMaxValue(facetCategory.key, min.value, max.value)" />
          </div>
        </div>
        <div>
          <mat-selection-list *ngIf="resultsLength > 0 && !isLoadingResults; else whenNoResult">
            <ng-container *ngFor="let facets of facetCategory.value | keyvalue; index as facetIndex">
              <mat-list-option
                [selected]="isFacetSelected(facetCategory.key, facets.key)"
                (click)="quickFilterByFacet(facetCategory.key, facets.key)"
                checkboxPosition="before"
                *ngIf="
                  !!facets?.key &&
                  !(
                    (getType(facetCategory.key) === FieldTypes.TRAFFICLIGHT || isAppointmentList(facetCategory.key)) &&
                    facets?.key === '! {  }'
                  )
                ">
                <div matListItemTitle class="filter-item-row">
                  <div
                    *ngIf="
                      !(
                        getType(facetCategory.key) === FieldTypes.TRAFFICLIGHT || isAppointmentList(facetCategory.key)
                      ) || getQuickFilterFacets(facets.key) === 'N.A.'
                    "
                    class="facet-value">
                    {{ getQuickFilterFacets(facets.key) }}
                  </div>
                  <!-- Appointment List  -->
                  <div class="facet-value" *ngIf="isAppointmentList(facetCategory.key) && facets?.key !== '! {  }'">
                    <ds-chips
                      *ngIf="facets?.key !== '! {  }'"
                      [color]="facets?.key === 'mixed' ? 'gray' : getColor(facets?.key)"
                      label="{{ getLabelFromColor(facets.key) | translate }}" />
                  </div>
                  <!-- Traffic -->
                  <div class="facet-value" *ngIf="getType(facetCategory.key) === FieldTypes.TRAFFICLIGHT">
                    <ds-chips
                      *ngIf="facets?.key !== '! {  }'"
                      [color]="getColor(facets?.key)"
                      label="{{ getLabelFromColor(facets.key) | translate }}" />
                  </div>
                  <span *ngIf="!isAppointmentList(facetCategory.key)" class="filter-count-indicator mat-caption">
                    ({{ facets?.value }})
                  </span>
                </div>
              </mat-list-option>
              <!-- Appointment List whenNoResult -->
              <div
                *ngIf="isAppointmentList(facetCategory.key) && facets?.key === '! {  }'"
                class="appointment-noResultValue">
                {{ 'LABELS.APPOINTMENT_LIST' | translate }}
              </div>
            </ng-container>
          </mat-selection-list>
          <ng-template #whenNoResult>
            <mat-selection-list>
              <mat-list-option
                [selected]="isFacetSelected(facetCategory.key, value)"
                (click)="quickFilterByFacet(facetCategory.key, value)"
                checkboxPosition="before"
                *ngFor="let value of sessionStorageService.getQuickFilterReq(dataType)[facetCategory.key]">
                <div matListItemTitle class="filter-item-row">
                  <div
                    *ngIf="
                      getType(facetCategory.key) !== FieldTypes.TRAFFICLIGHT ||
                      getQuickFilterFacets(facetCategory.key) === 'N.A.'
                    "
                    class="facet-value">
                    {{ getQuickFilterFacets(value) }}
                  </div>
                  <div class="facet-value" *ngIf="getType(facetCategory.key) === FieldTypes.TRAFFICLIGHT">
                    <ng-container *ngFor="let color of colors">
                      <ds-chips [color]="color" label="{{ 'LABELS.Trafficlight' + color | translate }}" />
                    </ng-container>
                  </div>
                  <span class="filter-count-indicator mat-caption">(0)</span>
                </div>
              </mat-list-option>
            </mat-selection-list>
          </ng-template>
        </div>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</div>
