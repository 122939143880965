/**
 * Explanner Service Documentation
 * Explanner Service Documentation
 *
 * The version of the OpenAPI document: 1.7_landesstelle{20240611@153537}
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EnhancedCriteria } from './enhancedCriteria';
import { RequestObject } from './requestObject';

export interface MenueData {
  name?: string;
  title?: string;
  dataType?: MenueData.DataTypeEnum;
  /**
   * Was planned to be used for different views
   */
  defaultView?: string;
  writePermission?: boolean;
  deletePermission?: boolean;
  createPermission?: boolean;
  /**
   * Used to fix hidden filter
   */
  criteria?: Array<EnhancedCriteria>;
  blacklistedFields?: Set<string>;
  whitelistedFields?: Set<string>;
  defaultRequest?: RequestObject;
}

export namespace MenueData {
  export type DataTypeEnum = 'ADDRESS' | 'INSTITUTE' | 'OBJECT';
  export const DataTypeEnum = {
    Address: 'ADDRESS' as DataTypeEnum,
    Institute: 'INSTITUTE' as DataTypeEnum,
    Object: 'OBJECT' as DataTypeEnum,
  };
}
