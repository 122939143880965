import { Component, Input } from '@angular/core';

@Component({
  selector: 'ds-link-button',
  templateUrl: './link-button.component.html',
  styleUrls: ['./link-button.component.scss'],
})
export class LinkButtonComponent {
  @Input() link = '';
  @Input() target: '_self' | '_blank' | '_parent' | '_top' = '_self';
  @Input() text = '';
  @Input() icon = '';
  @Input() disabled = false;
}
