<div class="dialog-container flex-col">
  <ds-dialog-header [title]="this.selectedObjects.length + ' ' + ('LABELS.edit' + dataType | translate)" (clickButton)="onModalClose()" >
  </ds-dialog-header>
  @if((formManager.loading$ | async) === false){
  <div class="dialog-content">
    @if(!authService.hasWritePermissionsForDatatype(dataType)){
        <div
          class="flex-row no-write-permission-label maring-s">
          <label>{{ 'LABELS.noWritePermissionLabel' + dataType | translate }}</label>
        </div>
    }
        <exp-form-tabs
          [dataType]="dataType"
          [selectedObjects]="this.selectedObjects" />
  </div>
  }
  @if(formManager.loading$ | async){
  <div class="dialog-progress-bar-wrapper">
  <mat-progress-bar mode="indeterminate" />
  <span class="flex-col batch-edit-dialog-progress-bar-info"> {{ 'MESSAGE.loadingObjectData' | translate }}</span>
</div>
  }
  <ds-horizontal-divider />
  <ds-dialog-actions
  closeBtnLabel="{{ 'BUTTONS.cancel' | translate }}"
  submitBtnLabel="{{ 'BUTTONS.continue' | translate }}"
  (clickButton)="openSummaryDialog()"
  (closeBtnClicked)="onModalClose()"
  [isSubmitDisabled]="!isFormTouched || !authService.hasWritePermissionsForDatatype(dataType)"
  >
  </ds-dialog-actions>

</div>
