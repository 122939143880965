/**
 * Explanner Service Documentation
 * Explanner Service Documentation
 *
 * The version of the OpenAPI document: 1.7_landesstelle{20240611@153537}
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient,
    HttpContext,
    HttpEvent,
    HttpHeaders,
    HttpParameterCodec,
    HttpParams,
    HttpResponse
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

// @ts-ignore
import { Resize200Response } from '../model/resize200Response';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root',
})
export class ImageResizeControllerService {
  protected basePath = '/service';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration,
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach((elem) => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k)),
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * @param maxDimension
   * @param srcImage
   * @param mimeType
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public resize(
    maxDimension: number,
    srcImage: Blob,
    mimeType?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'multipart/form-data';
      context?: HttpContext;
    },
  ): Observable<Resize200Response>;
  public resize(
    maxDimension: number,
    srcImage: Blob,
    mimeType?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'multipart/form-data';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<Resize200Response>>;
  public resize(
    maxDimension: number,
    srcImage: Blob,
    mimeType?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'multipart/form-data';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<Resize200Response>>;
  public resize(
    maxDimension: number,
    srcImage: Blob,
    mimeType?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'multipart/form-data';
      context?: HttpContext;
    },
  ): Observable<any> {
    if (maxDimension === null || maxDimension === undefined) {
      throw new Error('Required parameter maxDimension was null or undefined when calling resize.');
    }
    if (srcImage === null || srcImage === undefined) {
      throw new Error('Required parameter srcImage was null or undefined when calling resize.');
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (maxDimension !== undefined && maxDimension !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>maxDimension, 'maxDimension');
    }
    if (mimeType !== undefined && mimeType !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>mimeType, 'mimeType');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['multipart/form-data'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['multipart/form-data'];

    const canConsumeForm = this.canConsumeForm(consumes);

    let localVarFormParams: { append(param: string, value: any): any };
    let localVarUseForm = false;
    let localVarConvertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    localVarUseForm = canConsumeForm;
    if (localVarUseForm) {
      localVarFormParams = new FormData();
    } else {
      localVarFormParams = new HttpParams({ encoder: this.encoder });
    }

    if (srcImage !== undefined) {
      localVarFormParams = (localVarFormParams.append('srcImage', <any>srcImage) as any) || localVarFormParams;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/resize`;
    return this.httpClient.request<Resize200Response>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param srcImage
   * @param maxDimension
   * @param mimeType
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public resizeFromLocalToImageResponse(
    srcImage: string,
    maxDimension: number,
    mimeType?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'multipart/form-data';
      context?: HttpContext;
    },
  ): Observable<Resize200Response>;
  public resizeFromLocalToImageResponse(
    srcImage: string,
    maxDimension: number,
    mimeType?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'multipart/form-data';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<Resize200Response>>;
  public resizeFromLocalToImageResponse(
    srcImage: string,
    maxDimension: number,
    mimeType?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'multipart/form-data';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<Resize200Response>>;
  public resizeFromLocalToImageResponse(
    srcImage: string,
    maxDimension: number,
    mimeType?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'multipart/form-data';
      context?: HttpContext;
    },
  ): Observable<any> {
    if (srcImage === null || srcImage === undefined) {
      throw new Error('Required parameter srcImage was null or undefined when calling resizeFromLocalToImageResponse.');
    }
    if (maxDimension === null || maxDimension === undefined) {
      throw new Error(
        'Required parameter maxDimension was null or undefined when calling resizeFromLocalToImageResponse.',
      );
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (srcImage !== undefined && srcImage !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>srcImage, 'srcImage');
    }
    if (maxDimension !== undefined && maxDimension !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>maxDimension, 'maxDimension');
    }
    if (mimeType !== undefined && mimeType !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>mimeType, 'mimeType');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['multipart/form-data'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/resizeFromLocalToImageResponse`;
    return this.httpClient.request<Resize200Response>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param srcImage
   * @param maxDimension
   * @param targetDir
   * @param mimeType
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public resizeLocally(
    srcImage: string,
    maxDimension: number,
    targetDir: string,
    mimeType?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<Resize200Response>;
  public resizeLocally(
    srcImage: string,
    maxDimension: number,
    targetDir: string,
    mimeType?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<Resize200Response>>;
  public resizeLocally(
    srcImage: string,
    maxDimension: number,
    targetDir: string,
    mimeType?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<Resize200Response>>;
  public resizeLocally(
    srcImage: string,
    maxDimension: number,
    targetDir: string,
    mimeType?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<any> {
    if (srcImage === null || srcImage === undefined) {
      throw new Error('Required parameter srcImage was null or undefined when calling resizeLocally.');
    }
    if (maxDimension === null || maxDimension === undefined) {
      throw new Error('Required parameter maxDimension was null or undefined when calling resizeLocally.');
    }
    if (targetDir === null || targetDir === undefined) {
      throw new Error('Required parameter targetDir was null or undefined when calling resizeLocally.');
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (srcImage !== undefined && srcImage !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>srcImage, 'srcImage');
    }
    if (maxDimension !== undefined && maxDimension !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>maxDimension, 'maxDimension');
    }
    if (targetDir !== undefined && targetDir !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>targetDir, 'targetDir');
    }
    if (mimeType !== undefined && mimeType !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>mimeType, 'mimeType');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/resizeLocally`;
    return this.httpClient.request<Resize200Response>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param maxDimension
   * @param targetDir
   * @param srcImage
   * @param mimeType
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public resizeToFile(
    maxDimension: number,
    targetDir: string,
    srcImage: Blob,
    mimeType?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<Resize200Response>;
  public resizeToFile(
    maxDimension: number,
    targetDir: string,
    srcImage: Blob,
    mimeType?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<Resize200Response>>;
  public resizeToFile(
    maxDimension: number,
    targetDir: string,
    srcImage: Blob,
    mimeType?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<Resize200Response>>;
  public resizeToFile(
    maxDimension: number,
    targetDir: string,
    srcImage: Blob,
    mimeType?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<any> {
    if (maxDimension === null || maxDimension === undefined) {
      throw new Error('Required parameter maxDimension was null or undefined when calling resizeToFile.');
    }
    if (targetDir === null || targetDir === undefined) {
      throw new Error('Required parameter targetDir was null or undefined when calling resizeToFile.');
    }
    if (srcImage === null || srcImage === undefined) {
      throw new Error('Required parameter srcImage was null or undefined when calling resizeToFile.');
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (maxDimension !== undefined && maxDimension !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>maxDimension, 'maxDimension');
    }
    if (targetDir !== undefined && targetDir !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>targetDir, 'targetDir');
    }
    if (mimeType !== undefined && mimeType !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>mimeType, 'mimeType');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['multipart/form-data'];

    const canConsumeForm = this.canConsumeForm(consumes);

    let localVarFormParams: { append(param: string, value: any): any };
    let localVarUseForm = false;
    let localVarConvertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    localVarUseForm = canConsumeForm;
    if (localVarUseForm) {
      localVarFormParams = new FormData();
    } else {
      localVarFormParams = new HttpParams({ encoder: this.encoder });
    }

    if (srcImage !== undefined) {
      localVarFormParams = (localVarFormParams.append('srcImage', <any>srcImage) as any) || localVarFormParams;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/resizeToFile`;
    return this.httpClient.request<Resize200Response>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param imgUrl
   * @param maxDimension
   * @param targetDir
   * @param mimeType
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public resizeUrlToFile(
    imgUrl: string,
    maxDimension: number,
    targetDir: string,
    mimeType?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<Resize200Response>;
  public resizeUrlToFile(
    imgUrl: string,
    maxDimension: number,
    targetDir: string,
    mimeType?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<Resize200Response>>;
  public resizeUrlToFile(
    imgUrl: string,
    maxDimension: number,
    targetDir: string,
    mimeType?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<Resize200Response>>;
  public resizeUrlToFile(
    imgUrl: string,
    maxDimension: number,
    targetDir: string,
    mimeType?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
    },
  ): Observable<any> {
    if (imgUrl === null || imgUrl === undefined) {
      throw new Error('Required parameter imgUrl was null or undefined when calling resizeUrlToFile.');
    }
    if (maxDimension === null || maxDimension === undefined) {
      throw new Error('Required parameter maxDimension was null or undefined when calling resizeUrlToFile.');
    }
    if (targetDir === null || targetDir === undefined) {
      throw new Error('Required parameter targetDir was null or undefined when calling resizeUrlToFile.');
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (imgUrl !== undefined && imgUrl !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>imgUrl, 'imgUrl');
    }
    if (maxDimension !== undefined && maxDimension !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>maxDimension, 'maxDimension');
    }
    if (targetDir !== undefined && targetDir !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>targetDir, 'targetDir');
    }
    if (mimeType !== undefined && mimeType !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>mimeType, 'mimeType');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/resizeUrlToFile`;
    return this.httpClient.request<Resize200Response>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param imgUrl
   * @param maxDimension
   * @param mimeType
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public resizeUrlToImageResponse(
    imgUrl: string,
    maxDimension: number,
    mimeType?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'multipart/form-data';
      context?: HttpContext;
    },
  ): Observable<Resize200Response>;
  public resizeUrlToImageResponse(
    imgUrl: string,
    maxDimension: number,
    mimeType?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'multipart/form-data';
      context?: HttpContext;
    },
  ): Observable<HttpResponse<Resize200Response>>;
  public resizeUrlToImageResponse(
    imgUrl: string,
    maxDimension: number,
    mimeType?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'multipart/form-data';
      context?: HttpContext;
    },
  ): Observable<HttpEvent<Resize200Response>>;
  public resizeUrlToImageResponse(
    imgUrl: string,
    maxDimension: number,
    mimeType?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'multipart/form-data';
      context?: HttpContext;
    },
  ): Observable<any> {
    if (imgUrl === null || imgUrl === undefined) {
      throw new Error('Required parameter imgUrl was null or undefined when calling resizeUrlToImageResponse.');
    }
    if (maxDimension === null || maxDimension === undefined) {
      throw new Error('Required parameter maxDimension was null or undefined when calling resizeUrlToImageResponse.');
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (imgUrl !== undefined && imgUrl !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>imgUrl, 'imgUrl');
    }
    if (maxDimension !== undefined && maxDimension !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>maxDimension, 'maxDimension');
    }
    if (mimeType !== undefined && mimeType !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>mimeType, 'mimeType');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['multipart/form-data'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/resizeUrlToImageResponse`;
    return this.httpClient.request<Resize200Response>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
