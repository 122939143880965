<div class="form-tabs-wrapper">
  <mat-tab-group
    [selectedIndex]="getActiveTab()"
    (selectedTabChange)="onTabChange($event)"
    [ariaLabel]="'A11Y.LABELS.formTabs' | translate"
    animationDuration="0ms"
    color="accent">
    @for (group of formArray.controls; track $index) {
      <mat-tab [label]="getTabTitle($index)" [class.error-tab]="group.invalid">
        <div [ngClass]="objectId ? 'flex-row generic-form-container-detailView' : 'flex-row generic-form-container'">
          <exp-generic-form
            #gForm
            [formDefinition]="forms[$index]"
            [formGroup]="getFormGroup($index)"
            [object]="workingObject"
            [fieldNames]="fieldNames"
            [dataType]="dataType"
            [optionPathArray]="optionPathArray"
            class="form-tabs-generic-form" />
        </div>
      </mat-tab>
    }
  </mat-tab-group>
</div>
