import { AfterViewChecked, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ChatService } from '../../../app/services/chat.service';
import { SessionStorageService } from '../../../app/services/storage/session-storage.service';
import { Message } from '../../model/Object/Message';

@Component({
  selector: 'exp-chat-dialog',
  templateUrl: './ai-dialog.component.html',
  styleUrls: ['./ai-dialog.component.scss'],
})
export class AiDialogComponent implements OnInit, AfterViewChecked {
  @ViewChild('chatHistory') private messageContainer!: ElementRef;
  projectName: string;
  fieldnames: any = [];
  filterState: any = [];
  public newMessage = '';
  public messages: Message[] = [];
  public chatID = '';
  public welcomeMessage!: string;
  public scrollFlag = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AiDialogComponent>,
    private sessionStorageService: SessionStorageService,
    private cookieService: CookieService,
    private chatService: ChatService,
    private translateService: TranslateService
  ) {
    this.fieldnames = data.fieldnames;
    this.filterState = data.filterState;
    this.projectName = data.project.project;
    const { messages, chatID } = this.sessionStorageService.getChatMessages();
    this.messages = messages;
    this.chatID = chatID;
  }

  public ngOnInit(): void {
    this.welcomeMessage = this.translateService.instant('CHATDIALOG.welcome_message');
    if (this.chatID === '') {
      const uuid = self.crypto.randomUUID();
      this.chatID = uuid;
      this.sessionStorageService.saveChatMessages(this.messages, this.chatID);
    }
    const cauth = this.cookieService.get('cauth');

    this.chatService.initializeChat(this.projectName, cauth).subscribe(
      () => {
        // no handling needed for now
      },
      (error) => {
        console.error('Error initializing chat:', error);
      }
    );
    if (this.messages.length === 0) {
      this.messages.push({ role: 'assistant', content: this.welcomeMessage });
    }
    this.scrollFlag = true;
  }

  ngAfterViewChecked(): void {
    if (this.scrollFlag) {
      this.scrollToBottom();
      this.scrollFlag = false;
    }
  }

  public updateNewMessage(event: Event): void {
    if (event instanceof InputEvent && event.target instanceof HTMLInputElement) {
      this.newMessage = event.target.value;
    }
  }

  public onSubmit(): void {
    this.sendMessage();
  }

  public sendMessage(): void {
    if (!this.newMessage || this.newMessage.trim() === '') {
      this.newMessage = '';
      return;
    }

    this.messages.push({ role: 'user', content: this.newMessage });
    this.scrollFlag = true;
    this.sessionStorageService.saveChatMessages(this.messages, this.chatID);
    this.newMessage = '';

    const payload = {
      messages: this.messages,
      fieldnames: this.fieldnames,
      filterState: this.filterState,
      currentSearch: this.data.currentSearch,
      projectName: this.data.project.project,
      chatID: this.chatID,
    };

    this.chatService.sendMessageToServer(payload).subscribe(
      (response) => {
        const jsonMessages = response.split('\n').filter(Boolean);
        for (const jsonString of jsonMessages) {
          const parsedMessage = JSON.parse(jsonString);
          if (parsedMessage.streaming) {
            if (this.messages.length && this.messages[this.messages.length - 1].role === 'user') {
              this.messages.push({
                role: 'assistant',
                content: '',
              });
              this.scrollFlag = true;
            }
            if (parsedMessage.content && this.messages.length) {
              this.messages[this.messages.length - 1].content += parsedMessage.content;
              this.sessionStorageService.saveChatMessages(this.messages, this.chatID);
            }
          } else if (parsedMessage.function_call) {
            this.messages.push(parsedMessage);
            this.scrollFlag = true;
            this.sessionStorageService.saveChatMessages(this.messages, this.chatID);
          }
        }
      },
      (error) => {
        console.error('Error:', error);
      }
    );
  }

  public onClickSearch(message: Message): void {
    // closes the mat-dialog and passes the search string to the parent component
    const searchValue = this.extractSuchanfrage(message);
    if (searchValue) {
      this.dialogRef.close(searchValue);
    }
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public resetMessages() {
    this.messages = [];
    this.messages.push({ role: 'assistant', content: this.welcomeMessage });
    const uuid = self.crypto.randomUUID();
    this.chatID = uuid;
    this.sessionStorageService.saveChatMessages(this.messages, this.chatID);
  }

  public extractSuchanfrage(args: Message): string {
    try {
      if (!args.function_call) {
        console.error('Error parsing function call args:');
        return '';
      }
      return args.function_call.arguments.slice(16, -2); // workaround because of the way the function call is parsed
    } catch (e) {
      console.error('Error parsing function call args:', e);
      return '';
    }
  }

  private scrollToBottom(): void {
    try {
      this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight;
    } catch (err) {
      console.error('Error scrolling to bottom:', err);
    }
  }
}
