import { AfterViewInit, Component, EventEmitter, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { RequestObject, ResponseObjectConcreteData, ScheduleCriteria } from '@frontends/exp-apiclient';
import { NGXLogger } from 'ngx-logger';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { DataType } from '../../../app/model/types/DataTypes';
import { ApiClientService } from '../../../app/services/api-client.service';

import { AuthService } from '@frontends/commons';
import { LocalStorageService } from '../../../app/services/storage/local-storage.service';
import { SessionStorageService } from '../../../app/services/storage/session-storage.service';
import { SearchManipulatorService } from '../../../app/services/utils/search-manipulator.service';
import { OverrideScheduleCriteria } from '../../model/Object/override-schedule-criteria';
import { StampDateRange } from '../../model/Object/stampDateRange';
import { OverviewTableComponent } from '../../shared/components/overview-table/overview-table.component';
import { AddDialogComponent } from '../add-dialog/add-dialog.component';

@Component({
  selector: 'exp-lookup-dialog',
  templateUrl: './lookup-dialog.component.html',
  styleUrls: ['./lookup-dialog.component.scss'],
})
export class LookupDialogComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(OverviewTableComponent) tableComponent!: OverviewTableComponent;

  public project!: string;
  public searchValue!: string;
  public fieldnames: any;
  public displayedColumns!: string[];
  public dataSource: MatTableDataSource<any>;
  public resultsLength = 0;
  public pageIndex = 0;
  public pagination = 10;
  public isLoadingResults = false;
  public viewCantBeLoaded = false;
  public objectIdList!: Array<string>;
  public dataType!: DataType;
  public isLookup!: boolean;

  private fullMatchIsSelected = false;
  private facets: any;
  private reqObject: RequestObject = {};
  private scheduleCriteriafieldName = '';
  private scheduleCriteria: OverrideScheduleCriteria = {};
  private scheduleCriteriaRequestObject: ScheduleCriteria = {};
  private scheduleCriteriaDateRangeKey = '_ScheduleCriteriaMinMaxForToolbox';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public lookupDialogRef: MatDialogRef<LookupDialogComponent>,
    private dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private logger: NGXLogger,
    public authService: AuthService,
    private apiClientService: ApiClientService,
    private sessionStorageService: SessionStorageService,
    private searchManipulation: SearchManipulatorService
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.initializer();
    this.getFields();
  }

  private initializer(): void {
    this.project = this.data.lookupToProject
      ? this.data.lookupToProject
      : this.localStorageService.getProject()?.project;
    this.dataType = this.data.lookupToDataType;
    this.isLookup = true;
    this.logger.debug(`[LOOKUP DIALOG]: ${this.project} - ${this.dataType} INIT`);
  }

  private getFields(): void {
    this.apiClientService.getFieldsForLoukup(this.project, this.dataType).subscribe((data) => {
      this.fieldnames = data;
    });
  }

  ngAfterViewInit(): void {
    this.apiClientService.getMenuForLookup(this.project).subscribe(
      (data) => {
        //Find first object whose value matches condition
        const datatype = data.find((obj) => obj.k?.dataType === this.dataType);
        if (datatype && datatype.v) {
          const columns = datatype.v[0]?.fields;
          if (columns) {
            this.displayedColumns = columns;
          }
          if (Number(datatype.v[0]?.facets?.length) > 0) {
            this.facets = datatype.v[0]?.facets;
          }
        }

        this.backendCall();
      },
      () => {
        this.viewCantBeLoaded = true;
      }
    );
  }

  public openAddDialog(): void {
    const dialogRef = this.dialog.open(AddDialogComponent, {
      data: {
        dataType: this.dataType,
      },
      height: '530px auto',
      width: '760px',
    });

    dialogRef.afterClosed().subscribe(() => {
      this.backendCall();
    });
  }

  public startSearch(searchValue: string): void {
    this.searchValue = searchValue;
    this.refreshFilter();
    this.backendCall();
  }

  private backendCall(): void {
    const tableSort = this.tableComponent.sort?.sortChange as EventEmitter<Sort>;
    tableSort?.subscribe(() => (this.paginator.pageIndex = 0));
    merge(tableSort, this.paginator?.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.pageIndex = this.paginator.pageIndex;
          this.isLoadingResults = true;
          this.reqObject = {
            criteria: {},
            scheduleCriteria: {},
            facets: this.facets,
            sort: this.tableComponent.sort?.active,
            order:
              this.tableComponent.sort?.direction === 'asc'
                ? 'ASC'
                : this.tableComponent.sort?.direction === 'desc'
                ? 'DESC'
                : undefined,
            page: this.paginator?.pageIndex,
            size: this.paginator?.pageSize,
          };
          if (this.searchValue && this.reqObject.criteria) {
            this.reqObject.criteria['fulltextsearch'] = [
              this.searchManipulation.manipulateSearchToFullText(this.searchValue).trim(),
            ];
          }

          if (
            this.sessionStorageService.getScheduleCriteriaDateRangeFilter(
              this.dataType,
              this.scheduleCriteriaDateRangeKey
            ) &&
            this.reqObject.scheduleCriteria
          ) {
            const scheduleCriteriaDateRange = this.sessionStorageService.getScheduleCriteriaDateRangeFilter(
              this.dataType,
              this.scheduleCriteriaDateRangeKey
            );
            this.scheduleCriteriaRequestObject.min = scheduleCriteriaDateRange.min;
            this.scheduleCriteriaRequestObject.max = scheduleCriteriaDateRange.max;
            this.scheduleCriteriafieldName = scheduleCriteriaDateRange.aggStateFieldName;
            this.reqObject.scheduleCriteria[this.scheduleCriteriafieldName] = this.scheduleCriteriaRequestObject;
          }
          return this.apiClientService
            .getRequestForPrj(this.project, this.dataType, this.reqObject)
            .pipe(catchError(() => observableOf(null)));
        }),
        map((data: ResponseObjectConcreteData | null) => {
          if (data === null) {
            this.isLoadingResults = false;
            return [];
          }

          this.isLoadingResults = false;
          this.resultsLength = data.matches || 0;
          return data.results;
        })
      )
      .subscribe((data) => {
        this.dataSource = new MatTableDataSource<any>(data);
      });
  }

  public returnScheduleCriteriafieldName(scheduleCriteriafieldName: string): string {
    return (this.scheduleCriteriafieldName = scheduleCriteriafieldName);
  }

  public onNewDateRange(range: StampDateRange): void {
    this.scheduleCriteria.min = range.min;
    this.scheduleCriteria.max = range.max;
    this.scheduleCriteria.aggStateFieldName = this.scheduleCriteriafieldName;
    if (this.scheduleCriteria.min && this.scheduleCriteria.max) {
      this.sessionStorageService.saveScheduleCriteriaWithFieldNameForToolbox(
        this.dataType,
        this.scheduleCriteriaDateRangeKey,
        this.scheduleCriteria
      );
    }
    this.backendCall();
  }

  private refreshFilter(): void {
    this.dataSource.filter = this.searchValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.paginator.pageIndex = 0;
  }
}
