import { Component, Input } from '@angular/core';
import { BaseButtonComponent } from '../../Base/base-button.component';

@Component({
  selector: 'ds-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent extends BaseButtonComponent {
  @Input() public icon = '';
}
