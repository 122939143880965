<button
  [ngClass]="fullWidth ? 'button-full-width' : ''"
  #tooltip="matTooltip"
  [matTooltip]="tooltipLabel"
  [matTooltipPosition]="toolTipPosition"
  [matTooltipHideDelay]="toolTipHideDelay"
  [attr.aria-label]="ariaLabel"
  mat-flat-button
  [color]="color"
  [disabled]="disabled"
  (click)="clickButton.emit($event)">
  <mat-label>{{ label }}</mat-label>
</button>
