import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ComponentsModule } from '../components/components.module';
import { SharedModule } from '../shared/shared.module';
import { AddDialogComponent } from './add-dialog/add-dialog.component';
import { BatchEditDialogComponent } from './batch-edit-dialog/batch-edit-dialog.component';
import { BatchEditSummaryDialogComponent } from './batch-edit-summary-dialog/batch-edit-summary-dialog.component';
import { CloneDialogComponent } from './clone-dialog/clone-dialog.component';
import { EditDialogComponent } from './edit-dialog/edit-dialog.component';
import { ExportDialogComponent } from './export-dialog/export-dialog.component';
import { FacetsFilterDialogComponent } from './facets-filter-dialog/facets-filter-dialog.component';
import { LookupDialogComponent } from './lookup-dialog/lookup-dialog.component';
import { PasswordChangeDialogComponent } from './password-change-dialog/password-change-dialog.component';
import { SearchDialogComponent } from './search-dialog/search-dialog.component';
import { SupportDialogComponent } from './support-dialog/support-dialog.component';

import {
  ButtonsModule,
  CheckboxModule,
  DatepickerModule,
  DividerModule,
  InputFieldsModule,
  RadiobuttonModule,
  SearchfieldModule,
  SelectModule,
  DialogsModule as DsDialogsModule,
} from '@frontends/designsystem';

import { TextFieldModule } from '@angular/cdk/text-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';

import { TranslateModule } from '@ngx-translate/core';
import { NgxPrintModule } from 'ngx-print';
import { ReservationDialogComponent } from './reservation-dialog/reservation-dialog.component';

@NgModule({
  declarations: [
    AddDialogComponent,
    CloneDialogComponent,
    EditDialogComponent,
    ExportDialogComponent,
    FacetsFilterDialogComponent,
    LookupDialogComponent,
    PasswordChangeDialogComponent,
    SupportDialogComponent,
    SearchDialogComponent,
    BatchEditDialogComponent,
    BatchEditSummaryDialogComponent,
    ReservationDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ComponentsModule,
    TranslateModule.forChild(),
    NgxPrintModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatTooltipModule,
    MatRadioModule,
    MatPaginatorModule,
    MatProgressBarModule,
    TextFieldModule,
    ButtonsModule,
    DividerModule,
    SelectModule,
    InputFieldsModule,
    SearchfieldModule,
    CheckboxModule,
    MatSelectModule,
    MatInputModule,
    RadiobuttonModule,
    DatepickerModule,
    DsDialogsModule,
  ],
  exports: [
    AddDialogComponent,
    CloneDialogComponent,
    EditDialogComponent,
    ExportDialogComponent,
    FacetsFilterDialogComponent,
    LookupDialogComponent,
    PasswordChangeDialogComponent,
    SupportDialogComponent,
    SearchDialogComponent,
    ReservationDialogComponent,
  ],
})
export class DialogsModule {}
