import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ThemeColors } from '@frontends/designsystem';
import { TranslateService } from '@ngx-translate/core';
import { Observable, map } from 'rxjs';
import { AlertComponent, AlertDialogModel } from '../../ui-components/alert/alert.component';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private translateService: TranslateService,
  ) {}

  public showRedirectConfirmationDialog(
    title: string,
    message: string,
    isInfo: boolean,
    subUrl: string,
    buttonYes: string,
    buttonNo: string,
    useWindowLocation = false,
  ): void {
    const dialogData = new AlertDialogModel(title, message, isInfo, buttonYes, buttonNo);
    const dialogRef = this.dialog.open(AlertComponent, {
      data: dialogData,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe();
    dialogRef.componentInstance.save.subscribe((res) => {
      if (res) {
        useWindowLocation ? (window.location.href = subUrl) : this.router.navigate([subUrl]);
        this.dialog.closeAll();
      } else {
        this.dialog.closeAll();
      }
    });
  }

  public confirmationDialog(
    title: string,
    message: string,
    buttonYes = this.translateService.instant('MESSAGE.ok'),
    buttonNo = this.translateService.instant('BUTTONS.cancel'),
    confirmBtnColor: ThemeColors = 'accent',
  ): Observable<boolean> {
    const dialogData = new AlertDialogModel(title, message, false, buttonYes, buttonNo, confirmBtnColor);
    const dialogRef = this.dialog.open(AlertComponent, {
      data: dialogData,
    });

    return dialogRef.componentInstance.save.pipe(
      map((res) => {
        dialogRef.close();
        return res;
      }),
    );
  }

  public infoDialog(
    title: string,
    message: string,
    buttonOk = this.translateService.instant('MESSAGE.ok'),
  ): Observable<boolean> {
    const dialogData = new AlertDialogModel(title, message, false, buttonOk);
    const dialogRef = this.dialog.open(AlertComponent, {
      data: dialogData,
    });

    return dialogRef.componentInstance.save.pipe(
      map((res) => {
        dialogRef.close();
        return res;
      }),
    );
  }

  public confirmDiscardDialogChanges() {
    const dialogData = new AlertDialogModel(
      '',
      this.translateService.instant('MESSAGE.DELETE_MESSAGE.discardAsk'),
      false,
      this.translateService.instant('BUTTONS.discard'),
      this.translateService.instant('BUTTONS.cancel'),
    );
    const dialogRef = this.dialog.open(AlertComponent, {
      data: dialogData,
    });

    return dialogRef;
  }

  public showInfoDialog(
    title: string,
    message: string,
    buttonYes = this.translateService.instant('MESSAGE.ok'),
  ): Observable<boolean> {
    const dialogData = new AlertDialogModel(title, message, true, buttonYes);
    const dialogRef = this.dialog.open(AlertComponent, {
      data: dialogData,
    });

    return dialogRef.componentInstance.save.pipe(
      map((res) => {
        dialogRef.close();
        return res;
      }),
    );
  }
}
