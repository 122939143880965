/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { SessionStorageService } from '../../../app/services/storage/session-storage.service';
import { FilterData } from '../../model/Data/FilterData';
import { FacetsFilterComponent } from '../../shared/components/facets-filter/facets-filter.component';
import { BaseDialogComponent } from '@frontends/commons';

@Component({
  selector: 'exp-facets-filter-dialog',
  templateUrl: './facets-filter-dialog.component.html',
  styleUrls: ['./facets-filter-dialog.component.scss'],
})
export class FacetsFilterDialogComponent extends BaseDialogComponent {
  @ViewChild(FacetsFilterComponent) facetFilter!: FacetsFilterComponent;
  private dataSubject = new BehaviorSubject<FilterData>(this.data);
  private clearFilterSubject = new BehaviorSubject<boolean>(false);

  public changes$: Observable<FilterData> = this.dataSubject.asObservable();
  public clearFilter$: Observable<boolean> = this.clearFilterSubject.asObservable();
  public hasChanges = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FilterData,
    protected override dialogRef: MatDialogRef<FacetsFilterDialogComponent>,
    private sessionStorageService: SessionStorageService,
  ) {
    super(dialogRef);
    if (this.sessionStorageService.getFilterLength(this.data.dataType) > 0) {
      this.hasChanges = true;
    }
  }

  public sendChanges(data: FilterData): void {
    if (this.sessionStorageService.getFilterLength(this.data.dataType) === 0) {
      this.hasChanges = false;
    } else {
      this.hasChanges = true;
    }
    this.dataSubject.next(data);
  }

  public resetFilter(): void {
    this.clearFilterSubject.next(true);
    this.sessionStorageService.resetFilters(this.data.dataType);
    this.facetFilter.clearFacetFilter();
    this.hasChanges = false;
  }
}
