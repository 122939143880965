/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { BaseFieldComponent } from '../../Base/base-field.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

interface ViewValueOption {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'ds-view-value-select',
  templateUrl: './view-value-select.component.html',
  styleUrls: ['./view-value-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ViewValueSelectComponent),
      multi: true,
    },
  ],
})
export class ViewValueSelectComponent extends BaseFieldComponent {
  @Input() options!: ViewValueOption[];
  @Input() selectedValue!: ViewValueOption; // doesn't set, need to check

  @Output() selectionChange: EventEmitter<string> = new EventEmitter<string>();

  selectionChanged(event: MatSelectChange): void {
    this.onChange(event.value);
    this.selectionChange.emit(event.value);
  }

  // Implement the ControlValueAccessor methods
  protected override onChange: (value: string) => void = () => {};

  override writeValue(value: string): void {
    this.value = value;
  }
  override registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }
}
