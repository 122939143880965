<mat-form-field class="filter-field" appearance="outline" [color]="color">
  <mat-label>{{ label }}</mat-label>
  <input
    data-testid="filter-input"
    aria-label="filter-field"
    matInput
    [type]="type"
    [required]="required"
    [disabled]="disabled"
    [(ngModel)]="value"
    [placeholder]="placeholder"
    (keyup.enter)="startfiltering()"
    (input)="onChangeInput()"
    (change)="onChange()" />
  <mat-hint aria-label="Input Hint" *ngIf="showHint"> {{ hintMessage }}</mat-hint>
  <mat-error aria-label="Error Hint" *ngIf="showError">{{ errorMessage }}</mat-error>
  <button data-testid="clear-btn" *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="clear()">
    <mat-icon [color]="color">close</mat-icon>
  </button>
</mat-form-field>
