import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ChangePassRequest, PassResetRequest, UserPassTuple } from '../../model';

@Injectable({
  providedIn: 'root',
})
export abstract class BaseAuthService {
  abstract userInfo$: Observable<any>;
  abstract userIsLoggedIn$: Observable<boolean>;

  abstract login(userPass: UserPassTuple): Observable<any>;

  abstract resetPassword(resetRequest: PassResetRequest): Observable<any>;

  abstract updatePassword(passRequest: ChangePassRequest): Observable<any>;

  abstract logout(): void;
}
