/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { BaseFieldComponent } from '../../Base/base-field.component';

@Component({
  selector: 'ds-mselect',
  templateUrl: './mselect.component.html',
  styleUrls: ['./mselect.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MSelectComponent),
      multi: true,
    },
  ],
})
export class MSelectComponent extends BaseFieldComponent {
  @Input() options: string[] = [];
  @Input() isHierarchy = false;
  @Input() selectWithOutEmptyValue = false;
  @Output() selectionChange = new EventEmitter<MatSelectChange>();

  selectionChanged(event: MatSelectChange) {
    this.onChange(event.value);
    this.selectionChange.emit(event);
  }

  // Implement the ControlValueAccessor methods
  protected override onChange: (value: string) => void = () => {};

  override writeValue(value: string): void {
    this.value = value;
  }
  override registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }
}
