<div class="validation-error ion-padding" *ngIf="type === 'required'">
  {{ title | translate }} {{ 'ERROR.required' | translate }}
</div>

<div class="validation-error ion-padding" *ngIf="type === 'minlength'">
  {{ title | translate }} {{ 'ERROR.minlength' | translate }}({{ length }})
</div>

<div class="validation-error ion-padding" *ngIf="type === 'maxlength'">
  {{ title | translate }} {{ 'ERROR.maxlength' | translate }}({{ length }})
</div>

<div class="validation-error ion-padding" *ngIf="type === 'noMatch'">
  {{ title | translate }} {{ 'ERROR.noMatch' | translate }}
</div>

<div class="validation-error ion-padding" *ngIf="type === 'invalid'">
  <span *ngIf="title">{{ title | translate }}:</span> {{ 'ERROR.invalid' | translate }}
</div>

<div class="validation-error ion-padding" *ngIf="type === 'generic'">
  <span *ngIf="title">{{ title | translate }}:</span> {{ text }}
</div>

<div class="validation-error ion-padding" *ngIf="type === 'numerical'">
  <span *ngIf="title">{{ title | translate }}:</span> {{ 'ERROR.numerical' | translate }}
</div>

<div class="validation-error ion-padding" *ngIf="type === 'min'">
  {{ title | translate }} {{ 'ERROR.min' | translate }}({{ length }})
</div>

<div class="validation-error ion-padding" *ngIf="type === 'max'">
  {{ title | translate }} {{ 'ERROR.max' | translate }}({{ length }})
</div>
