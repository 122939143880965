<mat-form-field class="search-field" appearance="outline" [color]="colorBorder">
  <mat-icon [color]="colorBorder" matPrefix>search</mat-icon>
  <input
    data-testid="search-input"
    aria-label="search-field"
    matInput
    type="text"
    [(ngModel)]="value"
    [placeholder]="placeholder"
    (keyup.enter)="startSearching()" />
  <button data-testid="clear-btn" *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="clear()">
    <mat-icon [color]="colorBorder">close</mat-icon>
  </button>
</mat-form-field>
