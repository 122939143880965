import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { SnackbarType } from '../../model/Enums/snackBarTypeEnum';
import { SnackbarComponent } from '../../ui-components';
@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) {}

  public openSnackbar(
    message: string,
    type?: SnackbarType,
    action?: string,
    duration?: number,
    horizontalPosition?: MatSnackBarHorizontalPosition,
    verticalPosition?: MatSnackBarVerticalPosition,
  ) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: { type: type, message: message, action: action },
      duration: duration ? duration : 2000,
      panelClass: type ? [type] : SnackbarType.DEFAULT,
      horizontalPosition: horizontalPosition || 'center',
      verticalPosition: verticalPosition,
    });
  }
}
