/* eslint-disable @typescript-eslint/no-empty-function */
import { AfterViewInit, Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { BaseFieldComponent } from '../../Base/base-field.component';

@Component({
  selector: 'ds-datepicker-range',
  templateUrl: './datepicker-range.component.html',
  styleUrls: ['./datepicker-range.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerRangeComponent),
      multi: true,
    },
  ],
})
export class DatepickerRangeComponent extends BaseFieldComponent implements AfterViewInit {
  @ViewChild(MatDateRangePicker) public picker!: MatDateRangePicker<Date>;
  @Input() public placeholderStart = '';
  @Input() public placeholderEnd = '';
  @Input() public max!: Date;
  @Input() public min!: Date;
  @Input() public start!: Date | null;
  @Input() public end!: Date | null;
  @Input() public initialStarDate!: number | null;
  @Input() public initialEndDate!: number | null;
  @Input() public isCheckBtnShown = true;
  @Input() public hasCheck = false;
  @Input() public hasClear = false;
  @Input() public isClearBtnShown = true;

  @Output() public pickerRef = new EventEmitter<MatDateRangePicker<Date>>();
  @Output() public dateRangeOutput = new EventEmitter<{ min: number; max: number }>();
  @Output() public dateRangeClearOutput = new EventEmitter<{ min: number; max: number }>();

  public dateRange: { min: number; max: number } = {
    min: 946684800,
    max: 7258118400, //JAN 01 2200
  };

  ngAfterViewInit(): void {
    if (this.initialStarDate !== null || this.initialEndDate !== null) {
      const startDateValue = this.initialStarDate !== null ? this.initialStarDate : 0;
      const endDateValue = this.initialEndDate !== null ? this.initialEndDate : 0;

      this.start = new Date(startDateValue as string | number);
      this.end = new Date(endDateValue as string | number);
    }
    this.pickerRef.emit(this.picker);
  }

  public inputStartDate(event: any) {
    this.dateRange.min = event.value?.['ts'] as number;
  }
  public inputEndDate(event: any) {
    this.dateRange.max = (event.value?.['ts'] as number) + 24 * 60 * 60 * 1000 - 1;
    this.dateRangeOutput.emit(this.dateRange);
  }

  public dateRangeChange() {
    this.onChange(this.dateRange);
    this.dateRangeOutput.emit(this.dateRange);
    this.onTouched();
  }

  public dateRangeClear() {
    this.start = null;
    this.end = null;
    this.dateRangeClearOutput.emit();
  }

  // Implement the ControlValueAccessor methods
  protected override onChange: (value: { min: number; max: number }) => void = () => {};

  override writeValue(value: { min: number; max: number }): void {
    this.dateRange = value;
  }
  override registerOnChange(fn: (value: { min: number; max: number }) => void): void {
    this.onChange = fn;
  }
}
