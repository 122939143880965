import { Injectable } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { UiControllerService } from '@frontends/exp-apiclient';
import { map, Observable } from 'rxjs';
import { DataTypes } from '../../model';

import { EMPTY_MENU_DATA, ProjectData, TupleMenuViewData } from '../../model/Data';

@Injectable({
  providedIn: 'root',
})
export class CmnApiClientService {
  constructor(private uiApiService: UiControllerService) {}

  public getMenuData(project: ProjectData): Observable<TupleMenuViewData[]> {
    return this.uiApiService.getMenue(project?.project).pipe(
      map((beResponse) => {
        return beResponse.map(
          (item) =>
            <TupleMenuViewData>{
              key: item.k
                ? {
                    name: item.k.name ?? '',
                    title: item.k.title ?? '',
                    dataType: item.k.dataType ?? DataTypes.OBJECT,
                    defaultView: item.k.defaultView ?? '',
                    writePermission: item.k.writePermission ?? false,
                    deletePermission: item.k.deletePermission ?? false,
                    createPermission: item.k.createPermission ?? false,
                    criteria: [],
                    ...item.k,
                  }
                : EMPTY_MENU_DATA,
              value: item.v
                ? item.v.map((view) => ({
                    name: view.name ?? '',
                    fields: view.fields ?? [],
                    facets: view.facets ?? [],
                  }))
                : [],
            },
        );
      }),
    );
  }
}
