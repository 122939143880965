import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function decimalValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let value = control.value;
    if (value === 'INVALID') {
      return { invalidNumber: { value: control.value } };
    }

    if (!value) {
      return null;
    }

    if (typeof value === 'number') {
      value = value.toString();
    }

    const dotCount = (value.match(/\./g) || []).length;
    const commaCount = (value.match(/,/g) || []).length;

    if (dotCount + commaCount > 1) {
      return { invalidNumber: { value: control.value } };
    }

    return null;
  };
}
