import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { LinkButtonComponent } from './link-button/link-button.component';
import { LinkComponent } from './link/link.component';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatIconModule],
  declarations: [LinkComponent, LinkButtonComponent],
  exports: [LinkComponent, LinkButtonComponent],
})
export class LinksModule {}
