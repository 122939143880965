/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { DataType, FormIndex, GenericMetaForm } from '@frontends/commons';

import { UiApiClientService } from '../api/ui-api-client.service';
import { LocalStorageService } from '../storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  private forms: FormIndex = {};

  constructor(private localStorageService: LocalStorageService, private uiApiClient: UiApiClientService) {}

  public getForm(projectIdentifier: string, dataType: DataType): Observable<GenericMetaForm> {
    const storingKey = dataType + '_' + projectIdentifier;
    if (this.localStorageService.getProject()?.project === projectIdentifier) {
      if (this.forms && this.forms[storingKey]) {
        return this.forms[storingKey];
      }
    }

    this.forms[storingKey] = this.fetchForm(projectIdentifier, dataType);
    return this.forms[storingKey];
  }

  private fetchForm(projectIdentifier: string, dataType: DataType): Observable<GenericMetaForm> {
    return this.uiApiClient.getForm(projectIdentifier, dataType);
  }
}
