import { Component } from '@angular/core';
import { OverlayInteractionService } from '../../../services/utils/file-uploader/overlay-interaction.service';

@Component({
  selector: 'exp-loading-overlay',
  templateUrl: './file-uploading-overlay.component.html',
  styleUrls: ['./file-uploading-overlay.component.scss'],
})
export class FileUploadingOverlayComponent {
  constructor(private overlayInteractionService: OverlayInteractionService) {}

  hideTheOverlay(): void {
    this.overlayInteractionService.closeBtnClicked();
  }
}
