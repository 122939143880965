export * from './aPI.service';
import { APIService } from './aPI.service';
import { AuthControllerService } from './authController.service';
import { ExtensionControllerService } from './extensionController.service';
import { FileControllerService } from './fileController.service';
import { ImageResizeControllerService } from './imageResizeController.service';
import { MgmtFormularControllerService } from './mgmtFormularController.service';
import { MgmtProjectControllerService } from './mgmtProjectController.service';
import { MgmtRoleControllerService } from './mgmtRoleController.service';
import { MgmtUserControllerService } from './mgmtUserController.service';
import { ObjectDataControllerService } from './objectDataController.service';
import { RoomplannerControllerService } from './roomplannerController.service';
import { UiControllerService } from './uiController.service';

export * from './authController.service';
export * from './extensionController.service';
export * from './fileController.service';
export * from './imageResizeController.service';
export * from './mgmtFormularController.service';
export * from './mgmtProjectController.service';
export * from './mgmtRoleController.service';
export * from './mgmtUserController.service';
export * from './objectDataController.service';
export * from './roomplannerController.service';
export * from './uiController.service';
export const APIS = [
  APIService,
  AuthControllerService,
  ExtensionControllerService,
  FileControllerService,
  ImageResizeControllerService,
  MgmtFormularControllerService,
  MgmtProjectControllerService,
  MgmtRoleControllerService,
  MgmtUserControllerService,
  ObjectDataControllerService,
  RoomplannerControllerService,
  UiControllerService,
];
