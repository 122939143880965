import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { AfterViewInit, Component, EventEmitter, Injectable, Input, OnInit, Output } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { DataType, HierarchyEntry } from '@frontends/commons';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { SessionStorageService } from '../../../services/storage/session-storage.service';

/**
 * Node for to-do item
 */
export class HierarchyItemNode {
  children!: HierarchyItemNode[];
  name!: string;
  path!: string;
  count!: number;
  parent!: string;
  hierarchyPath!: string;
}

/** Flat to-do item node with expandable and level information */
export class HierarchyItemFlatNode {
  name!: string;
  path!: string;
  count!: number;
  level!: number;
  expandable!: boolean;
  parent!: string;
  hierarchyPath!: string;
}

/**
 * Checklist database, it can build a tree structured Json object.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable()
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<HierarchyItemNode[]>([]);
  public hierarchyObject = {};

  get data(): HierarchyItemNode[] {
    return this.dataChange.value;
  }

  constructor() {
    this.initialize();
  }

  initialize() {
    // Build the tree nodes from Json object. The result is a list of `HierarchyItemNode` with nested
    // file node as children.

    const data = this.buildFileTree(this.hierarchyObject, 0);
    // Notify the change.
    this.dataChange.next(data);
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `HierarchyItemNode`.
   */
  buildFileTree(
    obj: { [key: string]: any },
    level: number,
    parent: string = '',
    parentPath: string = ''
  ): HierarchyItemNode[] {
    return Object.keys(obj).reduce<HierarchyItemNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new HierarchyItemNode();
      node.name = key;
      node.parent = parent;
      // No we concatenate parent's : names root>..>..
      node.hierarchyPath = parentPath ? `${parentPath} > ${key}` : key;

      if (value !== null && value.children) {
        if (Object.keys(value.children).length > 0 || typeof value === 'object') {
          node.children = this.buildFileTree(value.children, level + 1, key, node.hierarchyPath);
          node.path = value?.path;
          node.count = value?.count;
        }
      } else if (value !== null) {
        node.path = value?.path;
        node.count = value?.count;
      }
      return accumulator.concat(node);
    }, []);
  }
}
@Component({
  selector: 'exp-tree-check-filter',
  templateUrl: './tree-check-filter.component.html',
  styleUrls: ['./tree-check-filter.component.scss'],
  providers: [ChecklistDatabase],
})
export class TreeCheckFilterComponent implements OnInit, AfterViewInit {
  @Input() hierarchyTreeData: { [key: string]: HierarchyEntry } = {};
  @Input() dataType!: DataType;
  @Input() hierarchyKey!: string;
  @Output() selectedHierarchyDataChanged = new EventEmitter<any>();
  @Output() hierarchyQueryChanged = new EventEmitter<string>();
  @Input() resultsLength!: number;
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<HierarchyItemFlatNode, HierarchyItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<HierarchyItemNode, HierarchyItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: HierarchyItemFlatNode | null = null;

  treeControl: FlatTreeControl<HierarchyItemFlatNode>;

  treeFlattener: MatTreeFlattener<HierarchyItemNode, HierarchyItemFlatNode>;

  dataSource: MatTreeFlatDataSource<HierarchyItemNode, HierarchyItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<HierarchyItemFlatNode>(true /* multiple */);

  constructor(
    private _database: ChecklistDatabase,
    private sessionStorageService: SessionStorageService,
    private translateService: TranslateService
  ) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<HierarchyItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    _database.hierarchyObject = this.hierarchyTreeData;
    _database.dataChange.subscribe((data) => {
      this.dataSource.data = data;
    });
  }

  ngOnInit() {
    this._database.hierarchyObject = this.hierarchyTreeData;
    this._database.initialize();
  }

  ngAfterViewInit() {
    const tempChecklistData = this.sessionStorageService.getSelectedChecklistData(this.dataType, this.hierarchyKey);

    if (tempChecklistData && tempChecklistData.length) {
      tempChecklistData.forEach((element: HierarchyItemFlatNode) => {
        const selected = this.treeControl.dataNodes.find(
          (node) =>
            node.name === element.name &&
            node.path === element.path &&
            node.parent === element.parent &&
            node.level === element.level &&
            node.hierarchyPath === element.hierarchyPath
        );
        if (selected && !this.checklistSelection.isSelected(selected)) {
          if (selected.expandable) {
            this.treeControl.expand(selected);
          }
          this.itemSelectionToggle(selected);
        }
      });
    }
  }

  getHierarchyObject = () => this.hierarchyTreeData;
  getLevel = (node: HierarchyItemFlatNode) => node.level;

  isExpandable = (node: HierarchyItemFlatNode) => node.expandable;

  getChildren = (node: HierarchyItemNode): HierarchyItemNode[] => node.children;

  hasChild = (_: number, _nodeData: HierarchyItemFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: HierarchyItemFlatNode) => _nodeData.name === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: HierarchyItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.name === node.name ? existingNode : new HierarchyItemFlatNode();
    flatNode.name = node.name;
    flatNode.level = level;
    flatNode.expandable = !!node.children?.length;
    flatNode.path = node.path;
    flatNode.count = node.count;
    flatNode.parent = node.parent;
    flatNode.hierarchyPath = node.hierarchyPath;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: HierarchyItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 && descendants.every((child) => this.checklistSelection.isSelected(child));
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: HierarchyItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the item selection. Select/deselect all the descendants node*/
  itemSelectionToggle(node: HierarchyItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);

    if (this.checklistSelection.isSelected(node)) {
      this.checklistSelection.select(...descendants);
    } else {
      this.checklistSelection.deselect(...descendants);
    }
    descendants.forEach((child) => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);

    this.selectedHierarchyDataChanged.emit(this.checklistSelection.selected);
    this.hierarchyQueryChanged.emit(this.retrieveHierarchyQueryString());
  }

  /** Toggle a leaf item selection. Check all the parents to see if they changed */
  leafItemSelectionToggle(node: HierarchyItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
    this.selectedHierarchyDataChanged.emit(this.checklistSelection.selected);
    this.hierarchyQueryChanged.emit(this.retrieveHierarchyQueryString());
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: HierarchyItemFlatNode): void {
    let parent: HierarchyItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: HierarchyItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 && descendants.every((child) => this.checklistSelection.isSelected(child));
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: HierarchyItemFlatNode): HierarchyItemFlatNode | null {
    const currentLevel = this.getLevel(node);
    if (currentLevel < 1) {
      return null;
    }
    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  getNodeName(nodeName: string): string {
    switch (nodeName.trim()) {
      case '! {  }':
        return this.translateService.instant('LABELS.na');
      case 'green':
        return this.translateService.instant('TRAFFICLIGHT.available');
      case 'orange':
        return this.translateService.instant('TRAFFICLIGHT.reserved');
      case 'red':
        return this.translateService.instant('TRAFFICLIGHT.unavailable');
      default:
        return nodeName;
    }
  }

  retrieveHierarchyQueryString(): string {
    let result = '';
    for (let i = 0; i < this.checklistSelection.selected.length; i++) {
      if (i === this.checklistSelection.selected.length - 1) {
        result += '(' + this.concatAndStringParent(this.checklistSelection.selected[i]) + ')';
      } else {
        result += '(' + this.concatAndStringParent(this.checklistSelection.selected[i]) + ')' + ' OR ';
      }
    }
    return result;
  }

  concatAndStringParent(node: HierarchyItemFlatNode | null): string {
    let hQuery = '';
    if (!node) {
      return hQuery;
    }
    if (node.level === 0 && node.name !== '! {  }') {
      hQuery = node.path + ': ' + '"' + node.name + '"';
    } else if (node.level === 0 && node.name === '! {  }') {
      hQuery = '(NOT '.concat(node.path).concat(')');
    } else {
      hQuery = this.concatAndStringParent(this.getParentNode(node))
        .concat(' AND ')
        .concat(
          node.name === '! {  }'
            ? '(NOT '.concat(node.path).concat(')')
            : node.path.concat(': ').concat('"' + node.name + '"')
        );
    }
    return hQuery;
  }
  isNodeSelectedOrIndeterminated(node: HierarchyItemFlatNode): boolean {
    return this.checklistSelection.isSelected(node) || this.descendantsPartiallySelected(node);
  }
}
