/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { ChipColors } from '../../../types/colors';
import { BaseFieldComponent } from '../../Base/base-field.component';

@Component({
  selector: 'ds-traffic-light',
  templateUrl: './traffic-light.component.html',
  styleUrls: ['./traffic-light.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TrafficLightComponent),
      multi: true,
    },
  ],
})
export class TrafficLightComponent extends BaseFieldComponent {
  @Input() chipColors: ChipColors[] = ['green', 'orange', 'red'];
  @Input() chipLabels: { [color: string]: string } = {};
  @Input() checkedColor!: ChipColors;

  @Output() changeValue = new EventEmitter<MatRadioChange>();
  @Output()
  clickRadioButton = new EventEmitter<MouseEvent>();

  radioChange(event: MatRadioChange) {
    this.onChange(event.value);
    this.checkedColor = event.value;
    this.changeValue.emit(event.value);
    this.onTouched();
  }
  // Implement the ControlValueAccessor methods
  protected override onChange: (value: ChipColors) => void = () => {};

  override writeValue(value: ChipColors): void {
    this.checkedColor = value;
  }
  override registerOnChange(fn: (value: ChipColors) => void): void {
    this.onChange = fn;
  }
}
