/* eslint-disable @typescript-eslint/no-empty-function */
import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFieldComponent } from '../../Base/base-field.component';

const INVALID = 'INVALID';

@Component({
  selector: 'ds-number-field',
  templateUrl: './number-field.component.html',
  styleUrl: './number-field.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberFieldComponent),
      multi: true,
    },
  ],
})
export class NumberFieldComponent extends BaseFieldComponent implements AfterViewChecked {
  @Input() public override value: number | null = null;
  @Input() public isDecimal = false;
  @Input() public isDimension = false;
  @Input() public minNumber = '';
  @Input() public maxNumber = '';

  @Output() public inputChange = new EventEmitter<number | null | string>();

  private inputElement!: HTMLInputElement;

  private _inputRef!: ElementRef;

  @ViewChild('inputRef') set inputRef(ref: ElementRef) {
    if (ref) {
      this._inputRef = ref;
      this.inputElement = ref.nativeElement;
    }
  }

  ngAfterViewChecked(): void {
    if (this._inputRef) this.inputElement = this._inputRef.nativeElement;

    if (this.control) {
      this.control.valueChanges.subscribe((value) => {
        if (!value) {
          this.checkValidity();
        }
      });
    }
  }

  public inputBlur(): void {
    this.onBlur();
    this.inputChange.emit(this.value);
  }

  public inputChanged(): void {
    if (this.isDecimal && !this.inputElement.validity.valid) {
      this.onChange(INVALID);
      this.inputChange.emit(INVALID);
    } else {
      this.onChange(this.value);
      this.inputChange.emit(this.value);
    }
  }

  public onlyNumber(event: KeyboardEvent) {
    if (
      !event.code.includes('Digit') &&
      !event.code.includes('Numpad') &&
      !event.code.includes('NumpadDecimal') &&
      !event.code.includes('Comma') &&
      !event.code.includes('Period')
    ) {
      event.preventDefault();
    }
  }

  // Implement the ControlValueAccessor methods
  protected override onChange: (value: number | string | null) => void = () => {};

  private checkValidity() {
    if (this.isDecimal && !this.inputElement.validity.valid) {
      this.onChange(INVALID);
      this.inputChange.emit(INVALID);
    }
  }
}
