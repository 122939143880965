/**
 * Explanner Service Documentation
 * Explanner Service Documentation
 *
 * The version of the OpenAPI document: 1.7_landesstelle{20240611@153537}
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Resize200ResponseLinksInner { 
    params?: { [key: string]: string; };
    type?: string;
    uriBuilder?: object;
    rel?: string;
    rels?: Array<string>;
    title?: string;
    uri?: string;
}

