<div class="container" *ngIf="!valueOnly; else showReadOnly">
  <ds-icon-badge *ngIf="internal" />
  <mat-form-field appearance="outline" [color]="color">
    <mat-label>{{ label }}</mat-label>
    <input
      [formControl]="control"
      [(ngModel)]="value"
      [placeholder]="placeholder"
      [disabled]="disabled"
      matInput
      [matDatepicker]="picker"
      (dateChange)="onDateChange($event)"
      [required]="required" />
    <mat-hint *ngIf="showHint">{{ hintMessage }}</mat-hint>
    <mat-error *ngIf="showError">{{ errorMessage }}</mat-error>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</div>
<ng-template #showReadOnly>
  <div class="column-container">
    <span class="mat-subtitle-2">{{ label }}</span>
    <span class="mat-body-2">{{ value ? value : '-' }}</span>
  </div>
</ng-template>
