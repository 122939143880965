/**
 * Explanner Service Documentation
 * Explanner Service Documentation
 *
 * The version of the OpenAPI document: 1.7_landesstelle{20240611@153537}
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TrackingData { 
    docId?: string;
    affectedDocId?: string;
    causingUser?: string;
    timestamp?: number;
    verb?: TrackingData.VerbEnum;
    data?: { [key: string]: object; };
    dataType?: TrackingData.DataTypeEnum;
    version?: number;
}
export namespace TrackingData {
    export type VerbEnum = 'CREATE' | 'READ' | 'UPDATE' | 'DELETE';
    export const VerbEnum = {
        Create: 'CREATE' as VerbEnum,
        Read: 'READ' as VerbEnum,
        Update: 'UPDATE' as VerbEnum,
        Delete: 'DELETE' as VerbEnum
    };
    export type DataTypeEnum = 'ADDRESS' | 'INSTITUTE' | 'OBJECT';
    export const DataTypeEnum = {
        Address: 'ADDRESS' as DataTypeEnum,
        Institute: 'INSTITUTE' as DataTypeEnum,
        Object: 'OBJECT' as DataTypeEnum
    };
}


