import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InputType } from '../../../types/InputType';
import { ThemeColors } from '../../../types/colors';

@Component({
  selector: 'ds-input-filter',
  templateUrl: './input-filter.component.html',
  styleUrls: ['./input-filtercomponent.scss'],
})
export class InputFilterComponent {
  @Input() label = '';
  @Input() ariaLabel = '';
  @Input() placeholder = '';

  @Input() value = '';
  @Input() type: InputType = 'text';

  @Input() showHint = false;
  @Input() hintMessage = '';
  @Input() maxlength = '';

  @Input() showError = false;
  @Input() errorMessage = '';

  @Input() tooltip = '';
  @Input() iconClass = 'security';

  @Input() disabled = false;
  @Input() required = false;
  @Input() color: ThemeColors = 'primary';
  @Output() submitFilter = new EventEmitter<string>();
  @Output() submitClear = new EventEmitter<string>();
  @Output() inputChange = new EventEmitter<{
    value: string;
  }>();
  @Output() Change = new EventEmitter<{
    value: string;
  }>();

  startfiltering() {
    this.submitFilter.emit(this.value);
  }

  clear() {
    this.value = '';
    this.submitClear.emit('');
    this.startfiltering();
  }
  onChangeInput() {
    this.inputChange.emit({
      value: this.value,
    });
  }
  onChange() {
    this.Change.emit({
      value: this.value,
    });
  }
}
