@if (!valueOnly) {
  <div class="container">
    @if (internal) {
      <ds-icon-badge [iconClass]="iconClass" [tooltip]="tooltip" />
    }
    <mat-form-field [class.ng-invalid]="showError" [color]="color" appearance="outline">
      <mat-label>{{ label }}</mat-label>
      @if (!formControlName) {
        <input
          #inputRef
          (input)="inputChanged()"
          [(ngModel)]="value"
          [attr.aria-label]="ariaLabel"
          [disabled]="disabled"
          [inputMode]="isDecimal ? 'decimal' : 'numeric'"
          [max]="maxNumber"
          [min]="minNumber"
          [required]="required"
          [step]="isDecimal ? '0.01' : '1'"
          type="number"
          data-testid="input"
          matInput
          (blur)="inputBlur()"
          (keypress)="onlyNumber($event)" />
      } @else {
        <input
          #inputRef
          [(ngModel)]="value"
          [attr.aria-label]="ariaLabel"
          [formControl]="control"
          [inputMode]="isDecimal ? 'decimal' : 'numeric'"
          [min]="minNumber"
          [max]="maxNumber"
          [step]="isDecimal ? '0.01' : '1'"
          type="number"
          data-testid="input"
          matInput
          (blur)="inputBlur()"
          (keypress)="onlyNumber($event)" />
      }
      @if (showHint) {
        <mat-hint aria-label="Input Hint">{{ hintMessage }}</mat-hint>
      }
      @if (showError) {
        <mat-error aria-label="Error Hint">{{ errorMessage }}</mat-error>
      }
    </mat-form-field>
  </div>
} @else {
  @if (!isDimension) {
    <div class="column-container">
      <span class="mat-subtitle-2">{{ label }}</span>
      <span class="mat-body-2">{{ value ? value : '-' }}</span>
    </div>
  } @else {
    <div class="dimension-container">
      <span class="mat-body-2">{{ label }}: {{ value ? value : '-' }};&nbsp;</span>
    </div>
  }
}
