import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemeColors } from '../../../types/colors';
import { TooltipPosition } from '../../../types/positions';

@Component({
  selector: 'ds-label-icon',
  templateUrl: './label-icon.component.html',
  styleUrls: ['./label-icon.component.scss'],
})
export class LabelIconComponent {
  @Input()
  tooltipLabel = '';

  @Input()
  toolTipPosition: TooltipPosition = 'below';

  @Input()
  toolTipHideDelay = '';

  @Input()
  label = '';

  @Input()
  icon = '';

  @Input()
  fontColor: ThemeColors = 'primary';

  @Output()
  clickButton = new EventEmitter<MouseEvent>();
}
