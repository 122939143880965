import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'OBJECT', // this is a hardcoded default path to OBJECT, if the user navigates to root path
    pathMatch: 'full',
  },
  {
    path: 'default',
    redirectTo: 'OBJECT',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () => import('@frontends/commons').then((m) => m.LoginModule),
  },
  {
    path: ':type',
    loadChildren: () => import('./pages/pages.module').then((m) => m.PageModule),
    canActivate: [authGuard],
  },
  {
    path: '**',
    loadChildren: () => import('./pages/wildcard/wildcard.module').then((m) => m.WildcardModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
