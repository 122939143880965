import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { ResponseObject } from '@frontends/commons';
import { RequestObject } from '@frontends/exp-apiclient';
import { EMPTY, Observable } from 'rxjs';
import { DataApiClientService } from '../../api/data-api-client.service';

@Injectable({
  providedIn: 'root',
})
export class UniqueValidatorService {
  constructor(private dataApiClient: DataApiClientService) {}

  validateUnique(control: AbstractControl): Observable<ResponseObject> {
    const request: RequestObject = {
      criteria: {},
      fieldList: ['docId', 'basis_humanId'],
    };
    const path = this.getControlName(control);

    if (path && control.value && request.criteria) {
      request.criteria[path] = control.value.trim();

      return this.dataApiClient.getRequestForCurrentProject('OBJECT', request);
    } else {
      return EMPTY;
    }
  }

  private getControlName(formControl: AbstractControl): string {
    const formGroup = formControl.parent;
    let controlName = '';
    if (formGroup) {
      Object.keys(formGroup.controls).forEach((key) => {
        if (formGroup.get(key) === formControl) {
          controlName = key;
        }
      });
    }
    return controlName;
  }
}
