/**
 * Explanner Service Documentation
 * Explanner Service Documentation
 *
 * The version of the OpenAPI document: 1.7_landesstelle{20240611@153537}
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SearchPanelFields { 
    name?: string;
    path?: string;
    type?: SearchPanelFields.TypeEnum;
    options?: Array<string>;
}
export namespace SearchPanelFields {
    export type TypeEnum = 'STRING' | 'TRAFFICLIGHT' | 'TEXTAREA' | 'SELECT' | 'MULTISELECT' | 'CHECKBOX' | 'RADIO' | 'ADDRESS' | 'DOCUMENTS' | 'IMAGES' | 'LABEL' | 'DATE' | 'HIERARCHY' | 'DIMENSION' | 'NUMBER' | 'LOOKUP' | 'KVMAP' | 'DATA' | 'LOOKUP_LIST' | 'STRINGLIST' | 'APPOINTMENT_LIST';
    export const TypeEnum = {
        String: 'STRING' as TypeEnum,
        Trafficlight: 'TRAFFICLIGHT' as TypeEnum,
        Textarea: 'TEXTAREA' as TypeEnum,
        Select: 'SELECT' as TypeEnum,
        Multiselect: 'MULTISELECT' as TypeEnum,
        Checkbox: 'CHECKBOX' as TypeEnum,
        Radio: 'RADIO' as TypeEnum,
        Address: 'ADDRESS' as TypeEnum,
        Documents: 'DOCUMENTS' as TypeEnum,
        Images: 'IMAGES' as TypeEnum,
        Label: 'LABEL' as TypeEnum,
        Date: 'DATE' as TypeEnum,
        Hierarchy: 'HIERARCHY' as TypeEnum,
        Dimension: 'DIMENSION' as TypeEnum,
        Number: 'NUMBER' as TypeEnum,
        Lookup: 'LOOKUP' as TypeEnum,
        Kvmap: 'KVMAP' as TypeEnum,
        Data: 'DATA' as TypeEnum,
        LookupList: 'LOOKUP_LIST' as TypeEnum,
        Stringlist: 'STRINGLIST' as TypeEnum,
        AppointmentList: 'APPOINTMENT_LIST' as TypeEnum
    };
}


