import { Injectable } from '@angular/core';
import { DataType, ProjectData } from '@frontends/commons';
import { NGXLogger } from 'ngx-logger';
import { ExpTool } from '../../../app/model/ExpTool';
import { Message } from '../../model/Object/Message';

const DELIMITER = '_';
// CONSTANTS KEYS
const MINMAX_KEY = '_MinMax';
const FILTER_KEY = 'filter';
const EXPORT_REQUEST = 'exportRequest';
const EXPORTED_FIELDS = 'exportedFields';
const PRODUCT_TYPE = 'productType';
const TOOL_SITES = 'toolSites';
const HIERARCHY_FILTER = 'hierarchyFilter';
const SELECTED_CHECKLIST_DATA = 'selectedChecklistData';
const PAGE_INDEX = 'pageIndex';
const SAVE_SEARCH = 'saveSearch';
const ID_LIST = 'IDList';
const STRUCTURE = 'structure';
const CURRENT_INDEX = 'CurrentIndex';
const HIERARCHY_TREES = 'HIERARCHY_trees';
const SEARCH_DIALOG_FORM_VALUES = 'SEARCH_dialog_form_values';
const CHAT_MESSAGES = 'chatMessages';
const CHAT_ID = 'chatId';
const PROJECT_LIST = 'projectList';

//TODO [REFACTORING] store values here in service only access browser storage when value changes
@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  constructor(private logger: NGXLogger) {}

  /* ------SEARCH DIALOG FORM VALUES STORAGE START ---------- */
  saveSearchFormValues(formValue: any) {
    this.setItem(SEARCH_DIALOG_FORM_VALUES, JSON.stringify(formValue));
  }
  getSearchFormValues() {
    return JSON.parse(this.getItem(SEARCH_DIALOG_FORM_VALUES));
  }
  deleteSearchFormValues() {
    return sessionStorage.removeItem(SEARCH_DIALOG_FORM_VALUES);
  }
  /* ------SEARCH DIALOG FORM VALUES STORAGE END ---------- */

  /* ------TOOLS STORAGE START ---------- */
  saveTools(tiles: { [key: string]: string }) {
    const expTools: ExpTool = {};
    for (const key in tiles) {
      if (key && key !== '') {
        expTools[key] = tiles[key];
      }
    }
    this.setItem(TOOL_SITES, JSON.stringify(expTools));
  }

  getTools(): ExpTool {
    return JSON.parse(this.getItem(TOOL_SITES));
  }
  setTools(expTool: ExpTool) {
    this.setItem(TOOL_SITES, JSON.stringify(expTool));
  }
  /* ------TOOLS STORAGE END ---------- */

  /* ------- PROJECTS STORAGE START -------- */

  saveProjects(projectList: ProjectData[]) {
    this.setItem(PROJECT_LIST, JSON.stringify(projectList));
  }

  getProjects(): ProjectData[] {
    if (this.getItem(PROJECT_LIST)) {
      return JSON.parse(this.getItem(PROJECT_LIST));
    } else {
      return [];
    }
  }

  /* ------PRODUCT STORAGE START ---------- */

  setProductType(type: string) {
    this.setItem(PRODUCT_TYPE, JSON.stringify(type));
  }

  getProductType(): string {
    return JSON.parse(this.getItem(PRODUCT_TYPE));
  }

  /* ------PRODUCT STORAGE END ---------- */

  /* -----EXPORT STORAGE------- */
  saveExportFields(selectedFields: any, resultDocs: any) {
    this.setItem(EXPORT_REQUEST, JSON.stringify(resultDocs));
    this.setItem(EXPORTED_FIELDS, JSON.stringify(selectedFields));
  }

  /* ------PRODUCT STORAGE START ---------- */

  getExportFields() {
    return this.getItem(EXPORTED_FIELDS) ? JSON.parse(this.getItem(EXPORTED_FIELDS)) : null;
  }

  getExportRequest() {
    return this.getItem(EXPORT_REQUEST) ? JSON.parse(this.getItem(EXPORT_REQUEST)) : {};
  }
  /* -----EXPORT STORAGE END------- */

  /* -------- SEARCH STORAGE ------------- */
  saveSearch(dataType: DataType, searchValue: string): void {
    this.setItem(dataType + DELIMITER + SAVE_SEARCH, JSON.stringify(searchValue));
  }

  getSearch(dataType: DataType): string {
    return JSON.parse(this.getItem(dataType + DELIMITER + SAVE_SEARCH));
  }

  deleteSearch(dataType: DataType): void {
    this.removeItem(dataType + DELIMITER + SAVE_SEARCH);
  }
  /* -------- SEARCH STORAGE END ------------- */

  /* -------- PAGINATION STORAGE ------------- */

  savePageIndex(dataType: string, pageIndex: number): void {
    this.setItem(dataType + DELIMITER + PAGE_INDEX, JSON.stringify(pageIndex));
  }

  getPageIndex(dataType: string): number {
    return JSON.parse(this.getItem(dataType + DELIMITER + PAGE_INDEX));
  }

  deletePageIndex(dataType: string): void {
    this.removeItem(dataType + DELIMITER + PAGE_INDEX);
  }

  /* -------- PAGINATION STORAGE END ------------- */

  /* ------------OBJECT List Handling --------------------- */
  saveObjectIDList(dataType: DataType, objectIdList: string[]) {
    this.setItem(dataType + DELIMITER + ID_LIST, JSON.stringify(objectIdList));
  }

  getObjectIDList(dataType: DataType): Array<string> {
    return JSON.parse(this.getItem(dataType + DELIMITER + ID_LIST));
  }

  saveCurrentObjectIndex(dataType: DataType, index: number) {
    this.setItem(dataType + DELIMITER + CURRENT_INDEX, JSON.stringify(index));
  }

  getCurrentObjectIndex(dataType: DataType): number {
    return Number(this.getItem(dataType + DELIMITER + CURRENT_INDEX));
  }

  /*  ----------- OBJECT List Handling END -----------------------*/

  /* -------- FILTER STORAGE ------------- */
  saveQuickfilterReq(dataType: DataType, quickfilterRequest: any) {
    this.setItem(dataType + DELIMITER + FILTER_KEY, JSON.stringify(quickfilterRequest));
  }

  getQuickFilterReq(dataType: DataType) {
    return JSON.parse(this.getItem(dataType + DELIMITER + FILTER_KEY)) ?? {};
  }

  deleteQuickFilterReq(dataType: DataType): void {
    // this.removeItem(dataType + Filter');
    this.setItem(dataType + DELIMITER + FILTER_KEY, JSON.stringify({}));
  }
  /* ----------- Dimention RANGE FILTER STORAGE (ignored to use in futur) ----------- */
  saveDimRangeFilter(dataType: DataType, key: string, dimRangeFilterRequest: any): void {
    this.setItem(dataType + DELIMITER + key + FILTER_KEY, JSON.stringify(dimRangeFilterRequest));
  }
  getDimRangeFilter(dataType: DataType, key: string): any {
    return JSON.parse(this.getItem(dataType + DELIMITER + key + FILTER_KEY));
  }
  deleteDimRangeFilter(dataType: DataType, key: string): void {
    // this.removeItem(dataType + key + 'Filter');
    this.setItem(dataType + DELIMITER + key + DELIMITER + FILTER_KEY, JSON.stringify({}));
  }

  /* -----------SCHEDULE CRITERIA DATE RANGE FILTER STORAGE  ----------- */
  saveScheduleCriteriaDateRangeFilter(
    dataType: DataType,
    key: string,
    scheduleCriteriaDateRangeFilterRequest: any,
  ): void {
    this.setItem(
      dataType + DELIMITER + key + DELIMITER + FILTER_KEY,
      JSON.stringify(scheduleCriteriaDateRangeFilterRequest),
    );
  }

  getScheduleCriteriaDateRangeFilter(dataType: DataType, key: string): any {
    return JSON.parse(this.getItem(dataType + DELIMITER + key + DELIMITER + FILTER_KEY));
  }

  deleteScheduleCriteriaDateRangeFilter(dataType: DataType, key: string): void {
    // this.removeItem(dataType + key + 'Filter');
    this.setItem(dataType + DELIMITER + key + DELIMITER + FILTER_KEY, JSON.stringify({}));
  }

  saveScheduleCriteriaWithFieldNameForToolbox(
    dataType: DataType,
    key: string,
    scheduleCriteriaWithFieldNameForToolbox: any,
  ): void {
    this.setItem(
      dataType + DELIMITER + key + DELIMITER + FILTER_KEY,
      JSON.stringify(scheduleCriteriaWithFieldNameForToolbox),
    );
  }

  /* ----------- SCHEDULE CRITERIA DATE RANGE FILTER STORAGE END ----------- */

  /* ----------- DATE RANGE FILTER STORAGE  ----------- */
  saveDateRangeFilter(dataType: DataType, key: string, dateRangeFilterRequest: any): void {
    this.setItem(dataType + DELIMITER + key + DELIMITER + FILTER_KEY, JSON.stringify(dateRangeFilterRequest));
  }

  getDateRangeFilter(dataType: DataType, key: string): any {
    return JSON.parse(this.getItem(dataType + DELIMITER + key + DELIMITER + FILTER_KEY));
  }

  deleteDateRangeFilter(dataType: DataType, key: string): void {
    // this.removeItem(dataType + key + 'Filter');
    this.setItem(dataType + DELIMITER + key + DELIMITER + FILTER_KEY, JSON.stringify({}));
  }

  /* ----------- DATE RANGE FILTER STORAGE END ----------- */

  /* ----------- MIN MAX FILTER STORAGE  ----------- */
  saveMinMaxFilters(dataType: DataType, key: string, minMaxFilters: any): void {
    this.setItem(dataType + DELIMITER + key + DELIMITER + FILTER_KEY, JSON.stringify(minMaxFilters));
  }

  getMinMaxFilters(dataType: DataType, key: string): any {
    return JSON.parse(this.getItem(dataType + DELIMITER + key + DELIMITER + FILTER_KEY)) ?? {};
  }

  deleteMinMaxFilters(dataType: DataType, key: string): void {
    this.setItem(dataType + DELIMITER + key + DELIMITER + FILTER_KEY, JSON.stringify({}));
  }

  /* ----------- DATE RANGE FILTER STORAGE END ----------- */

  /* -------- HIERARCHY STRUCTURE STORAGE START ------------- */
  getHierarchyStructure(hierarchyKey: string) {
    return JSON.parse(this.getItem(hierarchyKey + DELIMITER + STRUCTURE));
  }
  setHierarchyStructure(hierarchyKey: string, hierarchyObject: string) {
    this.setItem(hierarchyKey + DELIMITER + STRUCTURE, JSON.stringify(hierarchyObject));
  }
  /* -------- HIERARCHY STRUCTURE STORAGE END ------------- */

  /* ---------- HIERARCHY FILTER STORAGE ------------ */
  saveHierarchyQueryReq(dataType: DataType, hierarchyKey: string, hierarchyFilterRequest: any) {
    const requestMap = this.getHierarchyQueryReq(dataType);
    if (hierarchyFilterRequest === '') {
      delete requestMap[hierarchyKey];
    } else {
      requestMap[hierarchyKey] = hierarchyFilterRequest;
    }
    this.setItem(dataType + DELIMITER + HIERARCHY_FILTER, JSON.stringify(requestMap));
  }

  getHierarchyQueryReq(dataType: DataType): { [key: string]: string } {
    return JSON.parse(this.getItem(dataType + DELIMITER + HIERARCHY_FILTER))
      ? JSON.parse(this.getItem(dataType + DELIMITER + HIERARCHY_FILTER))
      : {};
  }
  deleteHierarchyQuery(dataType: DataType): void {
    const hierarchyQuery = this.getHierarchyQueryReq(dataType);

    if (Object.keys(hierarchyQuery).length > 0) {
      sessionStorage.removeItem(dataType + DELIMITER + HIERARCHY_FILTER);
    }
  }

  saveSelectedChecklistData(dataType: DataType, hierarchyKey: string, selectedChecklistData: any) {
    const storedDataString = sessionStorage.getItem(dataType + DELIMITER + HIERARCHY_TREES);
    const storedData = storedDataString ? JSON.parse(storedDataString) : {};
    storedData[dataType + DELIMITER + hierarchyKey + DELIMITER + SELECTED_CHECKLIST_DATA] = selectedChecklistData;
    sessionStorage.setItem(dataType + DELIMITER + HIERARCHY_TREES, JSON.stringify(storedData));
  }

  getSelectedChecklistData(dataType: DataType, hierarchyKey: string) {
    const storedDataString = JSON.parse(sessionStorage.getItem(dataType + DELIMITER + HIERARCHY_TREES) ?? 'null');
    return storedDataString
      ? storedDataString[dataType + DELIMITER + hierarchyKey + DELIMITER + SELECTED_CHECKLIST_DATA]
      : [];
  }

  deleteHierarchyFilterByDataType(dataType: DataType) {
    sessionStorage.removeItem(dataType + DELIMITER + HIERARCHY_TREES);
  }

  getAllSelectedChecklistData(dataType: DataType) {
    const storedDataString = sessionStorage.getItem(dataType + DELIMITER + HIERARCHY_TREES);
    return storedDataString ? JSON.parse(storedDataString) : {};
  }

  /* ---------- HIERARCHY FILTER STORAGE END ------------ */
  /* ---------- RESET FILTER STORAGE ------------ */
  clearSessionStorage() {
    sessionStorage.clear();
  }

  resetStorageOnProjectChange() {
    const toolSites = this.getTools();
    const projectList = this.getProjects();
    this.clearSessionStorage();
    this.setTools(toolSites);
    this.saveProjects(projectList);
  }

  resetFilters(dataType: DataType) {
    this.deleteHierarchyFilterByDataType(dataType);
    this.deleteHierarchyQuery(dataType);
    this.deleteQuickFilterReq(dataType);
    this.deleteMinMaxFilters(dataType, MINMAX_KEY);
  }
  /* ---------- RESET FILTER STORAGE END ------------ */
  /* ---------- GET FILTER LENGTH ------------ */
  getFilterLength(dataType: DataType): number {
    return (
      Object.keys(this.getQuickFilterReq(dataType) || 0).length +
      Object.keys(this.getHierarchyQueryReq(dataType) || 0).length +
      Object.keys(this.getMinMaxFilters(dataType, MINMAX_KEY) || 0).length
    );
  }
  /* ---------- GET FILTER LENGTH END ------------ */
  /* ---------- CHECK FILTER STORAGE ------------ */
  checkFilters(dataType: DataType): boolean {
    const hierarchyFilter = this.getAllSelectedChecklistData(dataType);
    let hasValueInAtLeastOneHierarchyFilter = false;
    for (const key in hierarchyFilter) {
      if (Object.hasOwn(hierarchyFilter, key) && hierarchyFilter[key].length) {
        hasValueInAtLeastOneHierarchyFilter = true;
        break;
      }
    }
    const quickFilter = this.getQuickFilterReq(dataType);
    const isHierarchyFilterSelected = hasValueInAtLeastOneHierarchyFilter;
    const minMaxFilter = this.getMinMaxFilters(dataType, MINMAX_KEY);
    return (
      (quickFilter && Object.keys(quickFilter).length > 0) ||
      isHierarchyFilterSelected ||
      (minMaxFilter && Object.keys(minMaxFilter).length > 0)
    );
  }
  /* ---------- CHECK FILTER STORAGE END ------------ */

  /* -------- FILTER STORAGE END ------------- */

  /* -------- CHAT MESSAGES STORAGE ------------- */
  saveChatMessages(messages: Message[], chatID: string): void {
    this.setItem(CHAT_MESSAGES, JSON.stringify(messages));
    this.setItem(CHAT_ID, JSON.stringify(chatID));
  }
  getChatMessages(): { messages: Message[]; chatID: string } {
    const storedMessages = this.getItem(CHAT_MESSAGES);
    const chatID = this.getItem(CHAT_ID) || ''; // default to empty string if not found

    return {
      messages: storedMessages ? JSON.parse(storedMessages) : [],
      chatID,
    };
  }

  clearChatMessages(): void {
    this.removeItem(CHAT_MESSAGES);
    this.removeItem(CHAT_ID); // You probably want to clear the chatID as well when clearing messages
  }
  /* -------- CHAT MESSAGES STORAGE END ------------- */

  /*
   * Private wrapper methods to crud sessionstorage items
   * Methods are private to enforce, that each item has its own crud methods
   * Also these wrappers provide logging
   */

  private setItem(key: string, item: string): void {
    this.logger.trace(`[SessionStorage]: SET ITEM: ${key}`, JSON.parse(item));
    sessionStorage.setItem(key, item);
  }

  private getItem(key: string): string {
    const item = sessionStorage.getItem(key) as any;
    try {
      JSON.parse(item);
    } catch (error) {
      this.logger.warn(`[SessionStorage]: UNABLE TO PARSE ITEM FOR: ${key} `);
      return JSON.stringify(item);
    }
    this.logger.trace(`[SessionStorage]: FETCH ITEM: ${key}`, JSON.parse(item));
    return item;
  }

  private removeItem(key: string): void {
    this.logger.trace(`[SessionStorage]: REMOVE ITEM: ${key}`);
    sessionStorage.removeItem(key);
  }
}
