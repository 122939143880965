import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ButtonsModule, DialogsModule, DividerModule, InputFieldsModule } from '@frontends/designsystem';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BaseAuthService, SnackBarService } from '../../../services';

import { logger } from 'nx/src/utils/logger';
import { BaseDialogComponent } from '../../../base-components/base-dialog/base-dialog.component';
import { SnackbarType } from '../../../model/Enums/snackBarTypeEnum';

@Component({
  selector: 'cmn-password-change-dialog',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
    ButtonsModule,
    InputFieldsModule,
    DividerModule,
    TranslateModule,
    DialogsModule,
  ],
  providers: [TranslateService],
  templateUrl: './password-change-dialog.component.html',
  styleUrls: ['./password-change-dialog.component.scss'],
})
export class PasswordChangeDialogComponent extends BaseDialogComponent {
  public oldPassword!: string;
  public newPassword!: string;
  public newPasswordRepeat!: string;
  public showError = true;
  public matchError = false;
  /*
  At least one upper case English letter, (?=.*?[A-Z])
  At least one lower case English letter, (?=.*?[a-z])
  At least one digit, (?=.*?[0-9])
  At least one special character, (?=.*?[#?!@$%^&*-])
  Minimum eight in length .{8,} (with the anchors) */
  public passwordRegex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$');

  constructor(
    protected override dialogRef: MatDialogRef<PasswordChangeDialogComponent>,
    private authService: BaseAuthService,
    private snackbarService: SnackBarService,
    private translationService: TranslateService,
  ) {
    super(dialogRef);
  }

  public validate(password: string, passwordRepeat: string) {
    if (password === '' || !password.match(this.passwordRegex) || password.length < 8) {
      {
        this.showError = true;
      }
    } else {
      {
        this.showError = false;
      }
    }
    this.confirm(password, passwordRepeat);
  }

  public confirm(password: string, repeat: string) {
    if (password !== repeat) {
      {
        this.matchError = true;
      }
    } else {
      {
        this.matchError = false;
        this.newPassword = password;
        this.newPasswordRepeat = repeat;
      }
    }
  }

  public changePassword(): void {
    if (
      this.newPassword?.localeCompare(this.newPasswordRepeat) === 0 &&
      this.newPasswordRepeat?.length >= 8 &&
      this.newPassword.match(this.passwordRegex)
    ) {
      const passChangeRequest = {
        oldPass: this.oldPassword,
        newPass: this.newPassword,
        newPass2: this.newPasswordRepeat,
      };
      this.authService.updatePassword(passChangeRequest).subscribe(
        (response) => {
          if (response) {
            this.snackbarService.openSnackbar(
              this.translationService.instant(`SNACKBAR.passwordChanged`),
              SnackbarType.SUCCESS,
            );
            this.dialogRef.close();
          }
        },
        (error) => {
          logger.error(error);
          this.alertService.showRedirectConfirmationDialog(
            this.translationService.instant(`MESSAGE.ERROR_MESSAGE.somethingWentWrong`),
            this.translationService.instant(`SNACKBAR.wrongOldPassword`),
            true,
            '',
            this.translationService.instant(`BUTTONS.close`),
            '',
          );
        },
      );
    }
  }
}
