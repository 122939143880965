import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService, BaseDialogComponent, ObjectData } from '@frontends/commons';
import { NGXLogger } from 'ngx-logger';
import { filter, take } from 'rxjs';
import { AlertService } from '../../../app/services/alert.service';
import { DataType } from '../../model/types/DataTypes';

import { FormManagerService } from '../../services/form/form-manager.service';
import { LocalStorageService } from '../../services/storage/local-storage.service';
import { BatchEditService } from '../../services/utils/batch-edit/batch-edit.service';
import { BatchEditSummaryDialogComponent } from '../batch-edit-summary-dialog/batch-edit-summary-dialog.component';

@Component({
  selector: 'exp-batch-edit-dialog',
  templateUrl: './batch-edit-dialog.component.html',
  styleUrls: ['./batch-edit-dialog.component.scss'],
  providers: [FormManagerService],
})
export class BatchEditDialogComponent extends BaseDialogComponent implements OnInit {
  public isFormTouched = false;
  public dataType!: DataType;
  public projectIdentifier: string;
  public selectedObjects: ObjectData[];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected override dialogRef: MatDialogRef<BatchEditDialogComponent>,
    public dialog: MatDialog,
    public authService: AuthService,
    public formManager: FormManagerService,
    private logger: NGXLogger,
    private localStorageService: LocalStorageService,
    private batchEditService: BatchEditService,
    private alert: AlertService,
  ) {
    super(dialogRef);
    this.projectIdentifier = this.localStorageService.getProject()?.project || '';
    this.selectedObjects = this.batchEditService.getSelectedObjects();
  }

  ngOnInit(): void {
    this.initializer();
    this.formManager.formTouched$.subscribe((touched) => {
      if (touched) {
        this.isFormTouched = touched;
      }
    });
    this.logger.debug('[BATCH-EDIT-DIALOG]: INITIALIZED', this);
  }

  private initializer(): void {
    this.dataType = this.data.dataType;
    this.formManager.prepareFormData(this.projectIdentifier, this.dataType, '');
    this.formManager.loading$.subscribe((loading) => {
      if (!loading) {
        this.dialogRef.updateSize('760px', '90vh');
        this.logger.debug(`[BATCH-EDIT-DIALOG]: FROM MANAGER PREPARED DATA`);
      }
    });
  }

  public override onModalClose(): void {
    if (!this.isFormTouched) {
      return this.dialogRef.close();
    }

    const alertDialogRef = this.alert.confirmDiscardDialogChanges();
    alertDialogRef.componentInstance.save.pipe(take(1)).subscribe((isCanDiscard) => {
      if (isCanDiscard) {
        this.dialogRef.close();
      }
      alertDialogRef.close();
    });
  }

  public openSummaryDialog(): void {
    this.batchEditService.setFieldNames(this.formManager.getFieldNames());
    const ref = this.dialog.open(BatchEditSummaryDialogComponent, {
      width: '762px',
      maxHeight: '90vh',
      data: {
        dataType: this.dataType,
        optionPathArray: this.batchEditService.getOptionPathArray(),
        formValues: this.batchEditService.getFormValues(),
      },
    });
    ref.afterClosed().subscribe((result) => {
      if (result) {
        this.dialogRef.close(true);
      }
    });
  }
}
