import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TranslateHelpService {
  constructor(private translateService: TranslateService) {}

  /**
   *
   * currently the Translateservice doesnt support a proper way to check if a given translation Key has a translation value
   *
   * @param translationKey
   * @param language
   * @returns boolean whether translationKey is found in language json files
   */
  public hasTranslation(translationKey: string, language?: string): boolean {
    const translation = this.translateService.translations[language || this.translateService.currentLang];
    const value = translationKey.split('.').reduce((translation, k) => translation && translation[k], translation);
    return value !== undefined;
  }

  public translate(key: string, action?: string): string {
    let params: any;
    if (action) {
      params = { action: this.translate('ACTION.' + action) };
    }
    return this.translateService.instant('MESSAGE.' + key, params);
  }
}
