import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SearchfieldComponent } from './searchfield.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [SearchfieldComponent],
  imports: [CommonModule, MatInputModule, MatIconModule, MatButtonModule, FormsModule],
  exports: [SearchfieldComponent],
})
export class SearchfieldModule {}
