import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ds-searchfield',
  templateUrl: './searchfield.component.html',
  styleUrls: ['./searchfield.component.scss'],
})
export class SearchfieldComponent {
  @Input() ariaLabel = '';
  @Input() placeholder = '';
  @Input() value = '';
  @Input() colorBorder: 'primary' | 'accent' = 'primary';
  @Output() submitSearching = new EventEmitter<string>();
  @Output() submitClear = new EventEmitter<string>();
  startSearching() {
    this.submitSearching.emit(this.value);
  }
  clear() {
    this.value = '';
    this.submitClear.emit('');
    this.startSearching();
  }
}
