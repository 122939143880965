import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GenericFormField } from '@frontends/commons';
import { FormGroup } from '@angular/forms';
import { ChartOptions } from '../../../model/Object/chart';

@Component({
  selector: 'exp-kv-form-group',
  templateUrl: './kv-form-group.component.html',
  styleUrl: './kv-form-group.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KVFormGroupComponent {
  @Input() public formGroup!: FormGroup;
  @Input() public field!: GenericFormField;
  @Input() public internal!: boolean;
  @Input() public required!: boolean;
  @Input() public errorMessage!: string;
  @Input() public showError!: boolean;
  @Input() public graphOptions!: ChartOptions;
}
