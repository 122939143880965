<div class="ai-dialog-wrapper">
  <div class="ai-dialog-header">
    <h2 mat-dialog-title>{{ 'CHATDIALOG.title' | translate }} </h2>
    <ds-icon-button class="close-button" color="primary" icon="close" (click)="onClose()"/>
  </div>
  <ds-horizontal-divider [inset]="false"/>
  <div mat-dialog-content class="chat-history" #chatHistory>
    <div *ngFor="let message of messages">
      <div class="my-message" *ngIf="message.role === 'user'">
        <p>{{ message.content }}</p>
      </div>
      <div class="ai-message" *ngIf="message.role === 'assistant' && message.content">
        <p *ngIf="message.content">{{ message.content }}</p>
      </div>
      <div class="ai-message" *ngIf="message.function_call">
        <p>
          {{ 'CHATDIALOG.search_marker' | translate }}
          <a href="javascript:void(0)" (click)="onClickSearch(message)">
            {{ extractSuchanfrage(message) }}
          </a>
        </p>
      </div>
    </div>
  </div>
  <ds-horizontal-divider [inset]="false"/>
  <div mat-dialog-actions>
    <form (ngSubmit)="sendMessage()" class="form">
      <ds-input
        [label]="'CHATDIALOG.search' | translate"
        [placeholder]="'CHATDIALOG.placeholder' | translate"
        tooltip="Confidential"
        iconClass="security"
        [value]="newMessage"
        (change)="updateNewMessage($event)"/>
      <ds-icon-button color="accent" icon="send" (click)="onSubmit()"/>
      <ds-icon-button color="warn" icon="restart_alt" (click)="resetMessages()"/>
    </form>
  </div>  
</div>
