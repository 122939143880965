import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';
import { filter, take } from 'rxjs';
import { AlertService } from '../../services/alert/alert.service';

@Component({
  standalone: true,
  imports: [CommonModule],
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseDialogComponent {
  protected alertService = inject(AlertService);

  constructor(protected dialogRef: MatDialogRef<any>) {
    this.setupCustomDialogOnCloseEvent();
  }

  private setupCustomDialogOnCloseEvent() {
    this.dialogRef.disableClose = true;
    this.dialogRef.backdropClick().subscribe(() => this.onModalClose());
    this.dialogRef
      .keydownEvents()
      .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
      .subscribe(() => this.onModalClose());
  }

  protected shouldDisplayConfirmation() {
    return false;
  }

  protected onModalClose() {
    if (!this.shouldDisplayConfirmation()) {
      return this.dialogRef.close();
    }

    const alertDialogRef = this.alertService.confirmDiscardDialogChanges();
    alertDialogRef.componentInstance.save.pipe(take(1)).subscribe((isCanDiscard: boolean) => {
      if (isCanDiscard) {
        this.dialogRef.close();
      }
      alertDialogRef.close();
    });
  }
}
