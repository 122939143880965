import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataType, SearchFields } from '@frontends/commons';
import { FormManagerService } from '../../services/form/form-manager.service';
import { SessionStorageService } from '../../services/storage/session-storage.service';

export interface SearchPanelResponse {
  fields: SearchFields[];
  dataType?: DataType;
}

interface Control {
  inputControl: string;
  selectControl: string;
}
interface FormValues {
  searchFieldsRow: Control[];
  operator: 'AND' | 'OR';
}
@Component({
  selector: 'exp-search-dialog',
  templateUrl: './search-dialog.component.html',
  styleUrls: ['./search-dialog.component.scss'],
  providers: [FormManagerService],
})
export class SearchDialogComponent implements OnInit {
  public formGroup: FormGroup;
  public fieldNames!: string[];
  public showOperatorsSelect = false;

  private dataType!: DataType;
  private selectOptions!: SearchFields[];
  private searchQuery = '';
  private storedFormData: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SearchPanelResponse,
    private sessionStorageService: SessionStorageService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<SearchDialogComponent>
  ) {
    this.storedFormData = this.sessionStorageService.getSearchFormValues();
    this.formGroup = this.formBuilder.group({
      searchFieldsRow: this.formBuilder.array([]),
      operator: ['AND'],
    });

    if (data?.fields && data.fields[0].name) {
      this.selectOptions = data.fields;
      this.fieldNames = [];
      data.fields.forEach(({ name }) => {
        if (name) {
          this.fieldNames.push(name);
        }
      });
    }
    if (data?.dataType) {
      this.dataType = data.dataType;
    }
  }

  ngOnInit(): void {
    this.initializeFormWithStoredValues();
    this.showOperatorsSelect = this.formDataRows?.controls?.length >= 2;
    const operatorControl = this.formGroup.get('operator');
    if (this.showOperatorsSelect && operatorControl) {
      operatorControl.setValue(this.storedFormData.operator);
    }
  }

  private createSearchField(): FormGroup {
    return this.formBuilder.group({
      selectControl: [null],
      inputControl: [null],
    });
  }

  get userHasChangedSearchField(): boolean {
    const searchValue = this.sessionStorageService?.getSearch(this.dataType) || '';
    const savedSearchFormValues: FormValues = this.sessionStorageService?.getSearchFormValues();

    if (searchValue && savedSearchFormValues && savedSearchFormValues?.searchFieldsRow) {
      const formSearchQuery = savedSearchFormValues.searchFieldsRow
        .map((row) => {
          if (!row.selectControl || Array.isArray(row.selectControl) || !row.inputControl || row.inputControl === '') {
            return '';
          } else {
            return `${this.getPathByName(row.selectControl)}: (${row.inputControl})`;
          }
        })
        .filter((query) => query !== '');
      const logicalOperator = savedSearchFormValues.operator || '';
      const currentSearchQuery = formSearchQuery.join(` ${logicalOperator} `);

      return searchValue !== currentSearchQuery;
    }

    return false;
  }

  public addSearchField(): void {
    const rows = this.formGroup.get('searchFieldsRow') as FormArray;
    rows.push(this.createSearchField());
    this.showOperatorsSelect = this.formDataRows.controls.length >= 2;
  }

  public removeSearchField(index: number): void {
    const rows = this.formGroup.get('searchFieldsRow') as FormArray;
    rows.removeAt(index);
    this.showOperatorsSelect = this.formDataRows.controls.length >= 2;
  }
  get formDataRows(): FormArray {
    return this.formGroup.get('searchFieldsRow') as FormArray;
  }
  get formOperator(): FormArray {
    return this.formGroup.get('operator') as FormArray;
  }

  private getPathByName(selectControl: string): string {
    const matchingOption = this.selectOptions.find((option) => option.name === selectControl);
    return matchingOption?.path ? matchingOption.path : '';
  }

  public submitForm(): void {
    const formValue = this.formGroup.value;
    const logicalOperator = this.formOperator.value;
    const queryParts = formValue.searchFieldsRow
      .map((row: Control) => {
        if (!row.selectControl || Array.isArray(row.selectControl) || !row.inputControl || row.inputControl === '') {
          return '';
        } else {
          return `${this.getPathByName(row.selectControl)}: (${row.inputControl})`;
        }
      })
      .filter((queryPart: string) => queryPart.length > 0);
    this.sessionStorageService.saveSearchFormValues(formValue);

    if (this.formDataRows.controls.length >= 2) {
      this.searchQuery = queryParts.join(' ' + logicalOperator + ' ');
    } else {
      this.searchQuery = queryParts.join('');
    }

    this.saveSearch(this.searchQuery);
    this.dialogRef.close(this.searchQuery);
  }

  private saveSearch(searchQuery: string): void {
    this.sessionStorageService.saveSearch(this.dataType, searchQuery);
  }

  public getSelectControl(rowIndex: number): AbstractControl | null {
    const searchFieldsRow = this.formGroup.get('searchFieldsRow');

    if (searchFieldsRow !== null) {
      const rowControl = searchFieldsRow.get(rowIndex.toString());

      if (rowControl !== null) {
        return rowControl.get('selectControl');
      }
    }
    return null;
  }

  private initializeFormWithStoredValues(): void {
    if (this.storedFormData?.searchFieldsRow) {
      this.storedFormData.searchFieldsRow.forEach((row: Control) => {
        this.addSearchFields(row);
      });
    } else {
      this.addSearchField();
    }
  }

  private addSearchFields(data?: Control): void {
    const row = this.formBuilder.group({
      selectControl: [data ? data.selectControl : null],
      inputControl: [data ? data.inputControl : null],
    });
    this.formDataRows.push(row);
  }
}
