import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseButtonComponent } from '../../Base/base-button.component';

@Component({
  selector: 'ds-dialog-actions',
  templateUrl: './dialog-actions.component.html',
  styleUrls: ['./dialog-actions.component.scss'],
})
export class DialogActionsComponent extends BaseButtonComponent {
  @Input() closeBtnLabel!: string;
  @Input() submitBtnLabel!: string;
  @Input() isSubmitDisabled = false;
  @Output() closeBtnClicked = new EventEmitter<void>();

  public onCloseBtnClicked() {
    this.closeBtnClicked.emit();
  }
}
