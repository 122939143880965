import { Component, Injector } from '@angular/core';

import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ButtonsModule, DividerModule } from '@frontends/designsystem';
import { TranslateModule } from '@ngx-translate/core';
import { OverlayInteractionService } from '../../../services';

@Component({
  selector: 'cmn-loading-overlay',
  templateUrl: './file-uploading-overlay.component.html',
  styleUrls: ['./file-uploading-overlay.component.scss'],
  standalone: true,
  imports: [ButtonsModule, TranslateModule, MatProgressBarModule, DividerModule]
})
export class FileUploadingOverlayComponent {
  public message = '';

  constructor(
    private overlayInteractionService: OverlayInteractionService,
    private injector: Injector
  ) {
    this.message = this.injector.get('message');
  }

  public hideTheOverlay(): void {
    this.overlayInteractionService.closeBtnClicked();
  }
}
