/**
 * Explanner Service Documentation
 * Explanner Service Documentation
 *
 * The version of the OpenAPI document: 1.7_landesstelle{20240611@153537}
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MenueData } from './menueData';
import { ProjectData } from './projectData';

export interface InitData {
  type?: InitData.TypeEnum;
  projects?: Array<ProjectData>;
  tiles?: { [key: string]: string };
  permissions?: { [key: string]: { [key: string]: MenueData } };
  language?: string;
  userId?: string;
  firstname?: string;
  lastname?: string;
  email?: string;
}

export namespace InitData {
  export type TypeEnum = 'EXPLANNER' | 'TOOLBOX' | 'ROOMS' | 'GALAXY' | 'ASSETS' | 'MUSDB';
  export const TypeEnum = {
    Explanner: 'EXPLANNER' as TypeEnum,
    Toolbox: 'TOOLBOX' as TypeEnum,
    Rooms: 'ROOMS' as TypeEnum,
    Galaxy: 'GALAXY' as TypeEnum,
    Assets: 'ASSETS' as TypeEnum,
    Musdb: 'MUSDB' as TypeEnum,
  };
}
