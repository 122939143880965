import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService, GenericFormField } from '@frontends/commons';
import { ReservationDialogComponent } from '../../dialogs/reservation-dialog/reservation-dialog.component';
import { dateFormats } from '../../model/DateFormats';
import { Appointment } from '../../model/Object/appointment';
import { ReservationDialogValues } from '../../model/Object/dialog-values';
import { DataType } from '../../model/types/DataTypes';
import { AlertService } from '../../services/alert.service';
import { TranslateHelpService } from '../../services/translate-help.service';
import { getTrafficLightLabelFromColor } from '../../utils/helperFunctions';

@Component({
  selector: 'exp-appointments-list',
  templateUrl: './appointments-list.component.html',
  styleUrls: ['./appointments-list.component.scss'],
})
export class AppointmentListComponent implements OnInit {
  @Input() dataType!: DataType;
  @Input() field!: GenericFormField;
  @Input() fieldName!: string;
  @Input() appointments!: Appointment[];
  @Output() selectionChange = new EventEmitter<any>();

  formGroup: FormGroup;
  appointmentFields: FormArray;

  private invalidDateKey = 'Invalid Date';

  constructor(
    private translationHelp: TranslateHelpService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    public authService: AuthService
  ) {
    this.formGroup = this.formBuilder.group({
      reservation: this.formBuilder.array([]),
    });
    this.appointmentFields = this.formGroup.get('reservation') as FormArray;
  }

  ngOnInit() {
    this.initializedForm();
    this.submitChangesInForm();
  }

  submitChangesInForm() {
    this.formGroup.controls['reservation'].valueChanges.subscribe((value) => {
      this.selectionChange.emit(value);
    });
  }

  private initializedForm(): void {
    const appointments = this.appointments;
    if (appointments && appointments.length) {
      appointments.forEach(({ from, to, name, status }) => {
        const appointmentFormGroup = this.formBuilder.group({
          from,
          to,
          name,
          status,
        });

        this.appointmentFields.push(appointmentFormGroup);
      });
      this.sortAppointmentsArrayByDate();
    }
  }

  public convertStampDateToString(stampDate: number | string | Date): string {
    const isInvalidJsDate = stampDate instanceof Date && stampDate.toString() === this.invalidDateKey;
    const isInvalidStringDate = !stampDate || stampDate === this.invalidDateKey || stampDate === 'no date';
    const isInvalidNumberDate = typeof stampDate === 'number' && isNaN(stampDate);
    if (isInvalidJsDate || isInvalidStringDate || isInvalidNumberDate) {
      return '';
    } else {
      return formatDate(stampDate, dateFormats.dayMonthPointFormat, 'en-US');
    }
  }

  public getLabelFromColor(statusColor: string) {
    return getTrafficLightLabelFromColor(statusColor);
  }

  public removeAppointment(reservationName: string, index: number) {
    const dialogRef = this.alertService.deleteAppointmentConfirmationDialog(
      '',
      `${reservationName} ${this.translationHelp.translate('remove')} ?`,
      false,
      this.appointmentFields,
      index,
      this.translationHelp.translate('DELETE_MESSAGE.removeYes'),
      this.translationHelp.translate('cancel')
    );
    if (dialogRef) {
      dialogRef.afterClosed().subscribe((result: ReservationDialogValues) => {
        if (!result) {
          return;
        }
        this.addNewAppointment(this.getAppointmentFromDialogResult(result));
      });
    }
  }

  public openAddDialog(): void {
    const dialogRef = this.dialog.open(ReservationDialogComponent, {
      width: '645px',
      data: { title: 'BUTTONS.addReservation', reservations: this.appointmentFields.value },
    });

    dialogRef.afterClosed().subscribe((result: ReservationDialogValues) => {
      if (!result) {
        return;
      }
      this.addNewAppointment(this.getAppointmentFromDialogResult(result));
    });
  }

  private addNewAppointment(appointment: Appointment): void {
    const newAppointmentFormGroup = this.formBuilder.group(appointment);
    this.appointmentFields.push(newAppointmentFormGroup);
    this.sortAppointmentsArrayByDate();
  }

  private sortAppointmentsArrayByDate(): void {
    this.appointmentFields.controls.sort((a, b) => b.value.from - a.value.from);
  }

  private getAppointmentFromDialogResult(dialogValues: ReservationDialogValues): Appointment {
    return {
      from: new Date(dialogValues.range.min).getTime(),
      to: new Date(dialogValues.range.max).getTime(),
      name: dialogValues.title,
      status: dialogValues.status,
    };
  }

  public openEditDialog(row: any, index: number): void {
    const reservations = [...this.appointmentFields.value];
    reservations.splice(index, 1);

    const dialogRef = this.dialog.open(ReservationDialogComponent, {
      data: {
        title: 'TITLES.editReservation',
        ...row.value,
        reservations,
      },
      width: '645px',
    });

    dialogRef.afterClosed().subscribe((result: ReservationDialogValues) => {
      if (!result) {
        return;
      }
      this.appointmentFields.at(index).patchValue(this.getAppointmentFromDialogResult(result));
    });
  }
}
