/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import {
  AbstractControlOptions,
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  FormControlState,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { GenericFormField, ObjectData, PathAdditions } from '@frontends/commons';
import { NGXLogger } from 'ngx-logger';
import { FieldTypes } from '../../model/Enums/FieldTypesEnum';
import { decimalValidator } from './validators/decimal.validator';
import { UniqueFieldValidator } from './validators/unique.validator';

@Injectable({
  providedIn: 'root',
})
export class FormGroupBuilderService {
  pathAdditions: PathAdditions = new PathAdditions();

  constructor(
    private formBuilder: FormBuilder,
    private uniqueValidator: UniqueFieldValidator,
    private logger: NGXLogger,
  ) {}

  public createFormGroup(fields: GenericFormField[], object: ObjectData, userHasWritePermission: boolean): FormGroup {
    const fGroup: any = {};

    fields.forEach((field) => {
      if (field.type === 'LABEL') {
        return;
      }

      if (field.type === FieldTypes.HIERARCHY) {
        const hierGroup: any = this.createHierarchyGroup(field, object, userHasWritePermission);

        fGroup[field.path] = this.formBuilder.group(hierGroup);
        return;
      } else {
        if (field.type === FieldTypes.DIMENSION) {
          this.addPathAdditionFormControl(
            fGroup,
            field,
            object,
            this.pathAdditions.dimensionPathAddition,
            userHasWritePermission,
          );
          return;
        }
        if (field.type === FieldTypes.ADDRESS) {
          this.addPathAdditionFormControl(
            fGroup,
            field,
            object,
            this.pathAdditions.addressPathAddition,
            userHasWritePermission,
          );
          return;
        }

        if (field.type === FieldTypes.KVMAP) {
          const controls = this.createKVMapControls(field, object, userHasWritePermission);
          fGroup[field.path] = new FormGroup(controls);
          return;
        }

        fGroup[field.path] = this.createFormControl(field, object, userHasWritePermission);
      }
    });
    this.logger.debug(`[FORMBUILDER]: FORM GROUP: `, fGroup);
    return this.formBuilder.group(fGroup);
  }

  private createKVMapControls(field: GenericFormField, object: ObjectData, userHasWritePermission: boolean) {
    return field.options.reduce((acc, option) => {
      const storedValues = object[field.path] || null;
      const valueOption: FormControlState<any> = {
        value: storedValues?.[option],
        disabled: this.isFieldDisabled(field, userHasWritePermission),
      };
      acc[option] = new FormControl(valueOption);
      return acc;
    }, {} as any);
  }

  private createHierarchyGroup(field: GenericFormField, object: ObjectData, userHasWritePermission: boolean) {
    const hierGroup: any = {};
    const depth = this.getTreeDepth(field.hierarchy);

    for (let i = 1; i <= depth; i++) {
      const controlName = 'l' + i;
      const isRequiredOnFirstIteration = field.required && i === 1;

      hierGroup[controlName] = new FormControl(
        {
          value: object[field.path] ? object[field.path][controlName] : undefined,
          disabled: this.isFieldDisabled(field, userHasWritePermission),
        },
        isRequiredOnFirstIteration ? Validators.required : null,
      );
    }
    return hierGroup;
  }

  private createFormControl(field: GenericFormField, object: ObjectData, userHasWritePermission: boolean): FormControl {
    const valueOptions: FormControlState<any> = {
      value: field.type === 'DATE' ? this.parseDate(object[field.path]) : object[field.path],
      disabled: this.isFieldDisabled(field, userHasWritePermission),
    };

    const validatorOptions = this.getValidatorOptions(field);

    return new FormControl(valueOptions, validatorOptions);
  }

  private parseDate(date: string): Date | undefined {
    if (date) {
      const [day, month, year] = date.split('.');
      return new Date(+year, +month - 1, +day);
    }

    return undefined;
  }

  private getValidatorOptions(field: GenericFormField): AbstractControlOptions {
    const validators: ValidatorFn[] = [];
    const asyncValidators: AsyncValidatorFn[] = [];

    if (field.required) {
      validators.push(Validators.required);
    }

    if (field.path && field.path.includes('dimension')) {
      validators.push(decimalValidator());
    }

    if (field.unique) {
      asyncValidators.push(this.uniqueValidator.validate.bind(this.uniqueValidator));
    }

    return {
      validators: validators.length ? validators : null,
      asyncValidators: asyncValidators.length ? asyncValidators : null,
      updateOn: asyncValidators.length ? 'blur' : 'change',
    };
  }

  private addPathAdditionFormControl(
    fGroup: any,
    field: GenericFormField,
    object: ObjectData,
    pathAdditions: string[],
    userHasWritePermission: boolean,
  ) {
    pathAdditions.forEach((pathAddition) => {
      fGroup[field.path + '_' + pathAddition] = new FormControl(
        {
          value: object[field.path + '_' + pathAddition],
          disabled: this.isFieldDisabled(field, userHasWritePermission),
        },
        this.getValidatorOptions(field),
      );
    });
  }

  private isFieldDisabled(field: GenericFormField, userHasWritePermission: boolean): boolean {
    return field.disabled || !userHasWritePermission;
  }

  private getTreeDepth(hierarchy: any, depth: number = 0): number {
    if (typeof hierarchy !== 'object' || hierarchy === null) {
      return depth;
    }

    let max = depth;
    for (const key in hierarchy) {
      if (Object.hasOwn(hierarchy, key)) {
        max = Math.max(max, this.getTreeDepth(hierarchy[key], depth + 1));
      }
    }

    return max;
  }
}
